import React, { CSSProperties } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";
import i18next from "i18next";
// mui start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// mui end

import * as style from "../survey/common.style";

import { get } from "lodash";
import { CustomType, getI18nData } from "../survey/survey.util";

const CUSTOM_TYPE = CustomType.Boolean;

export class QuestionBooleanModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  // get, set for custom props in survey-json
  get labelTrue(): string {
    return this.getPropertyValue("labelTrue");
  }
  set labelTrue(val) {
    this.setPropertyValue("labelTrue", val);
  }
  get labelFalse(): string {
    return this.getPropertyValue("labelFalse");
  }
  set labelFalse(val) {
    this.setPropertyValue("labelFalse", val);
  }
}

// Add question type metadata for further serialization into JSON
export const initBooleanSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "labelTrue",
        default: "Yes", // surveyjs default value if custom-props not specific
      },
      {
        name: "labelFalse",
        default: "No", // surveyjs default value if custom-props not specific
      },
    ],
    function () {
      return new QuestionBooleanModel("");
    },
    "question",
  );
};

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionBooleanModel(name);
});
// A class that renders questions of the new type in the UI
export class SurveyQuestionBoolean extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.question.value,
    };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get disableAlpha() {
    return this.question.disableAlpha;
  }
  get labelTrue() {
    return getI18nData(this.question.labelTrue);
  }
  get labelFalse() {
    return getI18nData(this.question.labelFalse);
  }

  onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event?.target || {};
    this.question.value = value === "yes" ? true : false;
  }

  renderElement() {
    return (
      <FormControl fullWidth sx={style.formControl} variant="standard">
        <RadioGroup onChange={this.onInputChange.bind(this)} row name="row-radio-buttons-group">
          <FormControlLabel
            className="label-border"
            style={style.groupWidth}
            value="yes"
            control={<Radio color="default" id={this.question.name} size="small" />}
            label={this.labelTrue}
          />
          <FormControlLabel
            style={style.groupWidth}
            className="mr-0 label-border"
            value="no"
            control={<Radio color="default" id={this.question.name} size="small" />}
            label={this.labelFalse}
          />
        </RadioGroup>
      </FormControl>
    );
  }
}
