import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../../utils/hooks/use-query";
import { CheckAction, REG_ATTENDANCE_RULE } from "../../network/type";
import "../../pages/detail/detail-screen.css";

export const Bottom = ({
  checkoutPress,
  backPress,
}: {
  checkoutPress: Function;
  backPress: Function;
}) => {
  const { t } = useTranslation();
  const checkType = useQuery().get("checkType");
  const regAttendanceRule = useQuery().get("regAttendanceRule");
  return (
    <div className="flex py-12">
      <Button
        className="flex w-full"
        variant={
          checkType === CheckAction.CHECK_IN &&
          regAttendanceRule === REG_ATTENDANCE_RULE.CHECK_IN_OUT
            ? "outlined"
            : "contained"
        }
        color={
          checkType === CheckAction.CHECK_IN &&
          regAttendanceRule === REG_ATTENDANCE_RULE.CHECK_IN_OUT
            ? "info"
            : "secondary"
        }
        onClick={() => backPress()}
        style={{ marginRight: "10px" }}
      >
        {t("app.button.back")}
      </Button>
      {checkType === CheckAction.CHECK_IN &&
        regAttendanceRule === REG_ATTENDANCE_RULE.CHECK_IN_OUT && (
          <Button
            className="flex w-full"
            variant="contained"
            color="secondary"
            onClick={() => checkoutPress()}
          >
            {t("event.check_out")}
          </Button>
        )}
    </div>
  );
};
