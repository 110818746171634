import React, { Fragment, ReactElement, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormDataType, getTextFileConfig, useEventCheckMethod } from "./event-check-method.hook";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  MenuItem,
  TextField,
} from "@mui/material";
import { color } from "../../../../../mui-theme/palette";
import {
  CheckAction,
  ParticipantInfoKeyType,
  ParticipantType,
  REG_ATTENDANCE_RULE,
  RegNotificationMethod,
  RegistrationFormType,
} from "../../../network/type";
import { useParams } from "react-router-dom";
import { EVENT_PATH, ROOT_PATH } from "../../../../../routes/constants";
import { useQuery } from "../../../../../utils/hooks/use-query";
import { useAppSelector } from "../../../../../redux/store";
import { sessionFilter } from "../../../util/event.util";

export interface Option {
  text: string;
  value: any;
}

export enum CheckMethod {
  QR_CODE = "QR_CODE",
  OTHERS = "OTHERS",
}

export interface TextFileConfigType {
  infoKey: ParticipantInfoKeyType;
  label: string;
  placeholder: string;
  helperText?: string;
  invalidMessage?: string;
  type?: string;
  selectOptions?: any[];
  checkFormat?: (param: string, other?: any) => boolean;
}

type ComponentProps = {
  /* otherProp */
};

export const EventCheckMethodComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { t } = useTranslation();
  const { refId = "" } = useParams();
  const query = useQuery();
  const sessionId = query.get("sessionId") || "";
  const regAttendanceRule =
    (query.get("regAttendanceRule") as REG_ATTENDANCE_RULE) || REG_ATTENDANCE_RULE.CHECK_IN;
  const action = (query.get("action") as CheckAction) || CheckAction.CHECK_IN;
  const { sessions, regNotificationMethod } =
    useAppSelector((state) => state.event.eventDetailData) ?? {};

  const curSessionData = useMemo(() => {
    return sessionFilter(sessionId, sessions);
  }, [sessions, sessionId]);

  const getInformationContent = useCallback(
    (participants: Array<ParticipantType>) => {
      return (
        <>
          <span>
            {participants?.length === 1
              ? t("event.info_confirm_modal_desc")
              : t("event.multi_info_confirm_modal_desc", { num: participants.length })}
          </span>
          <div className="w-full overflow-scroll">
            {participants?.map(({ firstName, lastName, qrCode }) => {
              return (
                <Fragment key={qrCode}>
                  <br />
                  <div className="flex flex-col">
                    <span>{t("event.participant_first_name_with_name", { firstName })}</span>
                    <span>
                      {t("event.participant_last_name_with_name", { lastName: lastName || "-" })}
                    </span>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </>
      );
    },
    [t],
  );

  const {
    timerRef,
    checkMethodOption,
    agentOption,
    checkMethod,
    isAgent,
    countryCodeOptions,
    formData,
    selectedCountryCode,
    submitting,
    onSelect,
    onInputChange,
    onSelectCountryCode,
    onBlur,
    onConfirm,
    validateFormat,
  } = useEventCheckMethod({ refId, sessionId, action, regAttendanceRule });

  const curTextFileConfig = useMemo(() => {
    const config: Record<ParticipantInfoKeyType, TextFileConfigType> =
      getTextFileConfig(countryCodeOptions);
    if (!checkMethod || (checkMethod === CheckMethod.OTHERS && typeof isAgent === "undefined"))
      return;

    let key;
    if (checkMethod === CheckMethod.QR_CODE) {
      key = ParticipantInfoKeyType.QR_CODES;
    } else {
      key = isAgent
        ? ParticipantInfoKeyType.AGENT_CODE
        : regNotificationMethod === RegNotificationMethod.EMAIL
        ? ParticipantInfoKeyType.EMAIL
        : ParticipantInfoKeyType.PHONE;
    }
    return config[key];
  }, [countryCodeOptions, checkMethod, isAgent, regNotificationMethod]);

  const { placeholder, label, type, helperText, selectOptions, infoKey } = curTextFileConfig ?? {};

  const disableButton = useMemo(() => {
    if (!infoKey || submitting) return true;
    const inputValue = formData[infoKey]?.value;
    return (
      !inputValue ||
      (infoKey === ParticipantInfoKeyType.QR_CODES && inputValue?.replace(/\s/g, "").length !== 16)
    );
  }, [infoKey, submitting, formData]);

  const handleClick = useCallback(
    async ({
      formData,
      getContent,
      curConfig,
      countryCode,
    }: {
      formData: Record<string, FormDataType>;
      getContent: (param: Array<ParticipantType>) => ReactElement;
      curConfig: TextFileConfigType;
      countryCode?: string;
    }) => {
      const { infoKey } = curConfig;
      const { value, errorMsg } = formData[infoKey];
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      // valid check
      if (errorMsg) return;
      const isValid = await validateFormat(value, curConfig);
      if (!isValid) return;
      onConfirm({ infoKey, value, getContent, countryCode });
    },
    [onConfirm, timerRef, validateFormat],
  );

  return (
    <div className="w-full flex-1 bg-white mt-2 flex flex-col justify-between pb-6">
      <div className="w-full p-6">
        <CustomizeRadioGroup
          label={
            action === CheckAction.CHECK_IN
              ? t("event.check_in_event_by")
              : t("event.check_out_event_by")
          }
          options={checkMethodOption}
          onSelect={onSelect}
          selectedOption={checkMethod}
        />
        {checkMethod === CheckMethod.OTHERS && (
          <CustomizeRadioGroup
            label={t("event.whether_agent")}
            options={agentOption}
            onSelect={onSelect}
            selectedOption={isAgent}
          />
        )}

        {infoKey && (
          <FormControl
            fullWidth
            required
            className="mt-6 flex flex-col"
            error={!!formData[infoKey]?.errorMsg}
          >
            <FormLabel
              sx={{
                fontSize: 14,
                marginBottom: 1,
                color: "#333333",
              }}
            >
              {label}
            </FormLabel>
            <div className="w-full flex flex-row">
              {selectOptions && (
                <TextField
                  select
                  value={selectedCountryCode}
                  variant="standard"
                  onChange={onSelectCountryCode}
                >
                  {selectOptions?.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.key}>
                        {option.key}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
              <Input
                className="flex-1"
                value={formData[infoKey]?.value || ""}
                onBlur={(e) => onBlur(e.target.value, curTextFileConfig)}
                placeholder={placeholder}
                type={type || "text"}
                onChange={(e) => onInputChange(e.target.value, infoKey)}
                sx={{ fontSize: 16 }}
              />
            </div>
            <FormHelperText sx={{ marginLeft: 0 }}>
              {formData[infoKey]?.errorMsg || helperText}
            </FormHelperText>
          </FormControl>
        )}
      </div>

      <div className="w-full mt-5 flex flex-col px-4 pt-2">
        <Button
          variant="contained"
          disabled={disableButton}
          disableElevation
          color="primary"
          sx={{
            backgroundColor: color.majorRed,
            marginBottom: 1,
            ":hover": { backgroundColor: color.majorRed },
          }}
          onClick={async () => {
            handleClick({
              formData,
              getContent: getInformationContent,
              curConfig: curTextFileConfig!,
              countryCode: selectedCountryCode,
            });
          }}
        >
          {t("global.text.confirm")}
        </Button>

        {curSessionData?.regAllowWalkIn && action === CheckAction.CHECK_IN && (
          <a
            href={`${ROOT_PATH}/${EVENT_PATH}/form/${refId}?formType=${RegistrationFormType.WALKIN}&sessionId=${sessionId}`}
            className="w-full text-center mt-5 text-sm font-medium text-[#4386E5]"
          >
            {t("event.registered_event_tip")}
          </a>
        )}
      </div>
    </div>
  );
});

const CustomizeRadioGroup = ({
  label,
  options,
  selectedOption,
  onSelect,
}: {
  label: string;
  options: Option[];
  selectedOption?: any;
  onSelect: (param: Option) => void;
}) => {
  return (
    <div className="w-full mb-6">
      <span className="text-sm font-medium">
        {label}
        <span className="text-[#E8192C]"> *</span>
      </span>

      <div className="mt-3 w-full flex flex-row justify-between gap-x-4">
        {options?.map((option, index) => {
          return (
            <div
              key={index}
              className={`flex flex-1 items-center justify-center px-4 py-3 rounded-xl min-w-0 break-words ${
                option.value === selectedOption
                  ? "bg-[#FFE4E6] text-[#E8192C] border-2 border-[#FFE4E6]"
                  : "border-2 border-[#CCCCCC]"
              }`}
              onClick={() => onSelect(option)}
            >
              <span className="text-base font-semibold">{option.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
