import { get, isString } from "lodash";
import { forkJoin } from "rxjs";
import { Model } from "survey-core";
import store from "../../../../../redux/store";
import { GlobalHelper } from "../../../../../utils/helpers/global-helper";
import { StorageFileTypeEnum } from "../../../network/file-op-interface";
import {
  deleteFile,
  downFile,
  getResourceById,
  uploadBlobFile,
  uploadExamProof,
  uploadFile,
} from "../../../network/network";
import { ActionStatusEnum } from "./action-enum";
import { handleBase64Url, toBase64Url } from "./base64";
import { fileurlToBase64 } from "../../../pages/form/util";
import { OnboardingFormModuleName } from "../../../types";

export function initFileUploadAndDownLoadAndClear(survey: Model): void {
  survey.onUploadFiles.clear();
  survey.onDownloadFile.clear();
  survey.onClearFiles.clear();
  survey.onUploadFiles.add((_, options) => {
    const questionName = options.question.name;
    const sectionName = options.question.parent.name;
    const moduleName = GlobalHelper.getOnboardingCurrentSection()?.moduleName;
    const applicationId = store.getState()?.onboarding?.candidateOnboardingForm?.applicationId;
    const fileOb = options.files.map((file, index) => {
      return new Promise((resolve, reject) => {
        const upload = async (base64URL: string) => {
          try {
            const base64R = handleBase64Url(base64URL);
            const result = await uploadFile({
              moduleName,
              sectionName,
              applicationId,
              questionName,
              fileName: file.name,
              index: get(file, "index", 0),
              fileType:
                options.question.getType() === "signaturepad"
                  ? StorageFileTypeEnum.SIGNATURE
                  : StorageFileTypeEnum.UPLOAD_DOCUMENT,
              data: base64R.base64,
              mimeType: base64R.mimetype,
            });
            if (base64R.mimetype.includes("image/")) {
              const resources = await getResourceById(result.data);
              if (
                moduleName !== OnboardingFormModuleName.ESignature &&
                resources[0]?.meta?.sharpness &&
                resources[0]?.meta?.sharpness < 0.8
              ) {
                // The image is blurred
                deleteFile({
                  applicationId,
                  resourceId: result.data,
                });
                resolve(ActionStatusEnum.imageBlurError);
              } else {
                resolve(result.data);
              }
            } else {
              resolve(result.data);
            }
          } catch {
            resolve(ActionStatusEnum.error);
          }
        };
        if (isString(file)) {
          upload(file);
        } else {
          const fileReader = new FileReader();
          fileReader.onload = async (e) => {
            const fileBase64 = fileReader.result;
            upload(fileBase64 as string);
          };
          fileReader.readAsDataURL(file);
        }
      });
    });
    forkJoin(fileOb).subscribe((value) => {
      const values = value.filter(
        (v) =>
          v !== ActionStatusEnum.error &&
          v !== ActionStatusEnum.minSizeError &&
          v !== ActionStatusEnum.imageBlurError,
      );
      if (values.length === 0) {
        options.callback(value[0], undefined);
        return;
      }
      options.callback(ActionStatusEnum.success, values);
    });
  });
  survey.onDownloadFile.add(async (_, options) => {
    try {
      const applicationId = store.getState()?.onboarding?.candidateOnboardingForm?.applicationId;

      const result = await downFile({
        applicationId,
        resourceId: options.content,
      });
      if (result.data) {
        const content = result.data.fileContent as string;
        const mimeType = result.data.mimeType as string;
        const base64URL = toBase64Url(content, mimeType);
        const fileName = result.data.fileName as string;
        options.callback(ActionStatusEnum.success, {
          base64URL,
          fileName,
          mimeType,
        });
      }
    } catch {
      options.callback(ActionStatusEnum.error);
    }
  });
  survey.onClearFiles.add(async (_, options) => {
    try {
      const applicationId = store.getState()?.onboarding?.candidateOnboardingForm?.applicationId;
      if (!applicationId || !options.fileName) {
        options.callback(ActionStatusEnum.success, "");
        return;
      }
      const result = await deleteFile({
        applicationId,
        resourceId: options.fileName,
      });
      if (result.data) {
        options.callback(ActionStatusEnum.success, "");
      } else {
        options.callback(ActionStatusEnum.error, "");
      }
    } catch (error) {
      options.callback(ActionStatusEnum.error, "");
    }
  });
}

export function initFileUploadAndDownLoadAndClearForLicenseExam(survey: Model): void {
  survey.onUploadFiles.clear();
  survey.onDownloadFile.clear();
  survey.onClearFiles.clear();
  survey.onUploadFiles.add((_, options) => {
    const fileOb = options.files.map((file, index) => {
      return new Promise((resolve, reject) => {
        const upload = async (base64URL: string) => {
          try {
            const base64R = handleBase64Url(base64URL);
            const examProofResponse = await uploadExamProof(file.name, base64R.mimetype);
            const url = examProofResponse?.data?.url;
            await uploadBlobFile(url, base64R.base64);
            resolve(examProofResponse?.data);
          } catch {
            resolve(ActionStatusEnum.error);
          }
        };
        if (isString(file)) {
          upload(file);
        } else {
          const fileReader = new FileReader();
          fileReader.onload = async (e) => {
            upload((e.target?.result ?? "") as string);
          };
          fileReader.readAsDataURL(file);
        }
      });
    });
    forkJoin(fileOb).subscribe((value) => {
      const values = value.filter((v) => v !== ActionStatusEnum.error);
      if (values.length === 0) {
        options.callback(ActionStatusEnum.error, undefined);
        return;
      }
      options.callback(ActionStatusEnum.success, values);
    });
  });
  survey.onDownloadFile.add(async (_, options) => {
    try {
      const blobId = options?.content;
      const result = await getResourceById(blobId);
      if (result?.length > 0) {
        const mimeType = result[0].mimeType as string;
        const url = result[0].url as string;
        const fileName = result[0].originalFilename as string;
        fileurlToBase64(url)
          .then((base64Data) => {
            const base64URL = base64Data as string;
            options.callback(ActionStatusEnum.success, {
              fileName,
              base64URL,
              mimeType,
            });
          })
          .catch((error: any) => {
            console.error("Error converting file to Base64:", error);
          });
      }
    } catch {
      options.callback(ActionStatusEnum.error);
    }
  });
}
