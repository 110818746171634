import React, { useState } from "react";

import arrowRight from "../../../../components/icon/assets/arrow-right.svg";
import closeLogo from "../../../../components/icon/assets/close.svg";

import { useSetting } from "./setting.hook";

import { SxProps, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import FullListPopup from "../../../../components/full-list-popup/full-list-popup";
import styles from "./setting.module.css";
import { languageSelectionConfig } from "../../utils";
type SettingProps = {};

export const docButton: SxProps<Theme> = {
  width: "100%",
  fontWeight: 600,
  fontSize: "16px",
  height: "48px",
};

export const SettingScreen = (props: SettingProps) => {
  const {
    isPopupOpen,
    clickSetting,
    clickLanguage,
    clickDisclaimer,
    clickPrivacy,
    clickLogout,
    clickClose,
  } = useSetting();
  const { t, i18n } = useTranslation();

  const [selectedLang, setSelectedLang] = useState(i18n.language);

  return (
    <div className={styles.container}>
      {/* Header section */}
      <div className={styles.header}>
        <span className={styles.title} onClick={clickSetting}>
          {t("Common.settings")}
        </span>
        <img src={closeLogo} alt="" className={styles.logoImg} onClick={clickClose}></img>
      </div>
      {/* list section */}
      <div>
        <div className={styles.line} onClick={clickLanguage}>
          <span className={styles.text}> {t("Common.language")}</span>
          <img src={arrowRight} alt="" className={styles.logoImg}></img>
        </div>
        <div className={styles.line}>
          <span className={styles.text}> {t("Common.disclaimer")}</span>
          <img src={arrowRight} alt="" className={styles.logoImg}></img>
        </div>
        <div className={styles.line}>
          <span className={styles.text}> {t("Common.privacy_notice")}</span>
          <img src={arrowRight} alt="" className={styles.logoImg}></img>
        </div>
      </div>
      {/* bottom section */}
      <div className={styles.fixedBottom}>
        <button className={styles.logoutButton} onClick={clickLogout}>
          {t("Common.logout")}
        </button>
      </div>

      <FullListPopup
        isOpen={isPopupOpen}
        onClose={clickLanguage}
        selectData={{ value: selectedLang }}
        onChange={(data) => {
          if (data.value) {
            setSelectedLang(data.value);
          }
          i18n.changeLanguage(data?.value);
        }}
        data={languageSelectionConfig().availableLanguages.map((lang) => ({
          label: lang.languageFullName,
          value: lang.i18nLanguageCode,
        }))}
      />
    </div>
  );
};
