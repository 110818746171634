import React, { useCallback, useEffect, useMemo } from "react";
import i18next from "i18next";
import Svg403 from "./403.svg";
import Svg404 from "./no_content.svg";
import Svg500 from "./service_down.svg";
import SvgUnknown from "./unknown_error.svg";
import SvgExpired from "./expired.svg";
import { useQuery } from "../../utils/hooks/use-query";

const UNKNOWN_ERROR_CODE = -1;

export const ErrorStateBlock: React.FC<ErrorStateBlockProps> = (props) => {
  // const { t } = useTranslation();
  const { statusCode } = props;
  const { lang }: any = useQuery();
  // in case statusCode not found, use UNKNOWN_ERROR_CODE
  const ui = useMemo(() => {
    if (lang) {
      i18next.changeLanguage(lang);
    }
    const info = getErrorInfo(statusCode, props.title, props.description);
    return (
      <React.Fragment>
        <div className="flex flex-col w-full min-h-[50vh] h-full overflow-x-hidden items-center justify-center">
          <div>
            <img
              className="sm:w-48 md:w-56 lg:w-80 xl:w-96 2xl:w-[400px] h-auto"
              src={info.icon}
              alt={info.title}
            />
          </div>
          <div className="sm:text-lg text-xl font-bold pt-6">{info.title}</div>
          <div className="sm:text-sm lg:text-lg text-center px-6 pt-2 text-gray-400">
            {info.description}
          </div>
          <div className="sm:text-sm lg:text-lg pt-4 text-gray-400">
            ({i18next.t("error-state.code") + statusCode})
          </div>
        </div>
      </React.Fragment>
    );
  }, [props.description, lang, props.title, statusCode]);

  return ui;
};
const STATUS_ICON_MAP: any = {
  "400": SvgUnknown,
  "401": SvgUnknown,
  "402": SvgUnknown,
  "403": Svg403,
  "404": Svg404,
  "409": SvgUnknown,
  "410": SvgExpired,
  "415": SvgUnknown,
  "-1": SvgUnknown,
};
/**
 * specific 403 and 500+ error
 * @param code
 * @param desc
 * @returns
 */
function getErrorInfo(code: StatusCode, title: string | undefined, desc: string | undefined) {
  if (code === "-1" || code >= "500") {
    return {
      icon: Svg500,
      title: title ?? i18next.t("error-state.title-500"),
      description: desc ?? i18next.t("error-state.desc-500"),
    };
  } else if (code === "403") {
    return {
      icon: Svg403,
      title: title ?? i18next.t("error-state.title-403"),
      description: desc ?? i18next.t("error-state.desc-403"),
    };
  } else if (code === "410") {
    return {
      icon: SvgExpired,
      title: title ?? i18next.t("error-state.title-link_expired"),
      description: desc ?? i18next.t("error-state.desc-link_expired"),
    };
  }
  return {
    icon: STATUS_ICON_MAP[code] || STATUS_ICON_MAP[UNKNOWN_ERROR_CODE],
    title: title ?? (STATUS_MESSAGE_MAP[code] || STATUS_MESSAGE_MAP[UNKNOWN_ERROR_CODE]),
    description: desc || "",
  };
}
const STATUS_MESSAGE_MAP = {
  "300": "Multiple Choices",
  "301": "Moved Permanently",
  "302": "Found",
  "303": "See Other",
  "304": "Not Modified",
  "305": "Use Proxy",
  "307": "Temporary Redirect",
  "308": "Permanent Redirect",
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Proxy Authentication Required",
  "408": "Request Timeout",
  "409": "Conflict",
  "410": "Gone",
  "411": "Length Required",
  "412": "Precondition Failed",
  "413": "Payload Too Large",
  "414": "URI Too Long",
  "415": "Unsupported Media Type",
  "416": "Range Not Satisfiable",
  "417": "Expectation Failed",
  "418": "I'm a Teapot",
  "421": "Misdirected Request",
  "422": "Unprocessable Entity",
  "423": "Locked",
  "424": "Failed Dependency",
  "425": "Too Early",
  "426": "Upgrade Required",
  "428": "Precondition Required",
  "429": "Too Many Requests",
  "431": "Request Header Fields Too Large",
  "451": "Unavailable For Legal Reasons",
  "-1": "Unknown Error",
};
export type StatusCode = keyof typeof STATUS_MESSAGE_MAP;
export interface ErrorStateBlockProps {
  statusCode: StatusCode;
  title?: string;
  description?: string;
  icon?: any;
  lang?: string;
}
