import React, { useEffect, useMemo, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import PageContainer from "../../../../components/page-container/page-container";
import { Survey } from "survey-react-ui";
import { cloneDeep } from "lodash";
import { Model, settings } from "survey-core";
import { useLocation, useNavigate } from "react-router-dom";
import { OtpVerificationTemplate } from "./template";
import { initSurvey } from "../../components/surveyjs/survey/init-survey";
import { ROOT_PATH } from "../../../../routes/constants";
import { getFullRegionList } from "../../../common/network/network";
import { confirmActionAsyncFunc } from "../../components/surveyjs/survey/survey.util";
import { getOid } from "../../../prudna/pages/personal-info-form/send-otp";
import { OtpContextEnum, UserTypeEnum } from "../../../common/network/enum";
import { validateOtpFunc } from "./validate-otp";
const elements = OtpVerificationTemplate.pages[0].elements;

export const OtpVerificationScreen = () => {
  initSurvey();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isForgetPassword = location.state?.isForgetPassword;
  const [formValues, setForm] = useState({} as any);
  const [otp, setOtp] = useState(false);
  const [pageInitiating, setPageInitiating] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    convertTemplate(OtpVerificationTemplate);
    Promise.all([getFullRegionList()])
      .then((reses: any) => {
        const [regionConfigs] = reses;
        const regionPhoneField = elements.find((item: any) => item.name === "regionPhone");
        if (regionPhoneField?.templateElements?.[0] && regionConfigs) {
          const regionOpts = Object.values(regionConfigs).map((region: any) => ({
            text: `${region.code}`,
            key: region.code,
            value: region.code,
            reg: region.regex,
          }));
          // @ts-ignore
          regionPhoneField.templateElements[0].choices = regionOpts;
          setForm({
            regionCode: regionOpts?.[0].key ?? "+852",
          });
        } else {
          setForm({
            regionCode: "+852",
          });
        }

        const otpField: any = elements.find((item: any) => item.name === "otp");
        const otpConfig = {
          ...otpField.otpConfig,
          actionParams: {
            context: isForgetPassword ? OtpContextEnum.FORGOT_PASSWORD : OtpContextEnum.FIRST_LOGIN,
            lang: "en",
            userType: "CANDIDATE",
            deliveryMethod: "SMS",
          },
        };
        otpField.otpConfig = otpConfig;
      })
      .finally(() => {
        setPageInitiating(false);
      });
  }, [isForgetPassword]);

  const survey = useMemo(() => {
    if (pageInitiating) return null;
    const survey = new Model(cloneDeep(OtpVerificationTemplate));
    survey.showPageTitles = false;
    survey.showNavigationButtons = "none";
    survey.questionErrorLocation = "bottom";
    survey.showCompletedPage = false;
    const defaultValue = {
      regionPhone: [
        {
          regionCode: formValues.regionCode ?? "",
          phoneNumber: formValues.phoneNumber,
        },
      ],
    };
    survey.mergeData(defaultValue);
    survey.onComplete.add((sender, options) => {});
    settings.confirmActionAsync = confirmActionAsyncFunc;
    return survey;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInitiating]);

  const handleOnConfirm = async () => {
    if (!survey) return null;
    if (!survey.validateCurrentPage()) {
      setIsValid(false);
      return;
    }
    setIsLoading(true);

    const oid = getOid();
    if (!oid) {
      setIsValid(false);
      const otpQuestion = survey.getQuestionByName("otp");
      otpQuestion.addError(t("Common.incorrect_verification_code"));
    } else {
      const surveyData = survey.data;
      const res = await validateOtpFunc({
        context: isForgetPassword ? OtpContextEnum.FORGOT_PASSWORD : OtpContextEnum.FIRST_LOGIN,
        otp: `${getOid()}:${surveyData.otp}`,
        regionCode: surveyData.regionPhone[0].regionCode,
        phone: surveyData.regionPhone[0].phoneNumber,
        userType: UserTypeEnum.CANDIDATE,
      });
      if (res === true) {
        navigate(`${ROOT_PATH}/onboarding/set-password`, {
          state: {
            user: {
              username: `${surveyData.regionPhone[0].regionCode} ${surveyData.regionPhone[0].phoneNumber}`,
              otp: `${getOid()}:${surveyData.otp}`,
              context: isForgetPassword
                ? OtpContextEnum.FORGOT_PASSWORD
                : OtpContextEnum.FIRST_LOGIN,
            },
          },
        });
      } else {
        if (typeof res === "string") {
          const otpQuestion = survey.getQuestionByName("otp");
          otpQuestion.addError(res);
        }
        setIsValid(false);
      }
    }

    setIsLoading(false);
  };

  const handleValueChanged = (sender: any, options: any) => {
    if (options.name === "otp") {
      setOtp(options.value);
    }
    if (options.name === "regionPhone") {
      setForm({
        regionCode: options.value[0].regionCode,
        phoneNumber: options.value[0].phoneNumber,
      });
    }
  };

  useEffect(() => {
    if (otp && formValues.regionCode && formValues.phoneNumber) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formValues.phoneNumber, formValues.regionCode, otp]);

  return (
    <PageContainer
      title={isForgetPassword ? t("Common.reset_password") : t("Common.create_account")}
    >
      {!pageInitiating && (
        <div className="flex flex-1 flex-col max-w-md sm:max-w-xl lg:max-w-xl">
          <div className="flex flex-col w-full pb-4 px-8">
            <div className=" bg-white w-full rounded-tr-3xl h-10"></div>
            <Survey
              currentPageNo={0}
              className="survey-common"
              id="create-account"
              model={survey}
              onValueChanged={handleValueChanged}
            />
          </div>

          <div className=" w-full sticky bottom-0 bg-white pb-7 flex items-center justify-between gap-4 pt-16 px-8">
            <button
              className="bg-red-500 flex-1 text-white font-semibold py-3 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75 disabled:bg-[#CCCCCC] disabled:shadow-none"
              disabled={!isValid || isLoading}
              onClick={handleOnConfirm}
            >
              {t("global.text.confirm")}
            </button>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

const convertTemplate = (template: any) => {
  // Function to translate a given field if it exists
  const translateField = (obj: any, field: string) => {
    if (obj?.[field]) {
      obj[field] = getI18n().t(obj[field]);
    }
  };

  // Loop through each element in the first page
  template.pages[0].elements = template.pages[0].elements.map((q: any) => {
    // Translate title, labelTrue, labelFalse, requiredErrorText fields
    translateField(q, "title");
    translateField(q, "placeholder");
    translateField(q, "labelTrue");
    translateField(q, "labelFalse");
    translateField(q, "requiredErrorText");
    translateField(q, "tooltips");
    translateField(q?.modalConfig, "modalTitle");

    // translate choices if they exist
    if (q.choices && Array.isArray(q.choices)) {
      q.choices.forEach((choice: any) => {
        translateField(choice, "text");
      });
    }

    // Check if the element has validators and translate their text fields
    if (q.validators && Array.isArray(q.validators)) {
      q.validators.forEach((validator: any) => {
        translateField(validator, "text");
      });
    }

    // If the element is a paneldynamic type, we need to handle nested templateElements
    if (q.type === "paneldynamic" && q.templateElements && Array.isArray(q.templateElements)) {
      q.templateElements = q.templateElements.map((te: any) => {
        translateField(te, "title");
        translateField(te, "labelTrue");
        translateField(te, "labelFalse");
        translateField(te, "requiredErrorText");
        translateField(te, "placeholder");
        translateField(te?.modalConfig, "modalTitle");

        if (te.validators && Array.isArray(te.validators)) {
          te.validators.forEach((validator: any) => {
            translateField(validator, "text");
          });
        }

        return te;
      });
    }

    return q;
  });
};
