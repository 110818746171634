import React, { CSSProperties } from "react";
import { Serializer, QuestionRadiogroupModel as Question } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";
// mui start
import { createTheme, ThemeProvider } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { CustomType } from "../survey/survey.util";
import * as style from "../survey/common.style";
import { radioGroupStyle } from "./style";
import { quizAnswerUpdate } from "../../../network/network";
import MyFormControlLabel from "./form-control-label";
import { GlobalHelper } from "../../../../../utils/helpers/global-helper";
import { quizAnswerUpdateAsync } from "../../../redux/onboarding-slice";
import { get } from "lodash";
import { getI18n } from "react-i18next";
import { confirm } from "../../../../../components/modal/comfirm";

const CUSTOM_TYPE = CustomType.RadioGroup;

enum QuestionRadiogroupPreset {
  default = "default",
  radio = "radio",
  quiz = "quiz",
}

export class QuestionRadiogroupModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get preset(): QuestionRadiogroupPreset {
    return this.getPropertyValue("preset") ?? QuestionRadiogroupPreset.default;
  }
  set preset(val) {
    this.setPropertyValue("preset", val);
  }
}

//Add question type metadata for further serialization into JSON
export const initRadiogroupSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      { name: "showClearButton:boolean", default: false },
      { name: "separateSpecialChoices", visible: true },
      { name: "itemComponent", visible: false, default: "survey-radiogroup-item" },
      {
        name: "preset",
        default: "default",
      },
      {
        name: "tooltips",
        default: "",
      },
    ],
    function () {
      return new QuestionRadiogroupModel("");
    },
    "checkboxbase",
  );
};

const theme = createTheme({});

export class SurveyQuestionRadiogroup extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.question.value,
      options: this.question.choices,
    };
  }
  get question() {
    let isModuleReadOnly = false;
    if (this.questionBase.survey) {
      const activePage: any = this.questionBase.survey.currentPage;
      if (activePage) {
        isModuleReadOnly = activePage.readOnly;
      }
    }
    this.questionBase.readOnly = this.questionBase.readOnly || isModuleReadOnly;
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get preset() {
    return this.question.preset;
  }
  get answerPass() {
    return this.state.answerPass;
  }
  get answerExplanation() {
    return this.state.answerExplanation ?? "";
  }
  get defaultValue() {
    return this.question.defaultValue;
  }
  get style() {
    return (
      this.question.getPropertyValue("readOnly") ? { pointerEvents: "none" } : undefined
    ) as CSSProperties;
  }

  private quizValueChange(value: any) {
    const quizAnswer = {
      name: this.question.name,
      value: value,
    };
    GlobalHelper.getGlobalDispatch()(quizAnswerUpdateAsync(quizAnswer)).then(
      (response: any) => {
        const isOK = get(response, "payload.ok", true);
        if (isOK) {
          const data = get(response, "payload.data", {});
          this.setState({
            ...this.state,
            answerPass: data.questionCorrect === true,
            answerExplanation: data.globalFeedback,
          });
        } else {
          const error = get(response, "payload.error", {});
          if (error?.response?.status === 404) {
            confirm({
              message: getI18n().t("course_unavailable_try_another"),
            }).then(({ ok }) => {
              if (ok) {
                const globalNavigate = GlobalHelper.getGlobalNavigate();
                globalNavigate(-1);
              }
            });
          }
        }
      },
      (err: any) => {
        this.setState({ ...this.state, answerPass: false });
      },
    );
  }

  onValueChange(event: any) {
    const { value, checked } = event.target;
    if (checked) {
      this.question.value = value;
      if (this.preset === QuestionRadiogroupPreset.quiz) {
        this.quizValueChange(value);
      }
    }
  }
  protected renderElement(): JSX.Element {
    var cssClasses = this.question.cssClasses;
    var clearButton: JSX.Element | null = null;
    if (this.question.showClearButtonInContent) {
      clearButton = (
        <div>
          <input
            type="button"
            className={this.question.cssClasses.clearButton}
            onClick={() => this.question.clearValue()}
            value={this.question.clearButtonCaption}
          />
        </div>
      );
    }

    const generateFormControlLabel = (item: any) => {
      let controlEle = <Radio style={{ display: "none" }} />;
      let label;
      if (this.preset === QuestionRadiogroupPreset.radio) {
        controlEle = (
          <Radio className={`${this.value === item.value ? "text-red-600" : "text-gray-500"}`} />
        );
        label = <p className={`py-4 px-2`}>{item.text}</p>;
      } else if (this.preset === QuestionRadiogroupPreset.quiz) {
        label = (
          <p
            className={`p-4 mt-3 rounded-md border ${
              this.value === item.value && this.answerPass !== undefined
                ? this.answerPass !== false
                  ? "border-green-400 bg-green-100"
                  : "border-red-400 bg-red-100"
                : this.value === item.value
                ? "border-red-400 bg-red-100"
                : "border-gray-200"
            } bg-white whitespace-normal`}
          >
            {item.text}
          </p>
        );
      } else {
        label = (
          <p
            className={`p-4 mt-3 rounded-md border ${
              this.value === item.value ? "border-red-400 bg-red-100" : "border-gray-200"
            } bg-white whitespace-normal`}
          >
            {item.text}
          </p>
        );
      }
      return (
        <MyFormControlLabel
          value={item.value}
          key={item.value}
          disabled={this.question.readOnly}
          label={label}
          sx={radioGroupStyle.label}
          control={controlEle}
        />
      );
    };

    return (
      <ThemeProvider theme={theme}>
        <div style={this.style}>
          <FormControl fullWidth sx={style.formControl} variant="standard">
            <FormGroup>
              <RadioGroup
                aria-label="gender"
                value={this.value}
                onChange={
                  this.preset === QuestionRadiogroupPreset.quiz && this.value !== undefined
                    ? undefined
                    : this.onValueChange.bind(this)
                }
              >
                {this.state.options.map((item: any) => {
                  return generateFormControlLabel(item);
                })}
              </RadioGroup>
            </FormGroup>
          </FormControl>

          {this.preset === QuestionRadiogroupPreset.quiz &&
          this.value !== undefined &&
          this.answerPass !== undefined ? (
            <div className="mt-9 mb-4">
              <AnswerExplanation answerPass={this.answerPass} content={this.answerExplanation} />
            </div>
          ) : null}
        </div>
      </ThemeProvider>
    );
  }
}

type AnswerExplanationProps = {
  answerPass: boolean;
  content: string;
};
const AnswerExplanation: React.FC<AnswerExplanationProps> = (props) => {
  let answerExplanationTitle;
  if (props.answerPass) {
    answerExplanationTitle = getI18n().t("correct_answer_explanation_title");
  } else {
    answerExplanationTitle = getI18n().t("incorrect_answer_explanation_title");
  }

  return (
    <div>
      {/* answer explanation here */}
      {answerExplanationTitle ? (
        <div className="text-base font-sans font-medium text-[#333] mb-4">
          {answerExplanationTitle}
        </div>
      ) : null}
      <p className="whitespace-normal text-[#666] font-sans text-sm font-medium leading-6">
        {props.content}
      </p>
    </div>
  );
};
