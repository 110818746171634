import React from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ONBOARDING_PATH, ROOT_PATH } from "../../routes/constants";
import { useQuery } from "../../utils/hooks/use-query";
interface NavigatorCardProps {
  candidateName?: string;
  applicationNumber?: string | number;
}
const NavigatorCard = ({ candidateName, applicationNumber }: NavigatorCardProps) => {
  const navigate = useNavigate();
  const query = useQuery();

  const onClickHandle = () => {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}?${query.toString()}`);
  };
  const { t } = useTranslation();
  return (
    <div className="rounded-md bg-white shadow-md mt-1 mb-1.5 mx-6" onClick={onClickHandle}>
      <div className="flex items-center justify-between mx-4">
        <div className="flex flex-col my-3">
          <div className="text-sm font-medium mb-2">
            {t("Recruitment.onboarding_view_candidate_application_form", {
              candidateName,
            })}
          </div>
          <div className="text-sm font-medium mt-2">
            {t("Recruitment.onboarding_application_number")}: {applicationNumber}
          </div>
        </div>
        <div className="flex items-end">
          <ChevronRightOutlinedIcon className="text-gray-600" />
        </div>
      </div>
    </div>
  );
};

export default NavigatorCard;
