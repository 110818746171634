// prettier-ignore
export const firebaseConfig = {
apiKey: "AIzaSyCtkjtmcHHjaeqF64BklE8wuJrvqshpi34",
  authDomain: "coe-pruforce-sit.firebaseapp.com",
  projectId: "coe-pruforce-sit",
  storageBucket: "coe-pruforce-sit.appspot.com",
  messagingSenderId: "629758163605",
  appId: "1:629758163605:web:40171f4ae92c8ee5f6f99c",
  measurementId: "G-G7EQBYGERT"

};
