import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import storage from "redux-persist/lib/storage";
import {
  fetchAttendanceLanding,
  fetchEventDetail,
  fetchEventQRcodeDetail,
  submitParticipantInfo,
} from "../network/event-detail-crud";
import {
  CheckInfoType,
  EventDetail,
  EventQRcodeDetail,
  SessionCheckDataType,
  SubmitParticipantsInfoReqType,
  SubmitParticipantsInfoResType,
} from "../network/type";

export const eventPersistConfig = {
  key: "event",
  storage,
};

const initialState: EventState = {
  requesting: "idle",
};

export interface EventState {
  eventDetailData?: EventDetail;
  requesting?: "idle" | "pending" | "fulfilled" | "rejected";
  loading?: boolean;
  eventError?: AxiosError | any;
  eventQRcodeData?: EventQRcodeDetail;
  participantsInfo?: Array<CheckInfoType>;
  code?: AxiosError | any;
  sessionCheckData?: SessionCheckDataType;
}

export const fetchEventDetailByIdAsync = createAsyncThunk(
  "event/detail",
  async (params: string, thunkAPI: any) => {
    try {
      const result = await fetchEventDetail(params);
      return result;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchEventQRcodeDetailByIdAsync = createAsyncThunk(
  "event/qr-code",
  async (params: string, thunkAPI: any) => {
    try {
      const result = await fetchEventQRcodeDetail(params);
      return result;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const fetchAttendanceLandingByIdAsync = createAsyncThunk(
  "event/attendance/landing",
  async (params: string, thunkAPI: any) => {
    try {
      const result = await fetchAttendanceLanding(params);
      return result;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const submitParticipantInfoAsync = createAsyncThunk(
  "event/attendance/submit",
  async (params: { refId: string; data: SubmitParticipantsInfoReqType }, thunkAPI: any) => {
    try {
      const { refId, data } = params;
      const result = await submitParticipantInfo(refId, data);
      return result;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const eventSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {
    updateSessionCheckData: (state, action: PayloadAction<SessionCheckDataType>) => {
      state.sessionCheckData = { ...state.sessionCheckData, ...action.payload };
    },
    updateCode: (state, action) => {
      state.code = action.payload;
    },
  },
  extraReducers(builder: any) {
    builder
      .addCase(fetchEventDetailByIdAsync.pending, (state: EventState) => {
        state.requesting = "pending";
        state.eventDetailData = undefined;
        state.loading = true;
        state.eventError = null;
      })
      .addCase(
        fetchEventDetailByIdAsync.fulfilled,
        (state: EventState, action: PayloadAction<any>) => {
          state.requesting = "fulfilled";
          state.eventDetailData = action.payload.data;
          state.loading = false;
        },
      )
      .addCase(
        fetchEventDetailByIdAsync.rejected,
        (state: EventState, action: PayloadAction<any>) => {
          state.requesting = "rejected";
          state.eventDetailData = undefined;
          state.loading = false;
          state.eventError = action.payload?.response?.data;
        },
      )
      .addCase(fetchEventQRcodeDetailByIdAsync.pending, (state: EventState) => {
        state.requesting = "pending";
        state.eventQRcodeData = undefined;
        state.loading = true;
        state.eventError = null;
      })
      .addCase(
        fetchEventQRcodeDetailByIdAsync.fulfilled,
        (state: EventState, action: PayloadAction<any>) => {
          state.requesting = "fulfilled";
          state.eventQRcodeData = action.payload.data;
          state.loading = false;
        },
      )
      .addCase(
        fetchEventQRcodeDetailByIdAsync.rejected,
        (state: EventState, action: PayloadAction<any>) => {
          state.requesting = "rejected";
          state.eventQRcodeData = undefined;
          state.loading = false;
          state.eventError = action.payload?.response?.data;
        },
      )
      .addCase(fetchAttendanceLandingByIdAsync.pending, (state: EventState) => {
        state.requesting = "pending";
        state.eventDetailData = undefined;
        state.loading = true;
        state.code = null;
      })
      .addCase(
        fetchAttendanceLandingByIdAsync.fulfilled,
        (state: EventState, action: PayloadAction<any>) => {
          state.requesting = "fulfilled";
          state.eventDetailData = action.payload.data?.data;
          state.loading = false;
          state.code = action.payload?.data?.code;
        },
      )
      .addCase(
        fetchAttendanceLandingByIdAsync.rejected,
        (state: EventState, action: PayloadAction<any>) => {
          state.requesting = "rejected";
          state.eventDetailData = undefined;
          state.loading = false;
          state.code = action.payload?.response?.code;
        },
      )
      .addCase(
        submitParticipantInfoAsync.pending,
        (state: EventState, action: PayloadAction<any>) => {
          state.requesting = "pending";
          state.participantsInfo = undefined;
          state.loading = true;
          state.code = null;
        },
      )
      .addCase(
        submitParticipantInfoAsync.fulfilled,
        (state: EventState, action: PayloadAction<any>) => {
          state.requesting = "fulfilled";
          state.participantsInfo = action.payload?.data?.data;
          state.loading = false;
          state.code = action.payload?.data?.code;
        },
      )
      .addCase(
        submitParticipantInfoAsync.rejected,
        (state: EventState, action: PayloadAction<any>) => {
          state.requesting = "rejected";
          state.participantsInfo = undefined;
          state.loading = false;
          state.code = action.payload?.response?.code;
        },
      );
  },
});

export const { updateSessionCheckData, updateCode } = eventSlice.actions;
