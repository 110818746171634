export const label = { color: "#333" };
export const formControl = {
  mt: 3,
};
export const confirmLabel = {
  marginTop: "3px",
  marginRight: "-8px",
  display: "flex",
  justifyContent: "flex-end",
};
export const confirmRoundLabel = {
  marginTop: "3px",
  display: "flex",
};
export const groupWidth = {
  border: "1px solid #ccc",
  borderRadius: 8,
  marginLeft: 0,
  flex: 1,
};

export const formControlPhone = { mt: 3, display: "flex", justifyContent: "space-between" };
export const inputLabelProps = {
  shrink: true,
  style: { color: "#333" },
};
