import React, { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../redux/store";
import { RegisterStatus } from "../../../network/type";

export const TagComponent = memo(() => {
  // i18n
  const { t } = useTranslation();
  const { isAttended, status } = useAppSelector((state) => state.event.eventQRcodeData)!;

  const isCancelled = status === RegisterStatus.CANCELLED;

  return isCancelled || isAttended ? (
    <div
      className={`absolute top-2 right-2 px-2 py-1 rounded flex items-center justify-center ${
        isCancelled ? "bg-[#F5F5F5]" : "bg-[#DFF7EF]"
      }`}
    >
      <span className={`text-xs ${isCancelled ? "text-[#666666]" : "text-[#24C791]"}`}>
        {isCancelled ? t("event.cancelled") : t("event.attended")}
      </span>
    </div>
  ) : null;
});
