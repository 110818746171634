import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { ConfirmButton } from "../../../../../../components/buttons";
import { HeaderComponent } from "../../../../../../components/header/header.component";
import { SvgAssets } from "../../../../../../components/icon/assets";
import Icon from "../../../../../../components/icon/icon";
import { confirm } from "../../../../../../components/modal/comfirm";
import { PruToast } from "../../../../../../components/pru-toast";
import { useCanGoBack } from "../../../../../../utils/hooks/use-can-go-back";
import { useQuery } from "../../../../../../utils/hooks/use-query";
import { initFileUploadAndDownLoadAndClearForLicenseExam } from "../../../../components/surveyjs/file/upload-file";
import { initSurvey } from "../../../../components/surveyjs/survey/init-survey";
import { markdownHandler } from "../../../../components/surveyjs/survey/survey.util";
import {
  fetchTrainingExamByIdAsync,
  fetchTrainingExamCentresAsync,
  fetchTrainingExamTimeslotsAsync,
  getOnboardingTrainingSummaryAsync,
  postCandidateExamRegistryAsync,
} from "../../../../redux/onboarding-slice";
import {
  selectEnableLicenseExamPayment,
  selectOnboardingCurrentExam,
  selectOnboardingExamCentres,
  selectOnboardingExamTimeslots,
  selectOnboardingTrainingSummary,
} from "../../../../redux/selector";
import {
  CandidateStatus,
  ExamStatus,
  LicenseStatus,
  PaymentStatus,
  PaymentType,
  RegistrationApprovalStatus,
  RegistrationDTO,
  RegistrationStatus,
  RoleEnum,
} from "../../../../types";
import { convertExamSurveyDataToRegistrationDTO } from "../../../form/util";
import { ExamTemplate } from "./templateJson";
import { getToken, parseJwt } from "../../../../utils";
import {
  getTargetCandidate,
  updateTargetCandidatePaymentType,
} from "../../../course-menu-screen/utils";
import { TakeUIScreenViewEventParameters } from "../../../../../../common/ga/types/ga-general-type";
import { takeUIScreenViewEvent } from "../../../../../../common/ga/ga";
import { handleSurveyError } from "../../../../network/network.util";

export const ExamRegistryPaymentScreen: React.FC = () => {
  initSurvey();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const canGoBack = useCanGoBack();
  const dispatch = useDispatch();

  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};

  const [formValues, setForm] = useState({} as any);
  const candidateId = query.get("candidateId");
  const [survey, setSurvey] = useState(undefined as any);
  const [currentPageNo, setCurrentPageNo] = useState(0);

  const [confirmButtonText, setConfirmButtonText] = useState<string>();
  const [examData, setExamData] = useState<RegistrationDTO>({});
  const [surveyIsReadonly, setSurveyIsReadonly] = useState<boolean>(false);

  const currentExam = useSelector(selectOnboardingCurrentExam);
  const [pageInitiating, setPageInitiating] = useState(true);
  const enableLicenseExamPayment = useSelector(selectEnableLicenseExamPayment);

  const candidateOnboardingTrainingSummary = useSelector(selectOnboardingTrainingSummary);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();

  const { examId } = useParams();

  const examCentrePools = useSelector(selectOnboardingExamCentres);
  const examTimeslotPools = useSelector(selectOnboardingExamTimeslots);

  const getPaymentType = () => {
    const targetCandidate = getTargetCandidate();
    return targetCandidate.paymentType;
  };
  const getCandidateStutus = () => {
    const targetCandidate = getTargetCandidate();
    return targetCandidate.status;
  };

  const fetchData = useCallback(async () => {
    if (!examId || !candidateId) return;
    await dispatch<any>(fetchTrainingExamByIdAsync({ candidateId, examId: examId! }));
    dispatch<any>(getOnboardingTrainingSummaryAsync({ candidateId }));
    setPageInitiating(false);
  }, [candidateId, dispatch, examId]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    if (candidateOnboardingTrainingSummary?.trainingSummary) {
      setPaymentStatus(candidateOnboardingTrainingSummary?.trainingSummary?.paymentStatus);
    }
  }, [candidateOnboardingTrainingSummary]);

  const fetchExamCentres = useCallback(async () => {
    if (!currentExam) return;
    await dispatch<any>(fetchTrainingExamCentresAsync(currentExam));
  }, [currentExam, dispatch]);

  useEffect(() => {
    if (!currentExam) {
      return;
    }
    const registrationStatus = currentExam?.originData?.registrationStatus;
    const licenseApprovalStates = currentExam?.originData?.licenseApprovalStates;
    const registrationApprovalStates = currentExam?.originData?.registrationApprovalStates;
    const examStatus = currentExam?.examResult;
    if (
      registrationStatus === RegistrationStatus.NOT_STARTED ||
      registrationStatus === RegistrationStatus.TIMESLOT_CANCELLED ||
      registrationApprovalStates === RegistrationApprovalStatus.CANCELLED ||
      examStatus === ExamStatus.FAIL ||
      registrationApprovalStates === RegistrationApprovalStatus.RETURNED ||
      licenseApprovalStates === LicenseStatus.RETURNED ||
      registrationApprovalStates === RegistrationApprovalStatus.REJECTED ||
      licenseApprovalStates === LicenseStatus.REJECTED
    ) {
      const initFormData: any = {
        hasPassedExam:
          examStatus === ExamStatus.FAIL ||
          registrationApprovalStates === RegistrationApprovalStatus.REJECTED ||
          registrationStatus === RegistrationStatus.TIMESLOT_CANCELLED ||
          registrationApprovalStates === RegistrationApprovalStatus.CANCELLED ||
          licenseApprovalStates === LicenseStatus.REJECTED
            ? ""
            : currentExam?.originData?.hasPassedExam,
        hasLicenseProof:
          examStatus === ExamStatus.FAIL ||
          registrationApprovalStates === RegistrationApprovalStatus.REJECTED ||
          registrationStatus === RegistrationStatus.TIMESLOT_CANCELLED ||
          registrationApprovalStates === RegistrationApprovalStatus.CANCELLED ||
          licenseApprovalStates === LicenseStatus.REJECTED
            ? ""
            : currentExam?.originData?.licenseInfo?.hasLicenseProof,
        paymentType: getPaymentType(),
      };
      const licenseFeeReceiptNo = currentExam?.originData?.examPayment?.licenseFeeReceiptNo;
      const licenseFeeReceiptDate = currentExam?.originData?.examPayment?.licenseFeeReceiptDate;

      if (examStatus === ExamStatus.FAIL && licenseFeeReceiptNo && licenseFeeReceiptDate) {
        initFormData.licenseFeeReceiptNo = licenseFeeReceiptNo;
        initFormData.licenseFeeReceiptDate = licenseFeeReceiptDate;
      }
      setForm(initFormData);
      setSurveyIsReadonly(false);
    } else {
      setForm({
        hasPassedExam: currentExam?.originData?.hasPassedExam,
        hasLicenseProof: currentExam?.originData?.licenseInfo?.hasLicenseProof,
        examCentre: currentExam?.originData?.examSchedule?.examCentre,
        examScheduleId: currentExam?.originData?.examSchedule?.id,
        paymentType: getPaymentType(),
        examScore: currentExam?.originData?.licenseInfo?.examScore,
        examDate: currentExam?.originData?.licenseInfo?.examDate,
        officialReceiptNumber: currentExam?.originData?.licenseInfo?.officialReceiptNumber,
        paymentReceiptDate: currentExam?.originData?.licenseInfo?.paymentReceiptDate,
        proofInfos: currentExam?.originData?.licenseInfo?.proofInfos,
        licenseFeeReceiptNo: currentExam?.originData?.examPayment?.licenseFeeReceiptNo,
        licenseFeeReceiptDate: currentExam?.originData?.examPayment?.licenseFeeReceiptDate,
        examFeeReceiptNo: currentExam?.originData?.examPayment?.examFeeReceiptNo,
        examFeeReceiptDate: currentExam?.originData?.examPayment?.examFeeReceiptDate,
      });
      setSurveyIsReadonly(true);
    }
    if (tokenData.role !== RoleEnum.CANDIDATE) {
      setSurveyIsReadonly(true);
    }
    if (getCandidateStutus() !== CandidateStatus.IN_PROGRESS) {
      setSurveyIsReadonly(true);
    }
  }, [currentExam, tokenData.role]);

  useEffect(() => {
    if (!survey) {
      return;
    }
    const examCentreQuestion = survey.getQuestionByName("examCentre");
    const examRegistrationIdQuestion = survey.getQuestionByName("examScheduleId");
    var centreChoices;
    var timeChoices;
    if (!surveyIsReadonly) {
      centreChoices = examCentrePools.map(function (pool) {
        return {
          value: pool.examCentre,
          text: pool.examCentre,
        };
      });
      timeChoices = examTimeslotPools.map(function (pool) {
        return {
          value: pool.value,
          text: pool.name,
        };
      });
    } else {
      const examSchedule = currentExam?.originData?.examSchedule;
      centreChoices = [
        {
          value: examSchedule?.examCentre,
          text: examSchedule?.examCentre,
        },
      ];
      timeChoices = [
        {
          value: examSchedule?.id,
          text: `${examSchedule?.examDate},${examSchedule?.startTime}-${examSchedule?.endTime}`,
        },
      ];
    }
    examCentreQuestion.choices = centreChoices;
    examRegistrationIdQuestion.choices = timeChoices;
  }, [currentExam, examCentrePools, examTimeslotPools, survey, surveyIsReadonly]);

  useEffect(() => {
    fetchExamCentres();
  }, [fetchExamCentres]);

  useEffect(() => {
    if (currentExam && (examData.examCentre || formValues.examCentre)) {
      dispatch<any>(
        fetchTrainingExamTimeslotsAsync({
          ...currentExam,
          examCentre: examData.examCentre || formValues.examCentre,
        } as any),
      );
    }
  }, [currentExam, dispatch, examData.examCentre, formValues.examCentre]);

  const onSubmitHandler = useCallback(
    (examData: any) => {
      const examTime = examTimeslotPools.find((item) => item.value === examData.examScheduleId);
      confirm({
        message: examData?.hasPassedExam
          ? t("selected_info_confirm", {
              license: currentExam?.name,
              existingLicense: examData.hasPassedExam ? t("app.select.yes") : t("app.select.no"),
              proof: examData.hasLicenseProof ? t("app.select.yes") : t("app.select.no"),
              examProofCopy: examData.hasLicenseProof ? t("uploaded") : t("requested"),
            })
          : t("notPassedExam_selected_info_confirm", {
              time: examTime?.name,
              centre: examData.examCentre,
              remainingQuota: examTime?.remainingQuota,
            }),
        title: t("reminder_title"),
        confirmButtonText: t("global.text.confirm"),
        icon: <Icon svg={SvgAssets.notice} width={80} height={80} />,
        confirmationCheckboxLabel: examData?.hasPassedExam
          ? t("traditional_exam_information_payment_tips")
          : t("timeslot_unchanged_after_register"),
      }).then(async (result) => {
        if (result.ok) {
          const actionResponse = await dispatch<any>(
            postCandidateExamRegistryAsync({
              candidateId: candidateId as string,
              body: convertExamSurveyDataToRegistrationDTO(currentExam, examData),
            }),
          );
          const errors = actionResponse?.error ?? actionResponse?.payload?.errors;
          if (errors) {
            const message = errors.message ?? "Unknown error";
            handleSurveyError({ errors, survey, defaultErrMsg: message });
            return;
          } else {
            if (examData.paymentType) {
              updateTargetCandidatePaymentType(examData.paymentType);
            }
            PruToast({
              status: "success",
              message: t("common.success"),
            });
            if (canGoBack()) navigate(-1);
          }
        }
      });
    },
    [canGoBack, candidateId, currentExam, dispatch, examTimeslotPools, navigate, t, survey],
  );

  useEffect(() => {
    if (examData?.hasPassedExam || !enableLicenseExamPayment) {
      setConfirmButtonText(t("Submit"));
    } else if (currentPageNo === 0) {
      setConfirmButtonText(t("app.button.next"));
    } else {
      setConfirmButtonText(t("register"));
    }
  }, [currentPageNo, t, examData?.hasPassedExam, enableLicenseExamPayment]);

  useEffect(() => {
    convertTemplate(ExamTemplate);
    setSurvey(new Model(cloneDeep(ExamTemplate)));
    const gaData: TakeUIScreenViewEventParameters = {
      module: "Recruitment",
      feature: "License",
      journey: "view_individual_license",
      stage: "journey_landing",
      screen_id: "SCR_RCRTMT_LCNS_DTLS",
      screen_name: "Recruitment-License-Details-Screen",
    };
    takeUIScreenViewEvent(gaData);
  }, []);

  useEffect(() => {
    if (!survey) {
      return;
    }
    const paymentType = survey.getQuestionByName("paymentType");
    const examStatus = currentExam?.examResult;

    const licenseFeeReceiptNoQuestion = survey.getQuestionByName("licenseFeeReceiptNo");
    const paymentTypeQuestion = survey.getQuestionByName("licenseFeeReceiptDate");
    const isExamFailedButCodeNowLater =
      examStatus === ExamStatus.FAIL &&
      currentExam?.originData?.examPayment?.paymentType === PaymentType.CodeNowPayLater;
    const flag =
      paymentType.value === PaymentType.FullPayment &&
      (examStatus !== ExamStatus.FAIL || isExamFailedButCodeNowLater);

    licenseFeeReceiptNoQuestion.visible = flag;
    paymentTypeQuestion.visible = flag;
  }, [currentExam, examData, survey]);

  const form = useMemo(() => {
    if (pageInitiating) return;
    survey.showPageTitles = false;
    survey.showNavigationButtons = "none";
    survey.questionErrorLocation = "bottom";
    survey.showCompletedPage = false;
    survey.mergeData(formValues);
    survey.onComplete.add((_sender: any) => {});
    survey.onTextMarkdown.add(markdownHandler);
    survey.onGetQuestionTitleActions.add((_: any, options: any) => {
      if (options.question.tooltips) {
        options.titleActions = [
          {
            id: "show-popover",
            component: "popover",
            tooltip: options.question.tooltips,
          },
        ];
      }
    });
    setExamData(survey.data);
    survey.onValueChanged.add(async (_sender: any, options: any) => {
      setExamData({ ...survey.data, [options.name]: options.value });
    });

    survey.onCurrentPageChanged.add((_sender: any, options: any) => {
      const currentPageNo = survey.currentPageNo;
      setCurrentPageNo(currentPageNo);
    });
    survey.getAllQuestions().forEach((question: any) => {
      question.readOnly = surveyIsReadonly;
      if (question.name === "paymentType") {
        question.readOnly = getPaymentType() ? true : false;
      }
    });
    initFileUploadAndDownLoadAndClearForLicenseExam(survey);
    return (
      <div className="flex flex-1 flex-col">
        <div className="flex-1 bg-gray-100 flex-col w-screen pb-4">
          <div className="flex pt-5 pl-5 text-xl  font-semibold line-height-28">
            {currentExam?.originData?.examType}
          </div>
          <div className="bg-gray-100 w-screen flex justify-center items-center pt-5">
            <Survey
              currentPageNo={0}
              className="survey-common"
              id="training-payment"
              model={survey}
            />
          </div>
        </div>
      </div>
    );
  }, [formValues, pageInitiating, survey, surveyIsReadonly]);

  const bottom = useMemo(() => {
    if (!survey) {
      return;
    }

    const enabled =
      !surveyIsReadonly ||
      (examData.hasPassedExam === false && survey.currentPageNo === 0 && enableLicenseExamPayment);

    return (
      <div className=" w-screen sticky bottom-0 bg-gray-100 px-4 pt-2 pb-7 flex items-center justify-between gap-4">
        <ConfirmButton
          disabled={!enabled}
          onClick={() => {
            let isValid = false;
            isValid = survey.validateCurrentPage();
            if (!isValid) {
              return;
            }
            if (examData?.hasPassedExam || !enableLicenseExamPayment) {
              onSubmitHandler(examData);
            } else {
              if (survey.currentPageNo === 0) {
                survey.nextPage();
              } else {
                onSubmitHandler(examData);
              }
            }
          }}
        >
          {confirmButtonText}
        </ConfirmButton>
      </div>
    );
  }, [confirmButtonText, onSubmitHandler, survey, surveyIsReadonly, examData]);

  return (
    <div className="h-screen w-screen flex flex-col">
      <HeaderComponent
        title={t("Recruitment.license_exam")}
        onBack={() => {
          if (canGoBack()) {
            navigate(-1);
          }
        }}
      />
      <div className="flex flex-1 flex-col">
        {form}
        {bottom}
      </div>
    </div>
  );
};

const convertTemplate = (template: any) => {
  // Function to translate a given field if it exists
  const translateField = (obj: any, field: string) => {
    if (obj?.[field]) {
      obj[field] = getI18n().t(obj[field]);
    }
  };
  // Loop through each element in the first page
  template.pages.forEach((page: any) => {
    page.elements = page.elements.map((q: any) => {
      translateField(q, "title");
      translateField(q, "placeholder");
      translateField(q, "labelTrue");
      translateField(q, "labelFalse");
      translateField(q, "requiredErrorText");
      translateField(q, "tooltips");

      // translate choices if they exist
      if (q.choices && Array.isArray(q.choices)) {
        q.choices.forEach((choice: any) => {
          translateField(choice, "text");
        });
      }

      // Check if the element has validators and translate their text fields
      if (q.validators && Array.isArray(q.validators)) {
        q.validators.forEach((validator: any) => {
          translateField(validator, "text");
        });
      }

      // If the element is a paneldynamic type, we need to handle nested templateElements
      if (q.type === "paneldynamic" && q.templateElements && Array.isArray(q.templateElements)) {
        q.templateElements = q.templateElements.map((te: any) => {
          translateField(te, "title");
          translateField(te, "labelTrue");
          translateField(te, "labelFalse");
          translateField(te, "requiredErrorText");

          if (te.validators && Array.isArray(te.validators)) {
            te.validators.forEach((validator: any) => {
              translateField(validator, "text");
            });
          }

          return te;
        });
      }

      return q;
    });
  });
};
