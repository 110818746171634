import { get } from "lodash";
import { http } from "../../../utils/axios/axios";
import { UserPersonalData } from "../types";
import { PruToast } from "../../../components/pru-toast";
import { getI18n } from "react-i18next";
import { CORE_URL } from "../../onboarding/utils";
import { getCustomHeader } from "../../onboarding/network/network";
import { DeliveryMethodEnum, OtpContextEnum, UserTypeEnum } from "./enum";
const APIM_ANONYMOUS = get(window, "envConfig.REACT_APP_APIM_ANONYMOUS", "");
const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");

export async function getPRUdnaInvitationStatus(id: string): Promise<any> {
  const url = `/PRUdna/massSharing/${id}`;
  // const url = `http://localhost:2222/PRUdna/massSharing/${id}`;
  return http.get(url, { headers: { __loading: true } }).then((res: any) => res);
}

export async function submitPersonalData(
  param: UserPersonalData,
  sharingId: string,
  navigate: any,
) {
  const url = `/PRUdna/massSharing/${sharingId}/personal-info`;
  // const url = `http://localhost:2222/PRUdna/massSharing/${sharingId}/personal-info`;
  return http
    .post(url, param)
    .then((res: any) => {
      // PruToast({ message: getI18n().t("Submit successfully") });
      setTimeout(() => {
        window.location.href = res.data.examLink;
      }, 0);
      return true;
    })
    .catch((err) => {
      console.log("err===", err);
      const errorKey = get(err, "response.data.message");
      if (errorKey) {
        const msg = getI18n().t(`Recruitment.${errorKey}`);
        if (msg && !msg.startsWith("Recruitment.")) {
          PruToast({ message: msg });
          return false;
        }
      }
      if (err.status === 400) {
        PruToast(err?.data?.message);
      } else if (err.status === 410) {
        PruToast({ message: getI18n().t("Invitation Link Expired") });
      } else if (err.status === 412) {
        PruToast({ message: getI18n().t("You are already a PRU agent.") });
      }
      return false;
    });
}

export async function getPRUdnaCandidateAssessments(
  candidateId: string,
  assessmentId: string,
): Promise<any> {
  const url = `/PRUdna/shares/candidates/${candidateId}/assessments/${assessmentId}`;
  // const url = `http://localhost:2222/PRUdna/shares/candidates/${candidateId}/assessments/${assessmentId}`;
  return http.get(url, { headers: { __loading: true } }).then((res: any) => res?.data);
}

export async function postSharesAssessments(refId: string, param: any) {
  const apiUrl = `${APIM_BASE_URL}${APIM_ANONYMOUS}/tracking/events`;

  const postBody: any = {
    subject: refId,
    data: {
      name: "recruitment::view_assessment",
      type: "start_view",
      module: "recruitment",
      feature: "prudna",
      params: param,
      context: {},
    },
  };
  const response = await http.post(apiUrl, postBody);
  if (response) return response;
}
export const sendOtpReq = async (data: {
  phoneNum: string;
  context: OtpContextEnum;
  userType: UserTypeEnum;
}): Promise<any> => {
  const response = await http.post(
    `${CORE_URL}/auth/otp`,
    {
      ...data,
      lang: "en",
      deliveryMethod: DeliveryMethodEnum.SMS,
    },
    {
      headers: getCustomHeader(),
    },
  );
  if (!response.ok || typeof response.data === "string") {
    return response.data;
  }
  // transform the data into the format we are expecting
  return {
    kind: "ok",
    data: response.data as any,
  };
};

/**
 *
 *  const username = `${value?.region?.code} ${value?.phoneNumber}`;
    const res = await validateOtp({
      username: username,
      otp: `${value?.oid}:${value?.otpCode}`,
      context: props.type,
    });
 * @param data
 * @returns
 */
export const validateOtp = async (data: {
  username: string;
  context: OtpContextEnum;
  otp: string;
  userType: UserTypeEnum;
}): Promise<any> => {
  const response = await http.post(
    `${CORE_URL}/auth/validateOtp`,
    { ...data },
    {
      headers: getCustomHeader(),
    },
  );
  // the typical ways to die when calling an api
  if (!response.ok) {
    return response.data;
  }
  // transform the data into the format we are expecting
  return {
    kind: "ok",
    data: response.data as any,
  };
};
