import { getI18n } from "react-i18next";

export const convertTemplate = (template: any, recruitPublicConfigs: any) => {
  // Function to translate a given field if it exists
  const translateField = (obj: any, field: string) => {
    if (obj?.[field]) {
      obj[field] = getI18n().t(obj[field]);
    }
  };

  const smsOtpField = template.pages[0].elements.find((item: any) => item.name === "otp");
  if (recruitPublicConfigs?.PRUdna?.disableSMSOtp) {
    smsOtpField.isRequired = false;
    smsOtpField.visible = false;
  }

  // Loop through each element in the first page
  template.pages[0].elements = template.pages[0].elements.map((q: any) => {
    // Translate title, labelTrue, labelFalse, requiredErrorText fields
    translateField(q, "title");
    translateField(q, "placeholder");
    translateField(q, "labelTrue");
    translateField(q, "labelFalse");
    translateField(q, "requiredErrorText");
    translateField(q, "tooltips");
    translateField(q?.modalConfig, "modalTitle");

    // translate choices if they exist
    if (q.choices && Array.isArray(q.choices)) {
      q.choices.forEach((choice: any) => {
        translateField(choice, "text");
      });
    }

    // Check if the element has validators and translate their text fields
    if (q.validators && Array.isArray(q.validators)) {
      q.validators.forEach((validator: any) => {
        translateField(validator, "text");
      });
    }

    // If the element is a paneldynamic type, we need to handle nested templateElements
    if (q.type === "paneldynamic" && q.templateElements && Array.isArray(q.templateElements)) {
      q.templateElements = q.templateElements.map((te: any) => {
        translateField(te, "title");
        translateField(te, "labelTrue");
        translateField(te, "labelFalse");
        translateField(te, "requiredErrorText");
        translateField(te?.modalConfig, "modalTitle");

        if (te.validators && Array.isArray(te.validators)) {
          te.validators.forEach((validator: any) => {
            translateField(validator, "text");
          });
        }

        return te;
      });
    }

    return q;
  });
};
