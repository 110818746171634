import React, { useCallback } from "react";
import { Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./test-screen.css";

interface Props {
  message?: string;
}
export const Test1Screen: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);

  const { t } = useTranslation();
  const title = "Test1 Screen";
  window.document.title = title;

  return (
    <React.Fragment>
      <div className="center wrap">
        <h2>{title}</h2>

        <div>
          <h3>page: test demo-page1 </h3>
          <Link to="../demo-page2">to demo-page2</Link>
          <Link to="../">to home</Link>
        </div>
        <div className="note">
          Note: this screen is not wrapped with layout
        </div>
        <p>
          <Button variant="outlined" onClick={goBack}>
            {t("back")}
          </Button>
        </p>
      </div>
    </React.Fragment>
  );
};

export const Test2Screen: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);

  const { t } = useTranslation();
  const title = t("Test page2 Screen");
  window.document.title = title;

  return (
    <React.Fragment>
      <div className="center wrap">
        <h2>{title}</h2>
        <div>
          <Link to="../demo-page1">to demo-page1</Link>
          <Link to="../">to home</Link>
        </div>
        <div className="note">
          Note: this screen is not wrapped with layout
        </div>
        <p>
          <Button variant="outlined" onClick={goBack}>
            {t("back")}
          </Button>
        </p>
      </div>
    </React.Fragment>
  );
};
