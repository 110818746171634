import { ThemeProvider } from "@mui/material";
import React, { FC, useContext, useEffect, useMemo } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouteObject,
  useBlocker,
  useMatch,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import "./App.css";
import { theme } from "./mui-theme/create-theme";
import { routes } from "./routes/route-config";
import { Alert } from "./components/alert/alert";
import { useDispatch, useSelector } from "react-redux";
import { GlobalHelper } from "./utils/helpers/global-helper";
import { ToastContext } from "./components/pru-toast/toast-context";
import { LoadingComponent } from "./components/loading/loading.component";
import { updateGAData } from "./common/ga/redux/ga-slice";
import GAPanel from "./common/ga/components/ga-panel";
import { takeUIScreenViewEvent } from "./common/ga/ga";
import { linkOpen } from "./components/modal/link-open";
import { EVENT_OPEN_LINK_WITHIN_PAGE } from "./constants/constants";
import { checkRouteAccess } from "./utils/security/security-policy-util";
import { ErrorScreen } from "./components/error-screen/error-screen";
import { fetchPolicyConfigAsync } from "./redux/common/common-slice";
import { isEmpty } from "lodash";
import { getI18n } from "react-i18next";
import { prompt } from "./components/modal/prompt";
import { getLoginUserData } from "./modules/onboarding/utils";
import { RoleEnum } from "./modules/onboarding/types";
import { UseModalProvider } from "./components/use-modal/modal";

document.addEventListener(EVENT_OPEN_LINK_WITHIN_PAGE, (event: any) => {
  const url = event?.detail?.url;
  if (!url) return;
  linkOpen({ url });
});

const Routes: FC = () => {
  const element = useRoutes(routes);
  const match = useMatch("*");
  const dispatch = useDispatch();
  GlobalHelper.setGlobalDispatch(dispatch);
  const navigate = useNavigate();
  GlobalHelper.setGlobalNavigate(navigate);

  const toastRef = useContext(ToastContext);
  GlobalHelper.setGlobalToastRef(toastRef);
  useEffect(() => {
    dispatch<any>(fetchPolicyConfigAsync());
  }, []);
  useBlocker(({ nextLocation }: { currentLocation: any; nextLocation: any }) => {
    const { allow } = checkRouteAccess(nextLocation);
    if (!allow) {
      // show alert
      const role = getLoginUserData()?.role;
      if (role === RoleEnum.SECRETARY) {
        prompt({
          title: getI18n().t("Common.secretary_block_alert_title"),
          message: getI18n().t("Common.secretary_block_alert_description"),
          confirmButtonText: getI18n().t("global.text.confirm"),
          onConfirm: () => {},
        });
      }
    }
    return !allow;
  });

  const appPolicyConfig = useSelector((state: any) => state.common.policyConfigs);

  const pageUI = useMemo(() => {
    if (isEmpty(appPolicyConfig)) return null;
    GlobalHelper.setGlobalPublicConfig(appPolicyConfig);
    const { allow } = checkRouteAccess(window.location);
    if (!allow) {
      // show alert
      const role = getLoginUserData()?.role;
      if (role === RoleEnum.SECRETARY) {
        prompt({
          title: getI18n().t("Common.secretary_block_alert_title"),
          message: getI18n().t("Common.secretary_block_alert_description"),
          confirmButtonText: getI18n().t("global.text.confirm"),
          onConfirm: () => {},
        });
      }
      return <ErrorScreen statusCode="403" />;
    }
    return element;
  }, [element, appPolicyConfig]);
  useEffect(() => {
    const findRoute = (
      target: string,
      path: string,
      children?: RouteObject[],
    ): RouteObject | null => {
      if (!children) return null;

      const nextChild = children.find((val: any) => {
        return (
          val.path === "*" ||
          (val.path === "" && target === `${path}${val.path}`) ||
          (val.path !== "" && target.startsWith(`${path}/${val.path}`))
        );
      });

      if (!nextChild) return null;
      if (!nextChild.children) return nextChild;

      return findRoute(target, `${path}/${nextChild.path}`, nextChild.children);
    };

    const eleProps = element?.props;
    if (!eleProps || !match) return;

    const matchPathname = eleProps.match.pathname as string;
    const matchChildren = eleProps.match.route?.children as RouteObject[] | undefined;

    const currentRoute = findRoute(match.pathname, matchPathname, matchChildren);
    if (!currentRoute || !currentRoute.gaData) {
      return;
    }

    dispatch(updateGAData(currentRoute.gaData));
    takeUIScreenViewEvent(currentRoute.gaData);
  }, [match, element, dispatch]);

  return (
    <UseModalProvider>
      <ThemeProvider theme={theme}>
        <Alert />
        {pageUI}
        <GAPanel debug />
        <LoadingComponent />
      </ThemeProvider>
    </UseModalProvider>
  );
};
export const router = createBrowserRouter(
  createRoutesFromElements(<Route path="/*" element={<Routes />} />),
);
