import * as React from "react";
import { SurveyQuestionElementBase } from "survey-react-ui";
import { QuestionSignaturePadModel } from "survey-core";
import Button, { ButtonProps } from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar } from "@mui/material";

import { styled } from "@mui/material/styles";

import { CustomerSignaturePad } from "./pad";
import preview from "./img/preview-open.png";
import edit from "./img/edit.png";
import { AvatarEditor } from "./view";
import { createModalId, ModalsContext } from "../../../../../components/use-modal/use-modal";
import { resizeAndRotate } from "./resize";
import { ActionStatusEnum } from "../file/action-enum";
import { getI18n } from "react-i18next";

const padding = 20;
export class SurveyQuestionSignaturePad extends SurveyQuestionElementBase {
  static contextType = ModalsContext;
  editor = React.createRef<AvatarEditor>();
  width: number;
  height: number;
  dataFormat: string;
  constructor(props: any) {
    super(props);

    this.width =
      window.innerWidth > this.question.signatureWidth + padding * 2
        ? this.question.signatureWidth
        : window.innerWidth - padding * 2;
    this.height = this.question.signatureHeight;

    if (!this.question.value) {
      this.state = { loading: false, value: this.question.value };
    } else {
      this.state = { loading: true };
      this.loadImage(this.question.value);
    }
    this.dataFormat =
      this.question.dataFormat === "jpeg"
        ? "image/jpeg"
        : this.question.dataFormat === "svg"
        ? "image/svg+xml"
        : "";
  }

  async loadImage(resourceId: string) {
    this.setState({
      errorMessage: undefined,
      loading: true,
    });
    this.question.survey.downloadFile(
      this.question,
      this.question.name,
      resourceId,
      async (status, data) => {
        if (status === ActionStatusEnum.error)
          return this.setState({
            loading: false,
            openSnackBar: true,
            errorMessage: getI18n().t("onboarding.fail_to_get_file", {
              fileName: this.question.title,
            }),
          });
        if (status === ActionStatusEnum.success) {
          this.dataFormat = data.mimeType;
          const resizedBase64 = await resizeAndRotate(
            data.base64URL,
            0,
            this.width,
            this.height,
            this.dataFormat,
          );
          this.setState({ value: resizedBase64, loading: false });
        }
      },
    );
  }

  protected get question(): QuestionSignaturePadModel {
    let isModuleReadOnly = false;

    if (this.questionBase.survey) {
      const activePage: any = this.questionBase.survey.currentPage;
      if (activePage) {
        isModuleReadOnly = activePage.readOnly;
      }
    }

    this.questionBase.readOnly = this.questionBase.readOnly || isModuleReadOnly;
    return this.questionBase as QuestionSignaturePadModel;
  }
  getBackGroundColor() {
    const backgroundColorProperty = this.question.getPropertyByName("backgroundColor");
    return this.question.backgroundColor || backgroundColorProperty.defaultValue || "#F5F5F5";
  }
  /**
   *
   * @param data base 64 url
   */
  async fromData(data: string) {
    this.setState({ value: data, origin: data });
  }
  onResourceIdChange(data: string) {
    this.question.value = data;
  }

  openModal() {
    const context = this.context as React.ContextType<typeof ModalsContext>;
    context.openModal({
      id: createModalId(),
      children: (
        <CustomerSignaturePad
          originHeight={this.height}
          originWidth={this.width}
          readonly={this.question.readOnly}
          dataFormat={this.dataFormat}
          title={this.question.title}
          onClose={() => {
            this.setState({ modalStatus: false });
          }}
          questionModel={this.question}
          resourceChange={(data) => {
            this.onResourceIdChange(data);
          }}
          imageChange={(data) => {
            this.fromData(data);
          }}
          data={this.state.value}
        ></CustomerSignaturePad>
      ),
      showHeader: false,
      modalType: "bottom",
      modalProps: {
        className: "dialog-full",
        PaperProps: {
          style: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            width: window.innerWidth + "px",
            height: "100%",
          },
        },
      },
    });
  }
  protected renderElement(): JSX.Element {
    var editViewButton = this.viewEditButton();
    return (
      <div
        style={{
          height: this.height,
          width: this.width,
        }}
        className="relative"
      >
        <div
          style={{
            backgroundColor: this.getBackGroundColor(),
            borderColor: this.getBackGroundColor(),
          }}
          onClick={() => {
            this.openModal();
          }}
          className="h-full w-full flex items-center justify-center rounded-lg border"
        >
          {this.state.loading && (
            <div className="absolute h-full w-full flex items-center justify-center">
              <CircularProgress className="h-6 w-6 text-gray-500" />
            </div>
          )}

          {this.state.value && (
            <AvatarEditor
              style={{
                border: "2px solid #f5f5f5",
                borderRadius: 12,
              }}
              // className="border-2 border-gray-100 rounded-lg"
              ref={this.editor}
              readonly={true}
              image={this.state.value}
              backgroundColor={this.getBackGroundColor()}
              width={this.width}
              height={this.height}
              border={0}
            ></AvatarEditor>
          )}
        </div>
        {editViewButton}
        <Snackbar
          open={this.state.openSnackBar}
          ContentProps={{
            className: "rounded-lg max-w-full",
          }}
          onClick={() => this.setState({ openSnackBar: false })}
          autoHideDuration={4000}
          message={<span className="whitespace-normal text-center">{this.state.errorMessage}</span>}
          onClose={() => this.setState({ openSnackBar: false })}
          sx={{ height: "100%" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        />
      </div>
    );
  }

  viewEditButton(): JSX.Element | null {
    if (!this.question.value) return null;
    return (
      <div
        style={{
          display: "block",
          position: "absolute",
          top: -2,
          right: 0,
        }}
      >
        <ColorButton
          type="button"
          disabled={!!this.state.loading}
          onClick={() => {
            if (!!this.state.errorMessage) this.loadImage(this.question.value);
            else this.openModal();
          }}
        >
          {!this.question.readOnly ? (
            !!this.state.errorMessage ? (
              <RefreshIcon style={{ height: 16, width: 16, color: "#fff" }} />
            ) : (
              <img src={edit}></img>
            )
          ) : (
            <img src={preview}></img>
          )}
        </ColorButton>
      </div>
    );
  }
}

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: 4,
  minWidth: 0,
  borderRadius: 0,
  borderTopRightRadius: 12,
  borderBottomLeftRadius: 12,
  backgroundColor: "rgba(0, 0, 0, 0.50)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.50)",
  },
  "&:active": {
    backgroundColor: "rgba(0, 0, 0, 0.50)",
  },
}));
