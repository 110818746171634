import React, { CSSProperties } from "react";
import {
  ElementFactory,
  QuestionDropdownModel as _QuestionDropdownModel,
  Serializer,
  settings,
} from "survey-core";
import {
  SurveyQuestionDropdown as _SurveyQuestionDropdown,
  SurveyQuestionDropdownBase,
} from "survey-react-ui";
// mui start
import { ThemeProvider, createTheme, Select, MenuItem, Button, Modal } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
// mui end
import * as style from "../survey/common.style";
import { CustomType } from "../survey/survey.util";
import "./index.css";
import moduleStyle from "./style.module.css";
import { dropdownStyles } from "./dropdown.style";
import { ChevronRight } from "@mui/icons-material";
import { FullModalList, ModalData } from "./full-modal";
import { useGlobalModal } from "../../../../../components/use-modal/use-modal";
import { use } from "i18next";
import { isEmpty } from "lodash";

const CUSTOM_TYPE = CustomType.Dropdown;
// const CUSTOM_TYPE = "dropdown2";
type ModalConfig = {
  modalTitle?: string;
  fullScreen?: boolean;
  showSearch?: boolean;
};

export class QuestionDropdownModel extends _QuestionDropdownModel {
  getType() {
    return CUSTOM_TYPE;
  }
  get modalConfig(): ModalConfig {
    return this.getPropertyValue("modalConfig");
  }
  set modalConfig(val: ModalConfig) {
    this.setPropertyValue("modalConfig", val);
  }
}

// Add question type metadata for further serialization into JSON
export const initDropdownSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "placeholder",
        default: "Please select",
      },
      {
        name: "allowClear",
        default: true,
      },
      {
        name: "modalConfig",
        default: {},
      },
      {
        name: "choicesLazyLoadByUrl",
        default: {},
      },
      { name: "allowClear:boolean", alternativeName: "showOptionsCaption", default: true },
      { name: "choicesMin:number", default: 0 },
      { name: "choicesMax:number", default: 0 },
      { name: "choicesStep:number", default: 1, minValue: 1 },
      {
        name: "autocomplete",
        alternativeName: "autoComplete",
        choices: settings.questions.dataList,
      },
      { name: "textWrapEnabled:boolean", default: true },
      { name: "renderAs", default: "default", visible: false },
      { name: "searchEnabled:boolean", default: true, visible: false },
      { name: "searchMode", default: "contains", choices: ["contains", "startsWith"] },
      { name: "choicesLazyLoadEnabled:boolean", default: false, visible: false },
      { name: "choicesLazyLoadPageSize:number", default: 25, visible: false },
      { name: "inputFieldComponent", visible: false },
      { name: "itemComponent", visible: false, default: "" },
    ],
    function () {
      return new QuestionDropdownModel("");
    },
    "selectbase",
  );
};

const theme = createTheme({});

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionDropdownModel(name);
});
// A class that renders questions of the new type in the UI
export class SurveyQuestionDropdown extends SurveyQuestionDropdownBase<QuestionDropdownModel> {
  modal: any;
  constructor(props: any) {
    super(props);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.modal = useGlobalModal();
    this.state = {
      value: this.question.value,
      options: this.getChoices(),
      openModal: false,
    };
  }

  getChoices() {
    const arr = this.question.choices ?? [];
    const arrOption = arr.map((item: any) => {
      if (typeof item === "string") {
        return {
          key: item,
          label: item,
          value: item,
        };
      }
      return {
        key: item.value,
        label: item.text,
        value: item.value,
      };
    });
    return arrOption;
  }
  // get question() {
  //   let isModuleReadOnly = false;

  //   if (this.questionBase.survey) {
  //     const activePage: any = this.questionBase.survey.currentPage;
  //     if (activePage) {
  //       isModuleReadOnly = activePage.readOnly;
  //     }
  //   }

  //   this.questionBase.readOnly = this.questionBase.readOnly || isModuleReadOnly;
  //   return this.questionBase;
  // }
  get value() {
    return this.question.value;
  }
  get placeholder() {
    return this.question.placeholder;
  }
  get modalConfig(): ModalConfig {
    return this.question.modalConfig;
  }
  onAutoCompleteChange(event: any, value: any | null) {
    this.question.value = event.target.value;
  }

  onModalChange(v: ModalData) {
    this.question.value = v.value;
  }

  // Support read-only
  get style() {
    return (
      this.question.getPropertyValue("readOnly") ? { pointerEvents: "none" } : undefined
    ) as CSSProperties;
  }

  renderIcon() {
    return <ChevronRight />;
  }
  onCloseModal() {
    this.modal.close();
  }
  onOpenModal() {
    const modalContent = (
      <div
        className={
          this.modalConfig?.fullScreen ? "h-screen w-screen bg-white flex" : "bg-white rounded-2xl"
        }
      >
        <FullModalList
          data={this.state.options}
          showClose
          selected={this.question.value}
          showSearch={this.modalConfig?.showSearch}
          onClose={this.onCloseModal.bind(this)}
          onChange={this.onModalChange.bind(this)}
          header={this.modalConfig?.modalTitle}
        ></FullModalList>
      </div>
    );

    const modalProps = this.modalConfig?.fullScreen
      ? {
          showHeader: false,
          modalType: "bottom",
          modalProps: {
            className: "dialog-full",
            PaperProps: {
              style: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                width: window.innerWidth + "px",
              },
            },
          },
        }
      : {
          showHeader: false,
        };

    this.modal.open(modalContent, modalProps);
  }
  renderModalScreen() {
    return (
      <ThemeProvider theme={theme}>
        <div style={this.style}>
          <FormControl fullWidth sx={style.formControl} variant="standard">
            <Button
              id={this.question.name}
              style={dropdownStyles.fullModalBtn}
              onClick={this.onOpenModal.bind(this)}
              endIcon={<ExpandMoreIcon />}
            >
              {this.question.value ?? ""}
            </Button>
          </FormControl>
        </div>
      </ThemeProvider>
    );
  }

  renderElement() {
    if (this.modalConfig) return this.renderModalScreen();
    return <_SurveyQuestionDropdown {...this.props} />;
  }
}
