export function isFileTypeValid(fileType: string, allowedTypes: string): boolean {
  if (!allowedTypes) {
    // no set allowedTypes, accept all.
    return true;
  }
  const allowedTypeList = allowedTypes.split(",").map((type) => type.trim());

  for (const allowedType of allowedTypeList) {
    if (allowedType.startsWith(".")) {
      const fileExtension = allowedType.replace(".", "");
      if (fileType.toLowerCase().endsWith(fileExtension.toLowerCase())) {
        return true;
      }
    } else if (allowedType.endsWith("/*")) {
      const mediaType = allowedType.replace("/*", "");
      if (fileType.toLowerCase().startsWith(mediaType.toLowerCase())) {
        return true;
      }
    } else if (isMediaTypeValid(fileType, allowedType)) {
      return true;
    }
  }

  return false;
}

function isMediaTypeValid(fileType: string, mediaType: string): boolean {
  return fileType === mediaType;
}
