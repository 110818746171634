import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Model, settings, surveyLocalization } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/survey.i18n";
import { getI18n } from "react-i18next";
import "survey-core/defaultV2.min.css";

import {
  confirmActionAsyncFunc,
  findPanelIndex,
  findSectionByIndex,
  getTranslations,
  markdownHandler,
} from "./survey.util";
import { FormAction } from "../../../types";
import { initSurvey } from "./init-survey";
import { cloneDeep, get, isEqual } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./survey-override.css";
import { PruToast } from "../../../../../components/pru-toast";
import { mapSurveyLanguage } from "../../../network/network";
import { initFileUploadAndDownLoadAndClear } from "../file/upload-file";
import { useCanGoBack } from "../../../../../utils/hooks/use-can-go-back";
import { GlobalHelper } from "../../../../../utils/helpers/global-helper";
import { renderExternalNavigation, saveAndSubmit } from "./util.components";
import { useQuery } from "../../../../../utils/hooks/use-query";
import { isUrlInWhiteList, onboardingHttp } from "./http";
import { onboardingServerValidators } from "./server-validate";
import { getToken } from "../../../utils";
interface Props {
  json: any;
  onSubmit: (data: any, action: FormAction) => Promise<any>;
  defaultValue?: any;
  currentSectionName: string;
  setCurrentSectionName: (section: string) => void;
  disabled?: boolean;
}

export const SurveyComponent: React.FC<Props> = ({
  onSubmit,
  json,
  defaultValue,
  currentSectionName,
  setCurrentSectionName,
}) => {
  initSurvey();
  const [defaultFormVal, setDefaultFormVal] = useState(defaultValue);
  useEffect(() => {
    setDefaultFormVal((pre: any) => {
      if (isEqual(pre, defaultValue)) {
        return pre;
      } else {
        return defaultValue;
      }
    });
  }, [defaultValue]);
  const navigate = useNavigate();
  const canGoBack = useCanGoBack();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useQuery();
  const [survey, setSurvey] = useState(new Model(json));
  const [pageNo, setPageNo] = React.useState(-1);
  const dataRef = useRef<any>({});
  const isLastSectionSubmitted = useRef<boolean>(false);
  const { isLastSection, isFirstSection } = useMemo(() => {
    const currentModule = json.pages.find((module: any) =>
      module.elements.find((section: any) => section.name === currentSectionName),
    );
    if (!currentModule) {
      return {};
    }
    const result: any = {
      isLastSection: cloneDeep(currentModule.elements).pop().name === currentSectionName,
      isFirstSection: currentModule.elements[0].name === currentSectionName,
    };
    return result;
  }, [json, currentSectionName]);
  useEffect(() => {
    if (currentSectionName) {
      const pageNo = findPanelIndex(json, currentSectionName);
      setPageNo(pageNo);
    }
  }, [json, currentSectionName]);
  useEffect(() => {
    if (!survey.currentPage) return;
    const currentPageQuestions = survey.currentPage.questions;
    const currentPageQuestionsValue = {} as any;
    currentPageQuestions.forEach((question: any) => {
      currentPageQuestionsValue[question.name] = survey.data[question.name];
    });
    dataRef.current.questions = currentPageQuestionsValue;
    dataRef.current.originQuestions = currentPageQuestionsValue;
  }, [survey, currentSectionName]);

  const saveForm = useCallback(
    async (
      data: {
        originQuestions: any;
        questions: any;
      },
      action = FormAction.VALIDATION,
    ) => {
      const { originQuestions, questions } = data;
      if (action !== FormAction.SUBMIT && isEqual(originQuestions, questions)) {
        return;
      }
      const response: any = await onSubmit(questions, action);
      const errors = response?.error ?? response?.payload?.errors;
      if (!errors && !response?.noNeedToast) {
        const message =
          action === FormAction.SAVE
            ? getTranslations().the_data_has_been_saved
            : getTranslations().the_data_has_been_submitted;
        PruToast({
          message: message,
        });
      }
      return response;
    },
    [onSubmit],
  );
  useEffect(() => {
    if (pageNo > survey.pageCount - 1) {
      if (canGoBack()) navigate(-1);
    }
    const updatedCurrentSection = findSectionByIndex(json, pageNo);
    if (updatedCurrentSection) {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      newSearchParams.set("sectionName", updatedCurrentSection.name);
      setSearchParams(newSearchParams, { replace: true });
      GlobalHelper.setOnboardingCurrentSection({
        ...GlobalHelper.getOnboardingCurrentSection(),
        ...updatedCurrentSection,
      });
      setCurrentSectionName(updatedCurrentSection.name);
    }
  }, [pageNo, survey.pageCount, json, navigate, canGoBack, setCurrentSectionName]);
  useEffect(() => {
    isLastSectionSubmitted.current = false;
    return () => {
      const handleSave = async () => {
        const response = await saveForm(dataRef.current, FormAction.SAVE);
        const errors = response?.error ?? response?.payload?.errors;
        if (errors) {
          PruToast({
            message: "Failed to save",
          });
        }
      };
      if (!isLastSectionSubmitted.current) {
        handleSave();
      }
    };
  }, []);
  const decrementPageNo = React.useCallback(async () => {
    const response = await saveForm(dataRef.current, FormAction.SAVE);
    const errors = response?.error ?? response?.payload?.errors;
    if (!errors) {
      setPageNo(pageNo - 1);
    }
  }, [saveForm, pageNo]);

  const incrementPageNo = React.useCallback(async () => {
    await saveAndSubmit({
      survey,
      pageNo,
      isLastSection,
      canGoBack,
      navigate,
      setPageNo,
      saveForm,
      dataRef,
      isLastSectionSubmitted,
      query,
    });
  }, [survey, pageNo, isLastSection, canGoBack, navigate, saveForm, query]);
  const actionButtonsUI = useMemo(() => {
    return renderExternalNavigation({
      survey,
      currentSectionName,
      isFirstSection,
      decrementPageNo,
      incrementPageNo,
    });
  }, [survey, isFirstSection, currentSectionName, decrementPageNo, incrementPageNo]);
  useEffect(() => {
    const _survey = new Model({ ...json });
    setSurvey(_survey);
  }, [json]);
  const surveyFormUI = useMemo(() => {
    const appLang = getI18n().language;
    const surveyLang = mapSurveyLanguage(appLang);
    onboardingServerValidators.setEnv({ lang: appLang });
    survey.locale = surveyLang;
    settings.localization.defaultLocaleName = surveyLang;
    surveyLocalization.defaultLocale = surveyLang;
    survey.showPageTitles = false;
    survey.showNavigationButtons = "none";
    survey.questionErrorLocation = "bottom";
    survey.showCompletedPage = false;
    survey.mergeData(defaultFormVal);
    survey.onComplete.add((_sender: any) => {});
    survey.onValueChanged.add((_sender, options) => {
      dataRef.current.questions = { ...dataRef.current.questions, [options.name]: options.value };
    });
    survey.onTextMarkdown.add(markdownHandler);
    survey.onChoicesLazyLoad.add((_, options) => {
      const choicesLazyLoadByUrl = options.question?.choicesLazyLoadByUrl;
      if (choicesLazyLoadByUrl?.url) {
        const url = `${choicesLazyLoadByUrl.url}${
          choicesLazyLoadByUrl.url.includes("?") ? "&" : "?"
        }skip=${options.skip}&take=${options.take}&filter=${options.filter}`;
        onboardingHttp.get(url).then(({ data }) => {
          let _options: any[];
          if (choicesLazyLoadByUrl?.path) {
            _options = get(data, choicesLazyLoadByUrl?.path);
          } else {
            _options = data;
          }
          const opts = _options.map((option) => {
            return {
              value: get(option, choicesLazyLoadByUrl.valueName, option.value),
              text: get(option, choicesLazyLoadByUrl.titleName, option.text),
            };
          });
          options.setItems(opts, data.total);
        });
      }
    });
    let surveyDisplayLabelProceed: any = {};
    survey.onGetChoiceDisplayValue.add((_, options) => {
      const choicesLazyLoadByUrl = options.question?.choicesLazyLoadByUrl;
      const filterStr = options.values
        .map((value) => `filters[${choicesLazyLoadByUrl.valueName}]=` + value)
        .join("&");
      const questionName = options.question.name;
      if (!surveyDisplayLabelProceed[questionName] && choicesLazyLoadByUrl?.url) {
        const url = `${choicesLazyLoadByUrl.url}${
          choicesLazyLoadByUrl.url.includes("?") ? "&" : "?"
        }skip=0&take=100&${filterStr}`;
        onboardingHttp.get(url, {}).then(({ data }) => {
          let _options: any[];
          if (choicesLazyLoadByUrl?.path) {
            _options = get(data, choicesLazyLoadByUrl?.path);
          } else {
            _options = data;
          }
          const labels = _options
            .map((option, index) => {
              const optValue = get(option, choicesLazyLoadByUrl.valueName, option.value);
              const _index = options.values.indexOf(optValue);
              if (_index !== -1) {
                options.values.splice(index, 1);
                return get(option, choicesLazyLoadByUrl.titleName, option.text);
              }
            })
            .filter((item) => item !== undefined);
          options.setItems(labels.concat(options.values));
          surveyDisplayLabelProceed[questionName] = true;
        });
      }
    });

    /** Use this event to specify a custom error message */
    survey.onValidateQuestion.add((_, options) => {
      const value = options.value;

      const questionType = options.question.getType();
      if (questionType === "file") {
        // validate minimum number of upload-files
        const minFileNumber = options.question.minFileNumber;

        if (minFileNumber && Array.isArray(value) && value.length < minFileNumber) {
          options.error = getI18n().t("onboarding.minimum_file_number", {
            min: minFileNumber,
          });
          return;
        }
      }
      // other validation
      // ...
    });

    settings.web.onBeforeRequestChoices = (sender: any, options: any) => {
      const question = sender.owner.jsonObj;
      const choicesByUrl: any = question?.choicesByUrl;
      const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");
      const isApimUrl = choicesByUrl?.url && choicesByUrl?.url.startsWith(APIM_BASE_URL);
      if (isApimUrl) {
        options.request.setRequestHeader(
          "Ocp-Apim-Subscription-Key",
          get(window, "envConfig.REACT_APP_OEM_APIM_SUBSCRIPTION_KEY", ""),
        );
        if (isUrlInWhiteList(choicesByUrl?.url)) {
          options.request.setRequestHeader("Authorization", getToken());
        } else {
          options.request.setRequestHeader("Authorization", undefined);
        }
      } else {
        options.request.setRequestHeader("Ocp-Apim-Subscription-Key", undefined);
        options.request.setRequestHeader("Authorization", undefined);
      }
    };
    settings.confirmActionAsync = confirmActionAsyncFunc;
    initFileUploadAndDownLoadAndClear(survey);
    return pageNo > -1 ? (
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col w-screen p-4">
          <Survey
            currentPageNo={pageNo}
            className="survey-common"
            id="onboarding-survey"
            model={survey}
          />
        </div>
        {actionButtonsUI}
      </div>
    ) : null;
  }, [survey, defaultFormVal, pageNo, actionButtonsUI]);
  return surveyFormUI;
};
