import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirm } from "../../../../components/modal/comfirm";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { reset } from "../../../auth/redux/auth-slice";

interface HookProps {}
export const useSetting = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isPopupOpen, setPopupOpen] = useState(false);

  const clickSetting = () => {};
  const clickLanguage = () => {
    setPopupOpen(!isPopupOpen);
  };
  const clickDisclaimer = () => {};
  const clickPrivacy = () => {};
  const clickClose = () => {
    navigate(-1);
  };
  const clickLogout = () => {
    confirm({
      title: t("Common.are_you_sure_you_want_to_log_out"),
      message: "",
      confirmButtonText: t("global.text.confirm"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.ok) {
        sessionStorage.removeItem("AUTH_TOKEN");
        dispatch(reset());
        navigate(`${ROOT_PATH}/login`);
      }
    });
  };

  return {
    isPopupOpen,
    clickSetting,
    clickLanguage,
    clickDisclaimer,
    clickPrivacy,
    clickLogout,
    clickClose,
  };
};
