import { makeStyles } from "@mui/styles";

const CONTAINER_HEIGHT = 100;
const TEXT_ONLY_CONTAINER_HEIGHT = 74;
const IMG_ONLY_CONTAINER_HEIGHT = 124;

const CONTAINER_MARGIN_BOTTOM = 16;
const IMG_ONLY_CONTAINER_MARGIN_BOTTOM = 8;
const BORDER_RADIUS = 10;

const IMAGE_CONTAINER_WIDTH = 100;
const ORDER_SIZE = 24;

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "white",
    minHeight: CONTAINER_HEIGHT,
    marginBottom: CONTAINER_MARGIN_BOTTOM,
    borderRadius: BORDER_RADIUS,
    padding: 8,
    position: "relative",
  },
  textOnlyContainer: {
    backgroundColor: "white",
    minHeight: TEXT_ONLY_CONTAINER_HEIGHT,
    marginBottom: CONTAINER_MARGIN_BOTTOM,
    borderRadius: BORDER_RADIUS,
    padding: "12px 16px",
    position: "relative",
  },
  imgOnlyContainer: {
    backgroundColor: "white",
    minHeight: IMG_ONLY_CONTAINER_HEIGHT,
    marginBottom: IMG_ONLY_CONTAINER_MARGIN_BOTTOM,
    borderRadius: BORDER_RADIUS,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  unselected: {
    boxShadow: "0px 4px 16px 0px #00000014",
  },
  selected: {
    border: "2px solid #E8192C",
  },
  pointerEventsNone: {
    pointerEvents: "none",
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
  },
  borderCompensator: {
    margin: -2,
  },
  imgContainer: {
    width: IMAGE_CONTAINER_WIDTH,
    boxSizing: "border-box",
  },
  img: {
    maxHeight: 84,
    objectFit: "contain",
  },
  textContainer: {
    width: `calc(100% - ${IMAGE_CONTAINER_WIDTH}px)`,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  caption: {
    fontSize: 14,
    color: "#BABABA",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    columnGap: 8,
    rowGap: 6,
  },
  label: {
    backgroundColor: "#E0FBFF",
    color: "#24B1C7",
    padding: "4px 8px",
    borderRadius: 4,
    whiteSpace: "nowrap",
  },
  order: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: -1,
    right: -1,
    width: ORDER_SIZE,
    height: ORDER_SIZE,
    backgroundColor: "#E8192C",
    borderRadius: "0 12px",
  },
  orderFont: {
    color: "white",
    fontSize: 16,
  },
}));
