import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { get, omit } from "lodash";
import { RootState } from "../../../../redux/store";
import { SurveyComponent } from "../../../../components/surveyjs/survey/survey.component";
import { Layout } from "../../../../layout/layout";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { StatusCode } from "../../../../components/error-state/error-state";
import bannerDefault from "../form/img/banner.png";
import svgDone from "../form/img/done.svg";
import { StatusEnum, getI18nData, convert2SurveyjsI18nData } from "./form.util";
import { ErrorScreen } from "../../../../components/error-screen/error-screen";
import { getARLConfig, submit } from "../../network/network";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import "./form.css";
import { TakeUIClickEventParameters } from "../../../../common/ga/types/ga-general-type";
import { takeUIClickEvent } from "../../../../common/ga/ga";
import { eventTrackingService } from "../../../../utils/event-tracking/event-tracking.service";
import {
  TrackingInfo,
  TrackingEventType,
  EventFeature,
  EventModule,
} from "../../../../utils/event-tracking/event-tracking.model";
import { bodyText } from "./form.style";
import { Dialog, DialogTitle, Button, Typography, Box } from "@mui/material";

enum ARL_REDIRECTION_TYPE {
  URL = "GIVEN_URL",
  DIRECT_MANAGER = "DIRECT_MANAGER_URL",
}

export const SurveyFormScreen: React.FC = () => {
  const [errorStatus, setErrorStatus] = useState<StatusCode | "0">("0");
  const [submitStatus, setSubmitStatus] = useState<StatusEnum>(StatusEnum.IDLE);
  const [showDirectDialog, setShowDirectDialog] = useState(false);
  const [inactiveAgentErrorData, setInactiveAgentErrorData] = useState<any>();

  const { t } = useTranslation();
  const curLang = i18next.language;

  const params = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const redirectTo = queryParams.get("redirectTo");
  const [data, setData] = useState<{ ui: any }>();
  const [surveyJson, setSurveyJson] = useState<any>();

  useEffect(() => {
    if (!params.refId) {
      return;
    }
    getSurveyData(params.refId, redirectTo !== null);
  }, [params.refId]);

  const getSurveyData = useCallback((refId: string, redirect?: boolean) => {
    getARLConfig(refId, redirect)
      .then((res: any) => {
        const jsonCopy = get(res, "ui.inputFields");
        // const jsonCopy = { ...json };
        if (jsonCopy) {
          const callToAction = get(res, "ui.content.callToAction");
          let tncList = get(res, "ui.content.tncList", []);
          // set complete btn text
          jsonCopy.completeText = convert2SurveyjsI18nData(callToAction);
          // add tnc
          tncList = convert2SurveyjsI18nData(tncList);
          if (tncList) {
            const tnc = {
              type: "tnc",
              name: "tnc",
              titleLocation: "hidden",
              choices: tncList,
            } as any;
            jsonCopy.pages[0].elements.push(tnc);
          }
          setSurveyJson(jsonCopy);
        }
        setData(res);
      })
      .catch((err: AxiosError) => {
        const status = get(err, "response.status", "-1"); // -1: unknown error by default
        setErrorStatus(status.toString() as StatusCode);
        hanldleError(err);
        // setSubmitStatus(StatusEnum.FAILED);
      });
  }, []);

  const hanldleError = useCallback((err: AxiosError) => {
    const status = get(err, "response.status", "-1").toString();

    try {
      if (status === "404" || status === "308") {
        const errorData = JSON.parse(get(err, "response.data.message", "{}"));
        if (
          errorData &&
          (errorData.type === ARL_REDIRECTION_TYPE.DIRECT_MANAGER ||
            errorData.type === ARL_REDIRECTION_TYPE.URL)
        ) {
          setInactiveAgentErrorData(errorData);
        }
        setShowDirectDialog(true);
      }
    } catch (error) {}
  }, []);

  const onRedirectConfirm = useCallback(() => {
    setShowDirectDialog(false);
    if (inactiveAgentErrorData) {
      if (inactiveAgentErrorData.type === ARL_REDIRECTION_TYPE.URL) {
        window.location.replace(inactiveAgentErrorData.value);
      } else {
        const url = `/web/form/${inactiveAgentErrorData.value}?redirectTo=1`;
        window.location.replace(url);
      }
    }
  }, [errorStatus, inactiveAgentErrorData]);

  /** kafka event(not GA) tracking */
  const submitEvent = useCallback((refId: string, resourceUuid: string | null) => {
    if (!resourceUuid) {
      return;
    }
    const postBody: TrackingInfo = {
      subject: "anonymous:ref=refId",
      data: {
        context: {
          src: "publicweb",
          uuid: "",
          userAgent: window.navigator.userAgent,
        },

        name: "prospect::submit_leadform",
        module: EventModule.resource,
        feature: EventFeature.resource,
        target: resourceUuid,
        params: {
          startDatetime: Date.now(),
          refId: refId,
          sessionId: sessionStorage.getItem("session_id") || "",
          target: "submit_referral_link_form", // click target
        },
        type: TrackingEventType.click,
      },
    };
    eventTrackingService.doReport(postBody, true);
  }, []);

  const submitForm = useCallback(
    (formData: any) => {
      if (!params.refId) {
        return;
      }
      // add resource fields for lead-dist
      const queryParams = new URLSearchParams(window.location.search);
      const resourceUuid = queryParams.get("uuid");
      if (resourceUuid) {
        let resourceName: string | undefined = queryParams.get("name") || undefined;
        if (resourceName) {
          resourceName = decodeURIComponent(resourceName);
        }
        formData.resourceUuid = resourceUuid;
        formData.resourceName = resourceName;
        formData.refId = params.refId; // for hash-entry data backtracking
      }
      submit(params.refId, formData)
        .then(() => setSubmitStatus(StatusEnum.DONE))
        .catch((err: AxiosError) => {
          const status = get(err, "response.status", "-1"); // -1: unknown error by default
          setErrorStatus(status.toString() as StatusCode);
        });
      // debug status
      // setSubmitStatus(StatusEnum.DONE);
      submitEvent(params.refId, resourceUuid);
      const gaData: TakeUIClickEventParameters = {
        module: "LeadForm",
        feature: "ReferralLink",
        screen_name: "ReferralLinkPublicPortalForm",
        journey: "referral_link_form_submission",
        stage: "submit_referral_link_form",
        object_name: "CTA",
        object_type: "BUTTON",
      };
      takeUIClickEvent(gaData);
    },
    [params.refId],
  );

  if (errorStatus !== "0" && !inactiveAgentErrorData) {
    return (
      <Layout>
        <ErrorScreen statusCode={errorStatus} />
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="root-wrap">
        <div className="banner">
          <img alt="banner" src={get(data, "ui.content.bannerLink", bannerDefault)} />
        </div>
        {data && (
          <div className="content">
            <h1 className="title">{getI18nData(get(data, "ui.content.title"), curLang)}</h1>
            <p className="sub-title">{getI18nData(get(data, "ui.content.subTitle"), curLang)}</p>

            {/* submit done */}
            <div
              className={
                submitStatus === StatusEnum.DONE
                  ? "flex flex-col w-full h-full overflow-x-hidden items-center justify-center mt-16"
                  : "hidden"
              }
            >
              <div>
                <img alt="submit-done" className="mt-4 h-auto" src={svgDone} />
              </div>
              <div className="text-md lg:text-2xl font-bold pt-6">
                {getI18nData(get(data, "ui.submissionResponse.title"), curLang)}
              </div>
              <div className="text-sm lg:text-lg text-center px-6 pt-2 text-gray-400">
                {getI18nData(get(data, "ui.submissionResponse.content"), curLang)}
              </div>
            </div>
            <div className={submitStatus !== StatusEnum.IDLE ? "hidden" : ""}>
              <div
                style={bodyText}
                className="body"
                dangerouslySetInnerHTML={{
                  __html: getI18nData(get(data, "ui.content.body"), curLang),
                }}
              />
              <div className="flex-container top-line" />
            </div>
            {/** content end */}
          </div>
        )}
        {submitStatus === StatusEnum.IDLE && surveyJson && (
          <SurveyComponent onSubmit={submitForm} json={surveyJson} />
        )}
        <Dialog open={showDirectDialog} fullWidth>
          <Box margin={"16px"}>
            <Typography fontSize={24} fontWeight={600}>
              {t("reminder_title")}
            </Typography>
            <Typography
              lineHeight={"20px"}
              marginTop={"12px"}
              marginBottom={"20px"}
              fontWeight={500}
            >
              {t("arl.redirection.description")}
            </Typography>
            <Button
              onClick={onRedirectConfirm}
              variant="contained"
              fullWidth
              style={{ backgroundColor: "#E8192C", fontWeight: "bold" }}
            >
              {t("arl.redirection.button")}
            </Button>
          </Box>
        </Dialog>
      </div>
    </Layout>
  );
};
