import { FieldObject, extractFormData } from "../../../components/surveyjs/survey/survey.util";
import { extractFormData as extractDefaultFormData } from "../../../components/surveyjs/survey/survey-default-form/util";
import { http } from "../../../utils/axios/axios";
import { MircrositeDetail } from "../pages/microsite/microsite.util";
// import json from "./res.json";

/**
 * ARL page configs, including: location, region-phone and ARL config
 * @param refId
 * @param region
 * @param channel
 * @returns
 */
export function getARLConfig(
  refId: string,
  redirect?: boolean,
): Promise<{ location: unknown; regionPhone: unknown; ui: unknown }> {
  // let url = `http://192.168.19.80:2222/material/agent-referral-link-config/${refId}`;
  let url = `/material/agent-referral-link-config/${refId}`;
  if (redirect) {
    url += "?redirectTo=1";
  }
  return http.get(url, { headers: { __loading: true } }).then((res) => res.data);
}
/**
 * arl form submit
 * @param refId
 * @param data
 * @returns
 */
export function submit(refId: string, data: FieldObject) {
  const param: FieldObject = extractFormData(data);

  // const url = `http://localhost:2222/agent-referral/form/submit/${refId}`;
  const url = `/agent-referral/form/submit/${refId}`;
  return http.post(url, { data: param, headers: { __loading: true } }).then((res) => res.data);
}

/**
 * get golbal country-code & LBU cities
 * @param region sample: HK
 * @param channel sample: AGENCY
 * @returns
 */
export function getCountryCodeAndCity(
  region: string,
  channel: string,
): Promise<{ location: unknown; regionPhone: unknown }> {
  // return new Promise((reslove) => reslove(json as any));
  const url = `/material/country-code-and-city`;
  return http
    .get(url, { params: { region, channel }, headers: { __loading: true } })
    .then((res) => res.data);
}
export function getMicrositeById(refId: string): Promise<MircrositeDetail> {
  // refId = "uEP0T8";
  // const url = `http://localhost:2222/microsite/microsite-by-id/${refId}`;
  const url = `/microsite/microsite-by-id/${refId}`;
  return http.get(url, { headers: { __loading: true } }).then((res) => res.data);
}
/**
 * submit Microsite
 * @param refId
 * @param data
 * @returns
 */
export function submitMicrosite(refId: string, data: FieldObject) {
  const param: FieldObject = extractDefaultFormData(data);
  // const url = `http://localhost:2222/microsite/microsite-submit/${refId}`;
  const url = `/microsite/microsite-submit/${refId}`;
  return http.post(url, { data: param, headers: { __loading: true } }).then((res) => res.data);
}
