export function getImageDimensions(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();

      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };

      img.onerror = reject;
      img.src = event.target?.result as string;
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
/**
 * resize the image with required requiredWidth & requiredHeight. keep original image ratio, default black background
 * @param file source file
 * @param requiredWidth required image width(px)
 * @param requiredHeight required image height(px)
 * @returns
 */
export function resizeImage(file: File, requiredWidth: number, requiredHeight: number) {
  if (!file || !requiredWidth || !requiredHeight) {
    return new Promise((resolve, reject) => reject("Invalid parameters"));
  }
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();

      img.onload = () => {
        // const requiredWidth = 400;
        // const requiredHeight = 600;

        const { width, height } = calculateResizedDimensions(
          img.width,
          img.height,
          requiredWidth,
          requiredHeight,
        );

        canvas.width = requiredWidth;
        canvas.height = requiredHeight;

        const x = (requiredWidth - width) / 2;
        const y = (requiredHeight - height) / 2;

        ctx.fillStyle = "#FFFFFF"; // white background
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, x, y, width, height);

        /**
         * toBlob: it defaults to a black background.
         * so to get a image with white bg, uncomment below code and use newCtx.toBlob() instead
         */
        // Create a new canvas with a white background
        // const newCanvas = document.createElement("canvas");
        // const newCtx = newCanvas.getContext("2d")!;

        // newCanvas.width = canvas.width;
        // newCanvas.height = canvas.height;

        // // Set the new canvas background to white
        // newCtx.fillStyle = "#FFFFFF";
        // newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);

        // // Draw the original canvas onto the new canvas
        // newCtx.drawImage(canvas, 0, 0);

        canvas.toBlob((blob) => {
          if (blob) {
            const newFile = new File([blob], file.name, { type: blob.type });
            resolve(newFile);
          }
        }, "image/jpeg");
      };

      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
  });
}

export function calculateResizedDimensions(
  originalWidth: number,
  originalHeight: number,
  requiredWidth: number,
  requiredHeight: number,
) {
  const originalAspectRatio = originalWidth / originalHeight;
  const requiredAspectRatio = requiredWidth / requiredHeight;

  let width: number, height: number;

  if (originalAspectRatio > requiredAspectRatio) {
    width = requiredWidth;
    height = width / originalAspectRatio;
  } else {
    height = requiredHeight;
    width = height * originalAspectRatio;
  }

  return { width, height };
}
