import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { convertPdfToImages } from "./convert-to-image";
import {
  ReactZoomPanPinchContentRef,
  ReactZoomPanPinchContextState,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";

export interface PdfContext {
  currentPage: number;
  total: number;
  loading: boolean;
  setLoading: (v: boolean) => void;
  refresh: React.Dispatch<React.SetStateAction<{}>>;
  nextPage: () => void;
  prePage: () => void;
  setTotal: (t: number) => void;
  setFile: (t: File | string) => void;
  images: string[];
  setTransferRef?: (ref: ReactZoomPanPinchContextState) => void;
  transferRef?: ReactZoomPanPinchContextState;
}

export const PdfViewContext = createContext<PdfContext>({
  currentPage: 1,
  total: 0,
  refresh: () => {},
  nextPage: () => {},
  prePage: () => {},
  setTotal: () => {},
  loading: false,
  setLoading: (v: boolean) => {},
  images: [],
  /**
   * @param t file Blob or base64 string
   */
  setFile: (t: Blob | string) => {},
});

/**
 *
 * @param loadFile
 * @param startPage
 * @param dynamic
 * @returns
 */
export function usePdfViewContext(
  startPage: number = 1,
  dynamic = false,
  onPageChange?: (v: number) => void,
): PdfContext {
  const [_, refresh] = useState({});
  const totalRef = useRef<number | undefined>();
  const pageRef = useRef<number>(startPage);
  const loadingRef = useRef<boolean>(false);
  const fileRef = useRef<File | string | undefined>();
  const transferStateRef = useRef<ReactZoomPanPinchContextState>();

  const imagesRef = useRef<string[]>([]);
  const currentImage = useRef<string>();

  const nextPage = useCallback(() => {
    if (dynamic && loadingRef.current) return;
    const nextPage = pageRef.current + 1;
    if (nextPage <= (totalRef.current ?? 0)) {
      pageRef.current = nextPage;
      currentImage.current = imagesRef.current[nextPage];
      onPageChange?.(nextPage);
      if (dynamic) loadingRef.current = true;
      refresh({});
    }
  }, [loadingRef, pageRef, totalRef, onPageChange]);
  const prePage = useCallback(() => {
    if (dynamic && loadingRef.current) return;
    const prePage = pageRef.current - 1;
    if (prePage >= 1) {
      pageRef.current = prePage;
      currentImage.current = imagesRef.current[prePage];
      onPageChange?.(prePage);
      if (dynamic) loadingRef.current = true;
      refresh({});
    }
  }, [loadingRef, pageRef, onPageChange]);
  const setTotal = useCallback(
    (t: number) => {
      if (totalRef.current !== undefined) return;
      totalRef.current = t;
      refresh({});
    },
    [totalRef],
  );
  const setLoading = useCallback((v: boolean) => {
    loadingRef.current = v;
    refresh({});
  }, []);

  const setFile = useCallback(async (v: string | File) => {
    loadingRef.current = true;
    fileRef.current = v;
    refresh({});

    const images = await convertPdfToImages(v);
    if (!!images) {
      imagesRef.current = images;
      totalRef.current = images.length;
      currentImage.current = images[pageRef.current];
    }
    loadingRef.current = false;
    refresh({});
  }, []);

  return {
    total: totalRef.current ?? 0,
    currentPage: pageRef.current,
    refresh: refresh,
    nextPage: nextPage,
    images: imagesRef.current,
    prePage: prePage,
    setTotal: setTotal,
    loading: loadingRef.current,
    setLoading: setLoading,
    setFile: setFile,
    setTransferRef: (ref) => {
      transferStateRef.current = ref;
    },
    transferRef: transferStateRef.current,
  };
}
