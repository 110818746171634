import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import "../../pages/detail/detail-screen.css";
import { useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { debounce } from "lodash";
import { formatEventRegistrationDate, formatEventTime } from "../../util/event.util";
import { getI18nData } from "../../../../components/surveyjs/survey/survey.util";
import { EventType } from "../../network/type";
import "./details.css";

const PC_MAX_CONTENT_HEIGHT = 140;
const MOBILE_MAX_CONTENT_HEIGHT = 100;
const MOBILE_BREAK_WIDTH = 768;

export const Details = () => {
  const { t } = useTranslation();
  const [showViewMore, setShowViewMore] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);
  const data = useAppSelector((state) => state.event.eventDetailData);
  const {
    organizer,
    description,
    venue,
    regStartDate,
    regEndDate,
    startTime,
    endTime,
    type,
    quota,
  } = data!;

  const handleResize = useCallback(() => {
    const contentHeight = contentRef.current?.offsetHeight;
    if (window.innerWidth < MOBILE_BREAK_WIDTH) {
      if (contentHeight && contentHeight > MOBILE_MAX_CONTENT_HEIGHT) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }
    } else {
      if (contentHeight && contentHeight > PC_MAX_CONTENT_HEIGHT) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
      }
    }
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    const handleDebounceResize = debounce(() => {
      handleResize();
    }, 300);

    window.addEventListener("resize", handleDebounceResize);

    return () => {
      window.removeEventListener("resize", handleDebounceResize);
    };
  }, [handleResize]);

  return (
    <Fragment>
      <div className="divider" />
      <span className="font-semibold text-[16px] mb-2 md:text-2xl md:mb-[30px]">
        {t("event.details")}
      </span>
      <div
        id="event-details-desc"
        className={`relative w-full ${
          showViewMore && collapse ? "h-[156px] -mb-5 overflow-hidden md:h-[196px] md:mb-0" : ""
        }`}
      >
        <div
          ref={contentRef}
          dangerouslySetInnerHTML={{
            __html: getI18nData(description) || "",
          }}
        />
        {showViewMore && collapse && (
          <div className="w-full h-[100px] absolute bottom-0 left-0 bg-mask flex flex-row items-end justify-center">
            <div className="mb-5 flex flex-row items-center cursor-pointer">
              <span
                className="text-[14px] font-medium md:text-xl"
                onClick={() => setCollapse(false)}
              >
                {t("event.view_more")}
              </span>
              <Icon svg={SvgAssets.arrow} width={24} height={24} />
            </div>
          </div>
        )}
      </div>
      {showViewMore && !collapse && (
        <div className="mt-4 md:mt-[30px] self-center flex flex-row items-center cursor-pointer">
          <span
            className="text-[14px] font-medium leading-5 md:text-xl"
            onClick={() => setCollapse(true)}
          >
            {t("event.collapse")}
          </span>
          <Icon svg={SvgAssets.arrow} width={24} height={24} className="rotate-180" />
        </div>
      )}

      <div className="divider" />
      <div className="columns-1 lg:columns-2 gap-[30px] w-full">
        <DetailItem
          label={t("event.organizer")}
          value={getI18nData(organizer)}
          iconSvg={SvgAssets.customer}
        />
        <DetailItem
          label={t("event.time")}
          value={formatEventTime(startTime, endTime)}
          iconSvg={SvgAssets.time}
        />
        <DetailItem
          label={t("event.location")}
          value={type === EventType.VIRTUAL ? t("event.online_event") : getI18nData(venue)}
          iconSvg={SvgAssets.location}
        />
        <DetailItem
          label={t("event.registration_period")}
          value={formatEventRegistrationDate(regStartDate, regEndDate)}
          iconSvg={SvgAssets.date}
        />
        <DetailItem label={t("event.quota")} value={quota} iconSvg={SvgAssets.quota} />
      </div>
    </Fragment>
  );
};

const DetailItem = ({
  iconSvg,
  label,
  value,
}: {
  iconSvg: unknown;
  label: string;
  value?: string;
}) => {
  return value ? (
    <div className="flex flex-row w-full items-center break-inside-avoid-column mb-3 md:mb-5 last:mb-0">
      <div className="md:w-12 md:h-12 w-10 h-10 rounded-lg bg-[#FFE4E6] md:mr-4 mr-2 flex items-center justify-center flex-shrink-0">
        <Icon
          svg={iconSvg}
          className="md:w-[28px] md:h-[28px] w-[22px] h-[22px] iconColor flex-shrink-0"
        />
      </div>
      <div className="flex flex-col flex-1 min-w-0">
        <span className="text-base font-medium md:text-xl">{label}</span>
        <span className="text-[#666] text-sm font-medium md:text-base">{value}</span>
      </div>
    </div>
  ) : null;
};
