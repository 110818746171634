import React from "react";
import { EventBaseInfoComponent } from "../../components/event-check/event-base-info/event-base-info.component";
import { EventCheckMethodComponent } from "../../components/event-check/event-check-method/event-check-method.component";
import { Header } from "../../components/header";

export const EventCheckPage: React.FC = () => {
  return (
    <div className="flex flex-col w-full min-h-screen overflow-x-hidden bg-[#F5F5F5] break-words">
      <Header />
      <EventBaseInfoComponent />
      <EventCheckMethodComponent />
    </div>
  );
};
