import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AgentProfileItem } from "../types/agent-profile-types";
import { localeMapToBackend } from "../utils/locale-utils";
import { useStyles } from "./styles/basic-info.styles";

type BasicInfoProps = {
  agentProfile: AgentProfileItem;
};

const BasicInfo: FC<BasicInfoProps> = ({ agentProfile }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const mappedLocale = localeMapToBackend[currentLang] || currentLang;

  const { lbuName, designation, license } = agentProfile.agentDetail;

  return (
    <>
      <div className={classes.agentNameStyle}>{agentProfile.name[mappedLocale]}</div>
      <div className={classes.informationContainer}>
        {agentProfile.achievements && agentProfile.achievements.length !== 0 && (
          <div className={classes.informationContentStyle}>
            {agentProfile.achievements.join(", ")}
          </div>
        )}
        {designation[mappedLocale] && (
          <div className={classes.informationContentStyle}>{designation[mappedLocale]}</div>
        )}
        {lbuName && lbuName[mappedLocale] && (
          <div className={classes.informationContentStyle}>{lbuName[mappedLocale]}</div>
        )}
        {license.gi === "Y" && (
          <div className={classes.informationContentStyle}>{t("agentProfile.pp_pgihk_label")}</div>
        )}
        {license.hkfi && (
          <div className={classes.informationContentStyle}>
            {t("agentProfile.pp_ia_license_label")}: {license.hkfi}
          </div>
        )}
        {license.mpf && (
          <div className={classes.informationContentStyle}>
            {t("agentProfile.pp_mpf_license_label")}: {license.mpf}
          </div>
        )}
        {license.sfc && (
          <div className={classes.informationContentStyle}>
            {t("agentProfile.pp_sfc_license_label")}: {license.sfc}
            <br />
            {t("agentProfile.pp_sfc_license_statement")}
          </div>
        )}
      </div>
    </>
  );
};

export default BasicInfo;
