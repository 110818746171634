import React, { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CheckMethod, Option, TextFileConfigType } from "./event-check-method.component";
import { useAppSelector } from "../../../../../redux/store";
import { fetchConfig } from "../../../../agent-referral-link/redux/referral-link-slice";
import { getEntryData } from "../../../../../layout/network/network";
import { useNavigate } from "react-router-dom";
import { formatCountryCode } from "../../../../../components/surveyjs/survey/survey.util";
import { commonSlice } from "../../../../../redux/common/common-slice";
import { customizeConfirm } from "../../../../../components/modal/comfirm/customize-confirm";
import {
  submitParticipantInfoAsync,
  updateCode,
  updateSessionCheckData,
} from "../../../redux/event-slice";
import {
  validateParticipantInfo,
  fetchParticipantsInfoList,
} from "../../../network/event-detail-crud";
import {
  CheckAction,
  CheckInfoType,
  ParticipantInfoKeyType,
  ParticipantType,
  REG_ATTENDANCE_RULE,
  SubmitParticipantsInfoCodeEnum,
} from "../../../network/type";
import { EVENT_PATH, ROOT_PATH } from "../../../../../routes/constants";
import { PruToast } from "../../../../../components/pru-toast";

export interface FormDataType {
  key?: string;
  value: string;
  errorMsg?: string;
}

const { setHashEntryAction } = commonSlice.actions;

export const useEventCheckMethod = ({
  refId,
  sessionId,
  action,
  regAttendanceRule,
}: {
  refId: string;
  sessionId: string;
  action: CheckAction;
  regAttendanceRule: REG_ATTENDANCE_RULE;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkMethod, setCheckMethod] = useState<string>();
  const [isAgent, setIsAgent] = useState<boolean>();
  const [formData, setFormData] = useState<Record<string, FormDataType>>({});
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const timerRef = useRef<any>(null);

  const hashEntry = useAppSelector((state) => state.common?.hashEntry);
  const dropdownConfig = useAppSelector((state) => state.referralLink.dropdown);

  // redux
  const dispatch = useDispatch();

  const { value, defaultValue } = useMemo(() => {
    if (!dropdownConfig || !hashEntry?.data?.region) return {};
    return formatCountryCode(
      dropdownConfig.regionPhone,
      hashEntry?.data?.region?.toLocaleLowerCase(),
    );
  }, [dropdownConfig, hashEntry?.data?.region]);

  useEffect(() => {
    if (refId) {
      getEntryData(refId).then((res) => {
        const { region, channel } = res.data;
        dispatch(setHashEntryAction(res));
        dispatch<any>(fetchConfig({ region, channel }));
      });
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [dispatch, refId]);

  useEffect(() => {
    setSelectedCountryCode(defaultValue?.key);
  }, [defaultValue]);

  const checkMethodOption = useMemo(() => {
    return [
      { text: t("event.qr_code"), value: CheckMethod.QR_CODE },
      { text: t("event.other_ways"), value: CheckMethod.OTHERS },
    ];
  }, [t]);

  const agentOption = useMemo(() => {
    return [
      { text: t("app.select.yes"), value: true },
      { text: t("app.select.no"), value: false },
    ];
  }, [t]);

  const onSelect = (option: Option) => {
    if (typeof option.value === "string") {
      setCheckMethod(option.value);
    } else {
      setIsAgent(option.value);
    }
  };

  const onInputChange = useCallback((value: string, infoKey: ParticipantInfoKeyType) => {
    if (infoKey === ParticipantInfoKeyType.QR_CODES) {
      // format qr code => eg: 1234 1234 1234 1234
      const numericValue = value.replace(/\D/g, "");
      const limitedValue = numericValue.slice(0, 16);
      value = limitedValue.replace(/(\d{4})(?=\d)/g, "$1 ");
    }
    setFormData((prev) => ({ ...prev, [infoKey]: { ...prev[infoKey], value, errorMsg: "" } }));
  }, []);

  const onSelectCountryCode = useCallback((e: any) => {
    setSelectedCountryCode(e.target.value);
  }, []);

  const validateFormat = useCallback(
    async (value: string, curConfig?: TextFileConfigType) => {
      if (!value || !curConfig) return;
      const { checkFormat, invalidMessage = "", infoKey } = curConfig;
      const isValid = !checkFormat || checkFormat?.(value, selectedCountryCode);
      if (!isValid) {
        setFormData((prev) => ({
          ...prev,
          [infoKey]: { ...prev[infoKey], errorMsg: invalidMessage },
        }));
        return false;
      } else {
        try {
          // check registration
          const inputValue =
            infoKey === ParticipantInfoKeyType.QR_CODES ? [value?.replace(/\s/g, "")] : value;
          await validateParticipantInfo(refId, {
            [infoKey]: inputValue,
            sessionId,
            countryCode: infoKey === ParticipantInfoKeyType.PHONE ? selectedCountryCode : undefined,
            action,
          });
          return true;
        } catch (error: any) {
          setFormData((prev) => ({
            ...prev,
            [infoKey]: { ...prev[infoKey], errorMsg: error?.response?.data?.message },
          }));
          return false;
        }
      }
    },
    [action, refId, selectedCountryCode, sessionId],
  );

  const onBlur = useCallback(
    (value: string, curConfig?: TextFileConfigType) => {
      timerRef.current = setTimeout(() => {
        validateFormat(value, curConfig);
      }, 200);
    },
    [validateFormat],
  );

  const onConfirm = useCallback(
    async ({
      infoKey,
      value,
      getContent,
      countryCode,
    }: {
      infoKey: ParticipantInfoKeyType;
      value: string;
      getContent: (param: Array<ParticipantType>) => ReactElement;
      countryCode?: string;
    }) => {
      try {
        const inputValue =
          infoKey === ParticipantInfoKeyType.QR_CODES ? [value?.replace(/\s/g, "")] : value;
        // get participants info
        const result = await fetchParticipantsInfoList(refId, {
          action,
          sessionId,
          [infoKey]: inputValue,
          countryCode: infoKey === ParticipantInfoKeyType.PHONE ? countryCode : undefined,
        });
        dispatch(updateCode(result?.data?.code));
        if (
          result?.data?.code === SubmitParticipantsInfoCodeEnum.ALREADY_CHECKED_IN ||
          result?.data?.code === SubmitParticipantsInfoCodeEnum.ALREADY_CHECKED_OUT
        ) {
          // if participants have already checked-in/checked-out, jump to result page and show already check-in/check-out tips
          return navigate(
            `${ROOT_PATH}/${EVENT_PATH}/check_result/${refId}?sessionId=${sessionId}&checkType=${action}&regAttendanceRule=${regAttendanceRule}`,
          );
        }
        const participants = result?.data?.data;
        const qrCodes = participants?.map((item: ParticipantType) => item.qrCode);
        const content = getContent(participants);
        // show info confirm modal
        const res = await customizeConfirm({
          title: t("event.info_confirm_modal_title"),
          content,
          maxHeight: `${window.screen.height * 0.65}px`,
        });
        // click confirm button
        if (res.ok && !submitting) {
          setSubmitting(true);
          const submitRes = await dispatch<any>(
            submitParticipantInfoAsync({
              refId,
              data: { qrCodes, sessionId, action },
            }),
          );
          setSubmitting(false);
          if (submitRes?.error) return PruToast({ message: t("Common.server_error") });
          const registrationIds = submitRes?.payload?.data?.data?.map(
            (item: CheckInfoType) => item.registrationId,
          );
          // store session check data
          dispatch<any>(
            updateSessionCheckData({ [`session-${sessionId}`]: { qrCodes, registrationIds } }),
          );
          navigate(
            `${ROOT_PATH}/${EVENT_PATH}/check_result/${refId}?sessionId=${sessionId}&checkType=${action}&regAttendanceRule=${regAttendanceRule}`,
          );
        }
      } catch (error: any) {
        setFormData((prev) => ({
          ...prev,
          [infoKey]: { ...prev[infoKey], errorMsg: error?.response?.data?.message },
        }));
      }
    },
    [action, dispatch, navigate, refId, sessionId, regAttendanceRule, submitting, t],
  );

  return {
    timerRef,
    checkMethodOption,
    agentOption,
    onSelect,
    checkMethod,
    isAgent,
    countryCodeOptions: value,
    formData,
    submitting,
    onInputChange,
    selectedCountryCode,
    onSelectCountryCode,
    validateFormat,
    onConfirm,
    onBlur,
  };
};

export const getTextFileConfig = (selectOptions?: any[]) => {
  return {
    [ParticipantInfoKeyType.QR_CODES]: {
      infoKey: ParticipantInfoKeyType.QR_CODES,
      label: getI18n().t("event.participation_qr_code"),
      placeholder: getI18n().t("event.qr_code_placeholder"),
      helperText: getI18n().t("event.qr_code_helper_text"),
      type: "tel",
    },
    [ParticipantInfoKeyType.AGENT_CODE]: {
      infoKey: ParticipantInfoKeyType.AGENT_CODE,
      label: getI18n().t("event.agent_code"),
      placeholder: getI18n().t("event.agent_code_placeholder"),
    },
    [ParticipantInfoKeyType.EMAIL]: {
      infoKey: ParticipantInfoKeyType.EMAIL,
      label: getI18n().t("event.email_address"),
      placeholder: getI18n().t("event.email_address_placeholder"),
      invalidMessage: getI18n().t("event.email_address_invalid_message"),
      checkFormat: (value: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(value);
      },
    },
    [ParticipantInfoKeyType.PHONE]: {
      infoKey: ParticipantInfoKeyType.PHONE,
      label: getI18n().t("event.phone"),
      placeholder: getI18n().t("event.phone_placeholder"),
      invalidMessage: getI18n().t("event.phone_invalid_message"),
      type: "tel",
      selectOptions,
      checkFormat: (value: string, countryCode: string) => {
        const regex = new RegExp(
          selectOptions?.filter((item) => item.key === countryCode)?.[0]?.regex,
        );
        return regex?.test(value);
      },
    },
  };
};
