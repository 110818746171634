import { useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery } from "../../../../../../utils/hooks/use-query";
import { useCanGoBack } from "../../../../../../utils/hooks/use-can-go-back";
import { OnboardingQuiz, OnboardingQuizStatus, OnboardingTrainingCourse } from "../../../../types";
import { selectOnboardingCourses } from "../../../../redux/selector";
import Icon from "../../../../../../components/icon/icon";
import { SvgAssets } from "../../../../../../components/icon/assets";
import {
  ButtonPresets,
  renderButton,
} from "../../../../components/surveyjs/survey/util.components";
import useThrottledTouchEnd from "../../../../hooks/use-throtted-touch-end";

type QuizFeedbackScreenProps = {};

export const CourseQuizFeedbackScreen = (props: QuizFeedbackScreenProps) => {
  useThrottledTouchEnd();
  const { t } = useTranslation();
  const translate = useMemo(
    () => ({
      application: t("Recruitment.onboarding_application"),
    }),
    [t],
  );
  const location = useLocation();
  const query = useQuery();
  const { quizId } = useParams();
  const courses: OnboardingTrainingCourse[] = useSelector(selectOnboardingCourses)!;

  const quiz: OnboardingQuiz = useMemo(() => {
    let _quiz: OnboardingQuiz = null as any;
    if (location.state.quiz) {
      return location.state.quiz;
    }
    for (const course of courses) {
      for (const lesson of course.lessons) {
        if (lesson.quiz?.quizId === quizId) {
          _quiz = lesson.quiz!;
          break;
        }
      }
      if (_quiz) break;
    }

    return _quiz;
  }, [location.state.quiz, courses, quizId]);

  const navigate = useNavigate();
  const canGoBack = useCanGoBack();
  const confirmHandler = useCallback(() => {
    if (canGoBack()) {
      navigate(-1);
    }
  }, [canGoBack, navigate]);
  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen w-screen flex flex-col">
      <div className="flex flex-1 w-full items-center flex-col pt-20">
        <Icon
          svg={
            quiz?.status === OnboardingQuizStatus.PASSED
              ? SvgAssets.congrats
              : SvgAssets.failFeedback
          }
          width={200}
          height={200}
        />
        {quiz?.status === OnboardingQuizStatus.PASSED ? (
          <div className="text-green-500 text-3xl font-semibold">{t("Congratulations!")}</div>
        ) : (
          <div className="text-red-500 text-3xl font-semibold">{t("quiz_result_failed")}</div>
        )}
        <div className="text-gray-900 text-3xl font-bold mt-9">
          <span className="text-[40px]">{quiz.score}</span>
          <span>/100</span>
        </div>
      </div>
      <div className="flex w-full px-4 mb-6">
        {renderButton(
          quiz?.status === OnboardingQuizStatus.PASSED ? t("proceed") : t("retake"),
          confirmHandler,
          ButtonPresets.primary,
          true,
        )}
      </div>
    </div>
  );
};
