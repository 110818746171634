import React from "react";
import type { RouteObject } from "react-router-dom";
import { PersonalInfoScreen } from "./pages/personal-info-form/personal-info-form.screen";
import { LinkExpiredScreen } from "./pages/personal-info-form/link-expired.screen";
import { RedirectScreen } from "./pages/redirect/redirect.screen";

export const routesPRUdna: RouteObject[] = [
  { path: "assess-booking", element: <PersonalInfoScreen /> },
  { path: "link-expired", element: <LinkExpiredScreen /> },
  { path: "prudna-redirect", element: <RedirectScreen /> },
];
