import file from "./image/file.png";
import pdf from "./image/pdf.png";
import fileFailed from "./image/file_fail.png";

import picture from "./image/picture.png";
import pictureFailed from "./image/picture_fail.png";

export enum IconType {
  PICTURE = "picture",
  PDF = "pdf",
}

export const getIconSource = (loading: boolean, success: boolean, canPreview: boolean) => {
  if (loading) {
    if (canPreview) {
      return picture;
    } else {
      return file;
    }
  }
  if (success) {
    if (canPreview) {
      return picture;
    } else {
      return pdf;
    }
  } else {
    if (canPreview) {
      return pictureFailed;
    } else {
      return fileFailed;
    }
  }
};
export const getIconSourceByType = (type: IconType) => {
  if (type === IconType.PDF) {
    return pdf;
  } else {
    return picture;
  }
};
