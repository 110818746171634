import { makeStyles } from "@mui/styles";

const MAIN_WIDTH = 30;
const SWITCH_CONTAINER_WIDTH = 86;
const SWITCH_CONTAINER_HEIGHT = 36;
const SWITCH_CONTAINER_SIDE_PADDING = 4;
const SWITCH_CONTAINER_TOP_BOTTOM_PADDING = 3;

const SWITCH_WIDTH = (SWITCH_CONTAINER_WIDTH - SWITCH_CONTAINER_SIDE_PADDING * 2) / 2;
const SWITCH_HEIGHT = SWITCH_CONTAINER_HEIGHT - SWITCH_CONTAINER_TOP_BOTTOM_PADDING * 2;
const PROFILE_PIC_TOP = -90;

export const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: -PROFILE_PIC_TOP,
  },
  banner: {
    width: "100%",
  },
  imgCircleContainer: {
    width: 124,
    height: 124,
    borderWidth: 6,
    borderColor: "white",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    left: MAIN_WIDTH,
    top: PROFILE_PIC_TOP,
  },
  imgCircle: {
    borderRadius: "50%",
    // public portal specific
    width: "100%",
    height: "-webkit-fill-available",
    objectFit: "cover",
  },
  leftRedCircle: {
    borderRadius: "0 90px 0 0",
    height: 90,
    width: 90,
    backgroundColor: "white",
    opacity: 0.16,
    position: "absolute",
    top: 110,
    left: 15,
  },
  rightTopRedCircle: {
    height: 90,
    width: 90,
    backgroundColor: "white",
    opacity: 0.16,
    position: "absolute",
    right: 15,
    borderRadius: "0 0 0 90px",
  },
  rightMiddleRedCircle: {
    position: "absolute",
    top: 180,
    right: 15,
    height: 85,
    backgroundColor: "#E8192C26",
    borderRadius: "0 0 85px 85px",
    width: 140,
  },
  rightBottomRedCircle: {
    right: 15,
    width: 70,
    height: 150,
    top: 190,
    position: "absolute",
    borderRadius: "70px 0 0 70px",
    backgroundColor: "#E8192C26",
  },
  mainContainer: {
    position: "relative",
  },
  switchContainer: {
    backgroundColor: "#F5F5F5",
    position: "absolute",
    top: 16,
    right: 30,
    width: SWITCH_CONTAINER_WIDTH,
    height: SWITCH_CONTAINER_HEIGHT,
    padding: `${SWITCH_CONTAINER_TOP_BOTTOM_PADDING}px ${SWITCH_CONTAINER_SIDE_PADDING}px`,
    borderRadius: 10,
    zIndex: 1,
  },
  switch: {
    width: SWITCH_WIDTH,
    height: SWITCH_HEIGHT,
    margin: 0,
    // public portal specific
    justifyContent: "center",
  },
  switchChecked: {
    color: "#E8192C",
    backgroundColor: "white",
    borderRadius: 8,
    // public portal specific
    margin: 0,
    justifyContent: "center",
  },
  switchUnchecked: {
    color: "#999999",
    // public portal specific
    margin: 0,
    justifyContent: "center",
  },
  switchLabel: {
    width: "100%",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Arial",
  },
  langCheckBox: {
    display: "none",
  },
}));
