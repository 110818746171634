import React, { useEffect, useMemo, useRef, useState } from "react";
import { Model, settings, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import { getI18n, useTranslation } from "react-i18next";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { useQuery } from "../../../../utils/hooks/use-query";
import { PruToast } from "../../../../components/pru-toast";
import { useNavigate } from "react-router-dom";
import { PersonalInfoTemplate } from "./template";
import { getPRUdnaInvitationStatus, submitPersonalData } from "../../network/network";
import { cloneDeep } from "lodash";
import { getFullRegionList, getRecruitPublicConfig } from "../../../common/network/network";
import { initSurvey } from "../../../onboarding/components/surveyjs/survey/init-survey";
import {
  markdownHandler,
  confirmActionAsyncFunc,
  CustomFunctionName,
} from "../../../onboarding/components/surveyjs/survey/survey.util";
import moment from "moment";
import { PRUDNA_PATH, ROOT_PATH } from "../../../../routes/constants";
import "../../../onboarding/components/surveyjs/survey/survey-override.css";
import { convertLanguageKey } from "./utils";
import i18next from "i18next";
import { color } from "../../../../mui-theme/palette";
import { CircularProgress } from "@mui/material";
import "./personal-info-form.dispatch.css";
import { validateOtpFunc } from "./validate-otp";
import { OtpErrorCodeEnum, handlerError } from "./handle-otp-error";
import { getOid } from "./send-otp";
import { convertTemplate } from "./template-convert";
import { NameCompositionEnum } from "../../../../constants/constants";
import {
  TakeUIClickEventParameters,
  TakeUIScreenViewEventParameters,
} from "../../../../common/ga/types/ga-general-type";
import { takeUIClickEvent, takeUIScreenViewEvent } from "../../../../common/ga/ga";
import PageContainer from "../../../../components/page-container/page-container";
import { OtpContextEnum, UserTypeEnum } from "../../network/enum";

const elements = PersonalInfoTemplate.pages[0].elements;
export const PersonalInfoScreen: React.FC = () => {
  initSurvey();

  const [recruitPublicConfigs, setRecruitPublicConfigs] = useState({} as any);
  const template = useMemo(() => {
    const candidateFullnameComposition = recruitPublicConfigs?.candidateFullnameComposition;
    if (
      candidateFullnameComposition &&
      !candidateFullnameComposition.includes(NameCompositionEnum.lastName)
    ) {
      const index = elements.findIndex((ele) => ele.name === NameCompositionEnum.lastName);
      if (index > -1) {
        elements[index - 1].title = "Common.fullname";
        elements.splice(index, 1);
      }
    }
    const _template = cloneDeep(PersonalInfoTemplate);
    convertTemplate(_template, recruitPublicConfigs);
    return _template;
  }, [i18next.language, recruitPublicConfigs]);
  const { t } = useTranslation();
  const query: any = useQuery();
  const navigate = useNavigate();
  const [formValues, setForm] = useState({} as any);
  const sharingId = query.get("sharingId");
  const [loading, setLoading] = useState(true);
  const onConfirm = useRef(false);
  // TODO: getLanguage
  useEffect(() => {
    if (!sharingId) {
      PruToast({ message: "Wrong url, please check" });
      return;
    }
    setLoading(true);
    Promise.all([
      getFullRegionList(),
      getPRUdnaInvitationStatus(sharingId),
      getRecruitPublicConfig(),
    ])
      .then((reses: any) => {
        const [regionConfigs, invitationRes, recruitPublicConfigRes] = reses;
        setRecruitPublicConfigs(recruitPublicConfigRes.Recruitment);
        const invitation = invitationRes.data;
        setLoading(false);
        if (moment().isAfter(moment(invitation.expiredAt))) {
          // link is expired
          return navigate(`${ROOT_PATH}/${PRUDNA_PATH}/link-expired`);
        }
        const regionPhoneField = elements.find((item: any) => item.name === "regionPhone");
        if (regionPhoneField?.templateElements?.[0] && regionConfigs) {
          const regionOpts = Object.values(regionConfigs).map((region: any) => ({
            text: `${region.code} ${region.displayName}`,
            key: region.code,
            value: region.code,
            reg: region.regex,
          }));
          // @ts-ignore
          regionPhoneField.templateElements[0].choices = regionOpts;
          setForm({
            regionCode: regionOpts?.[0].key ?? "+852",
          });
        } else {
          setForm({
            regionCode: "+852",
          });
        }
        const invitationLangCode = invitation.examLanCode;
        const appLang = convertLanguageKey(invitationLangCode);
        sessionStorage.setItem("infoFormLang", appLang);
        i18next.changeLanguage(appLang);
      })
      .catch((err) => {
        return navigate(`${ROOT_PATH}/${PRUDNA_PATH}/link-expired`);
      });
  }, [sharingId]);
  const dataRef = useRef<any>({});
  const form = useMemo(() => {
    const survey = new Model(cloneDeep(template));
    survey.showPageTitles = false;
    survey.showNavigationButtons = "none";
    survey.questionErrorLocation = "bottom";
    survey.showCompletedPage = false;
    const defaultValue = {
      firstName: formValues.firstname ?? "",
      lastName: formValues.lastname ?? "",
      // gender: true,
      email: formValues.email ?? "",
      languagePreference: formValues.languagePreference !== "en",
      regionPhone: [
        {
          regionCode: formValues.regionCode ?? "",
          phoneNumber: formValues.phoneNumber,
        },
      ],
    };
    survey.mergeData(defaultValue);
    survey.onComplete.add((_sender: any) => {});
    survey.onTextMarkdown.add(markdownHandler);
    settings.confirmActionAsync = confirmActionAsyncFunc;
    return loading ? (
      <div className={`h-screen flex flex-1 items-center justify-center`}>
        <CircularProgress sx={{ color: color.majorRed }} />
      </div>
    ) : (
      <div className="flex flex-1 flex-col max-w-md px-6">
        <div className="flex flex-1 flex-col w-full pb-4">
          <Survey currentPageNo={0} className="survey-common" id="personal-info" model={survey} />
        </div>

        <div className=" w-full sticky bottom-0 bg-white pt-2 pb-7 flex items-center justify-between gap-4">
          <button
            className="bg-red-500 flex-1 text-white font-semibold py-3  rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
            disabled={onConfirm.current}
            onClick={async () => {
              const gaData: TakeUIClickEventParameters = {
                module: "Prudna",
                feature: "MassSharing",
                journey: "start_prudna",
                stage: "submit_personal_info_form",
                screen_id: "SCR_PRUDNA_PRSNL_INF_FRM",
                screen_name: "Prudna-Personal-Info-Form-Screen",
                object_name: "Confirm",
                object_type: "BUTTON",
              };
              takeUIClickEvent(gaData);

              if (onConfirm.current) return;
              onConfirm.current = true;
              let isValid = false;
              isValid = survey.validateCurrentPage();

              if (!isValid) {
                onConfirm.current = false;
                return;
              }
              const oid = getOid();
              if (!oid) {
                onConfirm.current = false;
                return handlerError(undefined, OtpErrorCodeEnum.INVALID_OTP_CODE);
              }
              const surveyData = survey.data;
              const personalData: any = {};

              personalData.firstName = surveyData.firstName;
              personalData.lastName = surveyData.lastName;
              personalData.email = surveyData.email;
              personalData.callingCode = survey.data.regionPhone[0].regionCode;
              personalData.number = survey.data.regionPhone[0].phoneNumber;
              const res = await validateOtpFunc({
                context: OtpContextEnum.PublicSharing,
                otp: `${getOid()}:${surveyData.otp}`,
                regionCode: personalData.callingCode,
                phone: personalData.number,
                userType: UserTypeEnum.ANONYMOUS,
              });
              if (!res) {
                onConfirm.current = false;
                return;
              }
              const submitRes = await submitPersonalData(personalData, sharingId, navigate);
              /**
               * if res === false or undefined should let user press confirm again
               */
              if (!submitRes) onConfirm.current = false;
            }}
          >
            {t("global.text.confirm")}
          </button>
        </div>
      </div>
    );
  }, [formValues, template]);

  useEffect(() => {
    const gaData: TakeUIScreenViewEventParameters = {
      module: "Prudna",
      feature: "MassSharing",
      journey: "start_prudna",
      stage: "complete_personal_info_form",
      screen_id: "SCR_PRUDNA_PRSNL_INF_FRM",
      screen_name: "Prudna-Personal-Info-Form-Screen",
    };
    takeUIScreenViewEvent(gaData);
  }, []);

  return (
    <PageContainer title={t("Sales.personal_info")} id={"personal-info"}>
      {form}
    </PageContainer>
  );
};
