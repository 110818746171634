import React, { useEffect, useMemo, useState } from "react";
import { Model, surveyLocalization } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { formatCity, setRegionElementChoice, initValidation } from "./util";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { fetchConfig } from "../../../../modules/agent-referral-link/redux/referral-link-slice";
import {
  convert2SurveyLocale,
  formatCountryCode,
  getElementByName,
  setElementChoice,
  setElementProps,
} from "../survey.util";
import i18next from "i18next";
import "../survey-override.css";

interface Props {
  json: object | undefined;
  onSubmit: (obj: any) => void;
  disabled?: boolean;
}

export const SurveyForm: React.FC<Props> = ({ onSubmit, json }) => {
  initValidation();
  const dispatch = useDispatch();
  const curLang = i18next.language;

  const hashEntry = useSelector<RootState>((state) => state.common?.hashEntry) as any;
  // regionPhone & city config
  const dropdownConfig = useSelector<RootState>((state) => state.referralLink.dropdown) as any;
  const [survey, setSurvey] = useState<Model | undefined>(undefined);

  const isPreview = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get("preview") === "1";
  }, []);

  useEffect(() => {
    if (!isPreview || !survey) {
      return;
    }
    // disable input on preview mode
    const element = document.getElementById("custom-survey");
    if (element) {
      element.style.pointerEvents = "none";
    }
  }, [isPreview, survey]);

  useEffect(() => {
    if (!dropdownConfig) {
      return;
    }
    const city = getElementByName(json, "city");
    let jsonData = json;
    if (city) {
      const cityData = formatCity(dropdownConfig.location);
      jsonData = setElementChoice(json, "city", cityData);
    }
    const regionPhone = getElementByName(json, "regionPhone");
    if (regionPhone) {
      const { region } = hashEntry?.data;
      const { value, defaultValue } = formatCountryCode(
        dropdownConfig.regionPhone,
        region.toLocaleLowerCase(),
      );
      jsonData = setRegionElementChoice(json, "regionPhone", value, defaultValue);
    }
    jsonData = setElementProps(json, "dateOfBirth", "date", "inputType");

    const customLocaleStrings = {
      pagePrevText: "上一頁",
      pageNextText: "下一頁",
    };

    surveyLocalization.locales["zh-tw"] = customLocaleStrings;

    const survey = new Model(jsonData);
    survey.questionErrorLocation = "bottom";

    // check survey validation when the "Complete" button is clicked
    survey.onComplete.add(function (sender, options) {
      // console.log("sender====", sender.data);
      // return;
      if (!survey.isValid) {
        // prevent completing the survey if it's invalid
        // options.allowComplete = false;
      }

      onSubmit(sender.data);
    });

    survey.onErrorCustomText.add(function (sender, options) {
      const errorText = options.error?.text;
      if (!errorText) {
        /**
         * override the default-message(no i18n needed) of required field
         * 'Response required.' -> 'Field is required'
         */
        options.text = "Field is required";
      }
    });

    // final survey model
    setSurvey(survey);
  }, [dropdownConfig, json]);

  useEffect(() => {
    if (!hashEntry || !hashEntry.data) {
      return;
    }
    const { region, channel } = hashEntry.data;
    dispatch<any>(fetchConfig({ region, channel }));
  }, [hashEntry]);

  if (!survey) {
    return null;
  }
  return <Survey id="default-survey" model={survey} locale={convert2SurveyLocale(curLang)} />;
};
