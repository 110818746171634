import { isEmpty } from "lodash";
import { onboardingHttp } from "./http";

// Interface defining the structure of a validation rule.
// Users need to define their own validation rules based on this interface.
interface ValidationRule {
  // The name of the question, used to extract the corresponding value from the data.
  questionName: string;
  // The URL of the API, which may include placeholders for environment variables.
  apiUrl: string;
  // The name of the query parameter, default is "value".
  queryWith?: string;
  // A map of error messages, returning corresponding error messages based on HTTP status codes.
  errorMessagesByStatus: Record<number, () => string>;
  // A function to determine if the response indicates a successful validation.
  isSuccess: (response: any) => boolean;
}

// Configuration interface for the validator, containing an array of validation rules.
interface ValidatorConfig {
  rules: ValidationRule[];
}

// Interface for environment configuration, allowing dynamic setting of environment variables.
interface EnvConfig {
  [key: string]: string;
}

// Class implementing the validator functionality.
class Validator {
  config: ValidatorConfig;
  env: EnvConfig;

  // Constructor initializes the validator with a configuration.
  constructor(config: ValidatorConfig) {
    this.config = config;
    this.env = {};
  }

  // Method to set environment variables.
  setEnv(newEnv: EnvConfig) {
    this.env = { ...this.env, ...newEnv };
  }

  // Asynchronous method to validate data against the configured rules.
  async validate(data: any): Promise<Record<string, string>> {
    const errors: Record<string, string> = {};

    // Iterate over each validation rule.
    for (const rule of this.config.rules) {
      const { questionName, apiUrl, errorMessagesByStatus, queryWith = "value", isSuccess } = rule;
      const value = data[questionName];

      // If the value exists, proceed with validation.
      if (value) {
        try {
          // Replace placeholders in the API URL with actual environment variables.
          const url = apiUrl.replace(/{([^}]+)}/g, (_, key) => this.env[key] || "");
          const response = await onboardingHttp.get(
            `${url}${url.includes("?") ? "&" : "?"}${queryWith}=${encodeURIComponent(value)}`,
          );
          const statusCode = response.status ?? response.statusCode;
          if (!response.ok && statusCode !== 200) {
            throw new Error(`HTTP error! Status: ${statusCode}`);
          }

          // If the response does not indicate success, add an error message.
          if (!isSuccess(response)) {
            const errGenearate = errorMessagesByStatus[statusCode] ?? errorMessagesByStatus[404];
            errors[questionName] = errGenearate?.() ?? "Invalid input";
          }
        } catch (error) {
          console.error("Error fetching validation data:", error);
        }
      }
    }

    // Return the accumulated errors.
    return errors;
  }
}

// Export an instance of the validator with predefined rules.
export const onboardingServerValidators = new Validator({
  rules: [
    {
      questionName: "country",
      apiUrl: "https://surveyjs.io/api/CountriesExample?lang={lang}",
      queryWith: "name",
      errorMessagesByStatus: {
        404: () => "Country is not found",
        500: () => "Internal server error occurred",
      },
      isSuccess: (response) => !isEmpty(response.data),
    },
  ],
});
