import React, { SyntheticEvent } from "react";
import { ElementFactory, FunctionFactory, Question, Serializer } from "survey-core";
// mui
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import * as style from "../survey/common.style";
// mui end
import { SurveyQuestionElementBase } from "survey-react-ui";
import { get } from "lodash";
import { AutoCompleteType, CustomType, getI18nData } from "../survey/survey.util";

const CUSTOM_TYPE = CustomType.RegionPhone;

export class QuestionRegionPhoneModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get placeholder(): string {
    return this.getPropertyValue("placeholder");
  }
  set placeholder(val) {
    this.setPropertyValue("placeholder", val);
  }
  get choices() {
    return this.getPropertyValue("choices");
  }
  set choices(val) {
    this.setPropertyValue("choices", val);
  }
  get extra() {
    return this.getPropertyValue("extra");
  }
  set extra(val) {
    this.setPropertyValue("extra", val);
  }
}

// Add question type metadata for further serialization into JSON
export const initRegionPhoneSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "choices",
        default: [],
      },
      {
        name: "extra",
        default: undefined,
      },
      {
        name: "placeholder",
        default: "Please input",
      },
    ],
    function () {
      return new QuestionRegionPhoneModel("");
    },
    "question",
  );
};

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionRegionPhoneModel(name);
});

// A class that renders questions of the new type in the UI
export class SurveyQuestionRegionPhone extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      regionOptions: this.question.choices,
      defaultOption: this.question.extra,
    };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get disableAlpha() {
    return this.question.disableAlpha;
  }
  get type() {
    return this.question.colorPickerType;
  }
  get placeholder() {
    return getI18nData(this.question.placeholder);
  }
  onAutoCompleteChange(event: SyntheticEvent<Element, Event>, value: AutoCompleteType | null) {
    this.question.value = { ...this.question.value, region: value, type: CUSTOM_TYPE };
  }
  onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event?.target;

    const region = this.question.value?.region || this.state.defaultOption;
    this.question.value = { region, number: value, type: CUSTOM_TYPE };
  }

  renderElement() {
    const defaultProps = {
      options: this.state.regionOptions,
      getOptionLabel: (option: AutoCompleteType) => option.key,
      defaultValue: this.state.defaultOption,
    };
    return (
      <div className="box-phone">
        <div className="box-item-left">
          <FormControl fullWidth sx={style.formControlPhone} variant="standard">
            <Autocomplete
              {...defaultProps}
              disableClearable
              onChange={this.onAutoCompleteChange.bind(this)}
              disablePortal
              id={this.question.name}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={style.inputLabelProps}
                  size="medium"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </div>
        <div className="box-item-right">
          <FormControl fullWidth sx={style.formControlPhone} variant="standard">
            <TextField
              color="info"
              id="phoneNumber"
              placeholder={this.placeholder}
              onChange={this.onInputChange.bind(this)}
              InputLabelProps={style.inputLabelProps}
              size="medium"
              variant="outlined"
            />
          </FormControl>
        </div>
      </div>
    );
  }
}
/**
 * check phone number regex when it's not empty
 * @param param0
 * @returns
 */
function verifyRegionPhone([value]: { region: object; number: string }[]): boolean {
  if (!value.number || !value.region) {
    return false;
  }
  const regex = new RegExp(get(value, "region.regex", "^(\\d){5,20}$") as string);
  return regex.test(value.number);
}

/** custom validation */
FunctionFactory.Instance.register("phoneNumberValidate", verifyRegionPhone);
