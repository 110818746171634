import { makeStyles } from "@mui/styles";
import { isPhone } from "../../../../../../constants/constants";

const INNER_WIDTH = window.innerWidth;
const SECTION_PADDING = 20;
const SECTION_MARGIN = 8;
const BASIC_INFO_SECTION_PADDING = "40px 30px 16px";
const PROMOTION_CARD_COLUMN_GAP = 29;

const iPadViewContainerStyle = {
  display: "flex",
  columnGap: 8,
};

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#F4F4F4",
    ...(() => (isPhone ? {} : iPadViewContainerStyle))(),
  },
  subContainer: {
    width: isPhone ? "100%" : "calc(50% - 4px)",
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    "&:before": {
      content: "''",
      display: "inline-block",
      height: 16,
      width: 3,
      backgroundColor: "red",
      marginRight: 10,
      marginLeft: 10,
    },
  },
  sectionContainer: {
    padding: SECTION_PADDING,
    marginBottom: SECTION_MARGIN,
    backgroundColor: "white",
  },
  basicInfoSectionContainer: {
    padding: BASIC_INFO_SECTION_PADDING,
    marginBottom: SECTION_MARGIN,
    backgroundColor: "white",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
  },
  aboutMeContainer: {
    fontSize: 14,
    padding: 10,
  },
  errorFileContainer: {
    padding: 10,
    display: "flex",
    justifyContent: "center",
  },
  intouchMeContainer: {
    paddingLeft: 10,
    display: "flex",
    flexDirection: "row",
  },
  intouchMeIcon: {
    borderRadius: 50,
    borderWidth: 2,
    borderColor: "#F0F0F0",
    padding: 10,
    marginRight: 30,
  },
  pointerEventsNone: {
    pointerEvents: "none",
  },
  viewMore: {
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
  },
  iPadViewPromotionContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: PROMOTION_CARD_COLUMN_GAP,
  },
  promotionCard: {
    width: "100%",
  },
  errorMsgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: 14,
    color: "#999999",
  },
  playerContainer: {
    borderRadius: 10,
    paddingInline: 10,
    paddingBlock: 5,
  },
  iPadViewPlayerContainer: {
    borderRadius: 10,
    maxWidth: INNER_WIDTH * 0.8,
    paddingBlock: 5,
  },
}));
