import { Alert as MUIAlert, AlertTitle } from "@mui/material";
import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AlertItem, AlertType, commonSlice } from "../../redux/common/common-slice";
import { RootState } from "../../redux/store";
import { GlobalHelper } from "../../utils/helpers/global-helper";

const { appendAlertAction, dismissAlertAction } = commonSlice.actions;

type RoutesInternal = {
  alertState: AlertItem[];
};
export function Alert() {
  const dispatch = useDispatch();
  const { alertState } = useSelector<RootState, RoutesInternal>((state) => {
    return {
      alertState: state.common.alertState,
    };
  }, shallowEqual);
  return (
    <div className="fixed right-0 z-[9999] top-[10vh]">
      {alertState &&
        alertState.map((alertItem, index) => {
          setTimeout(() => dispatch(dismissAlertAction({ index })), 5000);
          return (
            <MUIAlert
              key={`alert-item-${index}`}
              severity={alertItem.severity}
              onClose={() => dispatch(dismissAlertAction({ index }))}
            >
              {alertItem.title && <AlertTitle>{alertItem.title}</AlertTitle>}
              {alertItem.content}
            </MUIAlert>
          );
        })}
    </div>
  );
}

export const appendAlert = (props: AlertItem[]) => {
  const globalDispatch = GlobalHelper.getGlobalDispatch();
  globalDispatch?.(appendAlertAction(props));
};
