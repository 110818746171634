import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import type { RouteObject } from "react-router-dom";
import { routesDemo } from "../modules/demo/route-config";
import { routesAgentProfile } from "../modules/agent-profile/route-config";
import { EVENT_PATH, ONBOARDING_PATH, PRUDNA_PATH, ROOT_PATH } from "./constants"; // ROOT_PATH defined on docker env
import { routesResource } from "../modules/resource/route-config";
import { ErrorScreen } from "../components/error-screen/error-screen";
import { routesAgentReferralLink } from "../modules/agent-referral-link/route-config";
import { routesOnboarding } from "../modules/onboarding/route-config";
import { routesEvent } from "../modules/event/route-config";
import { routesPRUdna } from "../modules/prudna/route-config";
import { PersonalInfoScreen } from "../modules/prudna/pages/personal-info-form/personal-info-form.screen";
import { LoginScreen } from "../modules/onboarding/pages/login/login.screen";

// console.log("ROOT_PATH===", ROOT_PATH);
export const routes: RouteObject[] = [
  { path: ROOT_PATH + "/login", element: <LoginScreen /> },

  {
    path: ROOT_PATH,
    element: <Navigate to={`${ROOT_PATH}/login`} replace />,
  },

  {
    path: ROOT_PATH + "/form/:refId",
    element: <Outlet />,
    children: configChildrenRoutes(routesAgentReferralLink),
  },
  {
    path: ROOT_PATH + "/profile/:refId",
    element: <Outlet />,
    children: configChildrenRoutes(routesAgentProfile),
  },
  {
    // sample: /web/resource/63808ae38734c6001dc15c85/kTB8Gq
    path: ROOT_PATH + "/resource/:resourceId/:refId",
    element: <Outlet />,
    children: configChildrenRoutes(routesResource),
  },
  {
    path: ROOT_PATH + `/${ONBOARDING_PATH}`,
    element: <Outlet />,
    children: configChildrenRoutes(routesOnboarding),
  },
  {
    path: ROOT_PATH + `/${EVENT_PATH}`,
    element: <Outlet />,
    children: routesEvent,
  },
  {
    path: ROOT_PATH + `/${PRUDNA_PATH}`,
    element: <Outlet />,
    children: configChildrenRoutes(routesPRUdna),
  },
  {
    path: ROOT_PATH + `/${PRUDNA_PATH}`,
    element: <Outlet />,
    children: [{ path: "booking", index: true, element: <PersonalInfoScreen /> }],
  },
  {
    path: ROOT_PATH + "/demo/:refId",
    element: <Outlet />,
    children: configChildrenRoutes(routesDemo),
  },
  {
    path: ROOT_PATH + "/410",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <ErrorScreen statusCode="410" />,
        gaData: {
          module: "Error",
          feature: "NA",
          journey: "NA",
          stage: "NA",
          screen_name: "Error410Page",
        },
      },
    ],
  },
  {
    path: ROOT_PATH + "/403",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <ErrorScreen statusCode="403" />,
        gaData: {
          module: "Error",
          feature: "NA",
          journey: "NA",
          stage: "NA",
          screen_name: "Error403Page",
        },
      },
    ],
  },
  {
    path: "*",
    element: <Outlet />,
    children: [
      {
        path: "*",
        element: <ErrorScreen statusCode="404" />,
        gaData: {
          module: "Error",
          feature: "NA",
          journey: "NA",
          stage: "NA",
          screen_name: "Error404Page",
        },
      },
    ],
  },
  // here: add routes config for new modules
];

/**
 * set default render to index-child, use first child if not found index-child
 * @param routes
 * @returns
 */
function configChildrenRoutes(routes: RouteObject[]): RouteObject[] {
  if (!routes || routes.length === 0) {
    return [];
  }
  let defaultRenderRoutes: RouteObject[];
  // find index child, use first child if not found
  let indexRoute: RouteObject = routes.find((route: RouteObject) => route.index) || routes[0];
  // set path:'' to make it default render
  indexRoute = { ...indexRoute, path: "", index: true } as RouteObject;
  // add default render route to children routes
  defaultRenderRoutes = [indexRoute, ...routes];
  return defaultRenderRoutes;

  /*********** sample routes result ***********
    const routesDemo: RouteObject[] = [
    { path: "", index: true, element: <LandingScreen /> }, // NOTE: this is default render screen
    { path: "demo-home", element: <LandingScreen /> },
    { path: "demo-page1", element: <Test1Screen /> },
    { path: "demo-page2", element: <Test2Screen /> },
  ];

  with above routes config, below urls will both render same screen
   > /demo/:refId
   > /demo/:refId/demo-home
  *********** sample routes result end *********** */
}
