import React, { useEffect, useMemo, useState } from "react";
import { getI18nData } from "../../../../utils/i18n/i18n.util";
import { useAppSelector } from "../../../../redux/store";
import { DetailBottom } from "./detail-bottom";
import { Document, Page, pdfjs } from "react-pdf";
import {
  cMapUrl,
  standardFontDataUrl,
  workerSrc,
} from "../../../onboarding/components/pdf-viewer/constant";
import { CircularProgress } from "@mui/material";
import { color } from "../../../../mui-theme/palette";

export const TemplateDetails = () => {
  const { descriptionImage } = useAppSelector((state) => state.event.eventDetailData)!;
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
  }, []);

  const onDocumentLoadSuccess = (result: any) => {
    const { numPages } = result;
    setTotal(numPages);
    setLoading(false);
  };

  return (
    <div className="w-full px-4">
      {getI18nData(descriptionImage)?.type === "application/pdf" ? (
        <Document
          options={{
            cMapUrl: cMapUrl,
            cMapPacked: true,
            standardFontDataUrl: standardFontDataUrl,
          }}
          file={getI18nData(descriptionImage)?.url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => setLoading(false)}
          loading={
            <div className={`h-screen w-screen flex flex-1 items-center justify-center`}>
              <CircularProgress sx={{ color: color.majorRed }} />
            </div>
          }
        >
          {!loading &&
            Array.from(new Array(total), (_, index) => index + 1)?.map((pageNumber) => (
              <Page
                key={pageNumber}
                onLoadSuccess={() => {}}
                devicePixelRatio={Math.min(2, window.devicePixelRatio)}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                pageNumber={pageNumber}
                width={window.innerWidth - 32}
                loading={
                  <div className={`w-full h-screen flex flex-1 items-center justify-center`}>
                    <CircularProgress sx={{ color: color.majorRed }} />
                  </div>
                }
              />
            ))}
        </Document>
      ) : (
        <div className="w-full">
          <img src={getI18nData(descriptionImage)?.url} alt="desc" className="w-full h-auto" />
        </div>
      )}
    </div>
  );
};
