import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { FixedSizeList as List, ListOnScrollProps } from "react-window";
import {
  ContentResourceType,
  FileResourceType,
  LinkResourceType,
  ResourceObject,
  ResourceType,
} from "../types/reource";
import "./resource-detail.css";
import { ErrorStateBlock } from "../../../components/error-state/error-state";
import { FileType } from "../../../constants/constants";
import { throttle } from "lodash";
import { ErrorBoundary } from "../../../components/error-boundary/error-boundary";
import { CircularProgress } from "@mui/material";
import { color } from "../../../mui-theme/palette";
import { ReactPdfViewer } from "./react-pdf-viewer/react-pdf-viewer.component";

export const ResourceDetail: React.FC<{ resource: Partial<ResourceObject> }> = ({ resource }) => {
  switch (resource.type) {
    case ResourceType.content:
      return <ContentResource resource={resource} />;
    case ResourceType.file:
      const url: string = (resource.i18nData as FileResourceType)?.file?.url;
      let fileType: string = decodeURIComponent(url).split("?")[0].replace(/.+\./, "");
      if (FileType.PDF === fileType) {
        const url = (resource.i18nData as FileResourceType)?.file?.url;
        return <PdfResource url={url} />;
      }
      if (FileType.IMG.includes(fileType)) {
        const url: string = (resource.i18nData as FileResourceType)?.file?.url;
        return <ImgResource url={url} />;
      }
      if (FileType.VIDEO.includes(fileType)) {
        return <VideoResource resource={resource} />;
      }

      return <ResourceIframe resource={resource} />;
    case ResourceType.link:
      return <ResourceIframe resource={resource} />;
    default:
      return <NotSupportResource resource={resource} />;
  }
};

const ContentResource: React.FC<{ resource: Partial<ResourceObject> }> = ({ resource }) => {
  return (
    <div
      className="px-4 py-6 break-all"
      dangerouslySetInnerHTML={{ __html: (resource.i18nData as ContentResourceType).content }}
    />
  );
};

const ResourceIframe: React.FC<{ resource: Partial<ResourceObject> }> = ({ resource }) => {
  const url: string =
    (resource.i18nData as FileResourceType)?.file?.url ||
    (resource.i18nData as LinkResourceType)?.link;
  window.location.replace(url); // redirect to target url
  return null;
  // return (
  //   <div className="flex flex-col flex-1">
  //     <iframe className="flex-1" src={url} height="100%" width="100%" />
  //   </div>
  // );
};

export const PdfResource: React.FC<{ url: string; onBottomReached?: () => void }> = ({
  url,
  onBottomReached,
}) => {
  // hide language switch btn, page has no label when render a PDF
  const langSwitch = document.getElementById("language-switch");
  if (langSwitch) {
    langSwitch.style.display = "none";
  }

  return (
    <div className="w-full h-full overflow-auto scroll-div">
      <ReactPdfViewer onBottomReached={onBottomReached} file={url} />
    </div>
  );
};
/*
export const PdfViewer = ({
  file,
  onBottomReached,
}: {
  file: any;
  onBottomReached?: () => void;
}) => {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  }, []);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const pageHeight = useMemo(() => window.innerWidth * Math.sqrt(2), []);
  const scrollHandleThrottleRef = useRef(undefined as any);
  const handleScroll = useCallback(
    (e: ListOnScrollProps) => {
      if (!scrollHandleThrottleRef.current) {
        scrollHandleThrottleRef.current = throttle((e: ListOnScrollProps) => {
          const { scrollOffset } = e;
          const currentPage = Math.ceil(scrollOffset / pageHeight) + 1;
          if (!numPages || currentPage >= numPages) {
            onBottomReached?.();
          }
        }, 500);
      }
      scrollHandleThrottleRef.current(e);
    },
    [pageHeight, numPages],
  );

  const rowRenderer = ({ index, style }: any) => {
    return (
      <div style={style}>
        <ErrorBoundary>
          <Page
            key={+index}
            devicePixelRatio={Math.min(2, window.devicePixelRatio)}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            pageNumber={index + 1}
            width={window.innerWidth}
          />
        </ErrorBoundary>
      </div>
    );
  };
  const listMemo = useMemo(() => {
    return (
      numPages && (
        <List
          height={window.innerHeight}
          onScroll={handleScroll}
          itemCount={numPages}
          itemSize={window.innerWidth * Math.sqrt(2)}
          width={window.innerWidth}>
          {rowRenderer}
        </List>
      )
    );
  }, [numPages]);
  const docMemo = useMemo(() => {
    return (
      <Document
        options={{
          cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
          standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
        }}
        file={file}
        loading={
          <div className={`h-screen w-screen flex flex-1 items-center justify-center`}>
            <CircularProgress sx={{ color: color.majorRed }} />
          </div>
        }
        onLoadSuccess={onDocumentLoadSuccess}>
        {listMemo}
      </Document>
    );
  }, [file, listMemo]);

  return docMemo;
};
*/

export const ImgResource: React.FC<{ url: string }> = ({ url }) => {
  return (
    <div className="flex flex-col flex-1 items-center justify-center bg-black">
      <img alt="url" className="object-contain" src={url} height="100%" width="100%" />
    </div>
  );
};

const VideoResource: React.FC<{ resource: Partial<ResourceObject> }> = ({ resource }) => {
  const url: string = (resource.i18nData as FileResourceType)?.file?.url;
  return (
    <div className="flex flex-col flex-1">
      <video className="mt-2" src={url} width="100%" controls />
    </div>
  );
};

const NotSupportResource: React.FC<{ resource: Partial<ResourceObject> }> = ({ resource }) => {
  const contentType: string = resource.type ?? "Unknown";
  return (
    <ErrorStateBlock statusCode="415" description={`Only support resource type: ${contentType}`} />
  );
};
