export const enum ActionStatusEnum {
  success = "success",
  error = "error",
  minSizeError = "minSizeError",
  imageBlurError = "imageBlurError",
}

export const enum WebviewActionEnum {
  uploadfile = "rn-to-web-uploadfile",
}
