import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../../redux/store";
import { fetchAttendanceLandingByIdAsync } from "../../redux/event-slice";

interface HookProps {
  refId: string;
  // other params
}

export const useLanding = ({ refId }: HookProps) => {
  const { t, i18n } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const { eventDetailData, loading, code } = useAppSelector((state) => state.event);

  useEffect(() => {
    dispatch<any>(fetchAttendanceLandingByIdAsync(refId));
  }, [dispatch, refId]);

  const handlePress = useCallback(() => {}, []);

  return {
    loading,
    eventDetailData,
    code,
    handlePress,
  };
};
