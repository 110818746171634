import { getAnalytics, logEvent, Analytics } from "firebase/analytics";
import {
  GAEventName,
  GAEventSource,
  GAFeature,
  GAModule,
  GAObjectType,
  GAScreenName,
  objectTypeShortFormMap,
} from "./types/ga-parameter-type";
import {
  GAEventParameters,
  TakeUIScreenViewEventParameters,
  TakeUIClickEventParameters,
} from "./types/ga-general-type";
import store from "../../redux/store";
import { getScreenNameShortForm, getShortForm } from "./utils";

export const takeUIScreenViewEvent = (parameters: TakeUIScreenViewEventParameters) => {
  const module = parameters.module;
  const feature = parameters.feature;
  const screen_name = parameters.screen_name;

  const journey = parameters.journey;
  const stage = parameters.stage;
  const screen_id = parameters.screen_id || getScreenId(module, feature, screen_name);

  takeGAEvent("ui_screen_visit", {
    event_type: "Screen",
    module,
    feature,
    journey,
    stage,
    screen_name,
    screen_id,
  });
};

export const takeUIClickEvent = (parameters: TakeUIClickEventParameters) => {
  const gaData = store.getState().ga.data;

  const module = parameters.module || gaData?.module || undefined;
  const feature = parameters.feature || gaData?.feature || undefined;
  const screen_name = parameters.screen_name || gaData?.screen_name || undefined;
  if (!module || !feature || !screen_name)
    throw new Error("Missing module or feature or screen_name");

  const journey = parameters.journey;
  const stage = parameters.stage;
  const screen_id = parameters.screen_id || getScreenId(module, feature, screen_name);
  const object_name = parameters.object_name;
  const object_type = parameters.object_type;
  const object_id =
    parameters.object_id ||
    getObjectId(module, feature, screen_name, { type: object_type, name: parameters.object_name });

  takeGAEvent("ui_click", {
    event_type: "Click",
    module,
    feature,
    journey,
    stage,
    screen_name,
    screen_id,
    object_name,
    object_type,
    object_id,
  });
};

const takeGAEvent = (eventName: GAEventName, parameters: GAEventParameters) => {
  let analytics: Analytics;
  try {
    analytics = getAnalytics();
  } catch {
    return;
  }

  let event_source: GAEventSource = `PRUForce-PublicPortal-IN-${
    window.ReactNativeWebView ? "webapp" : "web"
  }`;
  logEvent(analytics, eventName, {
    ...parameters,
    created_at: new Date(),
    event_source,
  });
};

const getScreenId = (module: GAModule, feature: GAFeature, screenName: GAScreenName) => {
  return `SRC${getShortForm(module)}${getShortForm(feature)}${getScreenNameShortForm(screenName)}`;
};

const getObjectId = (
  module: GAModule,
  feature: GAFeature,
  screenName: GAScreenName,
  object: {
    name: string;
    type: GAObjectType;
  },
) => {
  return `${objectTypeShortFormMap[object.type]}${getShortForm(module)}${getShortForm(
    feature,
  )}${getScreenNameShortForm(screenName)}${getShortForm(object.name)}`;
};
