import { PruToast } from "../../../../components/pru-toast";
import { sendOtpReq } from "../../../prudna/network/network";
import { SurveyModel } from "survey-core";
import { handlerError } from "./handle-otp-error";
import { getI18n } from "react-i18next";
import { get } from "lodash";
import {
  EndAdornment,
  regionPhoneValidate,
} from "../../../onboarding/components/surveyjs/survey/survey.util";

let oid: string | undefined = undefined;

export const sendOtpFunc = async (params: any[]) => {
  const [value, survey, config] = params as [string, SurveyModel, EndAdornment];
  const regionValue = survey.getQuestionByName("regionPhone")?.value?.[0];
  const code = regionValue?.regionCode;
  const phoneNumber = regionValue?.phoneNumber;
  if (!regionPhoneValidate([code, phoneNumber])) {
    PruToast({
      message: getI18n().t("Invalid Phone Number"),
      status: "error",
      duration: 3000,
    });
    return;
  }
  try {
    const res = await sendOtpReq({
      phoneNum: `${code} ${phoneNumber}`,
      ...(config.actionParams as any),
    });
    if (!get(res, "errors", undefined)) {
      oid = res.oid;
    }
  } catch (e: any) {
    const regionPhone = survey.getQuestionByName("regionPhone");
    const errorReturn = handlerError(e);
    if (errorReturn && typeof errorReturn === "string") {
      regionPhone.addError(errorReturn);
    }
  }
};
export const getOid = () => {
  return oid;
};
export const setOid = (value?: string) => {
  oid = value;
};
