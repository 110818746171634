import React from "react";
import type { RouteObject } from "react-router-dom";
import { DetailScreen } from "./pages/detail/detail-screen";
import { DownloadPage } from "./pages/download/download.screen";
import { LandingPage } from "./pages/landing/landing.screen";
import { SurveyFormScreen } from "./pages/survey-form/survey-form-screen";
import { EventCheckPage } from "./pages/event-check/event-check.screen";
import { CheckInOutResultPage } from "./pages/check-in-out-result/check-in-out-result.screen";

export const routesEvent: RouteObject[] = [
  { path: ":refId", element: <DetailScreen /> },
  { path: "form/:refId", element: <SurveyFormScreen /> },
  { path: "assistance/:hashCode", element: <DownloadPage /> },
  { path: "check/:refId", element: <EventCheckPage /> },
  { path: ":refId/attendance", element: <LandingPage /> },
  { path: "check_result/:refId", element: <CheckInOutResultPage /> },
];
