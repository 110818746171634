import React from "react";
import { useTranslation } from "react-i18next";
import { SvgAssets } from "../../../../components/icon/assets";
import Icon from "../../../../components/icon/icon";
import { useAppSelector } from "../../../../redux/store";
import { useQuery } from "../../../../utils/hooks/use-query";
import { CheckAction, SubmitParticipantsInfoCodeEnum } from "../../network/type";
import "../../pages/detail/detail-screen.css";

export const Tips = () => {
  const { t } = useTranslation();
  const checkType = useQuery().get("checkType");
  const { participantsInfo } = useAppSelector((state) => state.event);

  let tips;

  if (checkType === CheckAction.CHECK_IN) {
    tips =
      participantsInfo && participantsInfo?.length > 1
        ? t("event.num_of_registrants_successfully_checked_in", {
            count: participantsInfo?.length,
          })
        : t("event.successful_check_in");
  } else {
    tips = t("event.successful_check_out");
  }
  return (
    <div className="flex  flex-col items-center  gap-y-4  py-12 ">
      <Icon svg={SvgAssets.success} width={80} height={80} />
      <span className="text-base font-semibold text-center">{tips}</span>
    </div>
  );
};
