import React, { FC, useCallback } from "react";
import html2canvas from "html2canvas";
import { PruToast } from "../../../../../components/pru-toast";
import { useTranslation } from "react-i18next";
import { openFromApp } from "../../../util/event.util";
import { TakeUIClickEventParameters } from "../../../../../common/ga/types/ga-general-type";
import { takeUIClickEvent } from "../../../../../common/ga/ga";

export const useQrCodeContent = () => {
  const { t } = useTranslation();

  const downloadAsImage = useCallback(async () => {
    const isFromApp = openFromApp();
    try {
      const element = document.querySelector("#event-qr-code");
      if (element) {
        const styleElement = document.createElement("style");
        styleElement.innerText = "img { display: inline }";
        document.head.appendChild(styleElement);
        const canvas = await html2canvas(element as HTMLElement);
        const image = canvas.toDataURL("image/png", 1.0);
        if (isFromApp) {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              action: "savetoalbum",
              payload: { url: image },
            }),
          );
        } else {
          const gaData: TakeUIClickEventParameters = {
            journey: "event_rsvp",
            stage: "save_qr_code",
            object_name: "SaveToAlbum",
            object_type: "BUTTON",
          };
          takeUIClickEvent(gaData);
          const link = document.createElement("a");
          link.download = "download.png";
          link.href = image;
          document.body.appendChild(link);
          link.click();
          PruToast({
            status: "success",
            message: t("event.saved_qr_code_content_successfully_message"),
          });
          document.body.removeChild(link);
        }
        document.head.removeChild(styleElement);
      }
    } catch (error) {
      PruToast({
        message: t("event.saved_qr_code_content_failed_message"),
      });
    }
  }, [t]);

  return {
    downloadAsImage,
  };
};
