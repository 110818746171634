export async function resizeAndRotate(
  base64data: string,
  degree: number,
  width: number,
  height: number,
  format: string,
) {
  const rotated = await rotate(base64data, degree, format);
  // const resized = await resize(rotated, height, width, format);
  // return resized;
  return rotated;
}

export const rotate = async (srcBase64: string, degrees: number, format: string) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const image = new Image();

  image.src = srcBase64;
  await image.decode();

  canvas.width = degrees % 180 === 0 ? image.width : image.height;
  canvas.height = degrees % 180 === 0 ? image.height : image.width;
  if (ctx === null) throw new Error("can not create canvas");

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((degrees * Math.PI) / 180);
  ctx.drawImage(image, image.width / -2, image.height / -2);

  return canvas.toDataURL(format, 1);
};

export const resize = async (base64: string, height: number, width: number, format: string) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (ctx === null) throw new Error("can not create canvas");

  var img = new Image();
  img.src = base64;
  await img.decode();
  var iw = img.width;
  var ih = img.height;
  var scale = Math.min(width / iw, height / ih);
  var iwScaled = iw * scale;
  var ihScaled = ih * scale;
  canvas.width = iwScaled;
  canvas.height = ihScaled;
  ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
  return canvas.toDataURL(format, 1);
};
