import React, { FC, useEffect, useRef, useState } from "react";
import { get, omit, set } from "lodash";
import ReactPlayer from "react-player";
import { useStyles } from "./styles/white-player.styles";
import {
  TrackingEventType,
  TrackingInfo,
  EventModule,
  EventFeature,
} from "../../../utils/event-tracking/event-tracking.model";
import { eventTrackingService } from "../../../utils/event-tracking/event-tracking.service";

type WhitePlayerProps = {
  className?: string;
  url: string;
};

const WhitePlayer: FC<WhitePlayerProps> = ({ className, url }) => {
  const videoRef = useRef<any>();
  const videoPlayerRef = useRef<any>();
  const [videoWidth, setvideoWidth] = useState(0);
  const classes = useStyles({ videoWidth });

  useEffect(() => {
    if (videoRef && videoRef.current) {
      setvideoWidth(videoRef.current.offsetWidth);
    }
  }, [videoRef]);

  const videoClickTrackingData = {
    name: "prospect::view_profile",
    module: EventModule.agent_profile_site,
    feature: EventFeature.agent_info,
    journey: "highlight",
  };

  useEffect(() => {
    setTimeout(() => {
      if (videoPlayerRef && videoPlayerRef.current) {
        videoPlayerRef.current.seekTo(0);
      }
    }, 1500);
  }, [videoPlayerRef]);

  const handleVideoPlay = (playVideo: boolean) => {
    const rawData = {
      ...videoClickTrackingData,
      type: playVideo ? TrackingEventType.start_view : TrackingEventType.end_view,
    };
    const trackingInfo: TrackingInfo = {
      subject: `anonymous:ref=${eventTrackingService.refId}`,
      data: undefined,
    };
    const trackingData = { ...eventTrackingService.trackingData };
    const now = Date.now();
    if (rawData.type === TrackingEventType.start_view) {
      // delete endDatetime for start video click event
      set(trackingData, "params.startDatetime", now);
      const omitEndDatetime = omit(trackingData, "params.endDatetime");
      const dataTracking = { ...omitEndDatetime, ...rawData };
      trackingInfo.data = dataTracking;
    } else {
      const startTime = get(trackingData, "params.startDatetime");
      if (!startTime) {
        return;
      }
      set(trackingData as object, "params.endDatetime", now);

      const dataTracking = { ...trackingData, ...rawData };
      trackingInfo.data = dataTracking;
    }
    eventTrackingService.doReport(trackingInfo);
  };

  return (
    <div className={classes.container} ref={videoRef}>
      <meta name="referrer" content="no-referrer"></meta>
      <ReactPlayer
        controls
        ref={videoPlayerRef}
        width={"100%"}
        height={"auto"}
        className={className}
        url={url}
        onStart={() => {
          var videoEle: any = document.getElementById("video-myHighlight-player");
          if (videoEle) {
            if (videoEle.requestFullscreen) {
              videoEle.requestFullscreen();
            } else if (videoEle.mozRequestFullScreen) {
              /* Firefox */
              videoEle.mozRequestFullScreen();
            } else if (videoEle.webkitRequestFullscreen) {
              /* Chrome, Safari and Opera */
              videoEle.webkitRequestFullscreen();
            } else if (videoEle.msRequestFullscreen) {
              /* IE/Edge */
              videoEle.msRequestFullscreen();
            } else if (videoEle.oRequestFullscreen) {
              videoEle.oRequestFullscreen();
            }
          }
        }}
        config={{
          file: {
            attributes: {
              style: { width: "100%", height: "auto", objectFit: "fill" },
              id: "video-myHighlight-player",
              "x5-video-player-fullscreen": true,
            },
          },
        }}
        onPlay={() => handleVideoPlay(true)}
        onPause={() => handleVideoPlay(false)}
      />
    </div>
  );
};

export default WhitePlayer;
