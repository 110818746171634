import { getI18n } from "react-i18next";
import { FormAction } from "../../types";

export const getOnboardingReassessFeedback = (reassessType: string) => {
  const onboardingReassessFeedback: any = {
    [FormAction.REJECT]: {
      title: getI18n().t("Recruitment.reject_returned_success"),
      description: getI18n().t("Recruitment.application_form_rejected"),
    },
    [FormAction.REVISE]: {
      title: getI18n().t("Recruitment.revise_returned_success"),
      description: getI18n().t("Recruitment.application_form_revise_returned"),
    },
  };
  return onboardingReassessFeedback[reassessType];
};
