import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "./loading.component.css";
export function LoadingComponent() {
  const apiLoading = useSelector<RootState>((state) => state.common.apiLoading);
  let rootClassName = "hidden";
  if (apiLoading) {
    rootClassName =
      "fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center";
  }
  return (
    <div className={rootClassName}>
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  );
}
