import React, { useEffect, useMemo, useState } from "react";
import { Model, settings } from "survey-core";
import { Survey } from "survey-react-ui";
import {
  confirmActionAsyncFunc,
  markdownHandler,
} from "../../components/surveyjs/survey/survey.util";
import { initSurvey } from "../../components/surveyjs/survey/init-survey";
import { getI18n, useTranslation } from "react-i18next";
import { useQuery } from "../../../../utils/hooks/use-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ApplicationDetailTemplate, ApplicationType } from "./template";
import { submitApplicationDetail } from "../../network/network";
import { cloneDeep } from "lodash";
import { GlobalHelper } from "../../../../utils/helpers/global-helper";
import moment from "moment";
import { getFullRegionList } from "../../../common/network/network";
import { FRONTEND_ROOT_PATH, ONBOARDING_PATH } from "../../../../routes/constants";
import PageContainer from "../../../../components/page-container/page-container";
const dateFormat = "DD/MM/yyyy";
const elements = ApplicationDetailTemplate.pages[0].elements;
export const ApplicationDetailScreen: React.FC = () => {
  initSurvey();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const query: any = useQuery();
  const [formValues, setForm] = useState({} as any);
  const [pageInitiating, setPageInitiating] = useState(true);
  useEffect(() => {
    convertTemplate(ApplicationDetailTemplate);
    const promises: any[] = [];
    if (!GlobalHelper.getUserName()) {
      promises.push(getFullRegionList());
    }
    Promise.all(promises)
      .then((reses: any) => {
        if (reses.length === 0) {
          return;
        }
        const [regionConfigs] = reses;
        const regionPhoneField: any = elements.find((item: any) => item.name === "regionPhone");
        if (regionPhoneField?.templateElements?.[0] && regionConfigs) {
          const regionOpts = Object.values(regionConfigs).map((region: any) => ({
            text: `${region.code}`,
            key: region.code,
            value: region.code,
            reg: region.regex,
          }));
          // @ts-ignore
          regionPhoneField.templateElements[0].choices = regionOpts;
          setForm({
            regionCode: regionOpts?.[0].key ?? "+852",
          });
        } else {
          setForm({
            regionCode: "+852",
          });
        }
      })
      .finally(() => {
        setPageInitiating(false);
      });
  }, []);
  const form = useMemo(() => {
    if (pageInitiating) return null;
    const survey = new Model(cloneDeep(ApplicationDetailTemplate));
    survey.showPageTitles = false;
    survey.showNavigationButtons = "none";
    survey.questionErrorLocation = "bottom";
    survey.showCompletedPage = false;
    const defaultValue = {
      regionPhone: [
        {
          regionCode: formValues.regionCode,
          phoneNumber: formValues.phoneNumber,
        },
      ],
    };
    survey.mergeData(defaultValue);
    survey.onComplete.add((_sender: any) => {});
    survey.onTextMarkdown.add(markdownHandler);
    survey.onGetQuestionTitleActions.add((_, options) => {
      if (options.question.tooltips) {
        options.titleActions = [
          {
            id: "show-popover",
            component: "popover",
            tooltip: options.question.tooltips,
          },
        ];
      }
    });
    settings.confirmActionAsync = confirmActionAsyncFunc;
    return (
      <div className="flex flex-1 flex-col max-w-md sm:max-w-xl lg:max-w-xl px-6">
        <div className="flex flex-col w-full pb-4">
          <div className=" bg-white w-full rounded-tr-3xl h-10"></div>
          <Survey
            currentPageNo={0}
            className="survey-common"
            id="application-detail"
            model={survey}
          />
        </div>

        <div className=" w-full sticky bottom-0 bg-white pt-2 pb-7 flex items-center justify-between gap-4">
          <button
            className="bg-red-500 flex-1 text-white font-semibold py-3  rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
            onClick={() => {
              let isValid = false;
              isValid = survey.validateCurrentPage();

              if (!isValid) {
                return;
              }
              const surveyData = survey.data;
              const surveyDataRegionPhone = surveyData.regionPhone[0];
              const surveyDataPhoneNumber =
                surveyDataRegionPhone.regionCode && surveyDataRegionPhone.phoneNumber
                  ? `${surveyDataRegionPhone.regionCode} ${surveyDataRegionPhone.phoneNumber}`
                  : undefined;

              const applicationDetailData: any = {
                applicationType: surveyData.applicationType,
                ...(surveyData.applicationType === ApplicationType.NewRecruit
                  ? {}
                  : {
                      phone: surveyDataPhoneNumber ?? GlobalHelper.getUserName(),
                      formerAgentCode: surveyData.formerAgentCode,
                      licenseExpirationDate: moment(surveyData.licenseExpirationDate).format(
                        dateFormat,
                      ),
                      appointmentDate: moment(surveyData.appointmentDate).format(dateFormat),
                      terminationDate: moment(surveyData.terminationDate).format(dateFormat),
                    }),
              };
              submitApplicationDetail(applicationDetailData, survey, () => {
                navigate(
                  `${FRONTEND_ROOT_PATH}${
                    location.state?.from ?? `/web/${ONBOARDING_PATH}/join-now`
                  }?${query.toString()}`,

                  {
                    state: { ...location.state, applicationDetails: applicationDetailData },
                  },
                );
              });
            }}
          >
            {t("proceed")}
          </button>
        </div>
      </div>
    );
  }, [formValues, pageInitiating]);
  return <PageContainer title={t("Common.application_detail_screen_title")}>{form}</PageContainer>;
};

const convertTemplate = (template: any) => {
  // Function to translate a given field if it exists
  const translateField = (obj: any, field: string) => {
    if (obj?.[field]) {
      obj[field] = getI18n().t(obj[field]);
    }
  };

  const terminationDateField = template.pages[0].elements.find(
    (item: any) => item.name === "terminationDate",
  );
  const appointmentDateField = template.pages[0].elements.find(
    (item: any) => item.name === "appointmentDate",
  );
  const maxDate = moment().format("YYYY-MM-DD");
  terminationDateField.max = maxDate;
  appointmentDateField.max = maxDate;

  // Loop through each element in the first page
  template.pages[0].elements = template.pages[0].elements.map((q: any) => {
    // Translate title, labelTrue, labelFalse, requiredErrorText fields
    translateField(q, "title");
    translateField(q, "placeholder");
    translateField(q, "labelTrue");
    translateField(q, "labelFalse");
    translateField(q, "requiredErrorText");
    translateField(q, "tooltips");

    // translate choices if they exist
    if (q.choices && Array.isArray(q.choices)) {
      q.choices.forEach((choice: any) => {
        translateField(choice, "text");
      });
    }

    // Check if the element has validators and translate their text fields
    if (q.validators && Array.isArray(q.validators)) {
      q.validators.forEach((validator: any) => {
        translateField(validator, "text");
      });
    }

    // If the element is a paneldynamic type, we need to handle nested templateElements
    if (q.type === "paneldynamic" && q.templateElements && Array.isArray(q.templateElements)) {
      q.templateElements = q.templateElements.map((te: any) => {
        translateField(te, "title");
        translateField(te, "labelTrue");
        translateField(te, "labelFalse");
        translateField(te, "requiredErrorText");

        if (te.validators && Array.isArray(te.validators)) {
          te.validators.forEach((validator: any) => {
            translateField(validator, "text");
          });
        }

        return te;
      });
    }

    return q;
  });
};
