import React from "react";
import { getI18n } from "react-i18next";
import { isEmpty } from "lodash";
import { Model } from "survey-core";
import { SvgAssets } from "../../../../../components/icon/assets";
import { feedbackOpen } from "../../../../../components/modal/feedback";
import { PruToast } from "../../../../../components/pru-toast";
import { getToken, parseJwt, backToApp, getI18nTranslation, extractOwnerId } from "../../../utils";
import Icon from "../../../../../components/icon/icon";
import store from "../../../../../redux/store";
import { GlobalHelper } from "../../../../../utils/helpers/global-helper";
import {
  OnboardingApplicationStatus,
  OnboardingFormSectionName,
  FormAction,
  RoleEnum,
  OnboardingFormModuleName,
} from "../../../types";
import { TakeUIClickEventParameters } from "../../../../../common/ga/types/ga-general-type";
import { takeUIClickEvent } from "../../../../../common/ga/ga";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../../routes/constants";
import {
  getOnboardingModuleNameBySection,
  popupExpiredTemplateReminder,
} from "../../../pages/form/util";
import { onboardingServerValidators } from "./server-validate";
import { ONBOARDING_FINALIZED_STATUSES } from "../../../constants";

export const saveAndSubmit = async ({
  survey,
  pageNo,
  isLastSection,
  canGoBack,
  navigate,
  setPageNo,
  saveForm,
  dataRef,
  isLastSectionSubmitted,
  query,
}: any) => {
  let disableSubmit = false;
  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};
  const candidateOnboardingForm = store.getState()?.onboarding?.candidateOnboardingForm;
  const currentSectionName = GlobalHelper.getOnboardingCurrentSection().name;
  const isAssignee = store.getState().onboarding.isAssignee;
  const isFormCreator = candidateOnboardingForm?.formCreatorUserId === extractOwnerId(tokenData);
  if (!isAssignee) {
    disableSubmit = true;
  }
  const isSubmittedOrFinalized = ONBOARDING_FINALIZED_STATUSES.includes(
    candidateOnboardingForm.applicationStatus,
  );

  const isEditingOrFollowUp = [
    OnboardingApplicationStatus.EDITING,
    OnboardingApplicationStatus.FOLLOW_UP_REQUIRED,
  ].includes(candidateOnboardingForm.applicationStatus);

  const isFollowUpRequired =
    candidateOnboardingForm.applicationStatus === OnboardingApplicationStatus.FOLLOW_UP_REQUIRED;

  const isNotESignatureSection = currentSectionName !== OnboardingFormSectionName.eSignatureSection;

  if (
    (!isEmpty(candidateOnboardingForm) &&
      (isSubmittedOrFinalized || (isNotESignatureSection && !isEditingOrFollowUp))) ||
    (isFollowUpRequired && isNotESignatureSection && !isFormCreator)
  ) {
    disableSubmit = true;
  }
  if (disableSubmit) {
    if (isLastSection && currentSectionName !== OnboardingFormSectionName.termsNConditionsSection) {
      if (canGoBack()) {
        navigate(-1);
      }
    } else {
      setPageNo(pageNo + 1);
      const nextSectionName = survey.visiblePages[pageNo + 1].name;
      if (nextSectionName) {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set("sectionName", nextSectionName);
        window.history.replaceState({}, "", currentUrl.toString());
        setTimeout(() => {
          // set a delay to ensure the page is fully loaded
          popupExpiredTemplateReminder();
        }, 300);
      }
    }
    return;
  }
  const panel = survey.getPanelByName(currentSectionName);
  let isValid = false;
  let isReadonlyValid = true;
  // panel.questions.forEach((q: any) => {
  //   if (q.readOnly === false) return;
  //   q.readOnly = false;
  //   const res = q.validate();
  //   q.readOnly = true;
  //   if (res === false) isReadonlyValid = false;
  // });
  if (currentSectionName === OnboardingFormSectionName.eSignatureSection) {
    if (!isAssignee) {
      PruToast({
        message: getI18n().t("Recruitment.please_inform_form_creator_to_submit"),
      });
      return;
    }
  }
  isValid = survey.validateCurrentPage();

  if (!isValid || !isReadonlyValid) {
    return;
  }
  const visibleQuestionNames = panel.visibleRows.map((row: any) => row.visibleElements[0].name);
  if (visibleQuestionNames.length === 0 || !dataRef.current?.questions) return;
  // filter out questions that are not visible. note: questions is a key-value object
  const visibleQuestions = Object.keys(dataRef.current.questions).reduce((acc: any, key) => {
    if (visibleQuestionNames.includes(key)) {
      acc[key] = dataRef.current.questions[key];
    }
    return acc;
  }, {});
  dataRef.current.questions = visibleQuestions;

  const serverValidatorErrors = await onboardingServerValidators.validate(
    dataRef.current?.questions,
  );
  for (const key in serverValidatorErrors) {
    if (Object.prototype.hasOwnProperty.call(serverValidatorErrors, key)) {
      const errMsg = serverValidatorErrors[key];
      const question = survey.getQuestionByName(key);
      if (question) {
        question.addError(errMsg);
      }
    }
  }
  if (!isEmpty(serverValidatorErrors)) {
    PruToast({
      message: getI18n().t("Please check your data"),
    });
    return;
  }
  const response = await saveForm(dataRef.current, FormAction.SUBMIT);
  const errors = response?.error ?? response?.payload?.errors;
  const responseData = response?.payload?.data;

  if (!errors) {
    new Promise<{ continue: boolean }>((resolve, reject) => {
      if (currentSectionName === OnboardingFormSectionName.eSignatureSection) {
        const gaData: TakeUIClickEventParameters = {
          module: "Recruitment",
          feature: "ApplicationForm",
          journey: "edit_application_form",
          stage: "submit_form",
          screen_id: "SCR_RCRTMT_APPLCTN_E_SGNTR",
          screen_name: "Recruitment-Application-E-Signature-Screen",
          object_name: "Submit",
          object_type: "BUTTON",
        };
        takeUIClickEvent(gaData);
        let submittedTitleTransKey = "";
        let submittedDescTransKey = "";
        if (
          [
            OnboardingApplicationStatus.SUBMITTED,
            OnboardingApplicationStatus.L1_PENDING_APPROVAL,
            OnboardingApplicationStatus.L2_PENDING_APPROVAL,
            OnboardingApplicationStatus.L3_PENDING_APPROVAL,
            OnboardingApplicationStatus.L4_PENDING_APPROVAL,
            OnboardingApplicationStatus.L1_APPROVED,
            OnboardingApplicationStatus.L2_APPROVED,
            OnboardingApplicationStatus.L3_APPROVED,
          ].includes(responseData?.applicationStatus)
        ) {
          submittedTitleTransKey = "application_form_submitted_successfully";
          submittedDescTransKey = "application_form_submitted_successfully_desc";
        } else if (
          [
            OnboardingApplicationStatus.PENDING_SIGNATURE,
            OnboardingApplicationStatus.FOLLOW_UP_REQUIRED,
          ].includes(responseData?.applicationStatus)
        ) {
          switch (tokenData.role) {
            case RoleEnum.MANAGER:
            case RoleEnum.SECRETARY:
              submittedTitleTransKey = "application_form_submitted_successfully";
              submittedDescTransKey = "The application form has been forwarded to the next owner.";
              break;
            case RoleEnum.AGENT:
              submittedTitleTransKey = "Recruitment.onboarding_esignature_submitted_successfully";
              submittedDescTransKey =
                "Recruitment.onboarding_esignature_submitted_successfully_desc";
              break;
            case RoleEnum.CANDIDATE:
              submittedTitleTransKey = "Recruitment.onboarding_esignature_submitted_successfully";
              submittedDescTransKey =
                "Recruitment.onboarding_esignature_submitted_successfully_candidate_desc";
              break;
          }
        } else {
          resolve({ continue: true });
        }

        feedbackOpen({
          title: getI18n().t(submittedTitleTransKey),
          description: getI18n().t(submittedDescTransKey),
          onConfirm: () => {
            // go to next page
            backToApp();
            resolve({ continue: false });
          },

          icon: <Icon svg={SvgAssets.success} width={80} height={80} />,

          confirmButtonText: getI18n().t("global.text.confirm"),
        });
      } else {
        resolve({ continue: true });
      }
    }).then((promiseResolve) => {
      if (!promiseResolve.continue) {
        return;
      }
      if (isLastSection) {
        if (currentSectionName !== OnboardingFormSectionName.termsNConditionsSection) {
          isLastSectionSubmitted.current = true;
          const surveyTemplate: Model = store.getState().onboarding.currentTemplate ?? {};
          const preModuleTemplate = surveyTemplate.pages?.find(
            (page) => page?.name === OnboardingFormModuleName.PreQuestions,
          );
          const lastPreSection = preModuleTemplate?.elements?.slice()?.reverse()?.[0];
          if (currentSectionName === lastPreSection?.name) {
            // if located at last section in prequstions
            navigate(`${ROOT_PATH}/${ONBOARDING_PATH}?${query.toString()}`, { replace: true });
          } else if (canGoBack()) {
            navigate(-1);
          }
        } else {
          const surveyTemplate: Model = store.getState().onboarding.currentTemplate ?? {};
          const eSigModuleTemplate = surveyTemplate.pages?.find(
            (page) => page?.name === OnboardingFormModuleName.ESignature,
          );
          const hasSignature = !!eSigModuleTemplate?.elements?.length;
          if (hasSignature) {
            setPageNo((pre: number) => pre + 1);
          } else {
            let submittedTitleTransKey = "application_form_submitted_successfully";
            let submittedDescTransKey = "application_form_submitted_successfully_desc";
            feedbackOpen({
              title: getI18n().t(submittedTitleTransKey),
              description: getI18n().t(submittedDescTransKey),
              onConfirm: () => {
                // go to next page
                backToApp();
              },

              icon: <Icon svg={SvgAssets.success} width={80} height={80} />,

              confirmButtonText: getI18n().t("global.text.confirm"),
            });
          }
        }
      } else {
        setPageNo((pre: number) => pre + 1);
      }
    });
  } else {
    // handle errors from server side
    let hasSurveyError = false;
    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        const error = errors[key];
        const question = survey.getQuestionByName(key);
        if (question) {
          question.addError(error[0].text);
          hasSurveyError = true;
        }
      }
    }
    if (panel) panel.focusFirstErrorQuestion();
    if (!hasSurveyError && response?.payload?.statusCode) {
      if ([401, 403].includes(response?.payload?.statusCode)) {
        PruToast({
          message: getI18n().t("Common.unauthorized_error"),
        });
        return;
      }
      PruToast({
        message:
          getI18nTranslation(response?.payload?.message) || getI18n().t("Common.network_error"),
      });
    }
  }
};
export enum ButtonPresets {
  primary,
  secondary,
  tertiary,
}
export const renderButton = (text: any, func: any, preset: ButtonPresets, canRender: any) => {
  if (!canRender) return undefined;
  switch (preset) {
    case ButtonPresets.primary:
      return (
        <button
          className="bg-red-500 flex-1 text-white font-semibold py-3  rounded-lg  hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
          onClick={func}
        >
          {text}
        </button>
      );
    case ButtonPresets.secondary:
      return (
        <button
          className="border-2 border-neutrals-grey-cc bg-neutrals-white flex-1 text-gray-700 font-semibold py-3  rounded-lg  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75"
          onClick={func}
        >
          {text}
        </button>
      );
    case ButtonPresets.tertiary:
      return (
        <button
          className="bg-red-100 flex-1 text-red-500 font-semibold py-3  rounded-lg  hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-opacity-75"
          onClick={func}
        >
          {text}
        </button>
      );
  }
};

export const renderExternalNavigation = ({
  isFirstSection,
  decrementPageNo,
  incrementPageNo,
  currentSectionName,
}: any) => {
  const currentCandidateForm = store.getState().onboarding.candidateOnboardingForm;
  let nextButtonText = getI18n().t("proceed");
  let previousButtonText = getI18n().t("previous_section");
  let previousButtonCanRender = !isFirstSection;
  let previousButtonPreset = ButtonPresets.secondary;
  let previousButtonHandler = decrementPageNo;
  let nextButtonCanRender = true;
  const isAssignee = store.getState().onboarding.isAssignee;
  const isFormCreator = store.getState().onboarding.isFormCreator;
  let isSectionReadOnly =
    ONBOARDING_FINALIZED_STATUSES.includes(currentCandidateForm?.applicationStatus) || !isAssignee;
  const surveyTemplate: Model = store.getState().onboarding.currentTemplate ?? {};
  const currentModule = getOnboardingModuleNameBySection(surveyTemplate, currentSectionName);
  if (currentModule === OnboardingFormModuleName.PreQuestions) {
    nextButtonText = getI18n().t("global.text.confirm");
    previousButtonCanRender = false;
  } else {
    switch (currentSectionName) {
      case OnboardingFormSectionName.eSignatureSection:
        // reassess button logic

        const rawToken = getToken();
        const tokenData = rawToken ? parseJwt(rawToken) : {};
        if (
          tokenData.role === RoleEnum.MANAGER &&
          !isFormCreator &&
          isAssignee &&
          [OnboardingApplicationStatus.PENDING_SIGNATURE].includes(
            currentCandidateForm?.applicationStatus,
          )
        ) {
          previousButtonCanRender = true;
          previousButtonPreset = ButtonPresets.tertiary;
          previousButtonHandler = () => {
            const globalNavigate = GlobalHelper.getGlobalNavigate();
            globalNavigate(
              `${ROOT_PATH}/${ONBOARDING_PATH}/reassess${window.location.search}&templateId=${surveyTemplate.templateId}&applicationId=${currentCandidateForm.applicationId}`,
            );
            const gaData: TakeUIClickEventParameters = {
              module: "Recruitment",
              feature: "ApplicationForm",
              journey: "reassess_application",
              stage: "journey_entry",
              screen_id: "SCR_RCRTMT_APPLCTN_E_SGNTR",
              screen_name: "Recruitment-Application-E-Signature-Screen",
              object_name: "Submit",
              object_type: "BUTTON",
            };
            takeUIClickEvent(gaData);
          };
          previousButtonText = getI18n().t("Common.reassess");
        }
        if (isSectionReadOnly) {
          nextButtonCanRender = false;
        }
        if (
          currentCandidateForm?.applicationStatus === OnboardingApplicationStatus.FOLLOW_UP_REQUIRED
        ) {
          nextButtonText = getI18n().t("proceed");
        } else {
          nextButtonText = getI18n().t("Submit");
        }
        break;
      default:
        if (
          currentCandidateForm?.applicationStatus ===
            OnboardingApplicationStatus.PENDING_SIGNATURE ||
          !isFormCreator
        ) {
          isSectionReadOnly = true;
        }
    }
  }
  const nextButtonPreset = isSectionReadOnly ? ButtonPresets.secondary : ButtonPresets.primary;
  if (isSectionReadOnly) {
    nextButtonText = getI18n().t("next_section");
  }
  return (
    // fixed bottom-0
    <div className="w-screen sticky bottom-0 bg-white px-4 pt-2 pb-7 flex items-center justify-between gap-4">
      {renderButton(
        previousButtonText,
        previousButtonHandler,
        previousButtonPreset,
        previousButtonCanRender,
      )}
      {renderButton(nextButtonText, incrementPageNo, nextButtonPreset, nextButtonCanRender)}
    </div>
  );
};
