import { get } from "lodash";
import {
  AutoCompleteType,
  convert2SurveyLocale,
} from "../../../../components/surveyjs/survey/survey.util";

export enum StatusEnum {
  DONE = "done",
  FAILED = "failed",
  IDLE = "idle",
}
export interface I18nData {
  locale: string;
  value: any;
}

const defaultI18nData: I18nData[] = [{ locale: "en", value: "unknow" }];

export const localeEn = "en";

/**
 * get I18nData value by locale, return english value if value in local-language is empty
 * @param data
 * @param locale
 * @returns
 */
export function getI18nData(data: I18nData[] | undefined = defaultI18nData, locale: string) {
  if (!data || !Array.isArray(data)) {
    return "";
  }

  const enValue = data.find((item) => item.locale === localeEn)?.value;
  if (locale === localeEn) {
    return enValue;
  }
  // local language
  const localValue = data.find((item) => item.locale !== localeEn)?.value;
  return localValue || enValue;
}
/** filter optional fields */
export function getAllFormFields(arr: string[]) {
  const fieldsOptional: string[] = getOptionalFields(arr);
  return [
    "firstName",
    "lastName",
    "countryCode",
    "phoneNumber",
    "customerStatus",
    ...fieldsOptional,
  ];
}
function getOptionalFields(arr: string[]) {
  /** remove string array items that are not in the set ["city", "birthdate", "company"], */
  const allowedItems: Set<string> = new Set(["city", "birthdate", "company"]);
  const fields: string[] = arr.filter((item) => allowedItems.has(item));
  return fields;
}
/**
 * convert an array into a two-element-array array, to render responsive UI
 * example: [1, 2, 3]; => [[1, 2], [3, undefined]]
 */
export function convertOptionalFields(arrFields: string[]) {
  const fields: string[] = getOptionalFields(arrFields);

  return fields.reduce((acc: string[][], _, i) => {
    if (i % 2 === 0) {
      acc.push([fields[i], fields[i + 1]]);
    }
    return acc;
  }, []);
}

export function getDefaultCountryCode(
  obj: { [key: string]: { regex: string; code: string; displayName: string } },
  region: string,
): AutoCompleteType | null {
  const result = get(obj, region.toLocaleLowerCase());
  if (result) {
    return {
      regex: result.regex,
      key: result.code,
      label: result.displayName,
    };
  }
  return null;
}
export function formatCountryCode(obj: {
  [key: string]: { regex: string; code: string; displayName: string };
}): AutoCompleteType[] {
  if (!obj) {
    return [];
  }
  const arr: AutoCompleteType[] = [];
  Object.getOwnPropertyNames(obj).forEach(function (key: any) {
    arr.push({
      regex: obj[key].regex,
      key: obj[key].code,
      label: obj[key].displayName,
    });
  });
  return arr;
}
export interface City {
  key: string;
  label: { en: string; local: string };
}
export interface State {
  state: City & { city: City[] };
}
export function formatCity(arr: any[], lang: string): AutoCompleteType[] {
  if (!arr || arr.length < 1) {
    return [];
  }
  const curLang = (lang || "").toLocaleLowerCase();
  const arrResult = [] as AutoCompleteType[];
  arr.forEach((item) => {
    const stateValue = get(item, "state.key", "");
    let arrCity = get(item, "city", []);
    arrCity.forEach((city: City) => {
      const labelEn = get(city, "label.en");
      const label = curLang !== "en" ? get(city, "label.local", labelEn) : labelEn;
      arrResult.push({
        parentKey: "state",
        parentValue: stateValue,
        key: city.key,
        label: label,
      });
    });
  });
  return arrResult;
}

/** surveyjs fn start */
export function convert2SurveyjsI18nData(arr: I18nData[] | undefined) {
  if (!arr || arr.length < 1) {
    return undefined;
  }
  let data = {} as any;
  arr.map((item: I18nData) => {
    const locale = convert2SurveyLocale(item.locale);
    data[locale] = item.value;
  });
  return data;
}
/** surveyjs fn end */
