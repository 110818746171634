import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { takeUIScreenViewEvent } from "../../../../common/ga/ga";
import { TakeUIScreenViewEventParameters } from "../../../../common/ga/types/ga-general-type";
import { HeaderComponent } from "../../../../components/header/header.component";
import { useReassess } from "./reassess.hook";

export const ReassessPage: React.FC = () => {
  const { t } = useTranslation();

  // custom-hook
  const { goBack, surveyUI } = useReassess();

  useEffect(() => {
    const gaData: TakeUIScreenViewEventParameters = {
      module: "Recruitment",
      feature: "ApplicationForm",
      journey: "reassess_application",
      stage: "journey_landing",
      screen_id: "SCR_RCRTMT_RSSSS",
      screen_name: "Recruitment-Reassess-Screen",
    };
    takeUIScreenViewEvent(gaData);
  }, []);

  return (
    <React.Fragment>
      <div className="flex flex-col w-full min-h-screen overflow-x-hidden">
        <HeaderComponent title={t("Common.reassess")} onBack={goBack} />
        {surveyUI}
      </div>
    </React.Fragment>
  );
};
