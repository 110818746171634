import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Model, settings } from "survey-core";
import { Survey } from "survey-react-ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "survey-core/defaultV2.min.css";
import { initSurvey } from "../../../../components/surveyjs/survey/init-survey";
import {
  renderButton,
  ButtonPresets,
} from "../../../../components/surveyjs/survey/util.components";
import { useCanGoBack } from "../../../../../../utils/hooks/use-can-go-back";
import { GlobalHelper } from "../../../../../../utils/helpers/global-helper";
import { quizAnswerUpdateAsync } from "../../../../redux/onboarding-slice";
import { get } from "lodash";
import { OnboardingQuizStatus } from "../../../../types";
import { ROOT_PATH, ONBOARDING_PATH } from "../../../../../../routes/constants";
import { useQuery } from "../../../../../../utils/hooks/use-query";
import { useSelector } from "react-redux";
import { selectOnboardingQuizContent } from "../../../../redux/selector";
import { confirm } from "../../../../../../components/modal/comfirm";
import { useTranslation } from "react-i18next";
interface Props {
  json: any;
  onSubmit: (data: any, action: "SAVE" | "SUBMIT" | "VALIDATE") => void;
  defaultValue?: any;
}
let maxFailCountOccur = false;
const _QuizSurvey: React.FC<Props> = ({ onSubmit, json, defaultValue }) => {
  initSurvey();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const canGoBack = useCanGoBack();
  const [survey, setSurvey] = useState(new Model(json));
  const [pageNo, setPageNo] = React.useState(0);
  const dataRef = useRef<any>({});
  const query = useQuery();
  const { quizId } = useParams();
  const quizContent = useSelector(selectOnboardingQuizContent);
  const decrementPageNo = React.useCallback(async () => {}, []);

  const incrementPageNo = React.useCallback(async () => {
    const isValid = survey.validateCurrentPage();
    new Promise((resolve, reject) => {
      if (
        !maxFailCountOccur &&
        quizContent?.failedCount &&
        quizContent.failedCount > quizContent.maxFailCount!
      ) {
        maxFailCountOccur = true;
        confirm({
          title: t("reminder_title"),
          message: t("course_review_try_again_later"),
          confirmButtonText: t("leave_the_quiz"),
          cancelButtonText: t("onboarding.continue"),
        }).then((result) => {
          if (result.ok) {
            if (canGoBack()) {
              navigate(-1);
            }
            resolve({ leave: true });
          } else {
            resolve({ leave: false });
          }
        });
      } else {
        resolve({ leave: false });
      }
    }).then(({ leave }: any) => {
      if (!leave) {
        const currentPageNumber = survey.currentPageNo;
        const formValues = survey.pages[currentPageNumber].getValue();
        if (survey.isLastPage) {
          const quizAnswer = {
            name: Object.keys(formValues)[0],
            value: Object.values(formValues)[0],
            endQuiz: true,
          };
          GlobalHelper.getGlobalDispatch()(quizAnswerUpdateAsync(quizAnswer)).then(
            (response: any) => {
              const isOK = get(response, "payload.ok", true);
              if (isOK) {
                const data = get(response, "payload.data", {});
                if (data.quizStatus) {
                  navigate(
                    `${ROOT_PATH}/${ONBOARDING_PATH}/courses/quiz/${quizId}/feedback?${query.toString()}`,
                    {
                      state: { quiz: { status: data.quizStatus, score: data.score } },
                      replace: true,
                    },
                  );
                }
              } else {
                const error = get(response, "payload.error", {});
                if (error?.response?.status === 404) {
                  confirm({
                    message: t("course_unavailable_try_another"),
                  }).then(({ ok }) => {
                    if (ok) {
                      if (canGoBack()) {
                        navigate(-1);
                      }
                    }
                  });
                }
              }
            },
            (err: any) => {},
          );
        }
        if (isValid) {
          setPageNo((pre) => pre + 1);
        }
      }
    });
  }, [canGoBack, navigate, query, quizContent, quizId, survey, t]);
  useEffect(() => {
    maxFailCountOccur = false;
    const _survey = new Model({ ...json });
    setSurvey(_survey);
  }, [json]);
  const surveyFormUI = useMemo(() => {
    survey.showPageTitles = false;
    survey.showNavigationButtons = "none";
    survey.questionErrorLocation = "bottom";
    survey.showCompletedPage = false;
    survey.mergeData(defaultValue);
    survey.onComplete.add((_sender: any) => {});
    survey.onValueChanged.add((_sender, options) => {
      dataRef.current.questions = { ...dataRef.current.questions, [options.name]: options.value };
    });
    return (
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex flex-1 flex-col w-screen pb-4 overflow-y-auto overflow-x-hidden">
          <Survey
            currentPageNo={pageNo}
            className="survey-common"
            id="onboarding-quiz-survey"
            model={survey}
          />
        </div>
        <div className="flex w-screen px-4 mb-6">
          {renderButton("Proceed", incrementPageNo, ButtonPresets.primary, true)}
        </div>
      </div>
    );
  }, [defaultValue, incrementPageNo, pageNo, survey]);
  return surveyFormUI;
};

export const QuizSurvey = React.memo(_QuizSurvey);
