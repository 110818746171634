import React from "react";

interface IconProps {
  svg: any;
  width?: number | "100%";
  height?: number | "100%";
  className?: string;
}

export default function Icon({ svg, width, height, className }: IconProps) {
  return React.createElement(svg, { width, height: height ?? "100%", className });
}
