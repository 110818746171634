export const getShortForm = (str?: string): string => {
  if (!str) return "";
  let output = str.replace(/\s/g, "_");
  if (output.length > 4) {
    output = `${output.substring(0, 2)}${output.substring(output.length - 2, output.length)}`;
  }
  return `_${output.toUpperCase()}`;
};

export const getScreenNameShortForm = (str?: string): string => {
  if (!str) return "";
  return `_${str.replace(/\s/g, "_").toUpperCase()}`;
};
