import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import zoomIn from "./zoom-in.png";
import { TransformPdfView } from "./transform-pdf";
import { PdfViewContext, usePdfViewContext } from "./page-context";
type Props = {
  height?: number;
  clickZoom?: () => void;
};

export const PdfThumbnailView = (props: Props) => {
  const context = useContext(PdfViewContext);
  return (
    <div
      className="rounded-xl flex overflow-hidden relative mr-auto ml-auto items-center bg-white h-fit"
      style={{
        minHeight: props.height ?? 510,
        width: window.innerWidth - 32,
      }}
    >
      <div className="pointer-events-none">
        <TransformPdfView className="overflow-hidden m-auto" />
      </div>
      <div
        className="absolute p-2 top-0 right-0 rounded-tr-xl rounded-bl-xl"
        onClick={props.clickZoom}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.50)",
        }}
      >
        <img src={zoomIn} alt="zoomIn"></img>
      </div>
      <div
        className="absolute p-2 bottom-0 right-0 rounded-tl-xl rounded-br-xl text-xs font-medium text-white"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.50)",
        }}
      >
        <span>{`${context.currentPage}/${context.total}`}</span>
      </div>
    </div>
  );
};
