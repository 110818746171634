import * as React from "react";
import { Dayjs } from "dayjs";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import {
  PickersLayoutProps,
  usePickerLayout,
  pickersLayoutClasses,
  PickersLayoutRoot,
  PickersLayoutContentWrapper,
} from "@mui/x-date-pickers/PickersLayout";
import { DateView } from "@mui/x-date-pickers/models";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import { getI18n } from "react-i18next";

function CustomLayout(props: PickersLayoutProps<Dayjs | null, Dayjs, DateView>) {
  const { toolbar, tabs, content, actionBar } = usePickerLayout(props);
  return (
    <PickersLayoutRoot
      ownerState={props}
      sx={{
        overflow: "visible",
        [`.${pickersLayoutClasses.toolbar}`]: {
          gridColumn: 1,
          gridRow: 1,
        },
        [`.${pickersLayoutClasses.actionBar}`]: {
          gridColumn: 1,
          gridRow: 1,
        },
        [`.${pickersLayoutClasses.contentWrapper}`]: {
          gridColumn: 1,
          gridRow: 2,
        },
      }}
    >
      {toolbar}
      {actionBar}
      <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
        {tabs}
        {content}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
}

export default function CustomDatePicker(props: any) {
  const [time, setTime] = React.useState(props.value);
  const { onChange, value } = props;
  const isTilNow = props.isTilNow;

  function ActionList(props: PickersActionBarProps) {
    const datePickerLocaleText = {
      okButtonLabel: getI18n().t("global.text.confirm"),
      cancelButtonLabel: getI18n().t("app.button.cancel"),
      toolbarTitle: getI18n().t("Recruitment.select_date"),
    };
    const { onAccept, onCancel } = props;

    const onSetTilNow = () => {
      const time = "Now";
      onChange(time);
      setTime(time);
      onAccept();
    };

    const actions = [
      { text: datePickerLocaleText.cancelButtonLabel, method: onCancel },
      { text: datePickerLocaleText.okButtonLabel, method: onAccept },
    ];
    if (isTilNow) {
      actions.unshift({ text: getI18n().t("Common.til_now"), method: onSetTilNow });
    }
    return (
      <List sx={{ display: "flex", justifyContent: "flex-end" }}>
        {actions.map(({ text, method }) => (
          <ListItem key={text} style={{ width: "auto" }}>
            <Button variant="text" onClick={method}>
              {text}
            </Button>
          </ListItem>
        ))}
      </List>
    );
  }

  function CustomTextField(props: any) {
    let { error } = props;
    let time = value;
    if (time !== "Now" && time) {
      time = dayjs(time).format(props.ownerState.format);
    } else if (time === "Now") {
      error = false;
    }
    return <TextField {...props} value={time} error={error} />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        {...props}
        views={props.views || ["month", "year"]}
        value={time}
        onChange={(value) => {
          setTime(value);
          onChange(value);
        }}
        slotProps={{
          textField: {
            size: "small",
            inputProps: {
              onFocus: (e: any) => {
                e.target.blur();
              },
            },
          },
        }}
        slots={{
          layout: CustomLayout,
          actionBar: ActionList,
          textField: CustomTextField,
        }}
      />
    </LocalizationProvider>
  );
}
