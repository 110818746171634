import { pdfjs } from "react-pdf";
import { cMapUrl, standardFontDataUrl, workerSrc } from "./constant";
import { isString } from "lodash";
/**
 *
 * just for demo
 *
 * @param url
 * @returns
 *
 */

const readFileData = (file: File): Promise<string | ArrayBuffer | null | undefined> => {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e?.target?.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

/**
 *
 * @param file should be base64 string or File blob
 * @returns
 */
//return: images -> an array of images encoded in base64
export const convertPdfToImages = async (file: File | string) => {
  const data = isString(file) ? file : ((await readFileData(file)) as string);
  if (!data) return;
  const images: string[] = [];
  pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
  const pdf = await pdfjs.getDocument({
    url: data,
    cMapUrl: cMapUrl,
    cMapPacked: true,
    standardFontDataUrl: standardFontDataUrl,
  }).promise;
  const canvas = document.createElement("canvas");
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 1 });
    const context = canvas.getContext("2d");
    if (!context) return;
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    images.push(canvas.toDataURL(undefined, 100));
  }
  canvas.remove();
  return images;
};

// comment out not supported method
/**
 *
 * There are still some errors that have not been fixed and are temporarily unavailable.
 *
 * @param file
 * @returns
 */
//return: images -> an array of images encoded in base64
// export const convertPdfToSvg = async (file: File | string) => {
//   const data = isString(file) ? file : ((await readFileData(file)) as string);
//   if (!data) return;
//   const svgs: any[] = [];

//   pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
//   const pdf = await pdfjs.getDocument({
//     url: data,
//     cMapUrl: cMapUrl,
//     cMapPacked: true,
//     standardFontDataUrl: standardFontDataUrl,
//   }).promise;
//   for (let i = 0; i < pdf.numPages; i++) {
//     const page = await pdf.getPage(i + 1);
//     const viewport = page.getViewport({ scale: 1 });

//     const operatorList = await page.getOperatorList();
//     const svgGfx = new pdfjs.SVGGraphics(page.commonObjs, page.objs);

//     svgGfx.embedFonts = true;

//     //after upgrade react-pdf to 8.0.2, getSVG method is not available, comment out for now
//     // const nextSvg = await svgGfx.getSVG(operatorList, viewport);
//     // if (!nextSvg) return;
//     // svgs.push(nextSvg.toString());

//     //there is a promise returned by getSVG method, so we need to use then to get the svg string
//     // svgGfx.getSVG(operatorList, viewport).then((nextSvg) => {
//     //   if (nextSvg == null) return;
//     //   const serializer = new XMLSerializer();
//     //   const svgStr = serializer.serializeToString(nextSvg);
//     //   svgs.push(svgStr);
//     // });
//   }
//   return svgs;
// };
