import { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { confirm } from "../../../../components/modal/comfirm";
import { ErrorView } from "../../components/error-view";
import { Header } from "../../components/header";
import { fetchEventValidate } from "../../network/event-detail-crud";

export enum StatusCode {
  SUCCESS = "SUCCESS",
  INVALID_LINK = "INVALID_LINK",
  EVENT_EXPIRED = "EVENT_EXPIRED",
  EVENT_CANCELLED = "EVENT_CANCELLED",
}

export const DownloadPage: React.FC = () => {
  const { t } = useTranslation();
  const { hashCode = "" } = useParams();
  const [status, setStatus] = useState<StatusCode>();
  const [init, setInit] = useState(true);

  const AGENT_PORTAL_HOST = get(window, "envConfig.AGENT_PORTAL_HOST", "");

  const getEventValidate = useCallback(async (shortenCode: string) => {
    try {
      const res = await fetchEventValidate(shortenCode);
      const status: StatusCode = res.data.code;
      setStatus(status);
      setInit(false);
    } catch (err) {
      setInit(false);
    }
  }, []);

  useEffect(() => {
    if (hashCode) {
      getEventValidate(hashCode);
    }
  }, [getEventValidate, hashCode]);

  useEffect(() => {
    if (status === StatusCode.SUCCESS) {
      confirm({
        title: t("event.download_app_modal_title"),
        message: t("event.download_app_modal_desc"),
        confirmButtonText: t("event.download_app_modal_confirm_button_text"),
        cancelButtonText: t("event.download_app_modal_cancel_button_text"),
      }).then((result) => {
        if (result.ok) {
          window.location.href = `${AGENT_PORTAL_HOST}/pruforce/event/assistance/${hashCode}`;
        }
      });
    }
  }, [status]);

  return (
    <>
      <Header />
      {!init && status !== StatusCode.SUCCESS && (
        <ErrorView
          title={t("event.scanner_link_expired")}
          desc={
            status === StatusCode.EVENT_CANCELLED
              ? t("event.event_cancelled")
              : t("event.event_finished")
          }
        />
      )}
    </>
  );
};
