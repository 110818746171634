import React from "react";
import {
  CustomType,
  CustomFunctionName,
  regionPhoneValidate,
  idNumValidate,
} from "../survey/survey.util";
import {
  SurveyQuestionBoolean,
  SurveyQuestionDropdown,
  SurveyQuestionTextField,
  SurveyQuestionCheckbox,
  SurveyQuestionRadiogroup,
  SurveyQuestionDownload,
} from "../index";
import { FunctionFactory } from "survey-core";
import {
  ReactQuestionFactory,
  SurveyQuestionBooleanCheckbox,
  SurveyQuestionBoolean as SvBoolean,
  SurveyQuestionBooleanRadio,
  SurveyQuestionPanelDynamic,
  SurveyQuestionMatrix,
  SurveyQuestionRanking,
  // SurveyQuestionFile,
  SurveyQuestionComment,
  SurveyQuestionMatrixDynamic,
  SurveyQuestionImagePicker,
  SurveyQuestionMultipleText,
  SurveyQuestionButtonGroup,
  SurveyQuestionText,
  TagboxFilterString,
  SurveyQuestionTagbox,
  SurveyQuestionMatrixDropdown,
  SurveyQuestionExpression,
  SurveyQuestionRating,
  SurveyQuestionDropdown as SvDropdown,
  SurveyQuestionImage,
  SurveyQuestionCustom,
  SurveyQuestionComposite,
  SurveyQuestionEmpty,
  SurveyQuestionHtml,
  SurveyQuestionDropdownSelect,
  SurveyQuestionRatingDropdown,
  ReactElementFactory,
  // SurveyQuestionSignaturePad,
} from "survey-react-ui";
import { initTextSerializer } from "../text/text.component";
import { initBoolSerializer } from "../boolean/boolean.component";
import { initDropdownSerializer } from "../dropdown/dropdown.component";
import { SurveyQuestionSignaturePad } from "../signaturepad/component";
import { SurveyQuestionFile } from "../file/component";
import { initFileSerializer } from "../file/question";
import { initCheckboxSerializer } from "../checkbox/checkbox.component";
import { initRadiogroupSerializer } from "../radiogroup/radiogroup.component";
import { getOid, sendOtpFunc } from "../../../../prudna/pages/personal-info-form/send-otp";
import { PopoverComponent } from "../popover/popover.component";
import { initCustomizedSurvey } from "./init-customized-survey";
import { initDownloadSerializer } from "../download/download.component";

let isInitOnboardingSurvey = false;
export function initSurvey() {
  if (isInitOnboardingSurvey) return;
  initCustomizedSurvey();
  isInitOnboardingSurvey = true;
  initAllSerializer();
  ReactQuestionFactory.Instance = new ReactQuestionFactory();
  /**
   * You need to call 'registerall' before calling 'register customer
   */
  registerAll();
  registerCustomerize();
}
function registerCustomerize() {
  ReactQuestionFactory.Instance.registerQuestion(CustomType.Boolean, (props) => {
    return React.createElement(SurveyQuestionBoolean, props);
  });
  ReactQuestionFactory.Instance.registerQuestion(CustomType.Dropdown, (props) => {
    return React.createElement(SurveyQuestionDropdown, props);
  });
  ReactQuestionFactory.Instance.registerQuestion(CustomType.Text, (props) => {
    return React.createElement(SurveyQuestionTextField, props);
  });
  FunctionFactory.Instance.register("regionPhoneValidate", regionPhoneValidate);
  FunctionFactory.Instance.register("idNumValidate", idNumValidate);

  FunctionFactory.Instance.register(CustomFunctionName.sendOtp, sendOtpFunc);
  FunctionFactory.Instance.register(CustomFunctionName.getOid, getOid);
}

function registerAll() {
  ReactQuestionFactory.Instance.registerQuestion("signaturepad", (props) => {
    return React.createElement(SurveyQuestionSignaturePad, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("sv-rating-dropdown", (props) => {
    return React.createElement(SurveyQuestionRatingDropdown, props);
  });

  ReactQuestionFactory.Instance.registerQuestion("sv-dropdown-select", (props) => {
    return React.createElement(SurveyQuestionDropdownSelect, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("html", (props) => {
    return React.createElement(SurveyQuestionHtml, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("empty", (props) => {
    return React.createElement(SurveyQuestionEmpty, props);
  });

  ReactQuestionFactory.Instance.registerQuestion("custom", (props) => {
    return React.createElement(SurveyQuestionCustom, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("composite", (props) => {
    return React.createElement(SurveyQuestionComposite, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("image", (props) => {
    return React.createElement(SurveyQuestionImage, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("dropdown", (props) => {
    return React.createElement(SvDropdown, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("rating", (props) => {
    return React.createElement(SurveyQuestionRating, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("expression", (props) => {
    return React.createElement(SurveyQuestionExpression, props);
  });

  ReactQuestionFactory.Instance.registerQuestion("matrixdropdown", (props) => {
    return React.createElement(SurveyQuestionMatrixDropdown, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("tagbox", (props) => {
    return React.createElement(SurveyQuestionTagbox, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("sv-tagbox-filter", (props) => {
    return React.createElement(TagboxFilterString, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("text", (props) => {
    return React.createElement(SurveyQuestionText, props);
  });

  ReactQuestionFactory.Instance.registerQuestion("multipletext", (props) => {
    return React.createElement(SurveyQuestionMultipleText, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("buttongroup", (props) => {
    return React.createElement(SurveyQuestionButtonGroup, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("imagepicker", (props) => {
    return React.createElement(SurveyQuestionImagePicker, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("matrixdynamic", (props) => {
    return React.createElement(SurveyQuestionMatrixDynamic, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("comment", (props) => {
    return React.createElement(SurveyQuestionComment, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("file", (props) => {
    return React.createElement(SurveyQuestionFile, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("ranking", (props) => {
    return React.createElement(SurveyQuestionRanking, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("matrix", (props) => {
    return React.createElement(SurveyQuestionMatrix, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("checkbox", (props) => {
    return React.createElement(SurveyQuestionCheckbox, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("radiogroup", (props) => {
    return React.createElement(SurveyQuestionRadiogroup, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("paneldynamic", (props) => {
    return React.createElement(SurveyQuestionPanelDynamic, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("sv-boolean-radio", (props) => {
    return React.createElement(SurveyQuestionBooleanRadio, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("boolean", (props) => {
    return React.createElement(SvBoolean, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("sv-boolean-checkbox", (props) => {
    return React.createElement(SurveyQuestionBooleanCheckbox, props);
  });
  ReactElementFactory.Instance.registerElement("popover", (props) => {
    return React.createElement(PopoverComponent, props);
  });
  ReactQuestionFactory.Instance.registerQuestion("download", (props) => {
    return React.createElement(SurveyQuestionDownload, props);
  });
}

function initAllSerializer() {
  initTextSerializer();
  initBoolSerializer();
  initFileSerializer();
  initDropdownSerializer();
  initCheckboxSerializer();
  initRadiogroupSerializer();
  initDownloadSerializer();
}
