import { cloneDeep, get, isEmpty } from "lodash";
import store from "../../../../redux/store";
import { getOnboardingTrainingSummary } from "../../network/network";
import { OnboardingPreCheck, StatusEnum } from "../../types";
import { confirm } from "../../../../components/modal/comfirm";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { getI18n } from "react-i18next";
import { GlobalHelper } from "../../../../utils/helpers/global-helper";
import { ROOT_PATH, ONBOARDING_PATH } from "../../../../routes/constants";
import { getToken, parseJwt } from "../../utils";

/**
 * update survey json inputMaskPattern to standard input mask.
 * background: admin-portal not support input mask settings(old survey version), instead we added a custom-property: inputMaskPattern: "### ####", so need to convert here.
 * example:
 * source: { type: "text", name: "question1", title: "question1", inputMaskPattern: "### ####" }
 * target: { type: "text", name: "question1", title: "question1", "maskType": "pattern",  "maskSettings": {"pattern": "### ####"} }
 * @param surveyJson
 * @returns
 */
export function convertInputMaskPattern(surveyJson: any): any {
  let json = cloneDeep(surveyJson); // avoid TypeError: Cannot assign to read only property '0' of object '[object Array]'
  if (Array.isArray(json)) {
    for (let i = 0; i < json.length; i++) {
      json[i] = convertInputMaskPattern(json[i]);
    }
  } else if (typeof json === "object") {
    if (json && json.hasOwnProperty("elements") && Array.isArray(json.elements)) {
      for (let i = 0; i < json.elements.length; i++) {
        if (
          json.elements[i].type === "text" &&
          json.elements[i].hasOwnProperty("inputMaskPattern")
        ) {
          json.elements[i].maskType = "pattern";
          json.elements[i].maskSettings = {
            pattern: json.elements[i].inputMaskPattern,
          };
          // delete json.elements[i].inputMaskPattern;
        }
        json.elements[i] = convertInputMaskPattern(json.elements[i]);
      }
    }

    for (const key in json) {
      if (typeof json[key] === "object") {
        json[key] = convertInputMaskPattern(json[key]);
      }
    }
  }

  return json;
}

export function addDocumentDownloadJson(surveyJson: any, extraElement: any, sectionName: string) {
  let json = cloneDeep(surveyJson);
  for (const page of json.pages) {
    for (const element of page.elements as any) {
      if (element.type === "panel" && element.name === sectionName) {
        if (extraElement) {
          element.elements.unshift(extraElement);
        }
      }
    }
  }
  return json;
}

export interface OnboardingPreCheckResult {
  training?: boolean;
  licenseExam?: boolean;
}
const navigateToExams = () => {
  GlobalHelper.getGlobalNavigate()(
    `${ROOT_PATH}/${ONBOARDING_PATH}/exams${window.location.search}`,
  );
};
const navigateToCourses = () => {
  GlobalHelper.getGlobalNavigate()(
    `${ROOT_PATH}/${ONBOARDING_PATH}/courses${window.location.search}`,
  );
};
export async function onboardingPreCheck(params?: {
  silent?: boolean;
  confirmCallback?: (props?: any) => void;
  cancelCallback?: (props?: any) => void;
}): Promise<OnboardingPreCheckResult> {
  const { silent, confirmCallback, cancelCallback } = params || {};
  const preCheck = get(store.getState(), "onboarding.configs.onboardingPreCheck", []).filter(
    (checkItem: OnboardingPreCheck) =>
      [OnboardingPreCheck.Training, OnboardingPreCheck.LicenseExam].includes(checkItem),
  );
  if (isEmpty(preCheck)) return {};
  const queryParams = new URLSearchParams(window.location.search);
  const candidateId = queryParams.get("candidateId");
  if (!candidateId) return {};
  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};
  const isCandidateSelf = tokenData.sub === candidateId;
  const summaryResponse = await getOnboardingTrainingSummary({
    candidateId,
  });
  const result: any = {};
  const trainingSummary = get(summaryResponse, "data.trainingSummary", {});
  if (trainingSummary?.requiredToAttend === false) {
    const index = preCheck.indexOf(OnboardingPreCheck.Training);
    if (index !== -1) {
      preCheck.splice(preCheck.indexOf(OnboardingPreCheck.Training), 1);
    }
  }
  for (const check of preCheck) {
    switch (check) {
      case OnboardingPreCheck.Training:
        if (trainingSummary.status !== StatusEnum.COMPLETED) {
          result.training = false;
          if (!silent) {
            // show alert
            confirm({
              icon: "notice",
              title: getI18n().t("reminder_title"),
              message: isCandidateSelf
                ? getI18n().t(
                    "Recruitment.please_complete_onboarding_training_before_proceeding_to_the_next_step",
                  )
                : getI18n().t(
                    "Recruitment.please_complete_candidate_onboarding_training_before_submit_sig",
                  ),
              confirmButtonText: getI18n().t("Recruitment.go_to_onboarding_training"),
              onConfirm: () => {
                // redirect to training
                if (isCandidateSelf && !confirmCallback) {
                  navigateToCourses();
                }
                confirmCallback?.(navigateToCourses);
              },
              onCancel: () => {
                cancelCallback?.();
              },
            });
          }
          break;
        }
        break;
      case OnboardingPreCheck.LicenseExam:
        const licenseExamSummary = get(summaryResponse, "data.examSummary", {});
        if (!licenseExamSummary.isCompleted) {
          result.licenseExam = false;
          if (!silent) {
            // show alert
            confirm({
              icon: "notice",
              title: getI18n().t("reminder_title"),
              message: isCandidateSelf
                ? getI18n().t(
                    "Recruitment.please_complete_license_exam_before_proceeding_to_the_next_step",
                  )
                : getI18n().t(
                    "Recruitment.please_complete_candidate_license_exam_before_submit_sig",
                  ),
              confirmButtonText: isCandidateSelf
                ? getI18n().t("Recruitment.go_to_license_module")
                : getI18n().t("Recruitment.save_and_leave"),
              onConfirm: () => {
                // redirect to license module
                if (isCandidateSelf && !confirmCallback) {
                  navigateToExams();
                }
                confirmCallback?.(navigateToExams);
              },
              onCancel: () => {
                cancelCallback?.();
              },
            });
          }
          break;
        }
        break;
      default:
        break;
    }
    // Exit the loop if any module is incomplete
    if (result.training === false || result.licenseExam === false) {
      break;
    }
  }
  return result;
}
