import { CircularProgress } from "@mui/material";
import { get } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorScreen } from "../../../../components/error-screen/error-screen";
import { HeaderComponent } from "../../../../components/header/header.component";
import { confirm } from "../../../../components/modal/comfirm";
import NavigatorCard from "../../../../components/navigator-card/navigator-card";
import ProgressBar from "../../../../components/progress-bar/progress-bar";
import { onboardingExcludedMenuModules } from "../../../../constants/constants";
import { color } from "../../../../mui-theme/palette";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { GlobalHelper } from "../../../../utils/helpers/global-helper";
import { useCanGoBack } from "../../../../utils/hooks/use-can-go-back";
import { useQuery } from "../../../../utils/hooks/use-query";
import { SurveyComponent } from "../../components/surveyjs/survey/survey.component";
import useThrottledTouchEnd from "../../hooks/use-throtted-touch-end";
import { SourceEnum } from "../../network/file-op-interface";
import {
  fetchCandidateOnboardingFormAsync,
  fetchConfigAsync,
  fetchOnboardingDocumentUploadAsync,
  fetchOnboardingFormTemplateAsync,
  reset,
} from "../../redux/onboarding-slice";
import {
  selectCandidateOnboardingForm,
  selectCandidateOnboardingFormValues,
  selectCompletedSectionLength,
  selectDataError,
  selectDocumentUploadList,
  selectOnboardingCandidateName,
  selectOnboardingFlow,
  selectOnboardingFormTemplate,
  selectTotalSectionLength,
} from "../../redux/selector";
import {
  FormAction,
  OnboardingApplicationStatus,
  OnboardingFormModuleName,
  OnboardingFormSectionName,
} from "../../types";
import { backToApp, checkCameraPermission, extractOwnerId, getToken, parseJwt } from "../../utils";
import "./form.css";
import { addDocumentDownloadJson, convertInputMaskPattern } from "./survey.util";
import { getOnboardingModuleNameBySection, submitOnboardingForm } from "./util";

export let preQuestionsData: any = {};
const individualHeaderModuleOrSectionNames = [OnboardingFormModuleName.ESignature];

const isShowNavigatorCard = (formCreatorUserId?: string) => {
  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};
  return !(extractOwnerId(tokenData) === formCreatorUserId);
};

export const SurveyFormScreen: React.FC = () => {
  useThrottledTouchEnd();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const canGoBack = useCanGoBack();
  const [loading, setLoading] = useState(true);
  const dataFetched = useRef(false);
  const query = useQuery();
  const candidateId = query.get("candidateId");

  const candidateOnboardingForm = useSelector(selectCandidateOnboardingForm);
  const candidateOnboardingFormValues = useSelector(selectCandidateOnboardingFormValues);
  const candidateName = useSelector(selectOnboardingCandidateName);
  const formTemplate = useSelector(selectOnboardingFormTemplate);
  const dataError = useSelector(selectDataError);
  const onboardingFlow = useSelector(selectOnboardingFlow);

  const totalSections = useSelector(selectTotalSectionLength);
  const compeletedSections = useSelector(selectCompletedSectionLength);
  const documentUploadList = useSelector(selectDocumentUploadList);

  useEffect(() => {
    if (window.history.length === 1) {
      // history lenght equal to 1 indicate this is the first route, should better to reset the state
      dispatch<any>(reset());
    }
  }, []);

  const initSectionName = useMemo(() => {
    const sectionName =
      get(location.state, "sectionName", query.get("sectionName")) ||
      OnboardingFormModuleName.PreQuestions;
    return sectionName;
  }, [location.state, query]);

  const [sectionName, setCurrentSectionName] = useState(initSectionName);

  const moduleName = useMemo(() => {
    return getOnboardingModuleNameBySection(formTemplate, sectionName, candidateId as string);
  }, [sectionName, formTemplate, candidateId]);

  const headerText = useMemo(() => {
    // default application title
    let title = t("Recruitment.onboarding_application");
    if (individualHeaderModuleOrSectionNames.includes(sectionName)) {
      title = t(`Recruitment.${sectionName}`);
    } else if (individualHeaderModuleOrSectionNames.includes(moduleName)) {
      title = t(`Recruitment.${moduleName}`);
    }
    return title;
  }, [sectionName, moduleName, t]);

  const submitForm = useCallback(async (data: any, formAction: FormAction) => {
    return await submitOnboardingForm({
      data,
      dispatch,
      formAction,
      canGoBack,
    });
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!candidateId) return setLoading(false);
    let form: any;
    const moduleName = GlobalHelper.getOnboardingCurrentSection()?.moduleName;
    if (!dataFetched.current || candidateOnboardingForm?.candidateId !== candidateId) {
      form = await dispatch<any>(fetchCandidateOnboardingFormAsync({ candidateId }));
      if (get(form, "error", false)) return setLoading(false);
    } else {
      if (moduleName === OnboardingFormModuleName.PreQuestions) {
        navigate(`${ROOT_PATH}/${ONBOARDING_PATH}?${query.toString()}`);
      }
    }
    const formInstance = form ? get(form, "payload.data[0]", undefined) : candidateOnboardingForm;
    if (formInstance?.applicationStatus === OnboardingApplicationStatus.FOLLOW_UP_REQUIRED) {
      // when form is in follow up required status, need to check whether user need to go through TNC and E-signature
      await dispatch<any>(fetchConfigAsync());
    }
    const fetchOnboardingFormTemplateParams = formInstance.templateId
      ? { templateId: formInstance.templateId, candidateId }
      : { candidateId, type: query.get("type") };

    const formTemplateAction = fetchOnboardingFormTemplateAsync(fetchOnboardingFormTemplateParams);
    if (moduleName === OnboardingFormModuleName.DocsUpload) {
      const documentUploadAction = fetchOnboardingDocumentUploadAsync({
        applicationId: candidateOnboardingForm?.applicationId,
        source: SourceEnum.DOCUMENT_UPLOAD,
      });
      await Promise.all([dispatch<any>(formTemplateAction), dispatch<any>(documentUploadAction)]);
    } else {
      await dispatch<any>(formTemplateAction);
    }

    setLoading(false);
    dataFetched.current = true;
  }, [candidateId, candidateOnboardingForm, dispatch, navigate, query]);
  useEffect(() => {
    if (!onboardingFlow || !candidateOnboardingForm) return;
    checkCameraPermission(candidateOnboardingForm, onboardingFlow);
  }, [onboardingFlow, candidateOnboardingForm]);

  useEffect(() => {
    if (!onboardingFlow) {
      dispatch<any>(fetchConfigAsync());
      return;
    }
    fetchData();
  }, [onboardingFlow]);

  const allQuestionBeforeDocumentUploadCompleted = useMemo(() => {
    if (!candidateOnboardingForm?.modules) {
      return true;
    }
    const allModulesExceptDocuments = Object.keys(candidateOnboardingForm.modules).filter(
      (key) => key !== OnboardingFormModuleName.DocsUpload,
    );
    for (const moduleKey of allModulesExceptDocuments) {
      const module = candidateOnboardingForm.modules[moduleKey];
      const statuses = Object.values(module).flatMap((section: any) => section?.status);
      if (!statuses.every((status) => status === "COMPLETED")) {
        return false;
      }
    }
    return true;
  }, [candidateOnboardingForm?.modules]);

  const surveyJson = useMemo(() => {
    if (formTemplate) {
      const json = addDocumentDownloadJson(
        formTemplate,
        {
          type: "download",
          name: OnboardingFormSectionName.documentUploadSection,
          title: t("download_the_documents_first_then_upload"),
          applicationId: candidateOnboardingForm?.applicationId,
          sources: allQuestionBeforeDocumentUploadCompleted ? documentUploadList : [],
        },
        OnboardingFormSectionName.documentUploadSection,
      );

      const allDownloadInForm = !!formTemplate?.pdfTemplates?.find(
        (item: any) => item.downloadInForm === true,
      );
      if (allDownloadInForm) {
        return convertInputMaskPattern(json);
      } else {
        return convertInputMaskPattern(formTemplate);
      }
    }
    return "";
  }, [
    candidateOnboardingForm,
    allQuestionBeforeDocumentUploadCompleted,
    JSON.stringify(documentUploadList),
    formTemplate,
    t,
  ]);

  const surveyUI = useMemo(() => {
    if (dataError) {
      return <ErrorScreen statusCode="403" />;
    }
    if (loading) {
      return (
        <div className={`w-screen h-screen flex flex-1 items-center justify-center`}>
          <CircularProgress sx={{ color: color.majorRed }} />
        </div>
      );
    }

    return surveyJson && sectionName ? (
      <div className="flex flex-col">
        <SurveyComponent
          currentSectionName={sectionName}
          setCurrentSectionName={setCurrentSectionName}
          json={surveyJson}
          defaultValue={candidateOnboardingFormValues}
          onSubmit={submitForm}
        />
      </div>
    ) : null;
  }, [loading, dataError, surveyJson, sectionName, candidateOnboardingFormValues, submitForm]);

  const goBack = useCallback(() => {
    const moduleName = GlobalHelper.getOnboardingCurrentSection()?.moduleName;
    if (moduleName === OnboardingFormModuleName.PreQuestions) {
      confirm({
        message: t("confirm_leave_form_warning"),
      }).then((result) => {
        if (result.ok) {
          backToApp();
        }
      });
    } else {
      if (canGoBack()) {
        navigate(-1);
      }
    }
  }, []);

  const pageBanner = useMemo(() => {
    const moduleName = GlobalHelper.getOnboardingCurrentSection()?.moduleName;
    return (
      <div>
        {!onboardingExcludedMenuModules.includes(moduleName) &&
          candidateOnboardingForm?.applicationStatus === OnboardingApplicationStatus.EDITING && (
            <div>
              <ProgressBar
                className="px-4"
                progress={compeletedSections / totalSections}
                text={`(${compeletedSections
                  .toString()
                  .padStart(totalSections.toString().length, "0")}/${totalSections})`}
              />
            </div>
          )}
        {individualHeaderModuleOrSectionNames.includes(moduleName) &&
          isShowNavigatorCard(candidateOnboardingForm?.formCreatorUserId) && (
            <NavigatorCard
              applicationNumber={candidateOnboardingForm?.applicationId}
              candidateName={candidateName}
            />
          )}
      </div>
    );
  }, [compeletedSections, totalSections, candidateName, candidateOnboardingForm]);

  return (
    <div className="h-screen w-screen flex flex-col">
      <HeaderComponent title={headerText} onBack={goBack} />
      {pageBanner}
      <div className="flex justify-center flex-1">{surveyUI}</div>
    </div>
  );
};
