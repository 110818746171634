import React, { FC } from "react";
import { useStyles } from "./icons.styles";

type IconProps = {
  disabled?: boolean;
};

export const EditIcon: FC<IconProps> = () => {
  const classes = useStyles();
  return <i className={`iconfont iconfont-edit1 ${classes.iconSize} ${classes.editIcon}`}></i>;
};

export const ArrowRightIcon: FC<IconProps> = ({ disabled }) => {
  const classes = useStyles();
  return (
    <i
      className={`iconfont iconfont-arrow ${classes.iconSize} ${
        !disabled ? classes.viewMoreIcon : ""
      }`}
    ></i>
  );
};
