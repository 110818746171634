import React, { useEffect, useState } from "react";
import { useRequest } from "ahooks";
import { useQuery } from "../../../../utils/hooks/use-query";
import moment from "moment";
import { convertLanguageKey } from "../../../../utils/lang";
import { getPRUdnaCandidateAssessments, postSharesAssessments } from "../../network/network";
import { PRUDNA_PATH, ROOT_PATH } from "../../../../routes/constants";

interface HookProps {
  navigate: any;
}

export const useRedirect = ({ navigate }: HookProps) => {
  const query: any = useQuery();
  const assessmentId = query.get("aid");
  const candidateId = query.get("cid");
  const [loading, setLoading] = useState(false);
  const { run: initPageData } = useRequest(
    () => getPRUdnaCandidateAssessments(candidateId, assessmentId),
    {
      manual: true, // set manual is true，prohibit auto request
      onSuccess: async (data) => {
        if (moment().isAfter(moment(data?.expiredAt))) {
          // link is expired
          const invitationLangCode = data?.examLanCode;
          const appLang = convertLanguageKey(invitationLangCode);
          sessionStorage.setItem("infoFormLang", appLang);
          navigate.replace(`${ROOT_PATH}/${PRUDNA_PATH}/link-expired`);
        } else {
          if (!data?.completed) {
            try {
              await postSharesAssessments(assessmentId, {
                candidateId: candidateId,
                eventType: "CANDIDATE_ASSESSMENT_STARTED",
                assessmentId: assessmentId,
              });
            } catch (e) {}
          }
          setLoading(false);
          window.location.replace(data?.examLink);
        }
      },
    },
  );

  useEffect(() => {
    setLoading(true);
    initPageData();
  }, [initPageData]);

  return {
    loading,
  };
};
