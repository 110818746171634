import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";

// Icon
import "./icon-font/fonts/iconfont.css";

import { router } from "./App";
import store from "./redux/store";
import { initializeApp } from "firebase/app";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n-config";
import { firebaseConfig } from "./firebase.config";
import { RouterProvider } from "react-router-dom";
import { ToastProvider } from "./components/pru-toast/toast-context";

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <ToastProvider>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </ToastProvider>
  </Provider>,
  /**
   * remove React.StrictMode, otherwise App will be render twice
   * https://stackoverflow.com/questions/61193122/react-default-create-react-app-app-component-rendered-twice
   */
  //   <React.StrictMode>
  //   <BrowserRouter>
  //     <App />
  //   </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
