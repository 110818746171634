export type GAEventName = "ui_click" | "ui_screen_visit";

export type GAEventType = "Click" | "Screen";

export type GAModule = "LeadForm" | "Error" | "Recruitment" | "Events" | "Prudna";

export type GAFeature = string;

export type GAJourney = string;

export type GAStage = string;

export type GAScreenName = string;

export const objectTypeShortFormMap = {
  BUTTON: "BTN",
  HYPERLINK: "HYPERLINK",
};

export type GAObjectType = keyof typeof objectTypeShortFormMap;

export type GAEventSource = "PRUForce-PublicPortal-IN-web" | "PRUForce-PublicPortal-IN-webapp";
