import React from "react";
import SvgExpired from "../img/expired.svg";
import Svg404 from "../../../components/error-state/no_content.svg";
import { useTranslation } from "react-i18next";
import { Code } from "../network/type";

const STATUS_ICON_MAP: any = {
  [Code.EVENT_CANCELLED]: Svg404,
  [Code.EVENT_EXPIRED]: SvgExpired,
};

export const ErrorView = ({
  statusCode = Code.EVENT_EXPIRED,
  title,
  desc,
}: {
  statusCode?: Code;
  title?: string;
  desc?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-y-4 px-4">
      <img src={STATUS_ICON_MAP[statusCode] || SvgExpired} width={180} height={180} alt="expired" />
      <span className="text-lg md:text-2xl font-semibold text-center">
        {title || t("error-state.title-403")}
      </span>
      {desc && (
        <span className="text-[16px] leading-6 md:text-xl font-semibold text-[#666] text-center">
          {desc}
        </span>
      )}
    </div>
  );
};
