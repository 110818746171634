import React, { useCallback } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "../../../../layout/layout";
import "./auto-tracking-screen.css";
import {
  TrackingData,
  EventModule,
  EventFeature,
} from "../../../../utils/event-tracking/event-tracking.model";
interface Props {
  message?: string;
}
export const AutoTrackingScreen: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), []);

  const { t } = useTranslation();
  const title = "auto click tracking demo";
  window.document.title = title;

  /** define the page tracking data */
  const pageTrackingData: TrackingData = {
    name: "prospect::auto-tracking",
    module: EventModule.agent_profile_site,
    feature: EventFeature.resource,
    journey: "landing-journey",
    stage: "test-stage",
    target: "whatsapp/phone/email",
  };

  const clickTrackingData = JSON.stringify({
    name: "prospect::auto-tracking",
    module: "agent_profile_portal",
    feature: "agent_naming_card",
    journey: "click-journey",
    stage: "test-stage",
    target: "whatsapp/phone/email",
  });

  return (
    <React.Fragment>
      <Layout trackingData={pageTrackingData} title={title}>
        <div className="wrap">
          <h2 className="center">{title}</h2>
          <b>clicking below button will auto post the tracking data to Server.</b>
          <p>
            <Button
              variant="outlined"
              data-auto_click_tracking={clickTrackingData}
              className="auto_click_tracking"
            >
              auto tracking on click
            </Button>
          </p>

          <h3>how to use</h3>
          <ul>
            <li>
              button add className: <b>auto_click_tracking</b>
            </li>
            <li>
              put the custom tracking data in attribute: <b>data-auto_click_tracking</b>
            </li>
          </ul>
          <div>
            <Link to="../">&gt; back to home</Link>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};
