import { SxProps, Theme } from "@mui/material";
import { color } from "../../../../mui-theme/palette";

export const completeBoxStyle: SxProps<Theme> = {
  backgroundColor: "#DFF7EF",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  display: "flex",
  flexShrink: 0,
  justifyContent: "center",
  alignItems: "center",
};
export const completeBoxMarginStyle: SxProps<Theme> = {
  ...completeBoxStyle,
  marginRight: "8px",
};
export const completeIconStyle: SxProps<Theme> = {
  color: "#24C791",
  fontSize: "20px",
};
export const itemHeader: SxProps<Theme> = {
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
  color: color.grey33,
};
export const item: SxProps<Theme> = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  color: color.grey33,
};
export const docText: SxProps<Theme> = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
};
export const docTips: SxProps<Theme> = {
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "21px",
  color: "#666",
};

export const docButton: SxProps<Theme> = {
  width: "100%",
  fontWeight: 600,
  fontSize: "16px",
  height: "48px",
};

export const sectionItem: SxProps<Theme> = {
  flex: "none",
  marginRight: "8px",
  ...itemHeader,
};
export const modalBox: SxProps<Theme> = {
  bgcolor: "background.paper",
  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",
  boxShadow: 24,
  marginTop: "auto",
  p: 2,
  flexDirection: "column",
  width: "100%",
};
export const docDot: SxProps<Theme> = {
  color: "#E8192C",
  fontSize: "8px",
  marginRight: "16px",
};

export const linkDoc: SxProps<Theme> = {
  color: "#4386E5",
  ...docText,
};
export const flex: SxProps<Theme> = { display: "flex" };
export const subheader: SxProps<Theme> = { padding: 0, borderRadius: "16px", ...itemHeader };
