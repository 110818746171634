// IdleTimer.tsx
import React, { useEffect, useRef } from "react";

interface IdleTimerProps {
  onIdle: () => void;
  timeout?: number;
}

const IdleTimer: React.FC<IdleTimerProps> = ({ onIdle, timeout = 15 * 60 * 1000 }) => {
  //15 minutes
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(onIdle, timeout);
  };

  useEffect(() => {
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);
    window.addEventListener("scroll", resetTimer);

    resetTimer(); // Start the timer on mount

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
      window.removeEventListener("scroll", resetTimer);
    };
  }, []);

  return null;
};

export default IdleTimer;
