import { get } from "lodash";
import { getI18n, useTranslation } from "react-i18next";
import { OnboardingApplicationStatus, OnboardingFlow, RoleEnum } from "./types";
import { allI18nKeys } from "../../i18n/translation";
import * as imageConversion from "image-conversion";
import { convertLanguageKey } from "../../utils/lang";
import { t } from "i18next";

export function backToApp() {
  window.ReactNativeWebView?.postMessage?.('{ "action" : "close"}');
  document.removeEventListener("touchend", () => null);
}

let isChecked = false;

export function checkCameraPermission(
  candidateOnboardingForm: any,
  onboardingFlow: OnboardingFlow,
) {
  if (isChecked) return;
  isChecked = true;
  const status = candidateOnboardingForm?.applicationStatus;
  const token = getToken();
  const tokenData = token ? parseJwt(token) : {};
  const isAssignee = extractOwnerId(tokenData) === candidateOnboardingForm?.assignee;
  switch (status) {
    case OnboardingApplicationStatus.EDITING:
    case OnboardingApplicationStatus.DRAFT:
      if (extractOwnerId(tokenData) === candidateOnboardingForm?.formCreatorUserId) {
        postMessage();
      }
      break;
    case OnboardingApplicationStatus.PENDING_SIGNATURE:
      if (
        (onboardingFlow === OnboardingFlow.Recruiter && isAssignee) ||
        (onboardingFlow === OnboardingFlow.Candidate &&
          isAssignee &&
          tokenData.role !== RoleEnum.CANDIDATE)
      ) {
        postMessage();
      }
      break;
    case OnboardingApplicationStatus.FOLLOW_UP_REQUIRED:
      postMessage();
      break;
    case OnboardingApplicationStatus.SUBMITTED:
    case OnboardingApplicationStatus.REJECTED:
    case OnboardingApplicationStatus.EXPIRED:
    case OnboardingApplicationStatus.ACCEPTED:
    default:
      break;
  }
}

export function postMessage() {
  const message = getI18n().t("onboarding.camera_permission_required_message");
  const title = getI18n().t("reminder_title");
  const confirmText = getI18n().t("onboarding.camera_permission_required_confirm");
  window.ReactNativeWebView?.postMessage?.(
    `{ "action" : "checkCameraPermission", "title" : "${title}" , "message" : "${message}" , "confirmText" : "${confirmText}"}`,
  );
}

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  if (!base64Url) return {};
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(""),
  );
  return JSON.parse(jsonPayload);
};
export const fakeToken = "Bearer xxxxxx";
export const getToken = () => {
  return sessionStorage.getItem("AUTH_TOKEN") ?? fakeToken;
};
export const getRefreshToken = () => {
  return sessionStorage.getItem("AUTH_REFRESH_TOKEN") ?? "";
};
export const getLoginUserData = () => {
  const token = getToken();
  const tokenData = token ? parseJwt(token) : {};
  return tokenData;
};
export const getI18nTranslation = (text: string) => {
  if (!text) return "";
  const key = text
    .replace(/[^a-zA-Z0-9_ ]/g, "") // Remove all non-alphanumeric characters except underscores, hyphens, and spaces
    .replace(/ /g, "_") // Replace spaces with underscores
    .toLowerCase(); // Convert to lowercase
  const recruitmentKey = `Recruitment.${key}`;

  const isI18nkKey = allI18nKeys.includes(recruitmentKey);
  return isI18nkKey ? getI18n().t(recruitmentKey) : "";
};

const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");
const APIM_RECRUITMENT = get(window, "envConfig.REACT_APP_APIM_RECRUITMENT", "");
const APIM_CORE = get(window, "envConfig.REACT_APP_APIM_CORE", "");
export const CORE_URL = `${APIM_BASE_URL}${APIM_CORE}`;
export const baseURL = `${APIM_BASE_URL}${APIM_RECRUITMENT}`;
export const COMMON_PUBLIC_FRONTEND_ENDPOINT = get(
  window,
  "envConfig.COMMON_PUBLIC_FRONTEND_ENDPOINT",
  "",
);

export const compressFile = async (file: any, fileType: any, compressFileSize: number) => {
  // compressFileSize ,KB
  const fileSize = file.size / 1024;
  const needCompress = fileSize > compressFileSize;
  if (needCompress) {
    const blob = await imageConversion.compressAccurately(file, compressFileSize);
    return blob;
  } else {
    return new Blob([file], { type: fileType });
  }
};

export const extractOwnerId = (user: {
  sub: any;
  role: RoleEnum;
  recruiterCode: any;
  delegate: any;
}): string => {
  const userId = user.sub;
  return user.delegate ?? userId;
};

export const languageSelectionConfig = () => {
  const REGION_LANGUAGE: string[] = get(window, "envConfig.REGION_LANGUAGE", "").split(",");
  const result = {
    availableLanguages: REGION_LANGUAGE.map((locale) => {
      locale = convertLanguageKey(locale);
      return {
        accessibilityLabel: t("lang." + locale),
        i18nLanguageCode: locale,
        languageFullName: t("lang." + locale),
      };
    }),
  };
  return result;
};

export const getAccessibilityLabel = (code: string) => {
  const { availableLanguages } = languageSelectionConfig();
  const language = availableLanguages.find((lang) => lang.i18nLanguageCode === code);
  return language ? language.accessibilityLabel : "";
};
