import { get } from "lodash";
import { surveyLocalization } from "survey-core";

export const initCustomizedSurvey = () => {
  const PRUFORCE_DEFAULT_LANGUAGE = get(window, "envConfig.PRUFORCE_DEFAULT_LANGUAGE", "");
  switch (PRUFORCE_DEFAULT_LANGUAGE) {
    case "id":
      const indLocale = surveyLocalization.locales["id"];
      surveyLocalization.defaultLocale = "id";
      indLocale.modalCancelButtonText = "Batal";
      break;
    default:
      break;
  }
};
