import React from "react";
import styles from "./full-list-popup.module.css";

export type ModalData = { value?: string; label?: string; key?: string };

interface FullListPopupProps {
  isOpen: boolean;
  onClose: () => void;
  data: ModalData[];
  selectData?: ModalData;
  onChange?: (d: ModalData) => void;
}

const FullListPopup: React.FC<FullListPopupProps> = ({
  selectData,
  data,
  isOpen,
  onClose,
  onChange,
}) => {
  const handSelect = (data: ModalData) => {
    onChange?.(data);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <div className={styles.header}>
          <h2 className={styles.title}>Settings</h2>
          <button onClick={onClose} className={styles.closeButton}>
            ✕
          </button>
        </div>
        <div className={styles.content}>
          {data.map((item) => (
            <button
              key={item.value}
              className={selectData?.value === item.value ? styles.selectBtn : styles.unSelectBtn}
              onClick={() => {
                handSelect(item);
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FullListPopup;
