import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import { fetchRegistrationForm, submitRegistrationForm } from "../../network/event-detail-crud";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorView } from "../../components/error-view";
import { ErrorScreen } from "../../../../components/error-screen/error-screen";
import { debounce, get } from "lodash";
import { StatusCode } from "../../../../components/error-state/error-state";
import { Layout } from "../../../../layout/layout";
import { Header } from "../../components/header";
import { fetchConfig } from "../../../agent-referral-link/redux/referral-link-slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { formatSurveyJson, getUnavailableModalConfig, openFromApp } from "../../util/event.util";
import {
  convert2SurveyLocale,
  customMarked,
} from "../../../../components/surveyjs/survey/survey.util";
import { useTranslation } from "react-i18next";
import {
  CheckAction,
  RegMethodType,
  RegistrationFormType,
  RegistrationResponseCode,
  SubmitParticipantsInfoCodeEnum,
} from "../../network/type";
import "./survey-form-screen.css";
import { prompt } from "../../../../components/modal/prompt";
import { PruToast } from "../../../../components/pru-toast";
import { initValidation } from "../../../../components/surveyjs/survey/survey-default-form/util";
import { EVENT_PATH, ROOT_PATH } from "../../../../routes/constants";
import { updateCode } from "../../redux/event-slice";
import { Marked, marked } from "marked";

export interface SurveyFormData {
  regFormBody: { [key: string]: any };
  regFormResult: { [key: string]: any } | null;
}

export const SurveyFormScreen = () => {
  initValidation();
  const { refId = "" } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const [surveyModel, setSurveyModel] = useState<Model>();
  const [errorStatus, setErrorStatus] = useState<StatusCode>();
  const [surveyData, setSurveyData] = useState<SurveyFormData>();
  const hashEntry = useAppSelector((state) => state.common?.hashEntry);
  const dropdownConfig = useAppSelector((state) => state.referralLink.dropdown);

  const getRegistrationForm = useCallback(async (shortenCode: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const registrationId = searchParams.get("registrationId") || undefined;
    const sessionId = searchParams.get("sessionId") || undefined;
    const formType = (searchParams.get("formType") as RegistrationFormType) || undefined;
    try {
      const res = await fetchRegistrationForm({
        shortenCode,
        registrationId,
        sessionId,
        type: formType,
      });
      if (res?.data?.regMethod === RegMethodType.LINK) {
        return window.location.replace(res?.data?.regLink);
      }
      setSurveyData(res.data);
    } catch (err) {
      const status = get(err, "response.status", "-1"); // -1: unknown error by default
      setErrorStatus(status.toString() as StatusCode);
    }
  }, []);

  useEffect(() => {
    if (refId) {
      getRegistrationForm(refId);
    }
  }, [getRegistrationForm, refId]);

  useEffect(() => {
    if (!hashEntry || !hashEntry.data) {
      return;
    }
    const { region, channel, regMethod } = hashEntry.data;
    if (regMethod === RegMethodType.LINK) return;
    dispatch<any>(fetchConfig({ region, channel }));
  }, [dispatch, hashEntry]);

  const promptConfirm = useCallback(
    (key: RegistrationResponseCode, refId: string, sessionId?: string) => {
      switch (key) {
        case RegistrationResponseCode.SESSION_FULL:
          window.location.reload();
          break;
        case RegistrationResponseCode.AGENT_QUOTA_FULL:
        case RegistrationResponseCode.EVENT_FULL:
          if (openFromApp()) {
            window.ReactNativeWebView?.postMessage('{ "action" : "close"}');
          } else {
            navigate(-1);
          }
          break;
        case RegistrationResponseCode.REGISTER_DUPLICATED:
          navigate(
            `${ROOT_PATH}/${EVENT_PATH}/check/${refId}?action=${CheckAction.CHECK_IN}&sessionId=${sessionId}`,
          );
          break;
        case RegistrationResponseCode.WALKIN_REGISTER_DUPLICATED:
          dispatch(updateCode(SubmitParticipantsInfoCodeEnum.ALREADY_CHECKED_IN));
          navigate(
            `${ROOT_PATH}/${EVENT_PATH}/check_result/${refId}?sessionId=${sessionId}&checkType=${CheckAction.CHECK_IN}`,
          );
          break;
        default:
          break;
      }
    },
    [dispatch, navigate],
  );

  const onCompleteSurvey = useCallback(
    async (survey: any) => {
      survey.isCompleted = false;
      const surveyData = survey.data;
      if (surveyData.registrant_countryCode) {
        surveyData.registrant_countryCode = surveyData.registrant_countryCode?.key;
      }
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const formType = (searchParams.get("formType") as RegistrationFormType) || undefined;
        const sessionId = searchParams.get("sessionId") || undefined;
        const res = await submitRegistrationForm({
          surveyResultBody: surveyData,
          shortenCode: refId,
          type: formType,
          sessionId,
        });
        if (res?.data?.code === RegistrationResponseCode.SUCCESS) {
          survey.isCompleted = true;
          survey.showCompletedPage = true;
        } else {
          const config = getUnavailableModalConfig(res?.data?.code);
          if (
            !config ||
            (res?.data?.code === RegistrationResponseCode.REGISTER_DUPLICATED &&
              formType !== RegistrationFormType.WALKIN)
          )
            return PruToast({ message: res?.data?.message || t("Common.server_error") });
          prompt(config).then((result) => {
            if (result.ok) {
              promptConfirm(res?.data?.code, refId, sessionId);
            }
          });
        }
      } catch (error) {
        const status = get(error, "response.status", "-1");
        setErrorStatus(status.toString() as StatusCode);
      }
    },
    [promptConfirm, refId, t],
  );

  const handleDebounceSubmit = useMemo(() => {
    return debounce(
      (survey: any) => {
        onCompleteSurvey(survey);
      },
      500,
      { leading: false, trailing: true },
    );
  }, [onCompleteSurvey]);

  useEffect(() => {
    if (surveyData) {
      const searchParams = new URLSearchParams(window.location.search);
      const { regFormBody, regFormResult } = formatSurveyJson({
        searchParams,
        surveyData: surveyData,
        region: hashEntry?.data?.region,
        dropdownConfig,
      });

      const survey = new Model(regFormBody);
      survey.showCompletedPage = false;

      if (regFormResult) {
        // show result page
        survey.mode = "display";
        survey.data = regFormResult;
      } else if (searchParams.get("registered") === "true") {
        // if prospect/candidate registered, display completed page
        survey.showCompletedPage = true;
        survey.doComplete();
      } else {
        survey.onComplete.add(handleDebounceSubmit);
      }

      survey.onTextMarkdown.add(function (survey, options) {
        if (options.element.name === "registrant_terms") {
          //convert the markdown text to html
          let str = customMarked.parseInline(options.text, { async: false });
          //set html
          options.html = str;
        }
      });

      setSurveyModel(survey);
    }
  }, [dropdownConfig, handleDebounceSubmit, hashEntry?.data?.region, surveyData]);

  return (
    <Layout disableLanguageButton disableTnc>
      {errorStatus ? (
        <>
          <Header />
          {errorStatus === "404" ? (
            <ErrorView title={t("event.event_expired")} />
          ) : (
            <ErrorScreen statusCode={errorStatus} />
          )}
        </>
      ) : (
        surveyModel && (
          <div className="w-full h-full pb-5">
            <Survey
              model={surveyModel}
              id="event-survey"
              locale={convert2SurveyLocale(i18n.language)}
            />
          </div>
        )
      )}
    </Layout>
  );
};
