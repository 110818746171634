import CheckIcon from "@mui/icons-material/Check";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { useQuery } from "../../../../utils/hooks/use-query";
import { completeBoxMarginStyle, completeIconStyle, subheader } from "./style";
import style from "./style.module.css";
import { ModuleSection } from "./type";
import { useTranslation } from "react-i18next";

type ReferencesMenuListProps = {
  sections?: ModuleSection[];
};

export const ReferencesMenuList = (props: ReferencesMenuListProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const { t } = useTranslation();
  return (
    <div
      className={`${style["list-container"]} ${style.flex1} ${style["flex-column"]} ${style.padding}`}
    >
      <div className={`${style.item}`}>
        <ListSubheader sx={subheader}>
          <ListItemButton
            onClick={() => {
              navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/document-download?${query.toString()}`);
            }}
          >
            <Box sx={completeBoxMarginStyle}>
              <CheckIcon sx={completeIconStyle} />
            </Box>
            <ListItemText primary={t("document_download")} />
            <ExpandMore />
          </ListItemButton>
        </ListSubheader>
      </div>
    </div>
  );
};
