// common translation
import commonEn from "./en.json";
import commonZh from "./zh-Hant.json";

// module translation
import arlEn from "./agent-referral-link/en.json";
import arlZh from "./agent-referral-link/zh-Hant.json";

import eventEn from "./event/en.json";
import eventZh from "./event/zh-Hant.json";
import { getI18n } from "react-i18next";

const en = { ...commonEn, ...arlEn, ...eventEn };
const zh = { ...commonZh, ...arlZh, ...eventZh };
export { en, zh };

export const allI18nKeys = Object.keys(en);

export enum I18nModule {
  Recruitment = "Recruitment",
  Common = "Recruitment",
}
export const translate = (key: string, module?: string) => {
  const translateKey = module ? `${module}.${key}` : key;
  if (allI18nKeys.indexOf(translateKey) === -1) {
    return key;
  }
  return getI18n().t(translateKey);
};
