import React, { useState, useEffect, useRef, memo, useMemo, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { VariableSizeList, ListOnScrollProps } from "react-window";
import { isEmpty, throttle } from "lodash";
import { ErrorBoundary } from "../../../../components/error-boundary/error-boundary";
import { CircularProgress } from "@mui/material";
import { color } from "../../../../mui-theme/palette";
import { TopMenuComponent } from "./top-menu/top-menu.component";
type ComponentProps = {
  file: string;
  onBottomReached?: () => void;
  enabledControl?: boolean; // zoom in/out control
};
const documentOptions = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@4.4.168//cmaps/`,
  cMapPacked: true,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@4.4.168//standard_fonts`,
};

const headerHeight = 46;
export const ReactPdfViewer: React.FC<ComponentProps> = memo(
  ({ file, onBottomReached }: ComponentProps) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageHeight, setPageHeight] = useState<number[]>([]);
    const [scale, setScale] = useState(1.0); // State for zoom level
    useEffect(() => {
      pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        "https://unpkg.com/pdfjs-dist@4.4.168/legacy/build/pdf.worker.min.mjs",
        import.meta.url,
      ).toString();
    }, []);

    const [pageWidth, setPageWidth] = useState<number>(window.innerWidth);
    const onDocumentLoadSuccess = useCallback(
      async (doc: any) => {
        const numPages = doc.numPages;
        setNumPages(numPages);
        const heights: number[] = [];

        for (let i = 1; i <= numPages; i++) {
          const pdfPage = await doc.getPage(i);
          const viewport = pdfPage.getViewport({ scale });
          const { height, width } = viewport;
          const ratio = height / width;
          heights.push(pageWidth * ratio * scale);
        }
        setPageHeight(heights);
      },
      [pageWidth, scale],
    );

    const cumulativeHeight = useMemo(() => {
      let cumulativeHeight = 0;
      for (let i = 0; i < pageHeight.length; i++) {
        cumulativeHeight += pageHeight[i];
      }
      return cumulativeHeight;
    }, [pageHeight, scale]);

    const scrollHandleThrottleRef = useRef<any>(undefined);
    const handleScroll = useCallback(
      (e: ListOnScrollProps) => {
        if (isEmpty(pageHeight)) return;
        // definde an offset to trigger onBottomReached
        const offset = 80;
        if (!scrollHandleThrottleRef.current) {
          scrollHandleThrottleRef.current = throttle((e: ListOnScrollProps, cumulativeHeight) => {
            const { scrollOffset } = e;
            const bottomScrollOffset = scrollOffset + window.innerHeight - headerHeight;
            // Check if the bottom is reached
            if (bottomScrollOffset + 80 >= cumulativeHeight) {
              onBottomReached?.();
            }
          }, 500);
        }
        scrollHandleThrottleRef.current(e, cumulativeHeight);
      },
      [pageHeight, cumulativeHeight, onBottomReached],
    );

    useEffect(() => {
      const handleSize = () => {
        const containerWidth = document.getElementById("react-pdf-container")!.clientWidth;
        setPageWidth(containerWidth);
      };

      window.addEventListener("resize", handleSize);
      return () => {
        window.removeEventListener("resize", handleSize);
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const zoomIn = () => setScale((prevScale) => prevScale + 0.2);
    const zoomOut = () =>
      setScale((prevScale) => {
        if (prevScale > 1) {
          return Math.max(prevScale - 0.2, 0.2);
        }
        return 1;
      });
    const rowRenderer = useCallback(
      ({ index, style }: { index: number; style: any }) => {
        return (
          <div style={style}>
            <ErrorBoundary>
              <Page
                key={+index}
                devicePixelRatio={Math.min(2, window.devicePixelRatio)}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                pageNumber={index + 1}
                width={pageWidth}
                scale={scale}
              />
            </ErrorBoundary>
          </div>
        );
      },
      [pageWidth, scale],
    );
    const getItemSize = useCallback(
      (index: number) => {
        return pageHeight[index] ?? pageWidth * Math.sqrt(2);
      },
      [pageWidth, pageHeight],
    );
    const listElement = useMemo(() => {
      // VariableSizeList is used to render pages with different heights
      return (
        <VariableSizeList
          height={window.innerHeight - headerHeight}
          onScroll={handleScroll}
          itemCount={numPages ?? 0}
          itemSize={getItemSize}
          width={pageWidth}
          children={rowRenderer}
          key={Math.random()}
        />
      );
    }, [getItemSize, handleScroll, numPages, pageWidth, rowRenderer]);

    const docElement = useMemo(() => {
      return (
        <Document
          options={documentOptions}
          file={file}
          loading={
            <div className={`h-screen w-screen flex flex-1 items-center justify-center`}>
              <CircularProgress sx={{ color: color.majorRed }} />
            </div>
          }
          key={scale}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {listElement}
        </Document>
      );
    }, [file, listElement, onDocumentLoadSuccess, scale]);
    if (!file) {
      return null;
    }

    return (
      <div id="react-pdf-container">
        {numPages ? <TopMenuComponent file={file} onZoomIn={zoomIn} onZoomOut={zoomOut} /> : null}
        {docElement}
      </div>
    );
  },
);
