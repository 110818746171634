import React, { useMemo } from "react";
import headerBg from "../icon/assets/header_bg.svg";

interface PageContainerProps {
  title: string;
  id?: string;
  children: React.ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = ({ title, children, id }) => {
  const childrenEle = useMemo(() => {
    if (!children) return null;
    return (
      <div
        className={`flex flex-1 mt-[156px] w-full rounded-tl-[24px] rounded-tr-[24px] bg-white pt-6 justify-center`}
      >
        {children}
      </div>
    );
  }, [children]);
  return (
    <div
      className="h-screen w-screen flex flex-col"
      id={id}
      style={{ backgroundImage: `url(${headerBg})`, backgroundRepeat: "no-repeat" }}
    >
      {/* Header section */}
      <div className="absolute top-14">
        <h1 className="text-center text-4xl font-bold p-6 text-white">{title}</h1>
      </div>

      {childrenEle}
    </div>
  );
};

export default PageContainer;
