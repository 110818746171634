import React, { useEffect, useContext, useCallback, useState } from "react";
import { convertPdfToImages } from "./convert-to-image";
import { PdfViewContext } from "./page-context";
import { useTransformEffect, useTransformContext } from "react-zoom-pan-pinch";

export type PdfViewProps = {
  className?: string;
};

export const PdfImageView = (props: PdfViewProps) => {
  const context = useContext(PdfViewContext);
  useTransformEffect((ref) => {
    context.setTransferRef?.(ref);
  });

  return (
    <div className={props.className}>
      <img className="bg-black" src={context.images[context.currentPage - 1]} />
    </div>
  );
};
