import React, { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { NOT_FOUND_ERROR_PAGE_PATH } from "../../../../constants/constants";
import { AGENT_PROFILE_BASE_PATH, getNotFoundRedirectUrl } from "../../constants";
import { AgentProfileItem, AwardItem } from "../../types/agent-profile-types";
import { fetchAgentProfile, getAgentAwards } from "../../network/agentProfileCrud";
import { Layout } from "../../../../layout/layout";
import { localeMapToBackend, localeMapToPromotionBackend } from "../../utils/locale-utils";
import { moveSelectedAwardsToTop } from "../../utils/list-utils";
import {
  TrackingData,
  EventModule,
  EventFeature,
} from "../../../../utils/event-tracking/event-tracking.model";
import AgentProfileEditMainForm from "./components/AgentProfileEditMainForm";
import { ROOT_PATH } from "../../../../routes/constants";

const AgentProfileEditMainPage: FC = () => {
  const { refId } = useParams<{ refId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const lang = i18n.language;
  let mappedLocale = localeMapToBackend[lang] || lang;
  let promotionMappedLocale = localeMapToPromotionBackend[lang] || lang;

  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>();
  const [awardList, setAwardList] = useState<AwardItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reloadData = async () => {
    if (refId) {
      setIsLoading(true);
      try {
        const res = await fetchAgentProfile(refId, mappedLocale, dispatch);
        setAgentProfile(res);
        const awardListRes = await getAgentAwards(refId, mappedLocale, dispatch);
        setAwardList(moveSelectedAwardsToTop(res.awards, awardListRes.awards));
      } catch (err) {
        // navigate(`${ROOT_PATH}${AGENT_PROFILE_BASE_PATH}/${refId}/error`);
        const redirect = getNotFoundRedirectUrl(lang);
        if (redirect) {
          window.location.href = redirect;
        } else {
          navigate(NOT_FOUND_ERROR_PAGE_PATH);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   if ((window as any).ReactNativeWebView) {
  //     (window as any).ReactNativeWebView.postMessage(
  //       `{ "action": "loadheaderprops", "payload": { "title": "profilePage", "backTarget": "app" } }`,
  //     );
  //   }
  // }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [refId, i18n.language]);

  const title = t("agentProfile.pp_agent_profile_title");
  const pageTrackingData: TrackingData = {
    name: "prospect::view_profile",
    module: EventModule.agent_profile_site,
    feature: EventFeature.agent_info,
    journey: "home",
  };

  return (
    <>
      {isLoading || !agentProfile ? (
        <></>
      ) : (
        <Layout trackingData={pageTrackingData} title={title}>
          <AgentProfileEditMainForm
            agentProfile={agentProfile}
            awardList={awardList}
            mappedLocale={mappedLocale}
            promotionMappedLocale={promotionMappedLocale}
          />
        </Layout>
      )}
    </>
  );
};

export default AgentProfileEditMainPage;
