import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { get } from "lodash";
import { getItem } from "../utils/local-storage";
import { en, zh } from "./translation";
import { convertLanguageKey } from "../utils/lang";

/** resource use same key(lower-case) as APP */
export const resources = {
  en: { translation: en },
  zhhk: { translation: zh },
  ph: { translation: en },
  km: { translation: en },
  id: { translation: en },
  my: { translation: en },
};
const queryParams = new URLSearchParams(window.location.search);

let queryLang = queryParams.get("lang") || "";
if (queryLang) {
  queryLang = convertLanguageKey(queryLang); // zh-Hant -> zhhk
}
const defaultLang = convertLanguageKey(get(window, "envConfig.PRUFORCE_DEFAULT_LANGUAGE", ""));
i18next.use(initReactI18next).init({
  // if you're using a language detector, do not define the lng option
  // priority: url query > local-storage > env config, default: en
  lng: queryLang || (getItem("i18nextLng") as string) || defaultLang || "en",
  debug: false,
  resources,
  compatibilityJSON: "v3",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
