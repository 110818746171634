export const dropdownStyles = {
  root: {
    paddingRight: 1,
    border: "1px solid #ccc",
  },
  menuItem: {
    fontSize: "0.85rem",
    paddingTop: "3px",
    paddingBottom: "3px",
    whiteSpace: "normal",
  },
  menuMaxHeight: {
    maxHeight: "50%",
  },
  fullModalBtn: {
    color: "#333",
    fontSize: 16,
    fontWeight: 500,
    // border: "1px solid #ccc",
    borderRadius: 0,
    borderBottom: "1px solid #f0f0f0",
  },
};
