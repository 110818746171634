import React, { CSSProperties } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";
// mui start
import { Checkbox, createTheme, ThemeProvider } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import CheckIcon from "@mui/icons-material/Check";
// mui end

import * as style from "../survey/common.style";
import { checkboxStyle } from "./checkbox.style";

import { CustomType } from "../survey/survey.util";

const CUSTOM_TYPE = CustomType.Checkbox;

export class QuestionCheckboxModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get choices() {
    return this.getPropertyValue("choices");
  }
  set choices(val) {
    this.setPropertyValue("choices", val);
  }
  get defaultValue() {
    return this.getPropertyValue("defaultValue");
  }
  set defaultValue(val) {
    this.setPropertyValue("defaultValue", val);
  }
}

//Add question type metadata for further serialization into JSON
export const initCheckboxSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "choices",
        default: [],
      },
      {
        name: "defaultValue",
        default: [],
      },
    ],
    function () {
      return new QuestionCheckboxModel("");
    },
    "question",
  );
};

const theme = createTheme({});

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionCheckboxModel(name);
});
// A class that renders questions of the new type in the UI
export class SurveyQuestionCheckbox extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.question.value ?? [],
      options: this.getChoices(),
    };
  }

  getChoices() {
    const arr = this.question.choices;
    const arrOption = arr.map((item: any) => {
      if (typeof item === "string") {
        return {
          key: item,
          label: item,
        };
      }
      return {
        key: item.value,
        label: item.text,
      };
    });
    return arrOption;
  }

  get question() {
    let isModuleReadOnly = false;
    if (this.questionBase.survey) {
      const activePage: any = this.questionBase.survey.currentPage;
      if (activePage) {
        isModuleReadOnly = activePage.readOnly;
      }
    }
    this.questionBase.readOnly = this.questionBase.readOnly || isModuleReadOnly;
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get defaultValue() {
    return this.question.defaultValue;
  }
  get style() {
    return (
      this.question.getPropertyValue("readOnly") ? { pointerEvents: "none" } : undefined
    ) as CSSProperties;
  }

  onCheckBoxChange(event: any) {
    const { value, checked } = event.target;
    let newValue = [...this.question.defaultValue];
    if (checked) {
      newValue.push(value);
    } else {
      const index = newValue.indexOf(value);
      if (index !== -1) {
        newValue.splice(index, 1);
      }
    }
    this.question.value = newValue;
  }

  renderElement() {
    return (
      <ThemeProvider theme={theme}>
        <div style={this.style}>
          <FormControl fullWidth sx={style.formControl} variant="standard">
            <FormGroup>
              {this.state.options.map((item: any) => {
                return (
                  <FormControlLabel
                    value={item.key}
                    key={item.key}
                    label={item.label}
                    onChange={this.onCheckBoxChange.bind(this)}
                    sx={{ "& .MuiTypography-root": checkboxStyle.label }}
                    control={
                      <Checkbox
                        defaultChecked={this.question.defaultValue?.some?.(
                          (value: any) => value === item.key,
                        )}
                        checkedIcon={<CheckIcon />}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </div>
      </ThemeProvider>
    );
  }
}
