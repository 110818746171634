import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { Model } from "survey-core";
import { MenuHeader } from "./header";
import {
  fetchCandidateOnboardingFormAsync,
  fetchConfigAsync,
  fetchOnboardingFormTemplateAsync,
  getOnboardingTrainingSummaryAsync,
  reset,
  submitApplicationFormAsync,
} from "../../redux/onboarding-slice";
import { convertData } from "./convert-data";
import { MenuListOfDoc } from "./list-of-doc";
import { MenuList } from "./menu";
import { debounce, get, isEmpty, throttle } from "lodash";
import i18next from "i18next";
import { MenuData, ModuleSection, OnboardingTrainingModuleType, PageNameEnum } from "./type";
import { ErrorScreen } from "../../../../components/error-screen/error-screen";
import { useNavigate } from "react-router-dom";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { HeaderComponent } from "../../../../components/header/header.component";
import { useQuery } from "../../../../utils/hooks/use-query";
import { convertLanguageKey } from "../../../../utils/lang";
import {
  selectCandidateOnboardingForm,
  selectCandidateOnboardingFormSubmiting,
  selectIsAssignee,
  selectOnboardingFlow,
  selectOnboardingFormPreparationDocs,
  selectOnboardingFormTemplate,
  selectVisibleMenuFormConfig,
  selectOnboardingTrainingSummary,
  selectAgreeApprovalFormConfig,
  selectIsFormCreator,
  selectConfigLoaded,
  selectCandidateOnboardingFormCreatedAt,
  selectShowOnboardingReferences,
  selectOnboardingConfigs,
  selectIsSecretaryReadonly,
} from "../../redux/selector";
import {
  FormAction,
  OnboardingApplicationSectionStatus,
  OnboardingApplicationStatus,
  OnboardingFormModuleName,
  OnboardingFormSectionName,
  OnboardingPreCheck,
  RoleEnum,
} from "../../types";
import { findPanelIndex } from "../../components/surveyjs/survey/survey.util";
import { confirm } from "../../../../components/modal/comfirm";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { PruToast } from "../../../../components/pru-toast";
import { useCanGoBack } from "../../../../utils/hooks/use-can-go-back";
import { PendingFollowUpBanner } from "../pending-follow-up";
import { TrainingMenuList } from "./training-menu";
import { getTrainingCourseModuleStatus, getTrainingExamModuleStatus } from "./util";
import { useGAScreenViewEvent } from "../../../../common/ga/hook/use-ga-screen-view-event";
import { takeUIClickEvent } from "../../../../common/ga/ga";
import { TakeUIClickEventParameters } from "../../../../common/ga/types/ga-general-type";
import { backToApp, extractOwnerId, getToken, parseJwt } from "../../utils";
import store from "../../../../redux/store";
import { onboardingPostModules } from "../../../../constants/constants";
import { GlobalHelper } from "../../../../utils/helpers/global-helper";
import { commonSlice } from "../../../../redux/common/common-slice";
import useThrottledTouchEnd from "../../hooks/use-throtted-touch-end";
import moment from "moment";
import { ReferencesMenuList } from "./references-menu";
import { onboardingPreCheck } from "../form/survey.util";
import { ONBOARDING_FINALIZED_STATUSES } from "../../constants";
const { showLoadingAction, hideLoadingAction } = commonSlice.actions;
type MenuScreenProps = {};

const trainingAndLicenseIsDependent = true;
export const MenuScreen = (props: MenuScreenProps) => {
  useThrottledTouchEnd();
  const { t, i18n } = useTranslation();
  const translate = useMemo(
    () => ({
      application: t("Recruitment.onboarding_application"),
      applicationNumber: t("Recruitment.onboarding_application_ref"),
      applicationStatus: t("Recruitment.onboarding_application_status"),
      lastupdatedat: t("Recruitment.onboarding_last_update"),
      docPre: t("Recruitment.onboarding_list_of_docs_pre"),
      doc: t("Recruitment.onboarding_list_of_docs_lower"),
      updoc: t("Recruitment.onboarding_list_of_docs"),
      docTail: t("Recruitment.onboarding_list_of_docs_tail"),
      next: t("proceed"),
      confirm: t("global.text.confirm"),
      followUpConfirm: t("Submit"),
      followUpConfirmMessage: t("onboarding.submit_message"),
      application_information: t("application_information"),
      training_and_qualifications: t("training_and_qualifications"),
      agree_and_approval: t("agree_and_approval"),
      onboarding_training: t("onboarding_training"),
      license_exam_registration: t("license_exam_registration"),
      references: t("references"),
      pass_form_to_recruiter: t("pass_form_to_recruiter"),
    }),
    [i18n.language],
  );
  const candidateOnboardingTrainingSummary = useSelector(selectOnboardingTrainingSummary);

  const [data, setData] = useState<MenuData | undefined>(undefined);
  const [agreeApprovalData, setApprovalData] = useState<MenuData | undefined>(undefined);

  const dispatch = useDispatch();
  const query = useQuery();
  const candidateId = query.get("candidateId");
  const agentCode = query.get("agentCode");
  const lang = query.get("lang") ?? "en";
  const navigate = useNavigate();
  const canGoBack = useCanGoBack();
  const candidateOnboardingForm = useSelector(selectCandidateOnboardingForm);
  const isResetRef = useRef<boolean>(false);
  isResetRef.current = isResetRef.current || candidateOnboardingForm === undefined;
  const menuFormTemplate = useSelector(selectVisibleMenuFormConfig);
  const approvalFormTemplate = useSelector(selectAgreeApprovalFormConfig);
  const formTemplate = useSelector(selectOnboardingFormTemplate) as Model;
  const formPreDocs = useSelector(selectOnboardingFormPreparationDocs);
  const onboardingFlow = useSelector(selectOnboardingFlow);
  const onboardingConfigs = useSelector(selectOnboardingConfigs);
  const onboardingConfigLoaded = useSelector(selectConfigLoaded);
  const formCreatedAt = useSelector(selectCandidateOnboardingFormCreatedAt);
  const showOnboardingReferences = useSelector(selectShowOnboardingReferences);
  const isSecretaryReadonly = useSelector(selectIsSecretaryReadonly);
  const formJustCreated = useMemo(() => {
    if (formCreatedAt) {
      return moment.utc().diff(moment.utc(formCreatedAt), "seconds") < 1;
    }
    return false;
  }, [formCreatedAt]);
  const { enableOnboardingCourse, enableOnboardingExamRegistry } = useMemo(() => {
    const { licenseExamVisibleOn, onboardingTrainingVisibleOn, onboardingPreCheck } = get(
      store.getState(),
      "onboarding.configs.Recruitment",
      {},
    );
    return {
      enableOnboardingCourse:
        !isEmpty(candidateOnboardingTrainingSummary?.trainingSummary) &&
        candidateOnboardingTrainingSummary?.trainingSummary.requiredToAttend !== false &&
        onboardingTrainingVisibleOn?.includes?.(PageNameEnum.OnboardingApplication),
      enableOnboardingExamRegistry:
        !isEmpty(candidateOnboardingTrainingSummary?.examSummary) &&
        candidateOnboardingTrainingSummary?.examSummary.requiredToAttend &&
        licenseExamVisibleOn?.includes?.(PageNameEnum.OnboardingApplication),
    };
  }, [candidateOnboardingTrainingSummary]);
  const trainingCompleted = useMemo(() => {
    if (!candidateOnboardingTrainingSummary) return false;
    const { onboardingPreCheck } = get(store.getState(), "onboarding.configs.Recruitment", {});
    return (
      (!enableOnboardingCourse ||
        (candidateOnboardingTrainingSummary.trainingSummary?.required ?? 0) <=
          candidateOnboardingTrainingSummary.trainingSummary?.completed ||
        !onboardingPreCheck?.includes?.(OnboardingPreCheck.Training)) &&
      (!enableOnboardingExamRegistry ||
        (candidateOnboardingTrainingSummary.examSummary?.total ?? 0) <=
          candidateOnboardingTrainingSummary.examSummary?.completed ||
        !onboardingPreCheck?.includes?.(OnboardingPreCheck.LicenseExam))
    );
  }, [candidateOnboardingTrainingSummary, enableOnboardingCourse, enableOnboardingExamRegistry]);
  const trainingMenu = useMemo(() => {
    const trainingSection: ModuleSection = {
      title: translate.onboarding_training,
      name: OnboardingTrainingModuleType.Training,
      data: [],
      expanded: false,
    };
    trainingSection.denominator =
      candidateOnboardingTrainingSummary?.trainingSummary?.required ?? 0;
    trainingSection.numerator = candidateOnboardingTrainingSummary?.trainingSummary?.completed ?? 0;
    trainingSection.status = getTrainingCourseModuleStatus(
      trainingSection,
      data?.isCompleted,
      trainingAndLicenseIsDependent,
    );
    const menus = [];
    if (enableOnboardingCourse) {
      menus.push(trainingSection);
    }
    const examSection: ModuleSection = {
      title: translate.license_exam_registration,
      name: OnboardingTrainingModuleType.ExamRegistration,
      data: [],
      expanded: false,
    };
    examSection.denominator = candidateOnboardingTrainingSummary?.examSummary?.total ?? 0;
    examSection.numerator = candidateOnboardingTrainingSummary?.examSummary?.completed ?? 0;
    examSection.status = getTrainingExamModuleStatus(
      trainingSection,
      examSection,
      trainingAndLicenseIsDependent,
    );
    if (enableOnboardingExamRegistry) {
      menus.push(examSection);
    }
    return menus;
  }, [
    candidateOnboardingTrainingSummary,
    data?.isCompleted,
    translate,
    enableOnboardingCourse,
    enableOnboardingExamRegistry,
  ]);
  const isSubmiting = useSelector(selectCandidateOnboardingFormSubmiting);
  const isAssignee = useSelector(selectIsAssignee);
  const isFormCreator = useSelector(selectIsFormCreator);
  const [loading, setLoading] = useState(true);
  const initEmptyFormWithConfirmRef = useRef({ initialized: false, clicked: false });
  useGAScreenViewEvent(
    {
      module: "Recruitment",
      feature: "ApplicationForm",
      journey: "enter_application_form",
      stage: "view_form_menu",
      screen_name: "Recruitment-Application-Menu-Screen",
    },
    true,
  );
  const initEmptyFormWithConfirm = useCallback(
    async ({ templateId, candidateId }: { templateId: string; candidateId: string }) => {
      if (initEmptyFormWithConfirmRef.current.initialized) return;
      if (templateId && candidateId) {
        initEmptyFormWithConfirmRef.current.initialized = true;
        await confirm(
          {
            message: t("start_new_application_form"),
            title: t("reminder_title"),
            confirmButtonText: translate.confirm,
          },
          { backdropClose: false, id: "" },
        ).then(async (result) => {
          if (!result.ok) {
            backToApp();
            return;
          }
          const response = await dispatch<any>(
            submitApplicationFormAsync({
              data: {
                templateId: templateId,
                candidateId: candidateId,
                sections: [],
              },
              action: FormAction.INIT,
            }),
          );
          const errors = response?.error ?? response?.payload?.errors;
          if (!errors) {
            PruToast({
              message: getI18n().t("Recruitment.onboarding_application_submitted_successfully"),
            });
          } else {
            PruToast({
              message: getI18n().t("Common.network_error"),
            });
            if (canGoBack()) navigate(-1);
          }
          /**
           * submit action
           */
        });
        initEmptyFormWithConfirmRef.current.clicked = true;
      }
    },
    [canGoBack, dispatch, navigate, t, translate.confirm],
  );
  useEffect(() => {
    GlobalHelper.getGlobalDispatch()?.(loading ? showLoadingAction() : hideLoadingAction());
  }, [loading]);
  const fetchData = useCallback(async () => {
    if (formJustCreated) {
      dispatch<any>(getOnboardingTrainingSummaryAsync({ candidateId: candidateId as string }));
      return;
    }
    setLoading(true);
    if (!candidateId) return setLoading(false);
    const form = await dispatch<any>(fetchCandidateOnboardingFormAsync({ candidateId }));
    if (get(form, "error", false)) {
      return setLoading(false);
    }
    const formInstance = get(form, "payload.data[0]", undefined);
    if (formInstance === undefined) return setLoading(false);
    const formNotExisted = isEmpty(formInstance);
    if (!formNotExisted) dispatch<any>(getOnboardingTrainingSummaryAsync({ candidateId }));
    const templateActionResponse = await dispatch<any>(
      fetchOnboardingFormTemplateAsync(
        formInstance?.templateId
          ? { templateId: formInstance.templateId, candidateId }
          : { candidateId, type: query.get("type") },
      ),
    );
    if (get(templateActionResponse, "error", false)) return setLoading(false);
    if (formNotExisted) {
      const surveyTemplate: Model = store.getState().onboarding.currentTemplate ?? {};
      const preModuleTemplate = surveyTemplate.pages?.find(
        (page) => page?.name === OnboardingFormModuleName.PreQuestions,
      );
      if (preModuleTemplate) {
        const firstPreSection = preModuleTemplate.elements?.[0];
        if (firstPreSection) {
          query.set("sectionName", firstPreSection.name);
          navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/form?${query.toString()}`, { replace: true });
          return;
        } else {
          // popup logic
          initEmptyFormWithConfirm({ templateId: surveyTemplate.templateId, candidateId });
        }
      } else {
        // popup logic
        initEmptyFormWithConfirm({ templateId: surveyTemplate.templateId, candidateId });
      }
    }
    setLoading(false);
  }, [candidateId, dispatch, navigate, query, initEmptyFormWithConfirm, formJustCreated]);
  const debounceFunc = useCallback(
    debounce(
      (func, ...params) => {
        func(...params);
      },
      1000,
      { leading: false, trailing: true },
    ),
    [],
  );
  useEffect(() => {
    if (!isResetRef.current) return;
    if (onboardingConfigLoaded && !onboardingFlow) return setLoading(false);
    if (isSubmiting) return setLoading(false);
    debounceFunc(fetchData);
  }, [debounceFunc, fetchData, isSubmiting, onboardingFlow]);
  const showAgreeAndApproval = useMemo(() => {
    if (!formTemplate || !candidateOnboardingForm) return false;
    const hasPostModule = formTemplate.pages.find((page) =>
      onboardingPostModules.includes(page.name as any),
    );
    return (
      candidateOnboardingForm?.applicationStatus !== OnboardingApplicationStatus.EDITING &&
      hasPostModule
    );
  }, [formTemplate, candidateOnboardingForm?.applicationStatus]);
  useEffect(() => {
    if (isResetRef.current) {
      const _data = convertData(candidateOnboardingForm, menuFormTemplate, translate);
      setData(_data);
    }
  }, [candidateOnboardingForm, menuFormTemplate, translate, setData]);
  useEffect(() => {
    setApprovalData(convertData(candidateOnboardingForm, approvalFormTemplate, translate));
  }, [candidateOnboardingForm, approvalFormTemplate, translate]);

  const throttledTouchEnd = useCallback(
    throttle(() => {
      window.ReactNativeWebView?.postMessage?.('{ "action" : "touchend"}');
    }, 1000),
    [],
  );
  useEffect(() => {
    GlobalHelper.setOnboardingCurrentSection({});
    dispatch<any>(reset());
    dispatch<any>(fetchConfigAsync());
    document.addEventListener("touchend", throttledTouchEnd);
    return () => {
      GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
    };
  }, []);
  useEffect(() => {
    i18next.changeLanguage(convertLanguageKey(lang));
  }, [lang]);
  const onSubmit = useCallback(() => {
    if (!candidateOnboardingForm) return;
    const gaData: TakeUIClickEventParameters = {
      module: "Recruitment",
      feature: "ApplicationForm",
      journey: "edit_application_form",
      stage: "resubmit_form",
      screen_id: "SCR_RCRTMT_APPLCTN_MN",
      screen_name: "Recruitment-Application-Menu-Screen",
      object_name: "Submit",
      object_type: "BUTTON",
    };
    takeUIClickEvent(gaData);
    // check if the tnc and e-signature is completed
    const tncSectionStatus =
      candidateOnboardingForm.modules[OnboardingFormModuleName.TermsNConditions][
        OnboardingFormSectionName.termsNConditionsSection
      ]?.status;

    if (tncSectionStatus !== OnboardingApplicationSectionStatus.COMPLETED) {
      PruToast({
        message: getI18n().t("Recruitment.tc_is_not_completed"),
      });
      return;
    }
    const eSigSectionStatus =
      candidateOnboardingForm.modules[OnboardingFormModuleName.ESignature][
        OnboardingFormSectionName.eSignatureSection
      ]?.status;
    if (eSigSectionStatus !== OnboardingApplicationSectionStatus.COMPLETED) {
      PruToast({
        message: getI18n().t("Recruitment.esig_is_not_completed"),
      });
      return;
    }

    confirm({
      message: translate.followUpConfirmMessage,
      title: t("reminder_title"),
      confirmButtonText: translate.followUpConfirm,
    }).then(async (result) => {
      if (!result.ok) return;
      const response = await dispatch<any>(
        submitApplicationFormAsync({
          data: {
            templateId: candidateOnboardingForm.templateId,
            candidateId: candidateOnboardingForm.candidateId,
            applicationId: candidateOnboardingForm.applicationId,
            sections: [],
          },
          action: FormAction.RE_SUBMIT,
        }),
      );
      const errors = response?.error ?? response?.payload?.errors;
      if (!errors) {
        if (canGoBack()) navigate(-1);
      } else {
        PruToast({
          message: getI18n().t("Common.network_error"),
        });
      }
      /**
       * submit action
       */
    });
  }, [candidateOnboardingForm]);

  const nextClickHandler = useCallback(async () => {
    // onboarding pre check
    const checkResult: any = await onboardingPreCheck();
    // if not completed training or exam, then return
    const allPropertiesTruthy = Object.keys(checkResult).every((key) => Boolean(checkResult[key]));
    if (!allPropertiesTruthy) return;
    // if status is not change to pending signature yet, then navigate to T&C page
    const tncSectionIndex = findPanelIndex(
      formTemplate,
      OnboardingFormSectionName.termsNConditionsSection,
    );
    if (tncSectionIndex === -1) {
      confirm({
        message: t("confirm_finalized_onboarding_form_desc"),
        title: t("reminder_title"),
        confirmButtonText: t("onboarding.continue"),
        icon: <Icon svg={SvgAssets.notice} width={80} height={80} />,
      }).then(async (result) => {
        if (!result.ok) return;
        const response = await dispatch<any>(
          submitApplicationFormAsync({
            data: {
              moduleName: OnboardingFormModuleName.TermsNConditions,
              templateId: candidateOnboardingForm.templateId,
              candidateId: candidateOnboardingForm.candidateId,
              applicationId: candidateOnboardingForm.applicationId,
              sections: [],
            },
            // !!!NOTE: submit and lock the form data except E-Sig section
            action: FormAction.LOCK,
          }),
        );
        const errors = response?.error ?? response?.payload?.errors;
        if (!errors) {
          // go to E-sig page case 1
          const surveyTemplate: Model = store.getState().onboarding.currentTemplate ?? {};
          const eSigModuleTemplate = surveyTemplate.pages?.find(
            (page) => page?.name === OnboardingFormModuleName.ESignature,
          );
          const hasSignature = !!eSigModuleTemplate?.elements?.length;
          if (hasSignature) {
            query.set("sectionName", OnboardingFormSectionName.eSignatureSection);
            navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/form?${query.toString()}`);
          }
        }
        if (response?.payload?.statusCode) {
          if ([401, 403].includes(response?.payload?.statusCode)) {
            PruToast({
              message: getI18n().t("Common.unauthorized_error"),
            });
            return;
          }
          PruToast({
            message: getI18n().t("Common.network_error"),
          });
        }
      });
    } else {
      query.set("sectionName", OnboardingFormSectionName.termsNConditionsSection);
      navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/form?${query.toString()}`);
    }
  }, [candidateOnboardingForm, formTemplate, onboardingConfigs]);

  const forwardForm = useCallback(async () => {
    // onboarding pre check
    const checkResult: any = await onboardingPreCheck();
    // if not completed training or exam, then return
    const allPropertiesTruthy = Object.keys(checkResult).every((key) => Boolean(checkResult[key]));
    if (!allPropertiesTruthy) return;

    confirm({
      message: t("completed_parts_pass_form_to_recruiter"),
      title: t("reminder_title"),
      icon: <Icon svg={SvgAssets.notice} width={80} height={80} />,
    }).then(async (result) => {
      if (result.ok) {
        const response = await dispatch<any>(
          submitApplicationFormAsync({
            data: {
              templateId: candidateOnboardingForm.templateId,
              candidateId: candidateOnboardingForm.candidateId,
              applicationId: candidateOnboardingForm.applicationId,
            },
            action: FormAction.FORWARD,
          }),
        );
        const errors = response?.error ?? response?.payload?.errors;

        if (errors) {
          PruToast({
            message: getI18n().t("Common.network_error"),
          });
        }
      }
    });
  }, [candidateOnboardingForm]);

  const withDrawOnPress = useCallback(() => {
    confirm({
      message: t("Recruitment.withdraw_application_form_clear_progress_confirm"),
      title: t("Common.warning"),
      confirmButtonText: t("Recruitment.withdraw_confirm_yes"),
      cancelButtonText: t("Recruitment.withdraw_confirm_no"),
      icon: <Icon svg={SvgAssets.notice} width={80} height={80} />,
    }).then(async (result) => {
      if (result.ok) {
        const response = await dispatch<any>(
          submitApplicationFormAsync({
            data: {
              templateId: candidateOnboardingForm.templateId,
              candidateId: candidateOnboardingForm.candidateId,
              applicationId: candidateOnboardingForm.applicationId,
            },
            action: FormAction.WITHDRAW,
          }),
        );
        const errors = response?.error ?? response?.payload?.errors;
        if (!errors) {
          if (canGoBack()) navigate(-1);
        } else {
          PruToast({
            message: getI18n().t("Common.network_error"),
          });
        }
      }
    });
  }, [candidateOnboardingForm]);

  const headerRightAnchor = useMemo(() => {
    if (!isFormCreator) return;
    if (isSecretaryReadonly) return;
    if (
      ![OnboardingApplicationStatus.DRAFT, OnboardingApplicationStatus.EDITING].includes(
        candidateOnboardingForm?.applicationStatus,
      )
    )
      return;
    return (
      <div onClick={withDrawOnPress}>
        <Icon svg={SvgAssets.withdraw} width={24} height={24} />
      </div>
    );
  }, [withDrawOnPress, isFormCreator, candidateOnboardingForm, isSecretaryReadonly]);
  const buttonUI = useMemo(() => {
    const rawToken = getToken();
    const tokenData = rawToken ? parseJwt(rawToken) : {};
    if (extractOwnerId(tokenData) !== candidateOnboardingForm?.formCreatorUserId) {
      return;
    }

    if (!data?.isCompleted) return;
    // if (!trainingCompleted) return;
    if (!isAssignee) return;
    if (ONBOARDING_FINALIZED_STATUSES.includes(candidateOnboardingForm?.applicationStatus)) return;
    if (
      candidateOnboardingForm?.applicationStatus ===
        OnboardingApplicationStatus.FOLLOW_UP_REQUIRED &&
      onboardingConfigs?.onboardingFormResubmitTnCRequired === false &&
      onboardingConfigs?.onboardingFormResubmitESignatureRequired === false
    ) {
      return (
        <div className="bg-gray-100 w-screen flex">
          <button
            className="bg-red-500 flex-1 mx-4 mb-4 text-white font-semibold py-3  rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
            onClick={onSubmit}
          >
            {translate.followUpConfirm}
          </button>
        </div>
      );
    }
    if (
      candidateOnboardingForm?.applicationStatus === OnboardingApplicationStatus.EDITING ||
      candidateOnboardingForm?.applicationStatus === OnboardingApplicationStatus.FOLLOW_UP_REQUIRED
    ) {
      const buttonText =
        tokenData.role === RoleEnum.SECRETARY ? translate.pass_form_to_recruiter : translate.next;
      const clickHandler = tokenData.role === RoleEnum.SECRETARY ? forwardForm : nextClickHandler;
      return (
        <div className="bg-gray-100 w-screen flex">
          <button
            className="bg-red-500 flex-1 mx-4 mb-4 text-white font-semibold py-3  rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
            onClick={clickHandler}
          >
            {buttonText}
          </button>
        </div>
      );
    }
    return null;
  }, [
    candidateOnboardingForm,
    isAssignee,
    nextClickHandler,
    onSubmit,
    translate,
    data,
    onboardingConfigs,
  ]);
  if (!isResetRef.current || loading) {
    return null;
  }

  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen flex flex-col">
      <HeaderComponent
        title={t("Recruitment.onboarding_application")}
        onBack={() => {
          if (canGoBack()) {
            navigate(-1);
          }
        }}
        rightAnchor={headerRightAnchor}
      />
      {!!candidateOnboardingForm?.applicationId && !loading && (
        <PendingFollowUpBanner applicationId={candidateOnboardingForm.applicationId} />
      )}
      {!!data?.header && <MenuHeader items={data.header} />}
      {
        <div className={`w-screen flex flex-1 flex-col overflow-auto`}>
          {!initEmptyFormWithConfirmRef.current.clicked &&
          initEmptyFormWithConfirmRef.current.initialized ? null : isEmpty(data) ? (
            <ErrorScreen statusCode="404" />
          ) : (
            <>
              <div className={`w-screen flex flex-1 flex-col`}>
                <div className="flex-1 flex-col bg-gray-100">
                  <div className="px-4 py-3 text-black font text-base font-medium">
                    {translate.application_information}
                  </div>
                  <MenuList sections={data.menu} />
                  {enableOnboardingCourse || enableOnboardingExamRegistry ? (
                    <>
                      <div className="px-4 py-3 text-black font text-base font-medium">
                        {translate.training_and_qualifications}
                      </div>
                      <TrainingMenuList sections={trainingMenu} />
                    </>
                  ) : null}
                  {showAgreeAndApproval ? (
                    <>
                      <div className="px-4 py-3 text-black font text-base font-medium">
                        {translate.agree_and_approval}
                      </div>
                      <MenuList sections={agreeApprovalData!.menu} />
                    </>
                  ) : null}
                  {[
                    OnboardingApplicationStatus.SUBMITTED,
                    OnboardingApplicationStatus.ACCEPTED,
                    OnboardingApplicationStatus.REJECTED,
                    OnboardingApplicationStatus.EXPIRED,
                    OnboardingApplicationStatus.L1_PENDING_APPROVAL,
                    OnboardingApplicationStatus.L2_PENDING_APPROVAL,
                    OnboardingApplicationStatus.L3_PENDING_APPROVAL,
                    OnboardingApplicationStatus.L4_PENDING_APPROVAL,
                    OnboardingApplicationStatus.L1_APPROVED,
                    OnboardingApplicationStatus.L2_APPROVED,
                    OnboardingApplicationStatus.L3_APPROVED,
                  ].includes(candidateOnboardingForm?.applicationStatus) &&
                    showOnboardingReferences && (
                      <>
                        <div className="px-4 py-3 text-black font text-base font-medium">
                          {translate.references}
                        </div>
                        <ReferencesMenuList sections={trainingMenu} />
                      </>
                    )}
                  {!!formPreDocs && <MenuListOfDoc items={formPreDocs} translate={translate} />}
                </div>
              </div>
            </>
          )}
        </div>
      }
      {buttonUI}
    </div>
  );
};
