import React, { useRef } from "react";
import SignaturePad, { PointGroup } from "signature_pad";
import { QuestionSignaturePadModel, IsMobile, SurveyModel } from "survey-core";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { getI18n } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import deleteImg from "./img/delete.png";
import { resizeCanvas } from "./utils/utils";
import { resizeAndRotate } from "./resize";
import { verifySignature, caculate } from "./is-real";
import { Snackbar } from "@mui/material";
import { ActionStatusEnum } from "../file/action-enum";

type Props = {
  imageChange: (data: string) => void;
  resourceChange: (data: string) => void;
  onClose: () => void;
  questionModel: QuestionSignaturePadModel;
  originHeight: number;
  originWidth: number;
  title: string;
  /**
   * base 64
   */
  data?: string;
  readonly?: boolean;
  dataFormat?: string;
};
type State = {
  openSnackBar: boolean;
  errorMessage?: string;
  loading: boolean;
};
const BACKGROUNDCOLOR = "#ffffff";
const TOP_PADDING = 16;
const TITLE_HEIGHT = 40;

export class CustomerSignaturePad extends React.Component<Props, State> {
  control?: HTMLElement;
  base64?: string;
  data?: PointGroup[];
  signaturePad?: SignaturePad;
  format: string;
  height: number;
  width: number;
  scale: number;
  isInputReadOnly: boolean;
  isDrawingValue: boolean = false;
  signArea?: number;
  constructor(props: any) {
    super(props);
    const heightPadding = this.props.questionModel.isMobile ? 0 : TOP_PADDING + TITLE_HEIGHT;
    const widthPadding = this.props.questionModel.isMobile ? TOP_PADDING + TITLE_HEIGHT + 8 : 0;
    const maxHeight = window.outerHeight - heightPadding;
    const maxWidth = window.outerWidth - widthPadding;
    const originHeight = this.props.questionModel.isMobile
      ? this.props.originWidth
      : this.props.originHeight;
    const originWidth = this.props.questionModel.isMobile
      ? this.props.originHeight
      : this.props.originWidth;
    this.scale = Math.min(maxHeight / originHeight, maxWidth / originWidth);
    this.height = originHeight * this.scale;
    this.width = originWidth * this.scale;
    this.base64 = this.props.data;

    this.isInputReadOnly = this.props.questionModel.readOnly;
    this.format =
      this.props.dataFormat ??
      (this.props.questionModel.dataFormat === "jpeg"
        ? "image/jpeg"
        : this.props.questionModel.dataFormat === "svg"
        ? "image/svg+xml"
        : "");
    this.state = {
      openSnackBar: false,
      loading: false,
    };
  }
  async loadData(data: string) {
    this.base64 = await resizeAndRotate(
      data,
      this.props.questionModel.isMobile ? 90 : 0,
      this.width,
      this.height,
      this.format,
    );
  }

  componentWillUnmount() {
    this.destroySignaturePad();
  }

  public setControl(element: HTMLElement | null): void {
    if (!!element) {
      this.control = element;
    }
  }
  render(): React.ReactNode {
    return (
      <div
        style={{
          height: window.innerHeight,
          width: window.innerWidth,
          flexDirection: this.props.questionModel.isMobile ? "row" : "column",
        }}
        className="flex bg-gray-100 relative"
        ref={(ref) => this.setControl(ref)}
      >
        {!this.props.questionModel.isMobile && (
          <div
            style={{
              backgroundColor: BACKGROUNDCOLOR,
              paddingTop: TOP_PADDING,
              paddingBottom: TOP_PADDING,
            }}
            className="flex items-center"
          >
            <Button onClick={this.props.onClose} startIcon={<ArrowBackIcon />}></Button>
            <span>{this.props.title}</span>
          </div>
        )}

        <div className="flex-1 flex items-center justify-center">
          <canvas
            style={{
              backgroundColor: BACKGROUNDCOLOR,
            }}
            className="outline-none"
            tabIndex={0}
          />
        </div>

        {this.props.questionModel.isMobile && (
          <div
            className="flex items-center"
            style={{
              writingMode: "vertical-lr",
              backgroundColor: BACKGROUNDCOLOR,
              paddingTop: TOP_PADDING,
            }}
          >
            <Button
              style={{
                transform: "rotate(90deg)",
                color: "#333",
              }}
              disabled={this.state.loading}
              // className="rotate-90 text-gray-500"
              onClick={this.props.onClose}
              startIcon={<ArrowBackIcon />}
            ></Button>
            <span>{this.props.title}</span>
          </div>
        )}

        {!this.isInputReadOnly && (
          <div
            className="absolute rounded-lg border-2 border-gray-400 bg-white"
            style={{
              ...(this.props.questionModel.isMobile
                ? { bottom: TOP_PADDING, right: TOP_PADDING, transform: "rotate(90deg)" }
                : { top: TOP_PADDING, right: TOP_PADDING }),
            }}
          >
            <IconButton
              style={{ width: 48, height: 48 }}
              disabled={this.state.loading}
              onClick={() => {
                this.base64 = undefined;
                this.signaturePad?.clear();
              }}
            >
              <img src={deleteImg} />
            </IconButton>
          </div>
        )}

        {!this.isInputReadOnly && (
          <div
            className="absolute rounded-lg bg-red-600 text-white"
            style={{
              ...(this.props.questionModel.isMobile
                ? { bottom: TOP_PADDING, left: TOP_PADDING, transform: "rotate(90deg)" }
                : { bottom: TOP_PADDING, right: TOP_PADDING }),
            }}
          >
            <IconButton
              style={{ width: 48, height: 48 }}
              disabled={this.state.loading}
              color="inherit"
              onClick={this.onConfirm.bind(this)}
            >
              <CheckIcon color="inherit" fontSize="inherit" />
            </IconButton>
          </div>
        )}
        <Snackbar
          open={this.state.openSnackBar}
          onClick={() => this.setState({ openSnackBar: false })}
          ContentProps={{
            style: this.props.questionModel.isMobile
              ? {
                  transform: "rotate(90deg)",
                  flexGrow: 0,
                  borderRadius: 12,
                  minWidth: 0,
                }
              : {
                  borderRadius: 12,
                  minWidth: 0,
                },
          }}
          autoHideDuration={4000}
          message={<span className="text-center">{this.state.errorMessage}</span>}
          onClose={() => this.setState({ openSnackBar: false })}
          sx={{ height: "100%" }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
      </div>
    );
  }
  private getPenColorFromTheme(): string {
    const _survey = this.props.questionModel.survey as SurveyModel;
    return (
      !!_survey && !!_survey.themeVariables && _survey.themeVariables["--sjs-primary-backcolor"]
    );
  }
  private updateColors(signaturePad: SignaturePad) {
    const penColorFromTheme = this.getPenColorFromTheme();
    const penColorProperty = this.props.questionModel.getPropertyByName("penColor");
    signaturePad.penColor =
      this.props.questionModel.penColor ||
      penColorFromTheme ||
      penColorProperty.defaultValue ||
      "#333333";
  }
  initSignaturePad(el: HTMLElement) {
    var canvas: any = el.getElementsByTagName("canvas")[0];
    var signaturePad = new SignaturePad(canvas, {
      maxWidth: 5,
      minWidth: 1,
      minDistance: 10,
    });
    if (this.isInputReadOnly) {
      if (!this.base64) {
        this.setState({
          openSnackBar: true,
          errorMessage: getI18n().t("Recruitment.do_not_have_permission_to_edit_section_at_moment"),
        });
      }
      signaturePad.off();
    }

    this.updateColors(signaturePad);

    signaturePad.addEventListener(
      "beginStroke",
      () => {
        this.isDrawingValue = true;
        canvas.focus();
      },
      { once: false },
    );

    signaturePad.addEventListener(
      "endStroke",
      () => {
        this.isDrawingValue = false;
        this.data = signaturePad.toData();
        this.signArea = caculate(canvas);
      },
      { once: false },
    );

    var updateValueHandler = () => {
      canvas.width = this.width;
      canvas.height = this.height;
      resizeCanvas(canvas);
      if (!this.base64) {
        signaturePad.clear();
      } else {
        if (this.base64) signaturePad.fromDataURL(this.base64);
      }
    };
    window.addEventListener("orientationchange", () => {
      updateValueHandler();
    });
    updateValueHandler();
    this.signaturePad = signaturePad;
  }

  async componentDidMount() {
    super.componentDidMount?.();
    if (this.props.data) await this.loadData(this.props.data);
    if (!!this.control) {
      this.initSignaturePad(this.control);
    }
  }
  destroySignaturePad() {
    if (this.signaturePad) {
      this.signaturePad.off();
    }
    this.signaturePad = undefined;
  }

  async onConfirm() {
    if (!this.signaturePad || this.props.questionModel.readOnly) return this.props.onClose();

    if (this.signaturePad.isEmpty()) {
      this.setState({
        openSnackBar: true,
        errorMessage: this.props.questionModel.requiredErrorText,
      });
      return;
    } else {
      const isR = verifySignature(this.signaturePad.toData(), {
        minSpeedDiff: 1,
      });
      if (!isR || (this.signArea !== undefined && this.signArea <= 10000)) {
        this.setState({
          openSnackBar: true,
          errorMessage: getI18n().t("onboarding.invalid_signature"),
        });
        return;
      }
    }
    const resizedBase64 = await resizeAndRotate(
      this.signaturePad?.toDataURL(this.format),
      this.props.questionModel.isMobile ? -90 : 0,
      this.props.originWidth,
      this.props.originHeight,
      this.format,
    );
    this.props.questionModel.survey.uploadFiles(
      this.props.questionModel,
      this.props.questionModel.name,
      [resizedBase64 as any],
      (status, data) => {
        this.setState({ loading: false });
        if (status === ActionStatusEnum.success) {
          this.props.resourceChange(data[0]);
          this.props.imageChange(resizedBase64);
          this.props.onClose();
        }
        if (status === ActionStatusEnum.error) {
          this.setState({
            openSnackBar: true,
            errorMessage: getI18n().t("onboarding.faied_to_upload_signature"),
          });
        }
      },
    );
  }
}
