import { Button, Input } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { appendAlert } from "../../../../components/alert/alert";
import { PruToast } from "../../../../components/pru-toast";
import { Layout } from "../../../../layout/layout";
import { AlertType } from "../../../../redux/common/common-slice";
import {
  EventFeature,
  EventModule,
  TrackingData,
} from "../../../../utils/event-tracking/event-tracking.model";
import { updateDemoTitle } from "../../redux/demo-slice";
import "./landing-screen.css";
import { confirm } from "../../../../components/modal/comfirm";
interface Props {
  items?: string[];
}

/** define the page tracking data */
const pageTrackingData: TrackingData = {
  name: "prospect::view_profile",
  module: EventModule.agent_profile_site,
  feature: EventFeature.agent_info,
  journey: "",
  stage: "",
  target: "whatsapp", // whatsapp/phone/email
};

const menus = [
  { name: "auto-tracking", to: "demo-auto-tracking" },
  { name: "test-page1", to: "demo-page1" },
  { name: "test-page2", to: "demo-page2" },
];

export const DemoLandingScreen: React.FC<Props> = ({ items }) => {
  const dispatch = useDispatch();
  const demoTitle = useSelector((state: any) => {
    return state.demo?.title;
  });
  return (
    <React.Fragment>
      <>
        <div className="wrap">
          <h3>demo landing screen</h3>
          <h4> test navigation</h4>
          <nav>
            <ul>
              {menus.map((menu) => {
                /* DO NOT forget to set unique-key for loop */
                return (
                  <li key={menu.name}>
                    <Link to={menu.to}>{menu.name}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <h4> test redux</h4>
          <Input
            placeholder="set demo title"
            defaultValue={demoTitle}
            onChange={(e) => {
              dispatch(updateDemoTitle(e.target.value));
            }}
          />
          <h4> Alert popup</h4>
          <Button
            onClick={() => {
              appendAlert([
                {
                  severity: AlertType.WARNING,
                  title: "Failed",
                  content: "You got an error here",
                },
              ]);
            }}
          >
            Click me to alert
          </Button>
          <h4> Toast popup</h4>
          <Button
            onClick={() => {
              PruToast({
                status: "success",
                message: "I am a toast",
              });
            }}
          >
            Click me to toast
          </Button>
          <h4> Modal popup</h4>
          <Button
            onClick={() => {
              confirm({
                message: "Are you sure to leave this form? All progress would be lost.",
              }).then(() => {});
            }}
          >
            Click me to popup a modal
          </Button>
        </div>
        <a href="https://pruforceagentcoedev.prudential.com.hk/pruforce">打开dev应用</a>
        <a href="https://pruforceagentcoesit.prudential.com.hk/pruforce">打开sit应用</a>
      </>
    </React.Fragment>
  );
};
