export const findText = (text: string, search: string) => {
  if (!search)
    return [
      {
        text,
        highlight: false,
      },
    ];
  const lowerText = text.toLocaleLowerCase();
  const lowerSearch = search.toLocaleLowerCase();

  let start = 0;
  const match: { text: string; highlight: boolean }[] = [];
  Array.from(lowerText.matchAll(new RegExp(lowerSearch, "g"))).forEach((d) => {
    /**
     * safe here because it's a bug from typescript, the index should be always number
     */
    const index = d.index as number;
    match.push({
      text: text.substring(start, index),
      highlight: false,
    });
    start = index + search.length;
    match.push({
      text: text.substring(index, start),
      highlight: true,
    });
  });
  if (start !== text.length)
    match.push({
      text: text.substring(start, text.length),
      highlight: false,
    });
  return match;
};
