import React, { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ACCESSIBILITY_TYPE, EventType } from "../../network/type";
import { useAppSelector } from "../../../../redux/store";
import { getI18nData } from "../../../../components/surveyjs/survey/survey.util";

export const DetailDesc = () => {
  const { t } = useTranslation();
  const { name, category, type } = useAppSelector((state) => state.event.eventDetailData)!;

  const eventTypeConfig = useMemo(
    () => ({
      [EventType.PHYSICAL]: t("event.physical"),
      [EventType.VIRTUAL]: t("event.virtual"),
      [EventType.HYBRID]: t("event.hybrid"),
    }),
    [t],
  );

  const accessibilityConfig = useMemo(
    () => ({
      [ACCESSIBILITY_TYPE.INTERNAL]: t("event.internal"),
      [ACCESSIBILITY_TYPE.PUBLIC]: t("event.public"),
    }),
    [t],
  );

  return (
    <Fragment>
      <span className="font-semibold text-xl md:text-4xl md:leading-[54px]">
        {getI18nData(name)}
      </span>

      <div className="mt-2 md:mt-4">
        <span className="event-tag text-[#666]">
          {accessibilityConfig[category?.accessibility]}
        </span>
        <span className="tag-divider" />
        <span className="event-tag text-[#666]">{getI18nData(category?.name)}</span>
        <span className="tag-divider" />
        <span className="event-tag text-[#666]">{eventTypeConfig[type]}</span>
      </div>
    </Fragment>
  );
};
