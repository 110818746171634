import { OnboardingApplicationStatus } from "./types";

export const ONBOARDING_FINALIZED_STATUSES = [
  OnboardingApplicationStatus.SUBMITTED,
  OnboardingApplicationStatus.ACCEPTED,
  OnboardingApplicationStatus.REJECTED,
  OnboardingApplicationStatus.EXPIRED,
  OnboardingApplicationStatus.L1_PENDING_APPROVAL,
  OnboardingApplicationStatus.L1_APPROVED,
  OnboardingApplicationStatus.L2_PENDING_APPROVAL,
  OnboardingApplicationStatus.L2_APPROVED,
  OnboardingApplicationStatus.L3_PENDING_APPROVAL,
  OnboardingApplicationStatus.L3_APPROVED,
  OnboardingApplicationStatus.L4_PENDING_APPROVAL,
  OnboardingApplicationStatus.CANCELLED,
];
