import { AxiosError } from "axios";
import { get, set } from "lodash";
import React from "react";
import { ErrorStateBlock } from "../components/error-state/error-state";
// import { HeaderComponent } from "../components/header/header.component";
import { RoutedProps, withRouter } from "../components/with-router/with-router.component";
import { commonSlice } from "../redux/common/common-slice";
import { canUseCookie } from "../utils/cookie";
import {
  AcceptType,
  EventFeature,
  TrackingData,
} from "../utils/event-tracking/event-tracking.model";
import {
  eventTrackingService,
  // isTncUpdated,
  saveAcceptTncTime,
} from "../utils/event-tracking/event-tracking.service";
import { GlobalHelper } from "../utils/helpers/global-helper";
import { LangOptionComponent } from "./lang-option/lang-option.component";
import "./layout.css";
import { getEntryData } from "./network/network";
import { TncComponent } from "./tnc/tnc.component";
// import { getCookie, setCookie } from "../utils/cookie";
const { setHashEntryAction } = commonSlice.actions;
interface Props extends RoutedProps {
  children: React.ReactNode | null;
  title: string; // page title
  trackingData?: TrackingData; // if null|undefined, WILL NOT do the data tracking
  disableLanguageButton?: boolean;
  disableTnc?: boolean;
}
interface HashData {
  _id: string;
  access: string;
  businessKey: string;
  data: object;
  key: string;
  module: string;
  reference: string;
  privateData: object;
  tnc: object;
}
type State = {
  refId: string;
  errorCode: number | undefined;
  loading: boolean;
  enableTracking: boolean;
  hashData: HashData;
};
class LayoutComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (this.props.title) {
      window.document.title = this.props.title;
    }
    const refId = get(this.props, "params.refId");
    const enableTracking = !!this.props.trackingData; // pass trackingData means auto-tracking
    this.state = {
      hashData: {} as HashData,
      refId: refId,
      enableTracking: enableTracking && !this.isPreview(),
      loading: true,
      errorCode: undefined,
    };
  }
  componentDidMount(): void {
    this.getData();
  }

  componentWillUnmount(): void {
    // end_view
    eventTrackingService.leaveReport("unmount");
  }
  async getData() {
    const refId = this.state.refId;
    await getEntryData(refId)
      .then((res: any) => {
        // refId validation failed.
        if (!res || res.key !== refId) {
          this.setHashEntry({});
          return this.setState({ errorCode: 404 }); // refId not found
        }
        this.setHashEntry(res);
        this.setState({ hashData: res }, () => {
          // tnc is disabled, user default accept tracking
          // const tncEnabled = get(res, "tnc.content.enabled", false);
          // if (!tncEnabled) {
          //   saveAcceptTncTime(new Date());
          // }
          this.initTracking(); // init tracking data
          eventTrackingService.entryReport(false, "mount");
        });
      })
      .catch((err: AxiosError) => {
        this.setHashEntry({});
        this.setState({ errorCode: err?.status || -1 });
      })
      .finally(() => this.setState({ loading: false }));
  }
  setHashEntry(data: any) {
    GlobalHelper.getGlobalDispatch()?.(setHashEntryAction(data));
  }
  initTracking() {
    if (!canUseCookie()) {
      return;
    }
    if (this.state.enableTracking) {
      // get and set TrackingData for later using
      const { name, module, feature, target, journey, stage } =
        this.props.trackingData || ({} as TrackingData);
      let trackingData: TrackingData = { name, module, feature, target, journey, stage };
      if (target) {
        /**
         * get and set tracking feature field, only for resource page(having target)
         * sales resource: resource
         * recruitment resource: recruitment
         * profile: agent_info (hard coded, no handling here)
         */
        const feature = get(this.state.hashData, "data.feature", EventFeature.resource);
        set(trackingData, "feature", feature);
      }
      eventTrackingService.setTrackingData(this.state.refId, trackingData, this.isEnableTracking);
      eventTrackingService.clickEventAutoTracking();
    } else {
      // fixing: disable Tracking if current page tracking is NOT allowed
      eventTrackingService.setTrackingData(this.state.refId, {} as TrackingData, () => false);
    }
  }
  isPreview() {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get("preview") === "1";
  }
  /** if allow to tracking */
  isEnableTracking = () => {
    if (!this.state.enableTracking) {
      return false; // no props.trackingData, means no-tracking
    }

    if (this.isPreview()) {
      return false;
    }

    // tnc is enabled, check if tnc is updated, user need to accept again
    // const updatedAt = get(this.state.hashData, "tnc.updatedAt", "");
    // const accepted = !isTncUpdated(updatedAt);
    /**
     * Indicates that the user confirms that the cookie setting of the browser is used,
     * and the user no longer needs to manually confirm.
     * The purpose of saving the policy in localstorage is to determine whether the policy is updated.
     * If it is updated, it needs to be prompted again
     */
    const accepted = canUseCookie();
    return accepted;
  };

  acceptTnC(accept: AcceptType) {
    if (accept === AcceptType.yes) {
      const updatedAt = get(this.state.hashData, "tnc.updatedAt", new Date());
      saveAcceptTncTime(updatedAt);
    }
  }

  render() {
    const { loading, errorCode } = this.state;

    if (loading) {
      return null;
    } else if (errorCode) {
      return (
        <div className="center">
          <ErrorStateBlock statusCode={errorCode as any} />
        </div>
      );
    }
    return (
      <>
        <div className="flex flex-col w-full min-h-screen overflow-x-hidden">
          {this.props.disableLanguageButton !== true && <LangOptionComponent />}
          {!this.props.disableTnc && (
            <TncComponent
              setting={this.state.hashData.tnc}
              onAccept={this.acceptTnC.bind(this, AcceptType.yes)}
              onDecline={this.acceptTnC.bind(this, AcceptType.no)}
            />
          )}
          {this.props.children}
        </div>
      </>
    );
  }
}
export const Layout = withRouter(LayoutComponent);
