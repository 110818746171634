import React, { FC, PropsWithChildren, memo, useCallback } from "react";
import { TakeUIClickEventParameters } from "../types/ga-general-type";
import { takeUIClickEvent } from "../ga";

type Props = TakeUIClickEventParameters;

const GAClickFragment: FC<PropsWithChildren<Props>> = ({ children, ...gaData }) => {
  const onClick = useCallback(() => {
    takeUIClickEvent(gaData);
  }, [gaData]);

  return <span onClick={onClick}>{children}</span>;
};

export default memo(GAClickFragment);
