import React, { useState, useContext, useRef } from "react";
import zoomOut from "./zoom-out.png";
import { PdfViewContext, usePdfViewContext } from "./page-context";
import { TransformPdfView } from "./transform-pdf";

type Props = {
  clickZoom?: () => void;
};

export const PdfFullView = (props: Props) => {
  const context = useContext(PdfViewContext);
  return (
    <div
      className="h-screen flex flex-col relative justify-center items-center bg-black"
      onClick={(e) => {
        if (!!context.transferRef?.state.scale && context.transferRef?.state.scale !== 1) return;
        const pre = (window.innerWidth * 1) / 3;
        const next = (window.innerWidth * 2) / 3;
        if (e.clientX < pre) context.prePage();
        if (e.clientX > next) context.nextPage();
      }}
    >
      <TransformPdfView
        transformProps={{
          contentClass: "!h-screen !items-center !justify-center",
          wrapperStyle: { backgroundColor: "black" },
        }}
      />
      <div
        className="absolute p-2 top-0 right-0"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          props.clickZoom?.();
        }}
      >
        <img src={zoomOut} alt="zoomIn"></img>
      </div>
      <div
        className="absolute p-2 bottom-2 right-0 text-xs text-white"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        {context.currentPage}/{context.total}
      </div>
    </div>
  );
};
