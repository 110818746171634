import { cloneDeep, get, isEmpty, isEqual } from "lodash";
import {
  OnboardingApplicationStatus,
  OnboardingFlow,
  OnboardingFormModuleName,
  OnboardingFormSectionName,
} from "../types";
import { OnboardingState, initialState } from "./onboarding-slice";
import { DocItem } from "../pages/menu/type";
import { onboardingPostModules, onboardingPreModules } from "../../../constants/constants";
import { extractOwnerId, getToken, parseJwt } from "../utils";
import { OnboardingTrainingPlatform } from "../pages/course-menu-screen/type";

export const selectCandidateOnboardingForm = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.candidateOnboardingForm;
};

export const selectOnboardingCourses = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.trainingCourses;
};

export const selectOnboardingQuizContent = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.trainingCourseQuiz;
};

export const selectOnboardingExams = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.trainingExams;
};

export const selectOnboardingCurrentExam = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.currentExam;
};

export const selectOnboardingTrainingSummary = (state: { onboarding: OnboardingState }) => {
  // return state.onboarding.trainingSummary;

  const _trainingAndExamSummary = cloneDeep(state.onboarding.trainingAndExamSummary);
  const trainingSummary = _trainingAndExamSummary?.trainingSummary ?? ({} as any);
  const trainingPlatform = state.onboarding.configs?.Recruitment?.trainingPlatform;
  if (!trainingSummary?.required && trainingPlatform === OnboardingTrainingPlatform.COE) {
    trainingSummary.requiredToAttend = false;
  }
  const examSummary = _trainingAndExamSummary?.examSummary ?? ({} as any);
  examSummary.requiredToAttend = true;
  if (!examSummary?.total) {
    examSummary.requiredToAttend = false;
  }
  return _trainingAndExamSummary;
};

export const selectOnboardingExamCentres = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.trainingExamCentres ?? [];
};

export const selectOnboardingExamTimeslots = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.trainingExamTimeslots ?? [];
};

export const selectOnboardingFlow = (state: {
  onboarding: OnboardingState;
}): OnboardingFlow | undefined => {
  return state.onboarding.configs.formCreator;
};

export const selectOnboardingConfigs = (state: { onboarding: OnboardingState }): any => {
  return state.onboarding.configs;
};

export const selectConfigLoaded = (state: { onboarding: OnboardingState }): boolean => {
  return !!state.onboarding.configsLoaded;
};

export const selectEnableOnboardingTraining = (state: {
  onboarding: OnboardingState;
}): OnboardingFlow | undefined => {
  return state.onboarding.configs.enableOnboardingTrainingModule ?? true; // enableOnboardingLicenseExamRegistrationModule
};
export const selectShowOnboardingReferences = (state: {
  onboarding: OnboardingState;
}): OnboardingFlow | undefined => {
  return state.onboarding.configs.showOnboardingReferences ?? true;
};
export const selectEnableLicenseExamPayment = (state: {
  onboarding: OnboardingState;
}): OnboardingFlow | undefined => {
  return state.onboarding.configs.enableLicenseExamPayment ?? true;
};

export const selectCandidateOnboardingFormSubmiting = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.submiting;
};

export const selectCandidateOnboardingFormCreatedAt = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.candidateOnboardingForm?.createdDate;
};

export const selectRecruiterCode = (state: { onboarding: OnboardingState }) => {
  const formData = selectCandidateOnboardingForm(state);
  return formData?.recruiterCode;
};

export const selectCandidateOnboardingFormValues = (state: { onboarding: OnboardingState }) => {
  const formData = selectCandidateOnboardingForm(state);
  const formTemplate = selectOnboardingFormTemplate(state);
  if (isEmpty(formData) || isEmpty(formTemplate)) return {};
  const defaultData = mergeQuestionAnswers(formData, formTemplate);
  // const formRenderedData = merge(defaultData, state.onboarding.populatedFieldData);
  return defaultData;
};
// export const selectOnboardingFormTemplateMap = (state: { onboarding: OnboardingState }) => {
//   return convertTemplate(state.onboarding.currentTemplate);
// };
export const selectOnboardingFormTemplateId = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.currentTemplate?.templateId;
};
export const selectOnboardingFormTemplate = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.currentTemplate;
};
export const selectDocumentDownloadList = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.documentDownloadList ?? [];
};
export const selectDocumentUploadList = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.documentUploadList ?? [];
};

export const selectDataError = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.getDataError;
};
export const selectOnboardingFormPreparationDocs = (state: { onboarding: OnboardingState }) => {
  const pages: any[] = selectOnboardingFormTemplate(state)?.pages;
  if (!pages) return [];
  const docs: DocItem[] = [];

  for (const element of pages) {
    const page = element;
    const pageName = get(page, "name", "");
    const panels = get<any[]>(page, "elements", []);

    for (const element of panels) {
      const panel = element;
      const panelName = get(panel, "name", "");

      if (
        pageName === OnboardingFormModuleName.PreQuestions &&
        panelName === OnboardingFormSectionName.formPreparation
      ) {
        const elements = get(panel, "elements", []);

        for (const element of elements) {
          const el = element;
          const choices = get(el, "choices", []);
          const name = get(el, "name", "");
          const title = get(el, "title", "");

          const choicesTitles = [];
          for (const element of choices) {
            choicesTitles.push(get(element, "text", ""));
          }

          docs.push({
            name: name,
            title: title,
            data: choicesTitles,
          });
        }
        break;
      }
    }
  }

  return docs;
};

export const selectOnboardingCandidateName = (state: { onboarding: OnboardingState }) => {
  const onboardingFormValues = selectCandidateOnboardingFormValues(state) as any;
  const candidateName = `${
    onboardingFormValues?.careerFirstNameEn ?? onboardingFormValues?.firstNameEn ?? ""
  } ${onboardingFormValues?.careerSurnameEn ?? onboardingFormValues?.surnameEn ?? ""}`;
  return candidateName;
};

export const selectIsSecretaryReadonly = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.candidateOnboardingForm?.isSecretaryReadonly;
};

export const selectFollowUpTasks = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.followUpTasks;
};

export const selectIsAssignee = (state: { onboarding: OnboardingState }) => {
  return state.onboarding.isAssignee;
};

export const selectIsFormCreator = (state: { onboarding: OnboardingState }) => {
  const rawToken = getToken();
  const tokenData = rawToken ? parseJwt(rawToken) : {};
  const ownerUserId = extractOwnerId(tokenData);
  return state.onboarding.candidateOnboardingForm?.formCreatorUserId === ownerUserId;
};

export const selectVisibleMenuFormConfig = (state: { onboarding: OnboardingState }) => {
  // select the menu form config from the current candidate onboarding form template
  let onboardingExcludedMenuModules = onboardingPreModules;
  onboardingExcludedMenuModules = onboardingExcludedMenuModules.concat(onboardingPostModules);
  const menuFormConfig = selectOnboardingFormTemplate(state)?.pages?.filter?.(
    (module: { visible: boolean; name: OnboardingFormModuleName }) =>
      !onboardingExcludedMenuModules.includes(module.name) && module.visible !== false,
  );

  return menuFormConfig;
};

export const selectAgreeApprovalFormConfig = (state: { onboarding: OnboardingState }) => {
  // select the menu form config from the current candidate onboarding form template
  const agreeApprovalFormConfig = selectOnboardingFormTemplate(state)?.pages?.filter?.(
    (module: { visible: boolean; name: OnboardingFormModuleName }) =>
      onboardingPostModules.includes(module.name) && module.visible !== false,
  );

  return agreeApprovalFormConfig;
};
// get the total number of sections in the form except pre-questions
export const selectTotalSectionLength = (state: { onboarding: OnboardingState }) => {
  const menuFormConfig = selectVisibleMenuFormConfig(state);
  let totalSection = 0;
  menuFormConfig?.forEach((module: any) => {
    totalSection += module?.elements?.filter((section: any) => section?.visible !== false)?.length;
  });
  return totalSection;
};

// get the progress of completed sections in the form except pre-questions, progress is equal to completed sections / total sections
export const selectCompletedSectionLength = (state: { onboarding: OnboardingState }) => {
  // calculate the number of completed sections from the candidate submitted form, completed sections have a property called status with value 'COMPLETED'
  const menuFormData = cloneDeep(selectCandidateOnboardingForm(state)?.modules);
  if (!menuFormData) return 0;
  delete menuFormData[OnboardingFormModuleName.PreQuestions];
  delete menuFormData[OnboardingFormModuleName.TermsNConditions];
  delete menuFormData[OnboardingFormModuleName.ESignature];
  let completedSection = 0;
  const menuTemplateConfig = selectVisibleMenuFormConfig(state);
  const allVisibleSections = getAllSectionNames(menuTemplateConfig);
  Object.keys(menuFormData || {}).forEach((moduleKey) => {
    const module = menuFormData?.[moduleKey];
    // find the number of completed sections in module
    Object.keys(module || {}).forEach((sectionKey) => {
      const section = module?.[sectionKey];
      if (section?.status === "COMPLETED" && allVisibleSections.includes(sectionKey)) {
        completedSection += 1;
      }
    });
  });
  return completedSection;
};
function getAllSectionNames(data: any) {
  if (!data) return [];
  return data.flatMap((item: any) => {
    return item.elements.map((element: any) => element.name);
  });
}

// ['identificationInformation', 'personalInformation', 'contactInformation', 'accountInformation', 'personalLiabilities']
function mergeQuestionAnswers(data: any, formTemplate: any) {
  const eSignatureTemplate = formTemplate?.pages?.find(
    (page: any) => page.name === OnboardingFormModuleName.ESignature,
  );
  const eSignatureSectionFields = eSignatureTemplate?.elements?.flatMap((element: any) =>
    element.elements.map((el: any) => el.name),
  );
  // initialize the result fields in eSignatureSectionFields with undefined
  const result =
    eSignatureSectionFields?.reduce((acc: any, field: string) => {
      acc[field] = undefined;
      return acc;
    }, {}) ?? {};

  for (const module of Object.values(data.modules)) {
    for (const section of Object.values(module as any)) {
      Object.assign(result, (section as any)?.questionAnswers ?? {});
    }
  }

  return result;
}

export function convertTemplate(templateJson: any): any {
  if (!templateJson) {
    return {};
  }
  const templateMap: any = {};
  for (let index = 0; index < templateJson.pages.length; index++) {
    const page = templateJson.pages[index];
    templateMap[page.name] = {
      name: page.name,
      pages: page.elements,
    };
  }
  return templateMap;
}
