import { makeStyles } from "@mui/styles";

const BADGE_COLUMN_GAP = 12;

const MULTI_COLUMN_TOP_BOTTOM_MARGIN = 30;
const MULTI_COLUMN_AWARD_MIN_HEIGHT = 60;
const VERTICAL_DIVIDER_WIDTH = 1;
const VERTICAL_DIVIDER_HEIGHT = 58;
const VERTICAL_DIVIDER_SIDE_MARGIN = 20;

export const useStyles = makeStyles(() => ({
  badgeListContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    columnGap: BADGE_COLUMN_GAP,
    marginBottom: 16,
  },
  badge: {
    width: `calc((100% - ${BADGE_COLUMN_GAP * 4 + 1}px) / 5)`,
    maxWidth: 56,
  },
  multiColumnAwardListContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  multiColumnAwardItemContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: `calc((100% - ${
      (VERTICAL_DIVIDER_WIDTH + VERTICAL_DIVIDER_SIDE_MARGIN * 2) * 2
    }px) / 3)`,
    minHeight: MULTI_COLUMN_AWARD_MIN_HEIGHT,
    margin: `${MULTI_COLUMN_TOP_BOTTOM_MARGIN}px 0`,
  },
  horizontalDivider: {
    width: "100%",
  },
  verticalDivider: {
    width: VERTICAL_DIVIDER_WIDTH,
    height: VERTICAL_DIVIDER_HEIGHT,
    margin: `${MULTI_COLUMN_TOP_BOTTOM_MARGIN}px ${VERTICAL_DIVIDER_SIDE_MARGIN}px`,
  },
  awardTitle: {
    fontSize: 16,
  },
  caption: {
    fontSize: 14,
    color: "#BABABA",
  },
  bottomSpacing: {
    marginBottom: 12,
  },
}));
