import React, { CSSProperties } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";
import i18next from "i18next";
// mui start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// mui end

import * as style from "../survey/common.style";
import "./tnc.component.css";

import { get } from "lodash";
import { CustomType, getI18nData } from "../survey/survey.util";
import { getTextAndLinkChunksFromText } from "../../../layout/tnc/tnc.component";

const CUSTOM_TYPE = CustomType.Tnc;

export class QuestionTncModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  // get, set for custom props in survey-json
  get labelTrue(): string {
    return this.getPropertyValue("labelTrue");
  }
  get choices(): string[] {
    return this.getPropertyValue("choices");
  }
  set choices(val) {
    this.setPropertyValue("choices", val);
  }
}

// Add question type metadata for further serialization into JSON
export const initTncSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "choices",
        default: [], // surveyjs default value if custom-props not specific
      },
    ],
    function () {
      return new QuestionTncModel("");
    },
    "question",
  );
};

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionTncModel(name);
});
// A class that renders questions of the new type in the UI
export class SurveyQuestionTnc extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
  }
  get question() {
    return this.questionBase;
  }
  get choices() {
    return getI18nData(this.question.choices) as any;
  }

  renderElement() {
    if (!this.choices) {
      return null;
    }
    return (
      <div className="tnc-list">
        {this.choices.map((item: any, index: number) => {
          if (typeof item === "string") {
            // convert mark-down format link
            const arrContent = getTextAndLinkChunksFromText(item);
            const content = arrContent.map(
              (item: { text: string; link: string }, index: number) => {
                if (item.link) {
                  return (
                    <a
                      key={index}
                      className="link"
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.text}
                    </a>
                  );
                }
                return item.text;
              },
            );
            return <p key={index}>{content}</p>;
          }
          return null;
        })}
      </div>
    );
  }
}
