import React from "react";
import { FC, memo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getGAData } from "../redux/ga-slice";

const styleSheets: { [key: string]: React.CSSProperties } = {
  container: {
    width: 200,
    padding: 7,
    position: "fixed",
    right: 7,
    bottom: 7,
    display: "flex",
    flexDirection: "column",
    gap: 5,
    border: "1px solid #000",
    borderRadius: 7,
    color: "#000",
    backgroundColor: "#fff",
    overflow: "hidden",
    transition: "all .1s",
    wordBreak: "break-word",
    zIndex: 999,
  },
  btnExpand: {
    width: 20,
    height: 20,
    position: "absolute",
    top: 7,
    right: 7,
    fontSize: 14,
    fontWeight: "bold",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    borderBottom: ".5px solid #eee",
  },
  header: {
    height: 20,
    fontSize: 10,
    fontWeight: "bold",
  },
  title: {
    marginBottom: 2,
    fontSize: 12,
    fontWeight: "bold",
  },
  value: {
    fontSize: 10,
  },
};

const GAPanel: FC<{ debug?: boolean }> = ({ debug }) => {
  const [expanded, setExpanded] = useState(false);
  const data = useSelector(getGAData);

  const location = useLocation();

  const onExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  if (window.envConfig["REACT_APP_ENV"] !== "dev") return null;
  if (!debug) return null;

  const eventSource = `PRUForce-PublicPortal-IN-${window.ReactNativeWebView ? "webapp" : "web"}`;

  return (
    <div
      style={{
        ...styleSheets.container,
        width: expanded ? 200 : 36,
        height: expanded ? "auto" : 36,
      }}
    >
      <button onClick={onExpandClick} style={styleSheets.btnExpand}>
        {expanded ? `>` : `<`}
      </button>
      {expanded && (
        <>
          <div style={styleSheets.itemContainer}>
            <label style={styleSheets.header}>Public Portal</label>
          </div>
          <div style={styleSheets.itemContainer}>
            <label style={styleSheets.title}>Path</label>
            <label style={styleSheets.value}>{location.pathname}</label>
          </div>
          <div style={styleSheets.itemContainer}>
            <label style={styleSheets.title}>Module</label>
            <label style={styleSheets.value}>{data?.module || "N/A"}</label>
          </div>
          <div style={styleSheets.itemContainer}>
            <label style={styleSheets.title}>Feature</label>
            <label style={styleSheets.value}>{data?.feature || "N/A"}</label>
          </div>
          <div style={styleSheets.itemContainer}>
            <label style={styleSheets.title}>Journey</label>
            <label style={styleSheets.value}>{data?.journey || "N/A"}</label>
          </div>
          <div style={styleSheets.itemContainer}>
            <label style={styleSheets.title}>Screen Name</label>
            <label style={styleSheets.value}>{data?.screen_name || "N/A"}</label>
          </div>
          <div style={styleSheets.itemContainer}>
            <label style={styleSheets.title}>Event Source</label>
            <label style={styleSheets.value}>{eventSource}</label>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(GAPanel);
