import React from "react";
import { FeedbackComponent } from "../../../../components/feedback/feedback.component";
import { useTranslation } from "react-i18next";
import { SvgAssets } from "../../../../components/icon/assets";
import Icon from "../../../../components/icon/icon";

export const JoinDoneScreen: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="h-screen w-screen flex flex-col">
      <FeedbackComponent
        title={t("Congratulations!")}
        icon={<Icon svg={SvgAssets.success} width={80} height={80} />}
        description={t("Join Done")}
      />
    </div>
  );
};
