import { getI18n } from "react-i18next";
import { SurveyError } from "survey-core";
export class ExceedSizeError extends SurveyError {
  constructor(private maxSize: number, errorOwner: any = null) {
    super(undefined, errorOwner);
    this.locText.text = this.getText();
  }
  public getErrorType(): string {
    return "exceedsize";
  }
  public getDefaultText(): string {
    return getI18n().t("onboarding.selected_files_exceeds_size_limit", {
      limit: this.getTextSize(),
    });
  }
  private getTextSize() {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    var fixed = [0, 0, 2, 3, 3];
    if (this.maxSize === 0) {
      return "0 Byte";
    }
    var i = Math.floor(Math.log(this.maxSize) / Math.log(1024));
    var value = this.maxSize / Math.pow(1024, i);
    return value.toFixed(fixed[i]) + " " + sizes[i];
  }
}
