import React, { CSSProperties } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";
// mui start
import { createTheme, ListItemText, ThemeProvider } from "@mui/material";
// mui end

import Svg404 from "../../../../../components/error-state/no_content.svg";
import { getIconSourceByType, IconType } from "../file/get-file-icon";
import downloadImage from "../file/image/download.png";
import { CustomType } from "../survey/survey.util";
import { getI18n } from "react-i18next";
import { styles } from "./download.style";

const CUSTOM_TYPE = CustomType.Download;

export class QuestionDownloadModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get sources() {
    return this.getPropertyValue("sources");
  }
  set sources(val) {
    this.setPropertyValue("sources", val);
  }
  get applicationId() {
    return this.getPropertyValue("applicationId");
  }
  set applicationId(val) {
    this.setPropertyValue("applicationId", val);
  }
}

//Add question type metadata for further serialization into JSON
export const initDownloadSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "sources",
        default: [],
      },
      {
        name: "applicationId",
        default: "",
      },
    ],
    function () {
      return new QuestionDownloadModel("");
    },
    "question",
  );
};

const theme = createTheme({});

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionDownloadModel(name);
});
// A class that renders questions of the new type in the UI
export class SurveyQuestionDownload extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.question.value,
      sources: this.question.sources,
    };
  }

  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get sources() {
    return this.question.sources;
  }
  get applicationId() {
    return this.question.applicationId;
  }

  get style() {
    return (
      this.question.getPropertyValue("readOnly") ? { pointerEvents: "none" } : undefined
    ) as CSSProperties;
  }

  async handleClick(item: any) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        action: "saveFile",
        payload: { resourceId: item.resourceId, fileName: item?.fileName },
      }),
    );
  }

  renderElement() {
    return (
      <ThemeProvider theme={theme}>
        <div
          style={{
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            padding: "8px 8px",
            flexFlow: "wrap",
            borderRadius: 12,
          }}
        >
          {this.state.sources ? (
            this.state.sources?.map((item: any, index: number) => {
              return (
                <div key={index} style={styles.documentItem}>
                  <div style={styles.documentImgContain}>
                    <img
                      style={styles.img}
                      src={getIconSourceByType(IconType.PDF)}
                      alt="img-icon"
                    ></img>
                    <ListItemText
                      primary={item?.fileName}
                      primaryTypographyProps={{
                        noWrap: true,
                        textOverflow: "ellipsis",
                        style: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        },
                      }}
                    />
                  </div>
                  <img
                    style={styles.img}
                    src={downloadImage}
                    alt="img-icon"
                    onClick={() => this.handleClick(item)}
                  />
                </div>
              );
            })
          ) : (
            <div className="flex flex-1 flex-col items-center justify-center  gap-y-2 px-4">
              <img src={Svg404} width={110} height={110} alt="expired" />
              <span className="text-[14px] text-center">No Content</span>
              <span className="text-[12px] font-semibold text-[#999] text-center">
                {getI18n().t("onboarding.complete_previous_modules_to_view_this_section")}
              </span>
            </div>
          )}
        </div>
      </ThemeProvider>
    );
  }
}
