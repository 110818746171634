import React, { memo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Checkbox, FormControlLabel } from "@mui/material";
import i18next from "i18next";
// import envConfig from "../../env-config";
import { convertLanguageKey } from "../../utils/lang";
import { useStyles } from "./lang-option.styles";
import { setItem } from "../../utils/local-storage";
import { get } from "lodash";

// const REGION_LANGUAGE: string[] = envConfig.REGION_LANGUAGE?.split(","); // "zh-Hant,en",
const REGION_LANGUAGE: string[] = get(window, "envConfig.REGION_LANGUAGE", "").split(",");
const switchMargin = { margin: 0 }; // PCAAEB-30180
interface Props {}
const LangOption: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang).then(() => {
      const queryParams = new URLSearchParams(window.location.search);
      // Set new or modify existing parameter value.
      queryParams.set("lang", lang);
      setItem("i18nextLng", lang); // save user selected lang
      // set lang to url, scenarios: refresh/bookmark/save-url/share
      navigate(location.pathname + "?" + queryParams.toString());
    });
  };
  if (REGION_LANGUAGE.length < 2) {
    return null; // no secondary language
  }
  return (
    <div id="language-switch" className={classes.switchContainer}>
      {REGION_LANGUAGE.map((locale) => {
        locale = convertLanguageKey(locale);
        const checked = locale === i18next.language;
        return (
          <FormControlLabel
            key={locale}
            style={switchMargin}
            className={`${classes.switch} ${
              checked ? classes.switchChecked : classes.switchUnchecked
            }`}
            control={
              <Checkbox
                className={classes.langCheckBox}
                checked={checked}
                onChange={(e) => {
                  if (e.target.checked) {
                    changeLanguage(locale);
                  }
                }}
              />
            }
            label={<span className={classes.switchLabel}>{i18next.t("lang." + locale)}</span>}
          />
        );
      })}
    </div>
  );
};
export const LangOptionComponent = memo(LangOption);
