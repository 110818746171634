import { lang } from "moment";
import { PruToast } from "../../../../components/pru-toast";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";

import { getIdTypeOptions } from "../../../../utils/common-utils";

export enum EditMode {
  EditByInvitation = "editbyinvitation",
  EditBaseOnProfile = "editbaseonprofile",
  NewWithInvitation = "newwithinvitation",
  NewWithLoginName = "newwithloginname",
}
interface HandleInvitationResParams {
  res: any;
  recruitPublicConfigRes: any;
  globalConfigRes: any;
  applicationDetails: any;
  query: any;
  setInvitationType: (type: string) => void;
  setForm: (form: any) => void;
  setInvitations: (invitations: any[]) => void;
  formValues: any;
  t: (key: string) => string;
  navigate: (path: string, state?: any) => void;
  location: any;
  elements: any[];
  editMode: EditMode;
}

export const handleInvitationRes = ({
  res,
  recruitPublicConfigRes,
  globalConfigRes,
  applicationDetails,
  query,
  setInvitationType,
  setForm,
  formValues,
  t,
  navigate,
  location,
  elements,
  editMode,
  setInvitations,
}: HandleInvitationResParams) => {
  if (
    !applicationDetails &&
    recruitPublicConfigRes.Recruitment?.candidateProfile?.enableApplicationDetail &&
    [EditMode.NewWithInvitation, EditMode.NewWithLoginName].includes(editMode)
  ) {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/application-detail?${query.toString()}`, {
      state: { ...location.state, from: location.pathname },
    });
    return;
  }
  if ([EditMode.NewWithInvitation, EditMode.EditByInvitation].includes(editMode)) {
    const {
      submitted,
      expired,
      deleted,
      phone,
      firstname,
      lastname,
      agentCode,
      gender,
      birthday,
      identityDocumentType: idType,
      identityDocumentNumber: idNum,
      email,
      invitationType = "new",
      languagePreference,
      languagePreferenceOps = '[{"code":"zh-Hant","name":"繁體中文","fallBackCode":"zh-Hant"},{"code":"en","name":"English","fallBackCode":"zh-Hant"}]',
    } = res?.data || res || {};
    setInvitationType(invitationType);

    if (submitted) {
      return navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/join-done`);
    } else if (expired) {
      return navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/join-expired`);
    } else if (deleted) {
      return navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/invitation-cancelled`);
    }
    if (!phone) {
      PruToast({
        message: t("Invalid Phone Number"),
      });
      return;
    }
    const languagePreferenceField: any = elements.find(
      (item) => item.name === "languagePreference",
    );
    const languagePreferenceOptions = JSON.parse(languagePreferenceOps);
    const choicesFromApi = languagePreferenceOptions.map((opt: any) => ({
      value: opt.code,
      text: opt.name,
    }));
    languagePreferenceField.choices = choicesFromApi.concat(
      languagePreferenceField.choices.filter(
        (choice: { value: any }) =>
          !choicesFromApi.find((_choice: { value: any }) => _choice.value === choice.value),
      ),
    );
    const arrPhone = phone.split(" ");
    arrPhone.length = 2; // avoid out of range exception when using arrPhone[1]

    if (invitationType === "edit") {
      setForm({
        firstname,
        lastname,
        gender,
        birthday,
        email,
        regionCode: arrPhone[0],
        phoneNumber: arrPhone[1],
        languagePreference,
        agentCode,
        idType,
        idNum,
      });
    } else {
      setForm({
        ...formValues,
        agentCode,
        firstname,
        lastname,
        gender,
        birthday,
        idType: idType ?? getIdTypeOptions(globalConfigRes.data?.content?.region?.idType)?.default,
        idNum,
        email,
        regionCode: arrPhone[0],
        phoneNumber: arrPhone[1],
        languagePreference,
      });
    }
  } else if (editMode === EditMode.NewWithLoginName) {
    const data = res?.data ?? [];
    const theFirstItem = data[0];
    const arrPhone = theFirstItem.phone.split(" ");
    setForm({
      ...formValues,
      agentCode: theFirstItem.agentCode,
      firstname: theFirstItem.firstname,
      lastname: theFirstItem.lastname,
      email: theFirstItem.email,
      idType: getIdTypeOptions(globalConfigRes.data?.content?.region?.idType)?.default,
      regionCode: arrPhone[0],
      phoneNumber: arrPhone[1],
      languagePreference: theFirstItem.languagePreference,
    });
    setInvitations(
      data.map((item: any) => {
        return {
          label: `${item.agentCode}, ${item.agentName.enUs}`,
          value: item.id,
          agentCode: item.agentCode,
          firstName: item.firstname,
          lastName: item.lastname,
          email: item.email,
        };
      }),
    );
  }
};
