import * as React from "react";
import { Dialog } from "@mui/material";
import { ModalContent } from "./modal-content";
import { ModalProviderProps } from "./modal.type";
import { ModalsContext, useModalsContext, useModalsValue } from "./use-modal";
import { merge } from "lodash";
import { useScreenWidth } from "../../utils/hooks/use-screen-width";
import "./modal.css";

const Modals = ({ index = 0 }: ModalProviderProps) => {
  const { modals, closeModal, context } = useModalsContext();
  const screenWidth = useScreenWidth();
  const modal = modals[index];
  const modalsLength = modals.length;
  const hasNextModal = modalsLength > index + 1;
  const { backdropClose = true, modalProps = {}, modalType } = modal || {};
  return (
    <>
      {modalsLength ? (
        <Dialog
          open
          {...modalProps}
          PaperProps={merge(
            {
              style: {
                margin: 0,
                borderWidth: 0,
                borderRadius: "16px",
                width: screenWidth - 56 + "px",
                ...(modalType === "bottom"
                  ? {
                      alignSelf: "end",
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }
                  : {}),
              },
            },
            modalProps?.PaperProps,
          )}
          onClose={(event, reason) => {
            if (reason === "backdropClick" && backdropClose === false) return;
            closeModal(modal.id);
          }}>
          {hasNextModal ? <Modals index={index + 1} /> : context}
          {modal.visible && <ModalContent {...modal} />}
        </Dialog>
      ) : null}
    </>
  );
};

export const UseModalProvider = ({ children, context = null }: any) => {
  const modalsValue = useModalsValue();

  return (
    <>
      <ModalsContext.Provider
        value={{
          ...modalsValue,
          context,
        }}>
        {children}
        <Modals />
      </ModalsContext.Provider>
    </>
  );
};
