import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRequest } from "ahooks";
import { confirm } from "../../../../components/modal/comfirm";
import { PruToast } from "../../../../components/pru-toast";
import { cancelRegistration } from "../../network/event-detail-crud";
import { CancelRegistrationResponseCode } from "../../network/type";
import { TakeUIClickEventParameters } from "../../../../common/ga/types/ga-general-type";
import { takeUIClickEvent } from "../../../../common/ga/ga";
import { openFromApp } from "../../util/event.util";

interface HookProps {
  qrCode: string;
  openPromptModal: () => void;
  isAgent?: boolean;
}

export const useParticipationQrCode = ({ qrCode, openPromptModal, isAgent }: HookProps) => {
  const { t } = useTranslation();

  const { runAsync } = useRequest(() => cancelRegistration(qrCode), {
    manual: true,
  });

  const handleClick = useCallback(() => {
    const gaData: TakeUIClickEventParameters = openFromApp()
      ? isAgent
        ? {
            module: "Events",
            feature: "RSVP Management",
            screen_name: "My QR Code",
            journey: "view_my_RSVP",
            stage: "cancel_my_RSVP",
            object_name: "CancelRegistration",
            object_type: "BUTTON",
          }
        : {
            module: "Events",
            feature: "RSVP Management",
            screen_name: "Participation QR Code",
            journey: "view_prospect_rsvp",
            stage: "cancel_participant_rsvp",
            object_name: "CancelRegistration",
            object_type: "BUTTON",
          }
      : {
          journey: "event_rsvp",
          stage: "cancel_RSVP",
          object_name: "CancelRegistration",
          object_type: "BUTTON",
        };
    takeUIClickEvent(gaData);

    confirm({
      title: t("event.cancel_registration"),
      message: t("event.cancel_registration_modal_desc"),
    }).then(({ ok }) => {
      if (ok) {
        runAsync()
          .then((res) => {
            if (res?.data?.code === CancelRegistrationResponseCode.AGENT_REQUIRED) {
              openPromptModal();
            } else {
              PruToast({
                status: "success",
                message: t("event.successfully_cancelled_message"),
              });
              window.location.reload();
            }
          })
          .catch((error) => {
            PruToast({
              status: "error",
              message: t("event.cancel_failed_message"),
            });
          });
      }
    });
  }, [isAgent, openPromptModal, runAsync, t]);

  return {
    handleClick,
  };
};
