import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { constants } from "../../../routes/constants";
import { formatCountryCode } from "../../../components/surveyjs/survey/survey.util";
import { getI18n } from "react-i18next";
import { SurveyFormData } from "../pages/survey-form/survey-form-screen";
import { SvgAssets } from "../../../components/icon/assets";
import { PromptModalProps } from "../../../components/modal/prompt";
import { Code, EventType, Session } from "../network/type";

/**
 * @description format event time
 * @param startTime
 * @param endTime
 * @returns ep: 31/10/2023 15:00-18:00 or 22/10/2023 - 23/10/2023
 */
export const formatEventTime = (startTime: string, endTime: string) => {
  return dayjs(startTime).format(constants.Default_Date_Format) ===
    dayjs(endTime).format(constants.Default_Date_Format)
    ? `${dayjs(startTime).format(constants.Date_Minute_Scale_Format)}-${dayjs(endTime).format(
        constants.Time_Format,
      )}`
    : `${dayjs(startTime).format(constants.Default_Date_Format)}-${dayjs(endTime).format(
        constants.Default_Date_Format,
      )}`;
};

/**
 * @description format event registration period
 * @param regStartDate
 * @param regEndDate
 * @returns ep: 31/10/2023 09:00-03/11/2023 20:00
 */
export const formatEventRegistrationDate = (regStartDate: string, regEndDate: string) => {
  return `${dayjs(regStartDate).format(constants.Date_Minute_Scale_Format)}-${dayjs(
    regEndDate,
  ).format(constants.Date_Minute_Scale_Format)}`;
};

export const decodeEventSearchParams = (searchParams: URLSearchParams) => {
  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const phone = searchParams.get("phone");
  const email = searchParams.get("email");

  const decodedFirstName = firstName ? decodeURIComponent(firstName) : "";
  const decodedLastName = lastName ? decodeURIComponent(lastName) : "";
  const decodedPhone = phone ? decodeURIComponent(phone) : "";
  const decodedEmail = email ? decodeURIComponent(email) : "";

  const { countryCode, phoneNumber } = formatAgentPhone(decodedPhone);

  return {
    firstName: decodedFirstName,
    lastName: decodedLastName,
    countryCode,
    phoneNumber,
    email: decodedEmail,
  };
};

/**
 * @description format agent phone number
 * @param phone eg "+852 32424324" "32424324"
 * @returns ep: {countryCode: "+852", phoneNumber: "32424324"} {countryCode: "", phoneNumber: "32424324"}
 */
export const formatAgentPhone = (phone: string) => {
  const regex = /^(\+\d+\s)?(.+)$/;
  const match = phone.match(regex);

  if (match) {
    const countryCode = match[1] ? match[1].trim() : "";
    const phoneNumber = match[2].trim();

    return { countryCode, phoneNumber };
  } else {
    return { countryCode: "", phoneNumber: "" };
  }
};

const setElementProperty = (data: any[], name: string, property: string, value: any) => {
  if (!value || !data) return;
  for (const item of data) {
    if (item.name === name) {
      item[property] = value;
      return;
    } else if (item.elements) {
      setElementProperty(item.elements, name, property, value);
    }
  }
};

const findElement = (data: any[], name: string) => {
  if (!data) return;
  for (const item of data) {
    if (item.name === name) {
      return item;
    } else if (item.elements) {
      const foundItem: any = findElement(item.elements, name);
      if (foundItem) {
        return foundItem;
      }
    }
  }
  return null;
};

export const formatSurveyJson = ({
  surveyData,
  searchParams,
  region,
  dropdownConfig,
}: {
  surveyData: SurveyFormData;
  searchParams: URLSearchParams;
  region: any;
  dropdownConfig?: any;
}) => {
  const surveyDataCopy = cloneDeep(surveyData);
  const { regFormBody, regFormResult } = surveyDataCopy;
  const { pages } = regFormBody;

  // add "(full)" text for full session on form page
  if (!regFormResult) {
    pages?.[0]?.elements?.forEach((item: any) => {
      if (item?.name.includes("session")) {
        item?.choices?.forEach((choice: any) => {
          if (choice.enableIf === "false") {
            Object.keys(choice.text).forEach((key) => {
              choice.text[key] += getI18n().t("event.full");
            });
          }
        });
      }
    });
  }

  const { email, phoneNumber, countryCode, firstName, lastName } =
    decodeEventSearchParams(searchParams);

  // set default survey field
  setElementProperty(pages, "registrant_firstName", "defaultValue", firstName);
  setElementProperty(pages, "registrant_lastName", "defaultValue", lastName);
  setElementProperty(pages, "registrant_email", "defaultValue", email);

  const regionPhone = findElement(pages, "countryCode_phoneNumber");
  if (regionPhone && dropdownConfig) {
    const { value, defaultValue } = formatCountryCode(
      dropdownConfig.regionPhone,
      region?.toLocaleLowerCase(),
    );

    if (regFormResult?.registrant_countryCode) {
      // if return regFormResult, it means that the results page needs to be displayed, then set the value for country code on the results page.
      regFormResult.registrant_countryCode = value
        ?.filter((item) => item.key === regFormResult.registrant_countryCode)
        ?.map(({ key, label, regex }) => ({ key, regex }))?.[0];
    }

    const countryCodeDefaultValue = findElement(pages, "registrant_countryCode")?.defaultValue;
    const currentCountryCodeValue = value?.filter(
      (item) => item.key === countryCode || item.key === countryCodeDefaultValue,
    )?.[0];

    // set country code dropdown options
    setElementProperty(
      regFormBody.pages,
      "registrant_countryCode",
      "choices",
      value?.map(({ key, label, regex }) => ({ value: { key, regex }, text: key })),
    );
    setElementProperty(regFormBody.pages, "registrant_countryCode", "defaultValue", {
      key: currentCountryCodeValue?.key || defaultValue?.key,
      regex: currentCountryCodeValue?.regex || defaultValue?.regex,
    });
    setElementProperty(regFormBody.pages, "registrant_phoneNumber", "defaultValue", phoneNumber);
  }

  regFormBody.showPreviewBeforeComplete = "off";
  return { regFormBody, regFormResult };
};

export enum UnavailableType {
  NOT_OPEN_YET = "NOT_OPEN_YET",
  REGISTRATION_CLOSE = "REGISTRATION_CLOSE",
  EVENT_FULL = "EVENT_FULL",
  SESSION_FULL = "SESSION_FULL",
  AGENT_REQUIRED = "AGENT_REQUIRED",
  INVALID_AGENT = "INVALID_AGENT",
  REGISTER_DUPLICATED = "REGISTER_DUPLICATED",
  WALKIN_REGISTER_DUPLICATED = "WALKIN_REGISTER_DUPLICATED",
  AGENT_QUOTA_FULL = "AGENT_QUOTA_FULL",
}

export const getUnavailableModalConfig = (type: UnavailableType): PromptModalProps | undefined => {
  const title = getI18n().t("event.registration_unavailable_modal_title");

  switch (type) {
    case UnavailableType.NOT_OPEN_YET:
      return {
        title,
        message: getI18n().t("event.not_open_registration_unavailable_modal_desc"),
      };
    case UnavailableType.REGISTRATION_CLOSE:
      return {
        title,
        message: getI18n().t("event.registration_close_registration_unavailable_modal_desc"),
      };
    case UnavailableType.AGENT_QUOTA_FULL:
    case UnavailableType.EVENT_FULL:
      return {
        title,
        message: getI18n().t("event.event_full_registration_unavailable_modal_desc"),
      };
    case UnavailableType.SESSION_FULL:
      return {
        icon: SvgAssets.failure,
        title: getI18n().t("event.sorry"),
        message: getI18n().t("event.event_session_full_modal_desc"),
        confirmButtonText: getI18n().t("global.text.confirm"),
        cancelButtonText: getI18n().t("cancel"),
      };
    case UnavailableType.INVALID_AGENT:
      return {
        icon: SvgAssets.failure,
        title: getI18n().t("event.error"),
        message: getI18n().t("event.invalid_agent_registration_unavailable_modal_desc"),
      };
    case UnavailableType.WALKIN_REGISTER_DUPLICATED:
    case UnavailableType.REGISTER_DUPLICATED:
      return {
        icon: SvgAssets.notice,
        title: getI18n().t("event.attention"),
        message: getI18n().t("event.registered_duplicated_registration_unavailable_modal_desc"),
        confirmButtonText: getI18n().t("event.check_in"),
        cancelButtonText: getI18n().t("cancel"),
      };
    default:
      return;
  }
};

export const getEventType = (type: EventType) => {
  let curType = "";

  switch (type) {
    case EventType.PHYSICAL:
      curType = getI18n().t("event.physical");
      break;
    case EventType.VIRTUAL:
      curType = getI18n().t("event.virtual");
      break;
    case EventType.HYBRID:
      curType = getI18n().t("event.hybrid");
      break;
    default:
      break;
  }

  return curType;
};

export const openFromApp = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("type") === "app";
};

export const getTitleByCode = (code: Code) => {
  let title = "";

  switch (code) {
    case Code.INVALID_LINK:
      title = getI18n().t("event.invalid_link");
      break;
    case Code.EVENT_EXPIRED:
      title = getI18n().t("event.event_finished");
      break;
    case Code.EVENT_CANCELLED:
      title = getI18n().t("event.event_cancelled");
      break;
    default:
      break;
  }

  return title;
};

export const sessionFilter = (sessionId: string, data?: Session[]): Session | undefined => {
  for (const item of data || []) {
    if (item._id === sessionId) {
      return item;
    }
    if (item.subSessions) {
      const foundItem = sessionFilter(sessionId, item.subSessions);
      if (foundItem) {
        return foundItem;
      }
    }
  }

  return undefined;
};
