interface EventImage {
  blobId: string;
  filename: string;
  url: string;
}

export enum ACCESSIBILITY_TYPE {
  INTERNAL = "INTERNAL",
  PUBLIC = "PUBLIC",
}

export enum EVENT_STATUS {
  ONGOING = "ONGOING",
  UPCOMING = "UPCOMING",
  PAST = "PAST",
}

export interface Attachment {
  blobId: string;
  filename: string;
  url: string;
}

export enum REG_ATTENDANCE_RULE {
  CHECK_IN_OUT = "CHECK_IN_OUT",
  CHECK_IN = "CHECK_IN",
}

type SubSessions = Omit<Session, "subSessions">;

export enum RegNotificationMethod {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export interface Session {
  _id: string;
  key: string;
  name: { [locale: string]: string };
  venue: { [locale: string]: string };
  startTime: string;
  endTime: string;
  subSessions?: SubSessions[];
  label?: EventLabel;
  regAttendanceRule?: REG_ATTENDANCE_RULE;
  regAllowWalkIn?: boolean;
  quota?: string;
}

export enum AudienceType {
  RECRUITMENT_PROSPECT = "RECRUITMENT_PROSPECT",
  SALES_PROSPECT = "SALES_PROSPECT",
  AGENT = "AGENT",
}

export enum EventType {
  PHYSICAL = "PHYSICAL",
  VIRTUAL = "VIRTUAL",
  HYBRID = "HYBRID",
}

export enum PartyType {
  RECRUITMENT_PROSPECT = "RECRUITMENT_PROSPECT",
  SALES_PROSPECT = "SALES_PROSPECT",
  AGENT = "AGENT",
  ANONYMOUS = "ANONYMOUS",
}

export enum RegMethodType {
  LINK = "LINK",
  TEMPLATE = "TEMPLATE",
}

export enum EventLabel {
  OPEN_FOR_REGISTRATION = "OPEN_FOR_REGISTRATION",
  CAN_WALK_IN = "CAN_WALK_IN", // registration has closed or quota is full, event or any session allows walk-in
}
export enum Code {
  SUCCESS = "SUCCESS",
  INVALID_LINK = "INVALID_LINK",
  EVENT_EXPIRED = "EVENT_EXPIRED",
  EVENT_CANCELLED = "EVENT_CANCELLED",
}

export enum EventHost {
  COMPANY = "COMPANY",
  AGENCY = "AGENCY",
}

export enum EventDescriptionTypeEnum {
  TEMPLATE = "TEMPLATE",
  UPLOAD = "UPLOAD",
  NO_TEMPLATE = "NO_TEMPLATE",
}

export enum EventImageTypeEnum {
  TEMPLATE = "TEMPLATE",
  UPLOAD = "UPLOAD",
}

export interface EventDetail {
  _id: string;
  category: EventCategory;
  eventStatus: EVENT_STATUS;
  type: EventType;
  remainingRegQuota: number;
  regAllowWalkIn?: boolean;
  agentRequired: boolean;
  shareToPublic: boolean;
  descriptionType?: { [locale: string]: EventDescriptionTypeEnum };
  description?: { [locale: string]: string };
  descriptionImage?: { [locale: string]: EventImage };
  eventImageType?: { [locale: string]: EventImageTypeEnum };
  eventImage?: { [locale: string]: EventImage };
  selectedEventImage?: { [locale: string]: EventImage };
  name: { [locale: string]: string };
  tags: { [locale: string]: string[] };
  organizer?: { [locale: string]: string };
  eventDate: string;
  startTime: string;
  endTime: string;
  venue: { [locale: string]: string };
  regStartDate: string;
  regEndDate: string;
  attachments?: { [locale: string]: Attachment[] };
  sessions?: Session[];
  registered: boolean; // whether prospect/candidate is registered
  isOverallQuotaFull: boolean;
  regMethod?: RegMethodType;
  regLink?: string;
  label?: EventLabel;
  multiSession?: boolean;
  regNotificationMethod?: RegNotificationMethod;
  quota?: string;
}

export enum RegistrationResponseCode {
  EVENT_FULL = "EVENT_FULL",
  SESSION_FULL = "SESSION_FULL",
  AGENT_QUOTA_FULL = "AGENT_QUOTA_FULL",
  REGISTER_DUPLICATED = "REGISTER_DUPLICATED",
  WALKIN_REGISTER_DUPLICATED = "WALKIN_REGISTER_DUPLICATED",
  EVENT_ERROR = "EVENT_ERROR",
  SUCCESS = "SUCCESS",
  INVALID_AGENT = "INVALID_AGENT",
}

export enum CancelRegistrationResponseCode {
  AGENT_REQUIRED = "AGENT_REQUIRED",
  CANCEL_ERROR = "CANCEL_ERROR",
}

export enum RegisterStatus {
  REGISTERED = "REGISTERED",
  CANCELLED = "CANCELLED",
}

interface EventCategory {
  _id: string;
  accessibility: ACCESSIBILITY_TYPE;
  name: { [locale: string]: string };
  audience?: AudienceType;
  createAt: string;
  host: EventHost[];
  lastUpdatedBy: string;
  status: string;
  updatedAt: string;
}

export interface AgendaSession extends Session {
  index: number;
  subIndex?: number;
}

export enum RegistrationTypeEnum {
  RSVP = "RSVP",
  WALKIN = "WALKIN",
}

export interface EventQRcodeDetail {
  agentCode: string;
  category: EventCategory;
  eventDate: string;
  startTime: string;
  endTime: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isAttended: boolean;
  name: { [locale: string]: string };
  qrCode: string;
  sessions?: AgendaSession[];
  status: RegisterStatus;
  type: EventType;
  venue: { [locale: string]: string };
  party: PartyType;
  expiredTime: string;
  registrationType: RegistrationTypeEnum;
}

export interface ParticipantType {
  firstName: string;
  lastName: string;
  qrCode: string;
}

export interface ParticipantsType {
  participants: Array<ParticipantType>;
}

export enum ParticipantInfoKeyType {
  EMAIL = "email",
  PHONE = "phone",
  QR_CODES = "qrCodes",
  AGENT_CODE = "agentCode",
}

export interface ValidateInfoReqType {
  sessionId: string;
  email?: string;
  countryCode?: string;
  phone?: string;
  qrCodes?: string[];
  agentCode?: string;
  action: CheckAction;
}

export enum CheckAction {
  CHECK_IN = "CHECK_IN",
  CHECK_OUT = "CHECK_OUT",
}

export interface SubmitParticipantsInfoReqType {
  sessionId: string;
  qrCodes?: string[];
  registrationIds?: string[]; // for getting check in information
  action: CheckAction; // can be CHECK_IN or CHECK_OUT
}

export interface CheckInfoType {
  registrationId: string;
  firstName: string;
  lastName?: string;
  referredAgentCode?: string;
  referredAgentName?: string;
  referredAgentPhone?: string;
  checkInAt: string;
  checkOutAt?: string;
  agentCode?: string;
}

export enum SubmitParticipantsInfoCodeEnum {
  ALREADY_CHECKED_IN = "ALREADY_CHECKED_IN",
  ALREADY_CHECKED_OUT = "ALREADY_CHECKED_OUT",
  CHECK_IN_NOT_EXIST = "CHECK_IN_NOT_EXIST",
  NO_ATTENDEE_FOUND = "NO_ATTENDEE_FOUND",
  SUCCESS = "SUCCESS",
}
export interface SubmitParticipantsInfoResType {
  code: SubmitParticipantsInfoCodeEnum;
  message: string;
  data: Array<CheckInfoType>;
}

export enum RegistrationFormType {
  RSVP = "RSVP",
  WALKIN = "WALKIN",
}
export interface RegistrationFormReqType {
  shortenCode: string;
  registrationId?: string;
  type?: RegistrationFormType;
  sessionId?: string;
}

export interface SubmitRegistrationFormReqType {
  shortenCode: string;
  type?: RegistrationFormType;
  sessionId?: string;
  surveyResultBody: any;
}

export type SessionCheckDataType = Record<string, { qrCodes: string[]; registrationIds: string[] }>;
