import React, { FC } from "react";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles/award-list.styles";
import { AwardItem } from "../types/agent-profile-types";

type AwardListProps = {
  mappedLocale: string;
  badges: AwardItem[];
  awards: AwardItem[];
  multiColumn?: boolean;
};

const AwardList: FC<AwardListProps> = ({ mappedLocale, badges, awards, multiColumn }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getYearUnit = (year: number) => {
    if (year > 1) {
      return t("agentProfile.pp_years_label");
    } else {
      return t("agentProfile.pp_year_label");
    }
  };

  return (
    <div>
      <div className={classes.badgeListContainer}>
        {badges.map((badge) =>
          badge?.image ? <img src={badge.image} alt="" className={classes.badge} /> : <></>,
        )}
      </div>
      {!multiColumn ? (
        <div>
          {awards.map((award) =>
            award ? (
              <>
                <Divider className={classes.bottomSpacing} />
                <div className={classes.bottomSpacing}>
                  <div className={classes.awardTitle}>{award.awardName[mappedLocale] || ""}</div>
                  {award.awardYear && award.awardYear !== "" && (
                    <div className={classes.caption}>
                      {`${award.awardYear} ${
                        award.isGroupedYear ? getYearUnit(Number(award.awardYear)) : ""
                      }`}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            ),
          )}
        </div>
      ) : (
        <div className={classes.multiColumnAwardListContainer}>
          {awards.map((award, index) =>
            award ? (
              <>
                {index % 3 === 0 && <Divider className={classes.horizontalDivider} />}
                {index % 3 !== 0 && (
                  <Divider className={classes.verticalDivider} orientation="vertical" />
                )}
                <div className={classes.multiColumnAwardItemContainer}>
                  <div className={classes.awardTitle}>{award.awardName[mappedLocale] || ""}</div>
                  {award.awardYear && award.awardYear !== "" && (
                    <div className={classes.caption}>
                      {`${award.awardYear} ${
                        award.isGroupedYear ? getYearUnit(Number(award.awardYear)) : ""
                      }`}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default AwardList;
