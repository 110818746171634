export function setCookie(name: string, value: string, days?: number) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
export function getCookie(name: string) {
  let arr;
  const reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) {
    return unescape(arr[2]);
  } else {
    return null;
  }
  // var nameEQ = name + "=";
  // var ca = document.cookie.split(";");
  // for (var i = 0; i < ca.length; i++) {
  //   var c = ca[i];
  //   while (c.charAt(0) == " ") c = c.substring(1, c.length);
  //   if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  // }
  // return null;
}
export function eraseCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

/**
 * To check whether a setting a cookie is enabled in the browser,
 * @returns
 */
export function canUseCookie(): boolean {
  let cookieEnabled = false;
  try {
    cookieEnabled = window.navigator.cookieEnabled;
  } catch (error) {
    console.log("can't read cookie setting", error);
  }
  return cookieEnabled;
}
