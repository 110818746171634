import { get, find, findIndex, set } from "lodash";
import i18next from "i18next";
import { Marked } from "marked";

export type FieldObject = {
  [key: string]: unknown;
};

export enum CustomType {
  RegionPhone = "regionphone",
  Text = "text",
  Dropdown = "dropdown",
  DatePicker = "date-picker",
  Boolean = "boolean",
  Test = "color-picker",
  Tnc = "tnc",
}

export interface City {
  key: string;
  label: { en: string; local: string };
}
export interface Choices {
  value: string;
  text: I18nData;
  parentKey?: string;
  parentValue?: string;
}
export interface RegionPhoneType {
  key: string;
  region: any;
  number: string;
}
export interface AutoCompleteType {
  key: string;
  label: string;
  regex?: string;
  parentKey?: string; // in the city list: this is state
  parentValue?: string;
  type?: string; // survey element type
}
export interface I18nData {
  [key: string]: string | any;
}
/**
 * get i18nData by language
 * @param i18nData sample: { "zh-cn": "中文2", default: "en-item2"} or "item 1",
 * @param lang
 * @returns
 */
export function getI18nData(
  i18nData: I18nData | string | undefined,
  lang: string = i18next.language,
) {
  if (!i18nData) {
    return "";
  }
  if (typeof i18nData === "string") {
    return i18nData;
  }
  const defaultValue = i18nData?.default || i18nData?.en;
  if (lang === "en") {
    return defaultValue;
  }

  const arrKey = Object.keys(i18nData); // ['zh-cn', 'default', 'en']
  const filteredKey = arrKey.find((key) => key !== "default" && key !== "en");
  return filteredKey ? i18nData[filteredKey] : defaultValue;
}
export function formatDropdownOptions(arr: { value: string; text: any }[]) {
  return (arr || []).map((item) => {
    const i18nText: any = get(item, "locTextValue.values");
    return { key: item.value, label: getI18nData(i18nText, i18next.language) };
  });
}

export interface AutoCompleteType {
  key: string;
  label: string;
  regex?: string;
  parentKey?: string; // in the city list: this is state
  parentValue?: string;
}

export function getElementByName(jsonObj: any, name: string) {
  const arrElement =
    get(jsonObj, "pages[0].elements[0].elements") || get(jsonObj, "pages[0].elements");
  const elem = find(arrElement, { name: name });
  return elem;
}

export function setElementChoice(jsonObj: any, name: string, value: any, extra?: any) {
  const arrElement =
    get(jsonObj, "pages[0].elements[0].elements") || get(jsonObj, "pages[0].elements");
  const elemIndex = findIndex(arrElement, { name: name });

  if (elemIndex !== -1) {
    arrElement[elemIndex].choices = value;
    arrElement[elemIndex].extra = extra;
    return set(jsonObj, "pages[0].elements", arrElement);
  }
  return jsonObj;
}

export function setElementProps(jsonObj: any, name: string, value: any, targetProps: string) {
  const arrElement =
    get(jsonObj, "pages[0].elements[0].elements") || get(jsonObj, "pages[0].elements");
  const elemIndex = findIndex(arrElement, { name: name });

  if (elemIndex !== -1) {
    arrElement[elemIndex][targetProps] = value;
    return set(jsonObj, "pages[0].elements", arrElement);
  }
  return jsonObj;
}

export function formatCity(arr: any[]): Choices[] {
  if (!arr || arr.length < 1) {
    return [];
  }
  const arrResult = [] as Choices[];
  arr.forEach((item) => {
    const state = get(item, "state.key", "");
    let arrCity = get(item, "city", []);
    arrCity.forEach((city: City) => {
      const labelEn = get(city, "label.en");
      const labelLocal = get(city, "label.local", labelEn);
      arrResult.push({
        parentKey: "state",
        parentValue: state,
        value: city.key,
        text: {
          default: labelEn,
          local: labelLocal,
        },
      });
    });
  });
  return arrResult;
}

export function formatCountryCode(
  obj: {
    [key: string]: { regex: string; code: string; displayName: string };
  },
  region: string,
): { value?: AutoCompleteType[]; defaultValue?: AutoCompleteType } {
  if (!obj) {
    return { value: undefined, defaultValue: undefined };
  }
  const arr: AutoCompleteType[] = [];
  let defaultValue: AutoCompleteType | undefined = undefined;
  Object.getOwnPropertyNames(obj).forEach(function (key: any) {
    const elem = {
      regex: obj[key].regex,
      key: obj[key].code,
      label: obj[key].displayName,
    };
    arr.push(elem);
    if (!defaultValue && region === key) {
      defaultValue = elem;
    }
  });
  return { value: arr, defaultValue: defaultValue };
}

/**
 * convert current lang to survey locale (survey has diff Locale. e.g. zh-tw)
 * @param lang
 * @returns
 */
export function convert2SurveyLocale(lang: string): string {
  const langMapping = {
    en: "default",
    "en-US": "default",
    zhhk: "zh-tw",
    zhHk: "zh-tw",
    "zh-Hant": "zh-tw",
    "zh-Hant-TW": "zh-tw",
    id: "id", // indonesia
    my: "my",
    ph: "ph",
    km: "km",
  };
  // @ts-ignore
  return langMapping[lang] || "default";
}
/**
 * extract custom-component to plain json.
 * example
  source: {
    "firstName": "name",
    "isCustomer": false
    "phoneNumber": {
      "number":"42333331"
      "region": {"regex": "^([4-8]\\d{7}|9[0-8]\\d{6})$","key": "+852","label": "Hong Kong"}
    },
    "city": {"key": "Kowloon","parentKey": "state","parentValue": "Hong Kong"}
  }
  result: {
    "firstName": "name",
    "phoneNumber": "42333331",
    "countryCode": "+852",
    "state": "Hong Kong"
    "city": "Kowloon",
    "isCustomer": true }
 * @param data
 */
export function extractFormData(formData: any) {
  const result = { ...formData };
  for (const rootKey in formData) {
    const value: AutoCompleteType & RegionPhoneType = formData[rootKey] as any;
    if (value && typeof value === "object") {
      if (value.type === CustomType.Dropdown) {
        const { key, parentKey, parentValue } = value;
        result[rootKey] = key; // dropdown value
        if (parentKey) {
          result[parentKey] = parentValue;
        }
      } else if (value.type === CustomType.RegionPhone) {
        const { region, number } = value;
        result[rootKey] = number;
        result.countryCode = region?.key;
      }
    }
  }
  return result;
}

export const customMarked = new Marked({
  renderer: {
    link: function ({ href, title, text }) {
      return `<a href="${href}" target="_blank">${text}</a>`;
    },
  },
});
