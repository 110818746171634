import { get } from "lodash";
import { ModuleSection, StatusEnum } from "./type";
import store from "../../../../redux/store";
import { OnboardingTrainingPlatform } from "../course-menu-screen/type";

export const getTrainingCourseModuleStatus = (
  trainingSection: ModuleSection,
  isMenuFormCompleted?: boolean,
  dependent?: boolean,
) => {
  const trainingPlatform = get(
    store.getState(),
    "onboarding.configs.Recruitment.trainingPlatform",
    OnboardingTrainingPlatform.COE,
  );
  const isCourseCompleted =
    trainingPlatform === OnboardingTrainingPlatform.COE
      ? trainingSection && trainingSection.numerator! >= trainingSection.denominator!
      : false;
  if (isCourseCompleted) {
    return StatusEnum.completed;
  }
  if (dependent) {
    return StatusEnum.untouched;
  } else {
    if (isMenuFormCompleted) {
      return StatusEnum.untouched;
    }
  }
  if (isMenuFormCompleted && !dependent) {
    return StatusEnum.untouched;
  }
  return StatusEnum.disabled;
};

export const getTrainingExamModuleStatus = (
  trainingSection: ModuleSection,
  examSection: ModuleSection,
  dependent?: boolean,
) => {
  const isExamCompleted = examSection && examSection.numerator! >= examSection.denominator!;
  const isCourseCompleted =
    trainingSection && trainingSection.numerator! >= trainingSection.denominator!;
  if (isExamCompleted) {
    if (isCourseCompleted) {
      return StatusEnum.completed;
    } else {
      return StatusEnum.completedButDisabled;
    }
  }
  if (dependent) {
    return StatusEnum.untouched;
  } else {
    if (isCourseCompleted) {
      return StatusEnum.untouched;
    }
  }
  return StatusEnum.disabled;
};
