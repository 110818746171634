import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "../../../../layout/layout";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  fetchEventDetailByIdAsync,
  fetchEventQRcodeDetailByIdAsync,
} from "../../redux/event-slice";
import { ErrorScreen } from "../../../../components/error-screen/error-screen";
import { ErrorView } from "../../components/error-view";
import "./detail-screen.css";
import { Header } from "../../components/header";
import { EventDetail } from "../../components/event-detail";
import { ParticipationQrCodeComponent } from "../../components/participation-qr-code/participation-qr-code.component";
import { openFromApp } from "../../util/event.util";
import { takeUIScreenViewEvent } from "../../../../common/ga/ga";
import { GAData, updateGAData } from "../../../../common/ga/redux/ga-slice";
import { useTranslation } from "react-i18next";

enum PageType {
  QR_CODE = "QR_CODE",
  DETAIL_FORM = "DETAIL_FORM",
}

export const DetailScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { refId = "" } = useParams();
  const { eventDetailData, eventQRcodeData, eventError, loading } = useAppSelector(
    (state) => state.event,
  );
  const { hashEntry } = useAppSelector((state) => state.common);

  const isFromApp = useMemo(() => openFromApp(), []);

  useEffect(() => {
    if (isFromApp || !hashEntry?.data?.type) return;
    const gaData: GAData =
      hashEntry?.data?.type === PageType.QR_CODE
        ? {
            feature: "Events(Web)",
            module: "Events",
            journey: "event_rsvp",
            stage: "view_qr_code",
            screen_name: "QR-Code-Screen",
          }
        : {
            feature: "Events(Web)",
            module: "Events",
            journey: "event_rsvp",
            stage: "view_event_details",
            screen_name: "Event-Detail-Screen",
          };
    dispatch(updateGAData(gaData));
    takeUIScreenViewEvent(gaData);
  }, [dispatch, hashEntry?.data?.type, isFromApp]);

  useEffect(() => {
    if (hashEntry?.data?.type) {
      if (hashEntry?.data?.type === PageType.QR_CODE) {
        dispatch<any>(fetchEventQRcodeDetailByIdAsync(refId));
      } else {
        dispatch<any>(fetchEventDetailByIdAsync(refId));
      }
    }
  }, [dispatch, hashEntry?.data?.type, refId]);

  return (
    <Layout
      disableLanguageButton={isFromApp}
      disableTnc={hashEntry?.data?.type !== PageType.DETAIL_FORM}
    >
      {/* Header */}
      {!isFromApp && <Header />}

      {eventError &&
        (String(eventError.statusCode) === "404" ? (
          <ErrorView title={t("event.event_expired")} />
        ) : (
          <ErrorScreen statusCode={eventError.statusCode} />
        ))}

      {!loading && hashEntry?.data?.type
        ? hashEntry?.data?.type === PageType.QR_CODE
          ? eventQRcodeData && <ParticipationQrCodeComponent />
          : eventDetailData && <EventDetail />
        : null}
    </Layout>
  );
};
