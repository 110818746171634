import React, { CSSProperties } from "react";
import { ElementFactory, Question, Serializer, FunctionFactory } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";
// mui start
import { TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
// mui end

import * as style from "../survey/common.style";

import { get } from "lodash";
import { CustomType, getI18nData } from "../survey/survey.util";

const CUSTOM_TYPE = CustomType.Text;

export class QuestionTextFieldModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get placeholder(): string {
    return this.getPropertyValue("placeholder");
  }
  set placeholder(val) {
    this.setPropertyValue("placeholder", val);
  }
}

// Add question type metadata for further serialization into JSON
export const initTextSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "placeholder",
        default: "Please input",
      },
    ],
    function () {
      return new QuestionTextFieldModel("");
    },
    "question",
  );
};

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionTextFieldModel(name);
});
// A class that renders questions of the new type in the UI
export class SurveyQuestionTextField extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.question.value,
    };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get disableAlpha() {
    return this.question.disableAlpha;
  }
  onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event?.target || {};
    this.question.value = value;
  }
  get placeholder() {
    return getI18nData(this.question.placeholder);
  }

  renderElement() {
    return (
      <FormControl fullWidth sx={style.formControl} variant="standard">
        {/*  <span className="label">{this.state.title.default}</span>*/}
        <TextField
          id="firstName"
          autoComplete="off"
          placeholder={this.placeholder}
          onChange={this.onInputChange.bind(this)}
          InputLabelProps={style.inputLabelProps}
          size="medium"
          variant="outlined"
        />
      </FormControl>
    );
  }
}

/**
 * check email regex
 * @param param
 * @returns
 */
function emailValidate([value]: string[]): boolean {
  if (!value) {
    return true;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
}
/** custom validation */
FunctionFactory.Instance.register("emailValidate", emailValidate);
