import React, { useEffect, useState } from "react";
import { HeaderItem } from "./type";
import style from "./style.module.css";

type MenuHeaderProps = {
  items?: HeaderItem[];
  title?: string;
};

export const MenuHeader = (props: MenuHeaderProps) => {
  if (props.items === undefined || props.items.length === 0) {
    return <></>;
  }
  return (
    <div className={`w-full p-4 bg-white font-sans`}>
      <ul className={`${style.width}`}>
        {props.items.map((item, index) => {
          return (
            <div key={index}>
              {index !== 0 && <div className={`${style.gap}`}></div>}
              <li className={`${style["header-li"]}`} key={item.key}>
                <span className={`${style.headerKey}`}>{item.key}</span>
                <span className={`${style.headerValue}`} color={item.valueColor}>
                  {item.value}
                </span>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
