import React from "react";
import type { RouteObject } from "react-router-dom";
import { SurveyFormScreen } from "./pages/form/survey-form.screen";
import { MicrositeScreen } from "./pages/microsite/microsite.screen";

export const routesAgentReferralLink: RouteObject[] = [
  {
    path: "home",
    index: true,
    element: <SurveyFormScreen />,
    gaData: {
      module: "LeadForm",
      feature: "ReferralLink",
      journey: "referral_link_form_submission",
      stage: "view_referral_link",
      screen_name: "ReferralLinkPublicPortalForm",
    },
  },
  {
    path: "microsite",
    element: <MicrositeScreen />,
    gaData: {
      module: "LeadForm",
      feature: "Microsite",
      journey: "microsite_form_submission",
      stage: "view_microsite",
      screen_name: "MicrositePublicPortalForm",
    },
  },
];
