import React, { useCallback, useMemo } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { Model, settings } from "survey-core";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

import { useCanGoBack } from "../../../../utils/hooks/use-can-go-back";
import { useNavigate } from "react-router-dom";
import { getOnboardingFormReassessTemplate } from "./template";
import {
  confirmActionAsyncFunc,
  markdownHandler,
} from "../../components/surveyjs/survey/survey.util";
import { Survey } from "survey-react-ui";
import { initSurvey } from "../../components/surveyjs/survey/init-survey";
import { useQuery } from "../../../../utils/hooks/use-query";
import { submitApplicationFormAsync } from "../../redux/onboarding-slice";
import { PruToast } from "../../../../components/pru-toast";
import { feedbackOpen } from "../../../../components/modal/feedback";
import { backToApp } from "../../utils";
import { getOnboardingReassessFeedback } from "./utils";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { TakeUIClickEventParameters } from "../../../../common/ga/types/ga-general-type";
import { takeUIClickEvent } from "../../../../common/ga/ga";

interface HookProps {}

export const useReassess = () => {
  initSurvey();
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const canGoBack = useCanGoBack();
  const goBack = useCallback(() => {
    if (canGoBack()) {
      navigate(-1);
    }
  }, [canGoBack, navigate]);

  const surveyModel = useMemo(() => {
    const survey = new Model(cloneDeep(getOnboardingFormReassessTemplate()));
    return survey;
  }, []);

  const query = useQuery();

  const onSubmit = useCallback(async () => {
    const gaData: TakeUIClickEventParameters = {
      module: "Recruitment",
      feature: "ApplicationForm",
      journey: "reassess_application",
      stage: "confirm_reassessment",
      screen_id: "SCR_RCRTMT_RSSSS",
      screen_name: "Recruitment-Reassess-Screen",
      object_name: "Confirm",
      object_type: "BUTTON",
    };
    takeUIClickEvent(gaData);
    let isValid = false;
    isValid = surveyModel.validateCurrentPage();
    if (!isValid) {
      return;
    }
    const surveyData = surveyModel.data;
    const response = await dispatch<any>(
      submitApplicationFormAsync({
        data: {
          templateId: query.get("templateId") ?? "",
          candidateId: query.get("candidateId") ?? "",
          applicationId: query.get("applicationId") ?? "",

          additionalInfo: {
            reason: surveyData.reason,
          },
        },
        action: surveyData.reassessType, // REVISE / REJECT
      }),
    );
    const errors = response?.error ?? response?.payload?.errors;
    if (!errors) {
      feedbackOpen({
        ...getOnboardingReassessFeedback(surveyData.reassessType),
        onConfirm: () => {
          // go to next page
          backToApp();
        },

        icon: <Icon svg={SvgAssets.success} width={80} height={80} />,

        confirmButtonText: getI18n().t("global.text.confirm"),
      });
    } else {
      PruToast({
        message: getI18n().t("Common.network_error"),
      });
    }
  }, [surveyModel, query]);

  const surveyUI = useMemo(() => {
    surveyModel.showPageTitles = false;
    surveyModel.showNavigationButtons = "none";
    surveyModel.questionErrorLocation = "bottom";
    surveyModel.showCompletedPage = false;
    const defaultValue = {};
    surveyModel.mergeData(defaultValue);
    surveyModel.onComplete.add((_sender: any) => {});
    surveyModel.onTextMarkdown.add(markdownHandler);
    surveyModel.onGetQuestionTitleActions.add((_, options) => {
      if (options.question.tooltips) {
        options.titleActions = [
          {
            id: "show-popover",
            component: "popover",
            tooltip: options.question.tooltips,
          },
        ];
      }
    });
    settings.confirmActionAsync = confirmActionAsyncFunc;
    return (
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col w-screen pb-4">
          <div className=" bg-white w-screen rounded-tr-3xl h-10"></div>
          <Survey
            currentPageNo={0}
            className="survey-common"
            id="onboarding-reassess"
            model={surveyModel}
          />
        </div>

        <div className=" w-screen sticky bottom-0 bg-white px-4 pt-2 pb-7 flex items-center justify-between gap-4">
          <button
            className="bg-red-500 flex-1 text-white font-semibold py-3  rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
            onClick={onSubmit}
          >
            {t("global.text.confirm")}
          </button>
        </div>
      </div>
    );
  }, [onSubmit, surveyModel, t]);
  return {
    goBack,
    surveyUI,
  };
};
