import React from "react";
import { ReactQuestionFactory } from "survey-react-ui";
import { CustomType } from "./survey/survey.util";
import { SurveyQuestionTnc, initTncSerializer } from "./tnc/tnc.component";
import {
  SurveyQuestionDatePicker,
  initDatePickerSerializer,
} from "./date-picker/date-picker.component";
import { SurveyQuestionDropdown, initDropdownSerializer } from "./dropdown/dropdown.component";
import {
  SurveyQuestionRegionPhone,
  initRegionPhoneSerializer,
} from "./region-phone/region-phone.component";
import { SurveyQuestionTextField, initTextSerializer } from "./text/text.component";
import { SurveyQuestionBoolean, initBooleanSerializer } from "./boolean/boolean.component";

let isInitOnboardingSurvey = false;

export function initSurvey() {
  if (isInitOnboardingSurvey) return;
  isInitOnboardingSurvey = true;
  initAllSerializer();
  registerCustomize();
}

function registerCustomize() {
  ReactQuestionFactory.Instance.registerQuestion(CustomType.Tnc, (props) => {
    return React.createElement(SurveyQuestionTnc, props);
  });
  ReactQuestionFactory.Instance.registerQuestion(CustomType.Boolean, (props) => {
    return React.createElement(SurveyQuestionBoolean, props);
  });
  ReactQuestionFactory.Instance.registerQuestion(CustomType.DatePicker, (props) => {
    return React.createElement(SurveyQuestionDatePicker, props);
  });
  ReactQuestionFactory.Instance.registerQuestion(CustomType.Dropdown, (props) => {
    return React.createElement(SurveyQuestionDropdown, props);
  });
  ReactQuestionFactory.Instance.registerQuestion(CustomType.RegionPhone, (props) => {
    return React.createElement(SurveyQuestionRegionPhone, props);
  });
  ReactQuestionFactory.Instance.registerQuestion(CustomType.Text, (props) => {
    return React.createElement(SurveyQuestionTextField, props);
  });
}

function initAllSerializer() {
  initTextSerializer();
  initRegionPhoneSerializer();
  initTncSerializer();
  initBooleanSerializer();
  initDatePickerSerializer();
  initDropdownSerializer();
}
