export enum UserTypeEnum {
  AGENT = "AGENT",
  CANDIDATE = "CANDIDATE",
  MANAGER = "MANAGER",
  ANONYMOUS = "ANONYMOUS",
}
export enum DeliveryMethodEnum {
  SMS = "SMS",
}

export enum OtpContextEnum {
  FIRST_LOGIN = "FirstLogin",
  FORGOT_PASSWORD = "ForgotPassword",
  PublicSharing = "PublicSharing",
}

export enum GetOTPLangOption {
  en = "en",
  zh = "zh",
}
