import React from "react";
import { cloneDeep, get, isEmpty, isEqual } from "lodash";
import { Model } from "survey-core";
import { SvgAssets } from "../../../../components/icon/assets";
import { submitApplicationFormAsync } from "../../redux/onboarding-slice";
import {
  FormAction,
  OnboardingFormModuleName,
  OnboardingApplicationSectionStatus,
  OnboardingFormSectionName,
  RegistrationDTO,
  OnboardingTemplateStatus,
  OnboardingTemplatePublishStatus,
  OnboardingApplicationStatus,
} from "../../types";
import { preQuestionsData } from "./survey-form.screen";
import { getI18n } from "react-i18next";
import { confirm } from "../../../../components/modal/comfirm";
import Icon from "../../../../components/icon/icon";
import { GlobalHelper } from "../../../../utils/helpers/global-helper";
import store from "../../../../redux/store";
import { onboardingPreCheck } from "./survey.util";
import { PruToast } from "../../../../components/pru-toast";
import moment from "moment";
import { extractOwnerId, getLoginUserData } from "../../utils";
import { notifyAppNavBack } from "../../../../utils/hooks/use-can-go-back";
import { prompt } from "../../../../components/modal/prompt";
import { ONBOARDING_FINALIZED_STATUSES } from "../../constants";
import { I18nModule, translate } from "../../../../i18n/translation";

function stringifyNumbers(data: any) {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (typeof value === "number") {
        data[key] = value.toString();
      }
    }
  }
  return data;
}
function removeUndefinedAndNullValues<T>(list: T[]): T[] {
  const newList: T[] = [];
  list.forEach((v) => {
    if (v !== null && v !== undefined) newList.push(v);
  });
  return newList;
}

function removeUndefined(obj: any): any {
  if (Array.isArray(obj)) {
    const newlist = removeUndefinedAndNullValues(obj);
    return newlist.map(removeUndefined);
  } else if (typeof obj === "object" && obj !== null) {
    const newObj: { [key: string]: any } = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = removeUndefined(obj[key]);
        if (value !== undefined) {
          newObj[key] = value;
        }
      }
    }
    return newObj;
  } else {
    return obj;
  }
}
export const submitOnboardingForm = async ({ data, dispatch, formAction, canGoBack }: any) => {
  const currentSection = GlobalHelper.getOnboardingCurrentSection();
  const candidateId = currentSection.candidateId;
  const moduleName = currentSection.moduleName;
  const sectionName = currentSection.name;
  if (!candidateId) return;
  // get section from candidateOnboardingForm by sectionName
  const candidateOnboardingForm = store.getState()?.onboarding?.candidateOnboardingForm;
  const formTemplate = store.getState()?.onboarding?.currentTemplate;
  const section = candidateOnboardingForm?.modules?.[moduleName]?.[sectionName];
  const currentSectionQuestionKeys: string[] =
    formTemplate.pages
      .find((page: any) => page.name === moduleName)
      .elements.find((section: any) => section.name === sectionName)
      .elements.map((q: any) => q.name) ?? [];
  const sectionQuestionAnswers = section?.questionAnswers;
  const dataWithoutUndefined = removeUndefined(data);
  for (const key in dataWithoutUndefined) {
    if (Object.prototype.hasOwnProperty.call(dataWithoutUndefined, key)) {
      if (!currentSectionQuestionKeys.includes(key)) {
        delete dataWithoutUndefined[key];
      }
    }
  }
  if (isEmpty(dataWithoutUndefined) && formAction === FormAction.SAVE) {
    return { noNeedToast: true };
  }
  const convertedData = {
    moduleName,
    templateId: formTemplate?.templateId,
    candidateId: candidateId,
    applicationId: candidateOnboardingForm?.applicationId,
    sections: [
      {
        sectionName,
        questionAnswers: stringifyNumbers(dataWithoutUndefined),
      },
    ],
  };
  if (moduleName !== OnboardingFormModuleName.PreQuestions) {
    if (
      sectionQuestionAnswers &&
      isEqual(sectionQuestionAnswers, dataWithoutUndefined) &&
      ([FormAction.SAVE, FormAction.VALIDATION].includes(formAction) ||
        (section.status === OnboardingApplicationSectionStatus.COMPLETED &&
          sectionName !== OnboardingFormSectionName.eSignatureSection))
    ) {
      return { noNeedToast: true };
    }
    if (
      formAction === FormAction.SUBMIT &&
      [
        OnboardingFormSectionName.termsNConditionsSection,
        OnboardingFormSectionName.eSignatureSection,
      ].includes(sectionName)
    ) {
      // onboarding pre check
      const checkResult: any = await onboardingPreCheck({
        confirmCallback: async (cb?: any) => {
          const actionResponse = await dispatch(
            submitApplicationFormAsync({
              data: convertedData,
              action: FormAction.SAVE,
            }),
          );
          // if no error, then exit this page
          const errors = actionResponse?.error ?? actionResponse?.payload?.errors;
          if (!errors) {
            PruToast({
              status: "success",
              message: getI18n().t("common.success"),
            });
            cb?.();
            // if (canGoBack()) GlobalHelper.getGlobalNavigate()(-1);
          }
        },
      });
      // if not completed training or exam, request the api depends on user's confirmation
      const allPropertiesTruthy = Object.keys(checkResult).every((key) =>
        Boolean(checkResult[key]),
      );
      if (!allPropertiesTruthy) return { error: {} };
    }
    if (
      sectionName === OnboardingFormSectionName.termsNConditionsSection &&
      formAction === FormAction.SUBMIT
    ) {
      const result: any = await confirm({
        message: getI18n().t("confirm_finalized_onboarding_form_desc"),
        title: getI18n().t("reminder_title"),
        confirmButtonText: getI18n().t("onboarding.continue"),
        icon: <Icon svg={SvgAssets.notice} width={80} height={80} />,
      });
      formAction = FormAction.LOCK;
      if (!result.ok) {
        // return error: {} to keep screen stay, but also not toast errors
        return { error: {} };
      }
    }
    const actionResponse = await dispatch(
      submitApplicationFormAsync({
        data: convertedData,
        // !!!NOTE: submit and lock the form data except E-Sig section
        action: formAction,
      }),
    );
    if (sectionName === OnboardingFormSectionName.eSignatureSection) {
      actionResponse.noNeedToast = true;
    }
    return actionResponse;
  } else {
    for (const section of convertedData.sections) {
      preQuestionsData[section.sectionName] = section;
    }
    const submitData: any = { ...convertedData, sections: Object.values(preQuestionsData) };
    // if this is the latest section of PreQuestions module, submit and create the form
    const surveyTemplate: Model = store.getState().onboarding.currentTemplate ?? {};
    const preModuleTemplate = surveyTemplate.pages?.find(
      (page) => page?.name === OnboardingFormModuleName.PreQuestions,
    );
    const lastPreSection = preModuleTemplate?.elements?.slice()?.reverse()?.[0];
    const isLastSection = sectionName === lastPreSection?.name;
    let result: any;
    if (sectionName === OnboardingFormSectionName.confirmationQuestions) {
      result = await dispatch(
        submitApplicationFormAsync({
          data: submitData,
          action: isLastSection ? FormAction.SUBMIT : FormAction.VALIDATION,
        }),
      );
    }
    if (isLastSection && formAction === FormAction.SUBMIT) {
      result = await dispatch(submitApplicationFormAsync({ data: submitData, action: formAction }));
    }
    return { ...result, noNeedToast: !isLastSection };
  }
};

export const getOnboardingModuleNameBySection = (
  formTemplate: any,
  sectionName: string,
  candidateId?: string,
) => {
  let moduleName = "" as OnboardingFormModuleName;
  if (!moduleName && sectionName && formTemplate) {
    moduleName = formTemplate.pages.find((page: any) => {
      return page.elements.find((section: any) => {
        if (section.name === sectionName) {
          candidateId &&
            GlobalHelper.setOnboardingCurrentSection({
              ...section,
              moduleName: page.name,
              candidateId,
            });
          return true;
        }
        return false;
      });
    }).name;
  }
  return moduleName;
};

export const convertExamSurveyDataToRegistrationDTO = (currentExam: any, examSurveyData: any) => {
  const registrationDTO: RegistrationDTO = {
    examName: currentExam?.originData?.examName?.original,
    examCode: currentExam?.originData?.examCode,
    examType: currentExam?.originData?.examType,
  };
  if (currentExam?.originData?.registrationId) {
    registrationDTO.registrationId = currentExam?.originData?.registrationId;
  }

  if (examSurveyData.hasOwnProperty("hasPassedExam")) {
    registrationDTO.hasPassedExam = examSurveyData.hasPassedExam;
  }

  if (examSurveyData.hasOwnProperty("hasLicenseProof")) {
    registrationDTO.licenseInfo = {
      hasLicenseProof: examSurveyData.hasLicenseProof,
    };
  }
  if (currentExam?.originData?.licenseInfo?.id) {
    registrationDTO.licenseInfo = registrationDTO.licenseInfo || {};
    registrationDTO.licenseInfo.id = currentExam?.originData?.licenseInfo?.id;
  }

  if (examSurveyData.hasOwnProperty("examScore")) {
    registrationDTO.licenseInfo = registrationDTO.licenseInfo || {};
    registrationDTO.licenseInfo.examScore = examSurveyData.examScore.toString();
  }
  if (examSurveyData.hasOwnProperty("examDate")) {
    registrationDTO.licenseInfo = registrationDTO.licenseInfo || {};
    registrationDTO.licenseInfo.examDate = moment(examSurveyData.examDate).format("YYYY-MM-DD");
  }

  if (examSurveyData.hasOwnProperty("proofInfos")) {
    registrationDTO.licenseInfo = registrationDTO.licenseInfo || {};
    registrationDTO.licenseInfo.proofInfos = examSurveyData.proofInfos.map((item: any) => ({
      resourceId: item.content._id,
      fileName: item.name,
      fileType: item.type,
      createdAt: new Date().toISOString(),
    }));
  }

  if (examSurveyData.hasOwnProperty("officialReceiptNumber")) {
    registrationDTO.licenseInfo = registrationDTO.licenseInfo || {};
    registrationDTO.licenseInfo.officialReceiptNumber =
      examSurveyData.officialReceiptNumber.toString();
  }

  if (examSurveyData.hasOwnProperty("paymentReceiptDate")) {
    registrationDTO.licenseInfo = registrationDTO.licenseInfo || {};
    registrationDTO.licenseInfo.paymentReceiptDate = moment(
      examSurveyData.paymentReceiptDate,
    ).format("YYYY-MM-DD");
  }
  if (examSurveyData.hasOwnProperty("examScheduleId")) {
    registrationDTO.examPayment = registrationDTO.examPayment || {};
    registrationDTO.examScheduleId = examSurveyData.examScheduleId;
  }
  if (examSurveyData.hasOwnProperty("paymentType")) {
    registrationDTO.examPayment = registrationDTO.examPayment || {};
    registrationDTO.examPayment.paymentType = examSurveyData.paymentType;
  }

  if (examSurveyData.hasOwnProperty("examFeeReceiptNo")) {
    registrationDTO.examPayment = registrationDTO.examPayment || {};
    registrationDTO.examPayment.examFeeReceiptNo = examSurveyData.examFeeReceiptNo.toString();
  }
  if (examSurveyData.hasOwnProperty("licenseFeeReceiptNo")) {
    registrationDTO.examPayment = registrationDTO.examPayment || {};
    registrationDTO.examPayment.licenseFeeReceiptNo = examSurveyData.licenseFeeReceiptNo.toString();
  }
  if (examSurveyData.hasOwnProperty("licenseFeeReceiptDate")) {
    registrationDTO.examPayment = registrationDTO.examPayment || {};
    registrationDTO.examPayment.licenseFeeReceiptDate = moment(
      examSurveyData.licenseFeeReceiptDate,
    ).format("YYYY-MM-DD");
  }
  if (examSurveyData.hasOwnProperty("examFeeReceiptDate")) {
    registrationDTO.examPayment = registrationDTO.examPayment || {};
    registrationDTO.examPayment.examFeeReceiptDate = moment(
      examSurveyData.examFeeReceiptDate,
    ).format("YYYY-MM-DD");
  }

  return registrationDTO;
};

export const fileurlToBase64 = (url: string) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

enum Message {
  EXPIRED = "application_form_updated_withdraw_fill_again",
  CONTINUE = "application_form_updated_continue_current_form",
  NOTIFY_CANDIDATE = "application_form_updated_ask_applicant_withdraw",
}
const backToApp = () => {
  notifyAppNavBack();
  return { keepAlive: true };
};
let currentSectionName = "";
export const popupExpiredTemplateReminder = (state?: any) => {
  const currentSection = GlobalHelper.getOnboardingCurrentSection();
  if (currentSectionName === currentSection.name) {
    return;
  }
  currentSectionName = currentSection?.name;
  const onboardingState = state ?? store.getState()?.onboarding;
  const template = onboardingState?.rawTemplate ?? {};
  const user = getLoginUserData();
  const currentOnboardingForm = cloneDeep(onboardingState?.candidateOnboardingForm);
  const isFromCreator = extractOwnerId(user) === currentOnboardingForm?.formCreatorUserId;
  const isAssignee = onboardingState?.isAssignee;
  const isSubmittedOrFinalized = ONBOARDING_FINALIZED_STATUSES.includes(
    currentOnboardingForm.applicationStatus,
  );
  if (isSubmittedOrFinalized) {
    return;
  }
  let isSectionReadOnly = !isAssignee;
  if (!isFromCreator && currentSectionName !== OnboardingFormSectionName.eSignatureSection) {
    isSectionReadOnly = true;
  }

  if (!currentSectionName) {
    // located at menu page
    if (!isAssignee && isFromCreator) {
      return;
    }
  } else {
    if (isSectionReadOnly) {
      return;
    }
  }
  if (isEmpty(currentOnboardingForm)) {
    return;
  }
  if (
    template.status === OnboardingTemplateStatus.ACTIVE &&
    (!template.publishStatus ||
      template.publishStatus === OnboardingTemplatePublishStatus.PUBLISHED)
  ) {
    return;
  }
  let message: string = Message.EXPIRED;
  let cancelButtonText: string | undefined;
  let confirmButtonText = getI18n().t("global.text.confirm");
  let onConfirm = () => {};
  if (!isFromCreator) {
    if (
      [OnboardingApplicationStatus.EDITING, OnboardingApplicationStatus.DRAFT].includes(
        currentOnboardingForm.applicationStatus,
      )
    ) {
      message = Message.NOTIFY_CANDIDATE;
      onConfirm = backToApp;
    } else {
      message = Message.CONTINUE;
    }
  } else {
    if (
      [OnboardingApplicationStatus.EDITING, OnboardingApplicationStatus.DRAFT].includes(
        currentOnboardingForm.applicationStatus,
      )
    ) {
      // located at menu page
      cancelButtonText = getI18n().t("app.button.cancel");
      confirmButtonText = getI18n().t("Common.withdraw");
      onConfirm = async () => {
        confirm(
          {
            message: getI18n().t("Recruitment.withdraw_application_form_clear_progress_confirm"),
            title: getI18n().t("Common.warning"),
            confirmButtonText: getI18n().t("Recruitment.withdraw_confirm_yes"),
            cancelButtonText: getI18n().t("Recruitment.withdraw_confirm_no"),
            onCancel: backToApp,
            icon: <Icon svg={SvgAssets.notice} width={80} height={80} />,
          },
          { backdropClose: false, id: "" },
        ).then(async (result) => {
          if (result.ok) {
            const requestBody = {
              data: {
                templateId: currentOnboardingForm?.templateId,
                candidateId: currentOnboardingForm?.candidateId,
                applicationId: currentOnboardingForm?.applicationId,
              },
              action: FormAction.WITHDRAW,
            };
            const response = await GlobalHelper.getGlobalDispatch()(
              submitApplicationFormAsync(requestBody),
            );
            const errors = response?.error ?? response?.payload?.errors;
            if (errors) {
              PruToast({
                message: getI18n().t("Common.network_error"),
              });
            }
            backToApp();
          }
        });
      };
    } else {
      // located at e-signature page
      message = Message.CONTINUE;
    }
  }
  prompt(
    {
      title: getI18n().t("reminder_title"),
      message: translate(message, I18nModule.Recruitment),
      confirmButtonText,
      cancelButtonText,
      onConfirm,
      onCancel: backToApp,
      icon: SvgAssets.notice,
    },
    { backdropClose: false, id: "" },
  );
};
