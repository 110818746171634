import { get } from "lodash";
import { convertLanguageKey as portalConvertLanguageKey } from "../../../../utils/lang";

/** cover SMG Assessement lang to i18n resources key: web/src/i18n/i18n-config.ts
 * REGION_LANGUAGE env: https://code.pruconnect.net/projects/PCAAEB/repos/ebriefcase_devops/browse/k8s-manifests/coedev/env-configmap.yaml
 */
export function convertLanguageKey(lang: string): string {
  const REGION_LANGUAGE: string[] = get(window, "envConfig.REGION_LANGUAGE", "").split(",");
  const langMapping = {
    eng: portalConvertLanguageKey("en"),
    cht: portalConvertLanguageKey(
      REGION_LANGUAGE.find((item) => item === "zh-Hant") ? "zh-Hant" : "zhhk",
    ),
    chi: portalConvertLanguageKey(
      REGION_LANGUAGE.find((item) => item === "zh-Hant") ? "zh-Hant" : "zhhk",
    ),
    ind: portalConvertLanguageKey(REGION_LANGUAGE.find((item) => item === "id") ? "id" : "en"),
    mal: portalConvertLanguageKey(REGION_LANGUAGE.find((item) => item === "my") ? "my" : "en"),
    vie: portalConvertLanguageKey(REGION_LANGUAGE.find((item) => item === "vi") ? "vi" : "en"),
  };
  // @ts-ignore
  return langMapping[lang] || "en";
}
