import React, { useMemo } from "react";
import { ModuleSection, OnboardingTrainingModuleType, StatusEnum } from "./type";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { completeBoxMarginStyle, completeIconStyle, subheader, sectionItem } from "./style";
import { useNavigate } from "react-router-dom";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import style from "./style.module.css";
import { ListSubheader } from "@mui/material";
import { useQuery } from "../../../../utils/hooks/use-query";
import { PruToast } from "../../../../components/pru-toast";
import { getI18n } from "react-i18next";
import { selectOnboardingConfigs, selectOnboardingTrainingSummary } from "../../redux/selector";
import { useSelector } from "react-redux";
import { StatusEnum as CommonStatusEnum, RegistrationStatus } from "../../types";
import { OnboardingTrainingPlatform } from "../course-menu-screen/type";
import { getToken, parseJwt } from "../../utils";

type TrainingMenuListProps = {
  sections?: ModuleSection[];
};

export const TrainingMenuList = (props: TrainingMenuListProps) => {
  const { sections = [] } = props;
  const navigate = useNavigate();
  const candidateOnboardingTrainingSummary = useSelector(selectOnboardingTrainingSummary);
  const onboardingConfigs = useSelector(selectOnboardingConfigs);
  const { trainingSummary, examSummary } = useMemo(() => {
    return candidateOnboardingTrainingSummary ?? ({} as any);
  }, [candidateOnboardingTrainingSummary]);
  const query = useQuery();

  return (
    <div
      className={`${style["list-container"]} ${style.flex1} ${style["flex-column"]} ${style.padding}`}
    >
      <List>
        {sections.map((section) => {
          return (
            <div key={section.name} className={`${style.item}`}>
              <ListSubheader sx={subheader}>
                <ListItemButton
                  onClick={() => {
                    const rawToken = getToken();
                    const tokenData = rawToken ? parseJwt(rawToken) : {};
                    const candidateId = query.get("candidateId")!;
                    const isCandidateSelf = tokenData.sub === candidateId;
                    switch (section.name) {
                      case OnboardingTrainingModuleType.Training: {
                        if (section.status === StatusEnum.disabled) {
                          PruToast({
                            message: getI18n().t("please_complete_application_modules"),
                          });
                        } else if (!isCandidateSelf) {
                          if (
                            trainingSummary?.status === CommonStatusEnum.NOT_STARTED &&
                            !trainingSummary?.paymentStatus
                          ) {
                            PruToast({
                              message: getI18n().t("Recruitment.training_module_not_started_yet"),
                            });
                          } else if (
                            !trainingSummary?.paymentStatus &&
                            onboardingConfigs.trainingPlatform !== OnboardingTrainingPlatform.COE
                          ) {
                            PruToast({
                              message: getI18n().t(
                                "Recruitment.unable_to_display_content_Onboarding_Training_candidates",
                              ),
                            });
                          } else {
                            navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/courses?${query.toString()}`);
                          }
                        } else {
                          navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/courses?${query.toString()}`);
                        }

                        break;
                      }
                      case OnboardingTrainingModuleType.ExamRegistration: {
                        if (!isCandidateSelf) {
                          if (
                            [StatusEnum.disabled, StatusEnum.completedButDisabled].includes(
                              section.status as any,
                            )
                          ) {
                            PruToast({
                              message: getI18n().t("please_complete_onboarding_module"),
                            });
                          }
                          const startedExams = examSummary?.data?.filter?.(
                            (exam: any) =>
                              exam.registrationStatus !== RegistrationStatus.NOT_STARTED,
                          );
                          if (!startedExams || startedExams.length === 0) {
                            PruToast({
                              message: getI18n().t(
                                "Recruitment.license_exam_module_not_started_yet",
                              ),
                            });
                            break;
                          } else {
                            navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/exams?${query.toString()}`);
                          }
                        } else {
                          navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/exams?${query.toString()}`);
                        }
                        break;
                      }
                    }
                  }}
                >
                  {[StatusEnum.completed, StatusEnum.completedButDisabled].includes(
                    section.status as any,
                  ) ? (
                    <Box sx={completeBoxMarginStyle}>
                      <CheckIcon sx={completeIconStyle} />
                    </Box>
                  ) : section.denominator ? (
                    <ListItemText
                      sx={sectionItem}
                      primary={`(${section.numerator}/${section.denominator})`}
                    />
                  ) : null}
                  <ListItemText primary={section.title} />
                </ListItemButton>
              </ListSubheader>
            </div>
          );
        })}
      </List>
    </div>
  );
};
