import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchTrainingCoursesAsync,
  fetchConfigAsync,
  getOnboardingTrainingSummaryAsync,
} from "../../redux/onboarding-slice";
import { get, isEmpty, throttle } from "lodash";
import i18next from "i18next";
import { CircularProgress } from "@mui/material";
import { ErrorScreen } from "../../../../components/error-screen/error-screen";
import { useNavigate } from "react-router-dom";
import { HeaderComponent } from "../../../../components/header/header.component";
import { color } from "../../../../mui-theme/palette";
import { useQuery } from "../../../../utils/hooks/use-query";
import { convertLanguageKey } from "../../../../utils/lang";
import { selectOnboardingCourses } from "../../redux/selector";
import { OnboardingTrainingCourse, PaymentStatus } from "../../types";
import { useCanGoBack } from "../../../../utils/hooks/use-can-go-back";
import {
  ModuleSection,
  OnboardingCourseResourceType,
  OnboardingTrainingPlatform,
  SectionItem,
  StatusEnum,
} from "./type";
import { MenuList } from "./menu";
import useThrottledTouchEnd from "../../hooks/use-throtted-touch-end";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { PruToast } from "../../../../components/pru-toast";
import store from "../../../../redux/store";
import { redirectToOnboardingTraining } from "./utils";
import { getLoginUserData } from "../../utils";

type MenuScreenProps = {};

export const CourseMenuScreen = (props: MenuScreenProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const candidateId = query.get("candidateId");
  const lang = query.get("lang") ?? "en";
  useThrottledTouchEnd();

  const navigate = useNavigate();
  const [data, setData] = useState<ModuleSection[] | undefined>(undefined);
  const courses = useSelector(selectOnboardingCourses);
  const trainingPlatform = useSelector(
    (state: any) => state.onboarding.configs?.Recruitment?.trainingPlatform,
  );
  const [loading, setLoading] = useState(true);

  const redirectHandler = useCallback(
    async (
      needPayment: boolean,
      trainingSummary: any,
      trainingPlatform: OnboardingTrainingPlatform,
    ) => {
      if (
        needPayment &&
        (trainingSummary.paymentStatus !== PaymentStatus.APPROVED ||
          (trainingPlatform !== OnboardingTrainingPlatform.COE &&
            getLoginUserData()?.sub !== candidateId))
      ) {
        navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/courses/payment?${query.toString()}`, {
          replace: true,
          state: { summary: trainingSummary },
        });
        return;
      } else {
        await redirectToOnboardingTraining(trainingPlatform);
      }
    },
    [],
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!candidateId) return setLoading(false);
    document.addEventListener("touchend", throttledTouchEnd);
    const [configsResponse, actionResponse] = await Promise.all([
      dispatch<any>(fetchConfigAsync()),
      dispatch<any>(getOnboardingTrainingSummaryAsync({ candidateId })),
    ]);
    const errors = actionResponse?.error ?? actionResponse?.payload?.errors;
    if (errors) {
      PruToast({
        message: t(errors?.[0]?.message),
      });
      return;
    }
    const _trainingSummary = get(actionResponse, "payload.trainingSummary", {});
    const needPayment = get(
      configsResponse,
      "payload.data.Recruitment.enableTrainingPayment",
      false,
    );
    const trainingPlatform = get(
      configsResponse,
      "payload.data.Recruitment.trainingPlatform",
      OnboardingTrainingPlatform.COE,
    );
    if (!_trainingSummary.requiredToAttend) {
      setLoading(false);
      PruToast({
        message: "Onboarding training is not required for this candidate",
        status: "error",
      });
      return;
    }
    await redirectHandler(needPayment, _trainingSummary, trainingPlatform);
    await dispatch<any>(fetchTrainingCoursesAsync({ candidateId }));
    setLoading(false);
  }, [candidateId, dispatch, redirectHandler]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    if (isEmpty(courses)) {
      return;
    }
    setData(
      courses?.map((course: OnboardingTrainingCourse) => {
        const lessonAndQuizes: SectionItem[] = [];
        for (const lesson of course.lessons) {
          lessonAndQuizes.push({
            name: lesson.code,
            type: OnboardingCourseResourceType.Lesson,
            title: lesson.name,
            data: lesson,
            status: lesson.status as keyof typeof StatusEnum,
          });
          if (lesson.quiz) {
            lessonAndQuizes.push({
              name: lesson.quiz.code,
              title: lesson.quiz.name,
              type: OnboardingCourseResourceType.LessonQuiz,
              data: lesson.quiz,
              status: lesson.quiz.status as keyof typeof StatusEnum,
            });
          }
        }
        if (course.quiz) {
          lessonAndQuizes.push({
            name: course.quiz.code,
            title: course.quiz.name,
            type: OnboardingCourseResourceType.CourseQuiz,
            data: course.quiz,
            status: course.quiz.status as keyof typeof StatusEnum,
          });
        }
        return {
          title: course.name,
          name: course.code,
          data: lessonAndQuizes,
          expanded: course.status !== "COMPLETED",
          status: course.status as keyof typeof StatusEnum,
          denominator: lessonAndQuizes.length,
          numerator: lessonAndQuizes.filter((item) =>
            [StatusEnum.COMPLETED, StatusEnum.PASSED].includes(item.status as any),
          ).length,
        };
      }),
    );
  }, [courses]);

  const throttledTouchEnd = useCallback(
    throttle(() => {
      window.ReactNativeWebView?.postMessage?.('{ "action" : "touchend"}');
    }, 1000),
    [],
  );
  useEffect(() => {}, []);
  useEffect(() => {
    i18next.changeLanguage(convertLanguageKey(lang));
  }, [lang]);
  const pageUI = useMemo(() => {
    if (trainingPlatform !== OnboardingTrainingPlatform.COE || loading) {
      return (
        <div className={`h-screen flex flex-1 items-center justify-center`}>
          <CircularProgress sx={{ color: color.majorRed }} />
        </div>
      );
    }
    if (isEmpty(data)) {
      return <ErrorScreen statusCode="-1" />;
    }
    return (
      <div className={`w-screen flex flex-1 flex-col`}>
        <div className="flex-1 flex-col bg-gray-100">
          <MenuList sections={data} />
        </div>
      </div>
    );
  }, [loading, data, trainingPlatform]);

  const canGoBack = useCanGoBack();
  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen flex flex-col">
      <HeaderComponent
        title={t("onboarding_training")}
        onBack={() => {
          if (canGoBack()) {
            navigate(-1);
          }
        }}
      />
      {<div className={`w-screen flex flex-1 overflow-auto`}>{pageUI}</div>}
    </div>
  );
};
