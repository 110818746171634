import { getI18n } from "react-i18next";

export const getOnboardingFormReassessTemplate = () => {
  return {
    pages: [
      {
        name: "page1",
        elements: [
          {
            type: "dropdown",
            name: "reassessType",
            title: getI18n().t("Recruitment.onboarding_reassess_type"),
            isRequired: true,
            choices: [
              {
                value: "REVISE",
                text: getI18n().t("Recruitment.onboarding_reassess_type_REVISE"),
              },
              {
                value: "REJECT",
                text: getI18n().t("Recruitment.onboarding_reassess_type_REJECT"),
              },
            ],
          },
          {
            type: "comment",
            name: "reason",
            title: getI18n().t("Recruitment.onboarding_reassess_reason"),
            description: "Please describe the reason in detail",
            isRequired: true,
          },
        ],
      },
    ],
    showQuestionNumbers: "off",
  };
};
