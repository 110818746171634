import { AxiosRequestConfig } from "axios";
import { http } from "../../../../../utils/axios/axios";
import { get } from "lodash";
import { getToken } from "../../../utils";

const apimProtectedWhiteList = [
  get(window, "envConfig.REACT_APP_APIM_RECRUITMENT", ""),
  get(window, "envConfig.REACT_APP_APIM_AGENT", ""),
  get(window, "envConfig.REACT_APP_APIM_CORE", ""),
  get(window, "envConfig.REACT_APP_APIM_ANONYMOUS", ""),
];

export function isUrlInWhiteList(url: string) {
  const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");
  return apimProtectedWhiteList.some((subPath: any) =>
    url.startsWith(`${APIM_BASE_URL}${subPath}`),
  );
}

export const onboardingHttp = {
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");
    const isApimUrl = url.startsWith(APIM_BASE_URL);
    if (isApimUrl) {
      config = config ?? {};
      config.headers = config.headers ?? {};
      config.headers["Ocp-Apim-Subscription-Key"] = get(
        window,
        "envConfig.REACT_APP_OEM_APIM_SUBSCRIPTION_KEY",
        "",
      );
      if (isUrlInWhiteList(url)) {
        config.headers["Authorization"] = getToken();
      } else {
        config.headers["Authorization"] = undefined;
      }
    } else {
      config = config ?? {};
      config.headers = config.headers ?? {};
      config.headers["Ocp-Apim-Subscription-Key"] = undefined;
      config.headers["Authorization"] = undefined;
    }
    return http.get(url, config);
  },
};
