import axios from "axios";
import type { AxiosInstance, AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { get } from "lodash";
import { GlobalHelper } from "../helpers/global-helper";
import { commonSlice } from "../../redux/common/common-slice";
import { getLoginUserData } from "../../modules/onboarding/utils";
import { RoleEnum } from "../../modules/onboarding/types";
import { PruToast } from "../../components/pru-toast";
import { prompt } from "../../components/modal/prompt";
import { getI18n } from "react-i18next";
const { showLoadingAction, hideLoadingAction } = commonSlice.actions;

// dispatch the error, maybe to redux
const Message = {
  error: (msg: string) => {},
};

/* The type of data returned by the server is determined according to the interface document */
export interface Result<T = any> {
  status: number;
  message: string;
  data: T;
}
const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", ""); // https://phkl-apim-nprd.prudential.com.hk/pruforce
const APIM_PUBLIC = get(window, "envConfig.REACT_APP_APIM_PUBLIC", ""); // coe/dev
const service: AxiosInstance = axios.create({
  timeout: 60000,
  withCredentials: false,
  baseURL: `${APIM_BASE_URL}${APIM_PUBLIC}`,
});

/* request interceptors */
service.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    // if (user.token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    if (get(config, "headers.__loading")) {
      GlobalHelper.getGlobalDispatch()?.(showLoadingAction());
    }
    return config;
  },
  (error: AxiosError) => {
    Message.error(error.message);
    return Promise.reject(error);
  },
);

/* response interceptors */
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const { status, config, statusText } = response;
    if (config?.headers?.__loading) {
      GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
    }
    // whether the request is successful according to the user-defined error code

    if (status > 199 && status < 300) {
      return response;
    } else {
      // handle business error
      Message.error(statusText);
      return Promise.reject(new Error(statusText));
    }
  },
  (error: AxiosError) => {
    GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
    // HTTP network error
    let message = "";
    // HTTP status code
    const status = error.response?.status;
    switch (status) {
      case 401:
        message = "Token_Invalid";
        break;
      case 403:
        const userRole = getLoginUserData().role;
        // if the request method is GET, show the toast, otherwise show the alert
        const method = get(error, "config.method");
        if (method === "get") {
          PruToast({
            message: "Permission Denied",
          });
        } else {
          // show alert
          if (userRole === RoleEnum.SECRETARY) {
            prompt({
              title: getI18n().t("Common.secretary_block_alert_title"),
              message: getI18n().t("Common.secretary_block_alert_description"),
              confirmButtonText: getI18n().t("global.text.confirm"),
              onConfirm: () => {},
            });
          }
        }
        message = "Forbidden";
        break;
      case 404:
        message = "Not_Found";
        break;
      case 500:
        message = "Internal_Server_Error";
        break;
      default:
        message = "Request_Error";
    }

    Message.error(message);
    return Promise.reject(error);
  },
);

/* export request methods */
export const http = {
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return service.get(url, config);
  },

  post<T = any>(url: string, data?: object, config?: AxiosRequestConfig): Promise<T> {
    return service.post(url, data, config);
  },

  put<T = any>(url: string, data?: object, config?: AxiosRequestConfig): Promise<T> {
    return service.put(url, data, config);
  },

  patch<T = any>(url: string, data?: object, config?: AxiosRequestConfig): Promise<T> {
    return service.patch(url, data, config);
  },

  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return service.delete(url, config);
  },
};

/* also export axios instance */
export default service;
