import React, { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../redux/store";
import { RegistrationTypeEnum } from "../../../network/type";

export const ReminderComponent = memo(() => {
  // i18n
  const { t } = useTranslation();
  const { registrationType } = useAppSelector((state) => state.event.eventQRcodeData)!;

  return (
    <div className="w-full h-full border-solid border-t-[1px] border-[#F0F0F0] pt-4">
      <span className="text-base font-semibold">{t("event.gentle_reminder")}</span>
      <span className="mt-3 text-sm font-medium text-[#666] flex flex-col">
        {registrationType === RegistrationTypeEnum.WALKIN
          ? t("event.qr_code_walk_in_reminder_message")
          : t("event.qr_code_reminder_message")}
      </span>
    </div>
  );
});
