import { get, findIndex, set } from "lodash";
import { convert2SurveyLocale, I18nData, City } from "../survey.util";
import i18next from "i18next";
import { FunctionFactory } from "survey-core";
import dayjs, { Dayjs } from "dayjs";

/**
 * the special field name need to be convert for submit
 */
export enum CustomQuestionField {
  city = "city",
  countryCode = "countryCode",
  dateOfBirth = "dateOfBirth",
}

export interface CityChoices {
  value: { key: string; parentKey: string; parentValue: string };
  text: I18nData;
}

export interface RegionPhoneType {
  key: string;
  region: any;
  number: string;
}

/**
 * check phone number regex when it's not empty
 * @param param0
 * @returns
 */
export function dateOfBirthValidate([value]: Dayjs[]): boolean {
  if (!value) {
    return true;
  }

  const maxDate = dayjs();
  const minDate = dayjs(new Date()).subtract(120, "year");
  const selectedDate = dayjs(value);
  if (selectedDate > maxDate || selectedDate < minDate) {
    return false;
  }
  return true;
}

export function emailValidate([value]: string[]): boolean {
  if (!value) {
    return true;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
}

export function phoneNumberValidate(params: any[]) {
  const region = params[0];
  const phoneNumber = params[1];
  if (!phoneNumber || !region) {
    return false;
  }
  const regex = new RegExp(region?.regex ?? "^(\\d){5,20}$");
  return regex.test(phoneNumber);
}

export function initValidation() {
  FunctionFactory.Instance.register("emailValidate", emailValidate);
  FunctionFactory.Instance.register("phoneNumberValidate", phoneNumberValidate);
  FunctionFactory.Instance.register("dateOfBirthValidate", dateOfBirthValidate);
}

export function setRegionElementChoice(jsonObj: any, name: string, value: any, extra?: any) {
  const arrElement =
    get(jsonObj, "pages[0].elements[0].elements") || get(jsonObj, "pages[0].elements");
  const elemIndex = findIndex(arrElement, { name: name });

  if (elemIndex !== -1) {
    const regionElem = get(arrElement[elemIndex], "elements[0]");
    if (regionElem) {
      regionElem.choices = value.map((item: any) => {
        return {
          value: { key: item.key, regex: item.regex },
          text: item.key,
        };
      });
      if (extra) {
        regionElem.defaultValue = { key: extra.key, regex: extra.regex };
      }
    }
    return set(jsonObj, "pages[0].elements", arrElement);
  }
  return jsonObj;
}

export function formatCity(arr: any[]): CityChoices[] {
  if (!arr || arr.length < 1) {
    return [];
  }
  const arrResult = [] as CityChoices[];
  arr.forEach((item) => {
    const state = get(item, "state.key", "");
    let arrCity = get(item, "city", []);
    arrCity.forEach((city: City) => {
      const labelEn = get(city, "label.en");
      const labelLocal = get(city, "label.local", labelEn);
      const localLang = convert2SurveyLocale(i18next.language);
      arrResult.push({
        value: { key: city.key, parentKey: "state", parentValue: state },
        text: {
          default: labelEn,
          local: labelLocal,
          [localLang]: labelLocal,
        },
      });
    });
  });
  return arrResult;
}

/**
 * extract custom-component to plain json.
 * example
  source: {
    "firstName": "name",
    "isCustomer": false
     "region": {
        "key": "+852",
        "regex": "^([4-8]\\d{7}|9[0-8]\\d{6})$"
    },
    "phone": "42333331",
    "city": {"key": "Kowloon","parentKey": "state","parentValue": "Hong Kong"}
  }
  result: {
    "firstName": "name",
    "phoneNumber": "42333331",
    "countryCode": "+852",
    "state": "Hong Kong"
    "city": "Kowloon",
    "isCustomer": true }
 * @param data
 */
export function extractFormData(formData: any) {
  const result = { ...formData };
  for (const rootKey in formData) {
    const value = formData[rootKey] as any;
    if (value && typeof value === "object") {
      if (rootKey === CustomQuestionField.city) {
        const { key, parentKey, parentValue } = value;
        result[rootKey] = key; // dropdown value
        if (parentKey) {
          result[parentKey] = parentValue;
        }
      } else if (rootKey === CustomQuestionField.countryCode) {
        const { key, regex } = value;
        result[rootKey] = key;
      }
    }
    if (value && rootKey === CustomQuestionField.dateOfBirth) {
      const selectedDate = dayjs(value);
      result[rootKey] = selectedDate.utc(true).format();
    }
  }
  return result;
}
