import React, { useCallback, useEffect, useState } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";
// mui start
import { Checkbox, Radio, ToggleButtonGroup, ToggleButton } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// mui end

import * as style from "../survey/common.style";

import { CustomType } from "../survey/survey.util";
import { pdfOpen } from "../../../../../components/modal/pdf-open";
import { color } from "../../../../../mui-theme/palette";
import { debounce } from "lodash";
import { GlobalHelper } from "../../../../../utils/helpers/global-helper";
import { booleanStyles } from "./boolean.style";

const CUSTOM_TYPE = CustomType.Boolean;

export class QuestionBooleanModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  // get, set for custom props in survey-json
  get labelTrue(): string {
    return this.getPropertyValue("labelTrue");
  }
  set labelTrue(val) {
    this.setPropertyValue("labelTrue", val);
  }
  get labelFalse(): string {
    return this.getPropertyValue("labelFalse");
  }
  set labelFalse(val) {
    this.setPropertyValue("labelFalse", val);
  }
  get preset() {
    return this.getPropertyValue("preset");
  }
  set preset(val) {
    this.setPropertyValue("preset", val);
  }
  get valueTrue() {
    return this.getPropertyValue("valueTrue");
  }
  set valueTrue(val) {
    this.setPropertyValue("valueTrue", val);
  }
  get valueFalse() {
    return this.getPropertyValue("valueFalse");
  }
  set valueFalse(val) {
    this.setPropertyValue("valueFalse", val);
  }
}

//Add question type metadata for further serialization into JSON
export const initBoolSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "labelTrue",
        default: "Yes", // surveyjs default value if custom-props not specific
      },
      {
        name: "labelFalse",
        default: "No", // surveyjs default value if custom-props not specific
      },
      {
        name: "valueTrue",
        default: "true",
      },
      {
        name: "valueFalse",
        default: "false",
      },
      {
        name: "preset",
        default: "", // surveyjs default value if custom
      },
      {
        name: "attachmentUrl",
        default: "", // surveyjs default value if custom
      },
    ],
    function () {
      return new QuestionBooleanModel("");
    },
    "question",
  );
};

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionBooleanModel(name);
});
let clickAt = 0;
// A class that renders questions of the new type in the UI
export class SurveyQuestionBoolean extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.question.value,
    };
  }
  get question() {
    let isModuleReadOnly = false;

    if (this.questionBase.survey) {
      const activePage: any = this.questionBase.survey.currentPage;
      if (activePage) {
        isModuleReadOnly = activePage.readOnly;
      }
    }

    this.questionBase.readOnly = this.questionBase.readOnly || isModuleReadOnly;
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get labelTrue() {
    return this.question.labelTrue;
  }
  get labelFalse() {
    return this.question.labelFalse;
  }
  get valueTrue() {
    return this.question.valueTrue;
  }
  get valueFalse() {
    return this.question.valueFalse;
  }
  get preset() {
    return this.question.preset;
  }

  onInputChange(value: any) {
    this.question.value = value;
  }

  renderElement() {
    return (
      <SurveyQuestionBooleanElement
        question={this.question}
        onChange={this.onInputChange.bind(this)}
        preset={this.preset}
      />
    );
  }
}

const parentClick = (parent: any, e: any) => {
  if (Date.now() < clickAt + 500) return;
  clickAt = Date.now();
  const child = parent.querySelector(".confirm-checkbox");
  if (child) {
    child.click();
  }
};

const delegateEvent = debounce(
  () => {
    const parentElements = document.querySelectorAll(".sd-question--boolean");
    parentElements.forEach((parent) => {
      parent.addEventListener("click", (e) => {
        parentClick(parent, e);
      });
    });
  },
  1000,
  {
    leading: false,
    trailing: true,
  },
);
interface SurveyQuestionBooleanElementProps {
  question: Question;
  onChange: (value: any) => void;
  preset?: string;
}
const SurveyQuestionBooleanElement: React.FC<SurveyQuestionBooleanElementProps> = ({
  question,
  onChange,
  preset,
}) => {
  const [value, setValue] = useState<boolean | undefined>(question.value);

  useEffect(() => {
    delegateEvent();
  }, []);
  const handleValueChange = useCallback((newValue: boolean) => {
    setValue(newValue);
    onChange(newValue);
  }, []);
  const checkOption = useCallback(() => {
    setValue(true);
    onChange(true);
  }, []);
  const onClickHandle = useCallback((e: any) => {
    // !! DON'T REMOVE this e.stopPropagation();
    e.stopPropagation();
    question.attachmentUrl = question.attachmentUrl;
    //  ??"https://pruforceagentcoedev.prudential.com.hk/cdn/%24web/material_sales%2F642571eb36a57b890941e2a5.pdf";
    if (question.attachmentUrl) {
      // go to preview page
      pdfOpen({
        headerTx: GlobalHelper.getOnboardingCurrentSection()?.title,
        url: question.attachmentUrl,
        isConfirmed: question.value,
        onConfirm: !question.readOnly ? checkOption : undefined,
        // onCancel: () => {
        //   setValue(false);
        //   onChange(false);
        // },
      });
    }
  }, []);

  switch (preset) {
    case "confirm_checkbox":
      return (
        <FormControl fullWidth sx={style.formControl} variant="standard">
          <FormControlLabel
            disabled={question.readOnly}
            value={question.value}
            onClick={() => !question.readOnly && handleValueChange(!question.value)}
            style={style.confirmLabel}
            control={
              <Checkbox
                disabled={question.readOnly}
                color="secondary"
                id={question.name}
                size="small"
                checked={question.value}
              />
            }
            label=""
          />
        </FormControl>
      );

    case "confirm_checkbox_with_detail":
      return (
        <FormControl fullWidth variant="standard">
          <FormControlLabel
            disabled={question.readOnly}
            value={value}
            style={style.confirmLabel}
            control={
              <div
                className={`confirm-checkbox ${
                  question.readOnly ? "filter grayscale opacity-50" : ""
                }`}
                onClick={onClickHandle}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {value ? (
                    <CheckBoxIcon className="text-red-600" />
                  ) : (
                    <ChevronRightOutlinedIcon
                      // fontSize="small"
                      className="text-gray-600"
                    />
                  )}
                </div>
              </div>
            }
            label=""
          />
        </FormControl>
      );
    case "confirm_checkbox_round":
      return (
        <FormControl fullWidth sx={style.formControl} variant="standard">
          <FormControlLabel
            disabled={question.readOnly}
            value={!!question.value}
            style={style.confirmRoundLabel}
            onClick={() => !question.readOnly && handleValueChange(!question.value)}
            control={
              <Radio
                disabled={question.readOnly}
                color="secondary"
                id={question.name}
                size="small"
                checked={!!question.value}
              />
            }
            label=""
          />
        </FormControl>
      );
    default:
      return (
        <FormControl fullWidth sx={style.formControl} variant="standard">
          <ToggleButtonGroup value={value}>
            <FormControlLabel
              disabled={question.readOnly}
              style={booleanStyles.booleanItemLeft}
              sx={
                value === (question.valueTrue ?? true)
                  ? booleanStyles.selectedBg
                  : booleanStyles.defaultBg
              }
              onClick={() => !question.readOnly && handleValueChange(question.valueTrue ?? true)}
              control={
                <ToggleButton
                  disabled={question.readOnly}
                  id={question.name}
                  value={question.valueTrue ?? true}
                  sx={
                    value === (question.valueTrue ?? true)
                      ? [booleanStyles.selectedButton, booleanStyles.selectedBg]
                      : [booleanStyles.defaultButton, booleanStyles.defaultBg]
                  }
                  style={booleanStyles.booleanItemLeft}
                >
                  {question.labelTrue}
                </ToggleButton>
              }
              label={""}
            />
            <div style={{ width: 20 }}></div>
            <FormControlLabel
              disabled={question.readOnly}
              style={booleanStyles.booleanItemRight}
              sx={
                value === (question.valueFalse ?? false)
                  ? booleanStyles.selectedBg
                  : booleanStyles.defaultBg
              }
              onClick={() => !question.readOnly && handleValueChange(question.valueFalse ?? false)}
              control={
                <ToggleButton
                  disabled={question.readOnly}
                  id={question.name}
                  value={question.valueFalse ?? false}
                  sx={
                    value === (question.valueFalse ?? false)
                      ? [booleanStyles.selectedButton, booleanStyles.selectedBg]
                      : [booleanStyles.defaultButton, booleanStyles.defaultBg]
                  }
                  style={booleanStyles.booleanItemRight}
                >
                  {question.labelFalse}
                </ToggleButton>
              }
              label={""}
            />
          </ToggleButtonGroup>
        </FormControl>
      );
  }
};
