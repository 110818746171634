import React from "react";
import { HeaderComponent } from "../../header/header.component";
import { useGlobalModal } from "../../use-modal/use-modal";

interface WebPreviewProps {
  url: string;
  close?: Function;
}

export const WebPreview = ({ url, close }: WebPreviewProps) => {
  return (
    <div className="h-screen">
      <HeaderComponent onBack={close} />
      <iframe src={url} title="web preview" width="100%" height="100%" />
    </div>
  );
};
const isInWebView = () => {
  if (typeof navigator !== "undefined" && navigator.product === "ReactNative") {
    return true;
  }
  if (typeof window !== "undefined" && window.ReactNativeWebView) {
    return true;
  }
  return false;
};
export const linkOpen = (props: WebPreviewProps) => {
  const openWithNewTab = !isInWebView();
  if (openWithNewTab) {
    window.open(props.url, "_blank");
    return;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useGlobalModal().open(<WebPreview url={props.url} />, {
    showHeader: false,
    modalType: "bottom",
    modalProps: {
      className: "dialog-full",
      PaperProps: {
        style: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          width: window.innerWidth + "px",
        },
      },
    },
  });
};
