import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ModuleSection, OnboardingCourseResourceType, SectionItem, StatusEnum } from "./type";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import arrowRight from "../../assets/img/arrow-right.png";
import {
  completeBoxMarginStyle,
  completeBoxStyle,
  completeIconStyle,
  subheader,
  sectionItem,
  item,
} from "./style";
import { useNavigate, useNavigation } from "react-router-dom";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import style from "./style.module.css";
import { ListSubheader } from "@mui/material";
import { color } from "../../../../mui-theme/palette";
import { useQuery } from "../../../../utils/hooks/use-query";
import { useGlobalModal } from "../../../../components/use-modal/use-modal";
import { PreQuizOverview } from "./screens/course-quiz-screen/pre-quiz-overview.component";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { OnboardingLessonStatus, OnboardingQuizStatus } from "../../types";
import { PruToast } from "../../../../components/pru-toast";
import { useDispatch } from "react-redux";
import { lessonStatusUpdateAsync } from "../../redux/onboarding-slice";
import { useTranslation } from "react-i18next";

type MenuListProps = {
  sections?: ModuleSection[];
};

export const MenuList = (props: MenuListProps) => {
  const { t } = useTranslation();
  const { sections = [] } = props;
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();
  const candidateId = query.get("candidateId");

  const [expand, setExpand] = useState(
    new Set(sections.filter((p) => p.expanded).map((p) => p.name)),
  );
  const onClick = useCallback((name: string) => {
    setExpand((expandeds) => {
      const next = new Set(expandeds);
      next.has(name) ? next.delete(name) : next.add(name);
      return next;
    });
  }, []);

  const getItemClickHandler = useCallback(
    (d: SectionItem) => {
      if (d.data.readonly) {
        return;
      }
      const handlerPromise = new Promise((resolve, reject) => {
        let pathSuffix = "lesson";
        if (d.type !== OnboardingCourseResourceType.Lesson) {
          if (d.data.status === OnboardingQuizStatus.DISABLED) {
            PruToast({
              message: t("complete_lessons_before_quiz"),
            });
            return;
          }
          pathSuffix = `quiz/${d.data.quizId}`;
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useGlobalModal().open(
            <PreQuizOverview
              onConfirm={() => {
                resolve(pathSuffix);
              }}
              onCancel={() => {
                reject(pathSuffix);
              }}
              quiz={d.data}
            />,
            {
              showHeader: false,
              modalType: "bottom",
              modalProps: {
                className: "dialog-full",
                PaperProps: {
                  style: {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    width: window.innerWidth + "px",
                  },
                },
              },
            },
          );
        } else {
          if (d.data.status === OnboardingLessonStatus.NOT_STARTED) {
            dispatch<any>(
              lessonStatusUpdateAsync({
                lessonId: d.data.lessonId,
                status: OnboardingLessonStatus.IN_PROGRESS,
              }),
            );
          }
          resolve(pathSuffix);
        }
      });
      handlerPromise.then((pathSuffix) => {
        navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/courses/${pathSuffix}?${query.toString()}`, {
          state: { data: d.data },
        });
      });
    },
    [navigate, query],
  );

  return (
    <div
      className={`${style["list-container"]} ${style.flex1} ${style["flex-column"]} ${style.padding}`}
    >
      <List>
        {sections.map((section, index) => {
          return (
            <div key={index} className={`${style.item}`}>
              <ListSubheader sx={subheader}>
                <ListItemButton
                  onClick={() => {
                    onClick(section.name);
                  }}
                >
                  {section.status === StatusEnum.COMPLETED ? (
                    <Box sx={completeBoxMarginStyle}>
                      <CheckIcon sx={completeIconStyle} />
                    </Box>
                  ) : (
                    <ListItemText
                      sx={sectionItem}
                      primary={`(${section.numerator}/${section.denominator})`}
                    />
                  )}
                  <ListItemText primary={section.title} />
                  {expand.has(section.name) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListSubheader>
              <Collapse in={expand.has(section.name)}>
                <div className={`${style["padding-left-right"]}`}>
                  <List>
                    {section.data.map((d, index) => {
                      return (
                        <div className={`${style["item-child"]}`} key={index}>
                          <ListItemButton onClick={() => getItemClickHandler(d)}>
                            {d.type !== OnboardingCourseResourceType.Lesson ? (
                              <div className="pr-1">
                                <Icon svg={SvgAssets.editNote} width={24} height={24} />
                              </div>
                            ) : null}
                            <ListItemText sx={item} primary={d.title} />
                            <StatusIcon status={d.status}></StatusIcon>
                          </ListItemButton>
                        </div>
                      );
                    })}
                  </List>
                </div>
              </Collapse>
            </div>
          );
        })}
      </List>
    </div>
  );
};

const StatusIcon = React.memo(({ status }: { status?: keyof typeof StatusEnum }) => {
  switch (status) {
    case OnboardingLessonStatus.COMPLETED:
    case OnboardingQuizStatus.PASSED:
      return (
        <Box sx={completeBoxStyle}>
          <CheckIcon sx={completeIconStyle} />
        </Box>
      );
    case OnboardingLessonStatus.IN_PROGRESS:
    case OnboardingQuizStatus.IN_PROGRESS:
      return <img src={arrowRight} alt="banner" />;
    case OnboardingQuizStatus.FAILED:
      return <Icon svg={SvgAssets.failure} height={32} width={32} />;
    default:
      return <ChevronRightIcon />;
  }
});
