import { http } from "../../utils/axios/axios";
import { getItem, setItem } from "../../utils/session-storage";

export function getEntryData(refId: string) {
  const url = `/material/entry-data/${refId}`;
  const sessionStorageKey = `hash-entry-data-${refId}`;
  const cache = getItem(sessionStorageKey);
  if (cache) {
    return Promise.resolve(cache);
  }
  return http.get(url, { headers: { __loading: true } }).then((res) => {
    const data = res.data;
    setItem(sessionStorageKey, data);
    return data;
  });
}
