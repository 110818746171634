import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { takeUIScreenViewEvent } from "../ga";
import { GAData, updateGAData } from "../redux/ga-slice";

export const useGAScreenViewEvent = (gaData: GAData, redux?: boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!redux) return;
    dispatch(updateGAData(gaData));
    return () => {
      dispatch(updateGAData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //TODO: take screen view event
    takeUIScreenViewEvent(gaData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
