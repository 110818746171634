import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorStateBlock, StatusCode } from "../error-state/error-state";

export const ErrorScreen: React.FC<{ statusCode: StatusCode }> = ({ statusCode = "-1" }) => {
  const { t } = useTranslation();
  window.document.title = t("error-state.title-403");
  return (
    <div className="w-screen flex h-screen">
      <div className="m-auto">
        <ErrorStateBlock statusCode={statusCode} />
      </div>
    </div>
  );
};
