/**
 * [
    {
        "localizations": [
            {
                "_id": "612df7677d0f01001272b1fd",
                "id": "612df7677d0f01001272b1fd",
                "locale": "en",
                "published_at": "2021-08-31T09:33:28.099Z"
            }
        ],
        "_id": "612df7677d0f01001272b200",
        "uuid": "b8d1a304fa084d67b6d124bbe41221c5",
        "type": "content",
        "name": "2021付清保費/保單期滿推廣計劃 - 簡介（英文版）",
        "introduction": "\n",
        "module": "Promotion",
        "canShare": false,
        "publishToSetOnly": true,
        "coverpage": {
            "id": "612df76695a58900268a6f66",
            "fileName": "PRUForce 2021 Paid up KV.jpg",
            "url": "https://pruforceagentcoedev.prudential.com.hk/cdn/public/Promotion/612df76695a58900268a6f66.jpeg",
            "type": "image/jpeg",
            "importId": "612df76695a58900268a6f66"
        },
        "tag": [],
        "i18nData": {},
        "zones": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "C",
            "X"
        ],
        "designations": [
            "G5",
            "G4",
            "G3",
            "G2",
            "G1L3",
            "G1L2",
            "G1L1",
            "SIC2",
            "SIC1",
            "SLU",
            "LU",
            "LA"
        ],
        "lastPublishTime": "2021-06-30T17:31:23.064Z",
        "columnIds": [
            "612df3d97326740011cd33cb"
        ],
        "status": "PublishedToSetOnly",
        "locale": "zh-Hant",
        "updatedAt": "2021-09-03T01:05:14.401Z",
        "published_at": "2021-08-31T09:33:28.338Z",
        "createdAt": "2021-02-11T04:12:35.194Z",
        "columnReferences": [
            {
                "_id": "613174ca01553100113f84a2",
                "module": "Promotion",
                "weight": 32.1,
                "__v": 0,
                "column": {
                    "_id": "612df3d97326740011cd33cb",
                    "name": {
                        "en": "壽險",
                        "zh-Hant": "壽險"
                    },
                    "weight": 50,
                    "module": "Promotion",
                    "description": "",
                    "lastPublishTime": "2021-07-21T09:23:06.449Z",
                    "published_at": "2021-08-31T09:18:18.055Z",
                    "createdAt": "2020-11-24T10:32:19.181Z",
                    "updatedAt": "2021-07-21T09:23:06.449Z",
                    "__v": 0,
                    "systemUpdatedAt": "2021-07-21T09:23:06.449Z",
                    "region": "HK",
                    "channel": "AGENCY",
                    "id": "612df3d97326740011cd33cb"
                },
                "id": "613174ca01553100113f84a2"
            }
        ],
        "systemUpdatedAt": "2021-09-03T01:05:14.488Z",
        "__v": 6,
        "category": {
            "_id": "612df3d87326740011cd33c3",
            "name": "2021Q1",
            "module": "Promotion",
            "lastPublishTime": "2021-01-04T04:10:34.361Z",
            "published_at": "2021-08-31T09:18:16.398Z",
            "createdAt": "2021-01-04T04:10:34.361Z",
            "updatedAt": "2021-01-04T04:10:34.361Z",
            "__v": 0,
            "parent": "612df3d77326740011cd33c0",
            "systemUpdatedAt": "2021-01-04T04:10:34.361Z",
            "region": "HK",
            "channel": "AGENCY",
            "id": "612df3d87326740011cd33c3"
        },
        "effectiveDate": "2021-08-31T16:00:00.000Z",
        "expiredDate": null,
        "materialOrder": [],
        "region": "HK",
        "channel": "AGENCY",
        "childItems": [],
        "id": "612df7677d0f01001272b200"
    }
]
 */

export interface ResourceObject {
  id: string;
  uuid: string;
  type: ResourceType;
  name: string;
  introduction: string;
  module: string;
  canShare: boolean;
  tag: string[];
  i18nData: FileResourceType | ContentResourceType | LinkResourceType;
  status: string;
  locale: string;
}

export interface FileResourceType {
  file: File;
}
export interface File {
  id: string;
  fileName: string;
  url: string;
  type: string;
  importId: string;
}

export interface ContentResourceType {
  content: string;
}

export interface LinkResourceType {
  link: string;
}

export interface GetResourceThunkArg {
  langkey: string;
  uuid: string;
  refId: string;
}
export enum ResourceType {
  content = "content",
  file = "file",
  link = "link",
}
