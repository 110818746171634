export enum TrackingEventType {
  "start_view" = "start_view",
  "end_view" = "end_view",
  "click" = "click",
  // "share" = "share" // invalid on web
}
export enum AcceptType {
  "yes" = "yes",
  "no" = "no",
}
export enum EventModule {
  "resource" = "resource",
  "agent_profile_site" = "agent_profile_site",
  "lead_form" = "leadform",
  // "agent_profile" = "publicweb::agent-profile",
}
export enum EventFeature {
  "resource" = "resource",
  "agent_info" = "agent_info",
  "microsite" = "microsite",
}
export interface TrackingData {
  name: string; //"prospect::view_profile",
  type?: TrackingEventType | undefined;
  module: EventModule;
  target?: string; // "resource-uuid/whatsapp/phone/email/vcard/tnc_declined/leave_us_your_contact",
  feature: EventFeature;
  stage?: string | undefined; //"optional",
  journey?: string | undefined; //"optional",
  context?: {
    src: string; // fixed value "publicweb",
    userAgent: string; // "browser-user-agent-info"
    uuid: string; // user uuid (randomly generated)
    referrer?: string; // document.referrer
  };
  params?: {
    startDatetime: number; //"page-onload-time"
    endDatetime?: number; //"page-unload-time: "
    refId?: string; // refId from url params
    lang?: string; // user selected language
    sessionId?: string;
    trigger?: string; // trigger event name: unmount/visibilitychange/interval
    target?: string; // event target
  };
}
export interface TrackingInfo {
  subject: string; //"anonymous:ref={ref}",
  data: TrackingData | undefined;
}

/** TrackingSource for public-portal */
export const TrackingSource = "publicweb";

/** local-storage key, if user agree to accept tracking */
export const AcceptTrackingKey = "appcpt_tracking_key";

export const TncDeclined = "tnc_declined";
/**
 * html element className for auto click-event-tracking
 * example: <Button className="auto_click_tracking">auto_tracking</Button>
 */
export const AutoTrackingClassName = "auto_click_tracking";
export const AutoVideoTrackingClassName = "auto_video_click_tracking";
/**
 * custom data attribute name
 * set custom data for auto_click_tracking, default use BaseScreen trackingData
 *
 * usage(pre-requirement: add above AutoTrackingClassName to html element):
 * <Button data-auto_click_tracking={JSON.stringify(trackingData)} className="auto_click_tracking">auto_tracking</Button>
 */
export const AutoTrackingDataKey = "data-auto_click_tracking"; // custom attribute must use this format: data-xxx_yyy_zzz
export const DateFormat = "YYYY-MM-DD HH:mm:ss";
