import { PointGroup } from "signature_pad";

interface SignatureRequirements {
  /**
   * for each line
   */
  minSpeedDiff: number;
}

export function verifySignature(
  pointGroups: PointGroup[],
  requirements: SignatureRequirements,
): boolean {
  // If the signature pad can't capture data, return true
  if (pointGroups.length === 0) return true;

  const { minSpeedDiff } = requirements;

  let wrongCount = 0;

  for (const pointGroup of pointGroups) {
    const { points } = pointGroup;

    if (points.length > 1) {
      let prevPoint = points[0];
      let maxSpeed: number | undefined = undefined;
      let minSpeed: number | undefined = undefined;

      for (let i = 1; i < points.length; i++) {
        const currPoint = points[i];

        const timeDiff = currPoint.time - prevPoint.time;
        if (timeDiff < 5) {
          return false;
        }

        const distance = Math.sqrt(
          (currPoint.x - prevPoint.x) ** 2 + (currPoint.y - prevPoint.y) ** 2,
        );

        const speed = distance / timeDiff;

        if (!maxSpeed || maxSpeed < speed) maxSpeed = speed;
        if (!minSpeed || minSpeed > speed) minSpeed = speed;

        prevPoint = currPoint;
      }

      if (
        maxSpeed === undefined ||
        minSpeed === undefined ||
        maxSpeed === minSpeed ||
        (maxSpeed && minSpeed && maxSpeed - minSpeed < minSpeedDiff)
      ) {
        wrongCount++;
      }
    }
  }

  if (wrongCount === pointGroups.length) return false;

  return true;
}

/**
 * about 1-30 ms
 * average ~ 10
 * @param canvas
 * @returns
 */
export const caculate = (canvas: HTMLCanvasElement) => {
  const data1 = performance.now();

  // Get canvas context
  const context = canvas.getContext("2d");
  if (!context) return;

  // Get canvas width and height
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  // Define eye color range (assume blue)
  const eyeColorRange = {
    minRed: 51,
    maxRed: 51,
    minGreen: 51,
    maxGreen: 51,
    minBlue: 51,
    maxBlue: 51,
  };

  // Get image data from canvas
  const imageData = context.getImageData(0, 0, canvasWidth, canvasHeight);
  const pixelData = imageData.data;

  // Variables for calculating area
  let area = 0;

  // Iterate through pixel data
  for (let i = 0; i < pixelData.length; i += 4) {
    const red = pixelData[i];
    const green = pixelData[i + 1];
    const blue = pixelData[i + 2];

    // Check if pixel color is in eye color range
    if (isEyeColor(red, green, blue, eyeColorRange)) {
      // Add to eye color area
      area++;
    }
  }

  const data2 = performance.now();

  // Output area
  // console.log("Eye color area: ", area, "Time:", data2 - data1);
  return area;
};

function isEyeColor(
  red: number,
  green: number,
  blue: number,
  eyeColorRange: {
    minRed: number;
    maxRed: number;
    minGreen: number;
    maxGreen: number;
    minBlue: number;
    maxBlue: number;
  },
) {
  // Logic to determine if color is in eye color range
  // Implement according to specific requirements
  // Here we just check if RGB values are within specified range

  return (
    red >= eyeColorRange.minRed &&
    red <= eyeColorRange.maxRed &&
    green >= eyeColorRange.minGreen &&
    green <= eyeColorRange.maxGreen &&
    blue >= eyeColorRange.minBlue &&
    blue <= eyeColorRange.maxBlue
  );
}

function calculateExecutionTime(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: any[]) {
    const start = performance.now();
    const result = originalMethod.apply(this, args);
    const end = performance.now();
    const executionTime = end - start;
    return result;
  };

  return descriptor;
}
