import React, { useState } from "react";
import { DocItem } from "./type";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CircleIcon from "@mui/icons-material/Circle";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { linkDoc, docDot, flex, modalBox, docText, docTips, docButton, modalList } from "./style";
import style from "./style.module.css";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

type MenuListOfDocProps = {
  items?: DocItem[];
  translate: {
    docPre: string;
    doc: string;
    updoc: string;
    docTail: string;
  };
};

export const MenuListOfDoc = (props: MenuListOfDocProps) => {
  const { items = [] } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (items.length > 0) setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  return (
    <div className={`${style.padding} ${style.greybg} ${style["bottom0"]}`}>
      <span className={`${style.docText}`}>{props.translate.docPre}</span>
      <Link sx={linkDoc} component="button" underline="none" onClick={handleOpen}>
        {props.translate.doc}
      </Link>
      <span className={`${style.docText}`}>{props.translate.docTail}</span>
      <Modal
        sx={flex}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalBox}>
          <div className={`${style["modal-title"]}`}>
            <Typography id="modal-modal-title" variant="h6">
              {props.translate.updoc}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </div>
          <List sx={modalList}>
            {items.map((i, index) => {
              return (
                <div key={i.name}>
                  <ListItemText sx={docTips} primary={i.title}></ListItemText>
                  <List>
                    <div className={`${style["modal-title-item-con"]}`}>
                      {i.data.map((t) => {
                        return (
                          <div key={t} className={`${style["modal-item"]}`}>
                            <CircleIcon sx={docDot}></CircleIcon>
                            <ListItemText key={t} primary={t} sx={docText}></ListItemText>
                          </div>
                        );
                      })}
                    </div>
                  </List>
                  {index === 0 && <div className={`${style["modal-item-spacer"]}`}></div>}
                </div>
              );
            })}
          </List>
          <Button color="secondary" variant="contained" sx={docButton} onClick={handleClose}>
            {t("Confirm")}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
