import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { get } from "lodash";
import {
  ArrowForwardIos,
  LocalPhoneOutlined,
  WhatsApp,
  MailOutline,
  PersonOutline,
} from "@mui/icons-material";
import {
  AgentProfileItem,
  AwardItem,
  AboutMeDisplayStatusEnum,
} from "../../../types/agent-profile-types";
import {
  EventModule,
  EventFeature,
} from "../../../../../utils/event-tracking/event-tracking.model";
import { ROOT_PATH } from "../../../../../routes/constants";
import { AGENT_PROFILE_BASE_PATH } from "../../../constants";
import BasicInfoImage from "../../../components/BasicInfoImage";
import BasicInfo from "../../../components/BasicInfo";
import AwardList from "../../../components/AwardList";
import ItemCard from "../../../components/ItemCard";
import WhitePlayer from "../../../components/WhitePlayer";
import { ArrowRightIcon } from "../../../common/icons";
import { isPhone } from "../../../../../constants/constants";
import { fetchSaveContact } from "../../../network/agentProfileCrud";
import { useDispatch, useSelector } from "react-redux";
import { NOT_FOUND_ERROR_PAGE_PATH } from "../../../../../constants/constants";
import { useStyles } from "./styles/agent-profile-edit-main-form.styles";
import { translateAllParameters } from "../../../utils/i18n-utils";
import { getAboutDataTemplateMapping } from "../../../utils/data-utils";
import { RootState } from "../../../../../redux/store";
import GAClickFragment from "../../../../../common/ga/components/ga-click-fragment";

type AgentProfileEditMainFormProps = {
  agentProfile: AgentProfileItem;
  awardList: AwardItem[];
  mappedLocale: string;
  promotionMappedLocale: string;
};

type ReferralLinkOptions = { status: "0" | "1"; isOn: "0" | "1" };

const AgentProfileEditMainForm: FC<AgentProfileEditMainFormProps> = ({
  agentProfile,
  awardList,
  mappedLocale,
  promotionMappedLocale,
}) => {
  const { PUBLIC_URL } = process.env;
  const navigate = useNavigate();
  const { refId } = useParams<{ refId: string }>();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const hashEntry = useSelector<RootState>((state) => state.common?.hashEntry) as any;
  // show or hide the Referral link entry
  const showReferralLinkEntry = useMemo(() => {
    const options = get(hashEntry, "data.referralLinkOptions", []);
    if (!options || options.length < 1) {
      return false;
    }
    return options.some(
      (option: ReferralLinkOptions) => option.status === "1" && option.isOn === "1",
    );
  }, [hashEntry]);

  let phone: any = get(agentProfile, "agentDetail.phone.mobile", "");
  phone = phone ? phone.replaceAll("+", "").replaceAll(" ", "") : "";
  const hasAwards = agentProfile.awards?.length !== 0 || agentProfile.awardBadges?.length !== 0;
  const hasPromotions =
    agentProfile.promotions[promotionMappedLocale] &&
    agentProfile.promotions[promotionMappedLocale].length !== 0;

  const [isIPadAwardViewMore, setIsIPadAwardViewMore] = useState<boolean>(false);
  const queryParams = new URLSearchParams(window.location.search);
  const isPreview = queryParams.get("preview");

  const getSaveContact = () => {
    if (refId) {
      try {
        const res: string = fetchSaveContact(refId, dispatch);
        return res;
      } catch (err) {
        navigate(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
  };

  const resolveAboutMeParameter = (text: string) =>
    translateAllParameters(getAboutDataTemplateMapping(agentProfile), text);

  const clickGetInTouchWithMeTrackingData = {
    name: "prospect::click_contact",
    module: EventModule.agent_profile_site,
    feature: EventFeature.agent_info,
    journey: "contact_agent",
  };

  const clickViewMoreAwardTrackingData = {
    name: "prospect::click_award",
    module: EventModule.agent_profile_site,
    feature: EventFeature.agent_info,
    journey: "award",
  };

  const clickPromotionTrackingData = {
    name: "prospect::click_promotion",
    module: EventModule.agent_profile_site,
    feature: EventFeature.agent_info,
    journey: "promotion",
  };

  return (
    <div>
      <BasicInfoImage agentProfile={agentProfile} />
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <div className={classes.basicInfoSectionContainer}>
            <BasicInfo agentProfile={agentProfile} />
          </div>
          {isPhone && (
            <div className={classes.sectionContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>
                  {t(`agentProfile.pp_get_in_touch_with_me_header_title`)}
                </div>
              </div>
              {isPreview ? (
                <div className={classes.intouchMeContainer}>
                  <div className={classes.intouchMeIcon}>
                    <LocalPhoneOutlined className={classes.pointerEventsNone} />
                  </div>
                  {agentProfile.enableWhatsapp && (
                    <div className={classes.intouchMeIcon}>
                      <WhatsApp className={classes.pointerEventsNone} />
                    </div>
                  )}
                  <div className={classes.intouchMeIcon}>
                    <MailOutline className={classes.pointerEventsNone} />
                  </div>
                  <div className={classes.intouchMeIcon}>
                    <img
                      src={PUBLIC_URL + "/media/svg/icons/Person/add-person.svg"}
                      className={classes.pointerEventsNone}
                    />
                  </div>
                </div>
              ) : (
                <div className={classes.intouchMeContainer}>
                  <a
                    href={`tel:${agentProfile.agentDetail.phone.mobile}`}
                    className={`auto_click_tracking ${classes.intouchMeIcon}`}
                    data-auto_click_tracking={JSON.stringify({
                      ...clickGetInTouchWithMeTrackingData,
                      target: "phone",
                    })}
                  >
                    <LocalPhoneOutlined className={classes.pointerEventsNone} />
                  </a>
                  {agentProfile.enableWhatsapp && (
                    <a
                      href={`https://wa.me/${phone}`}
                      target="_blank"
                      className={`auto_click_tracking ${classes.intouchMeIcon}`}
                      data-auto_click_tracking={JSON.stringify({
                        ...clickGetInTouchWithMeTrackingData,
                        target: "whatsapp",
                      })}
                    >
                      <WhatsApp className={classes.pointerEventsNone} />
                    </a>
                  )}
                  <a
                    href={`mailto:${agentProfile.agentDetail.email}`}
                    className={`auto_click_tracking ${classes.intouchMeIcon}`}
                    data-auto_click_tracking={JSON.stringify({
                      ...clickGetInTouchWithMeTrackingData,
                      target: "email",
                    })}
                  >
                    <MailOutline className={classes.pointerEventsNone} />
                  </a>
                  <a
                    href={getSaveContact()}
                    className={`auto_click_tracking ${classes.intouchMeIcon}`}
                    data-auto_click_tracking={JSON.stringify({
                      ...clickGetInTouchWithMeTrackingData,
                      target: "vcard",
                    })}
                  >
                    <img
                      src={PUBLIC_URL + "/media/svg/icons/Person/add-person.svg"}
                      className={classes.pointerEventsNone}
                    />
                  </a>
                </div>
              )}
              {showReferralLinkEntry && (
                <div className={classes.viewMore}>
                  <GAClickFragment
                    journey="referral_link_form_submission"
                    stage="enter_referral_link"
                    object_name="LeaveUsYourContact"
                    object_type="HYPERLINK"
                  >
                    <Link
                      to={`../../form/${refId}${isPreview ? "?preview=1" : ""}`}
                      relative="path"
                      className="auto_click_tracking"
                      data-auto_click_tracking={JSON.stringify({
                        ...clickGetInTouchWithMeTrackingData,
                        target: "leave_us_your_contact",
                      })}
                    >
                      {t("arl.leave-us-your-contact")}
                    </Link>
                  </GAClickFragment>
                  <ArrowRightIcon />
                </div>
              )}
            </div>
          )}
          {agentProfile.myAboutMe &&
            agentProfile.aboutMeDisplayStatus === AboutMeDisplayStatusEnum.SHOW && (
              <div className={classes.sectionContainer}>
                <div className={classes.headerContainer}>
                  <div className={classes.header}>{t(`agentProfile.pp_about_me_header_title`)}</div>
                </div>
                <div className={classes.aboutMeContainer}>
                  {resolveAboutMeParameter(agentProfile.myAboutMe?.[mappedLocale])}
                </div>
              </div>
            )}
          {!!agentProfile.myHighlight?.[mappedLocale] && (
            <div className={classes.sectionContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>
                  {t(`agentProfile.pp_my_highlight_header_title`)}
                </div>
              </div>
              <WhitePlayer
                className={isPhone ? classes.playerContainer : classes.iPadViewPlayerContainer}
                url={agentProfile.myHighlight?.[mappedLocale]}
              />
            </div>
          )}
        </div>
        <div className={classes.subContainer}>
          {!isPhone && (
            <div className={classes.sectionContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>
                  {t(`agentProfile.pp_get_in_touch_with_me_header_title`)}
                </div>
              </div>
              {isPreview ? (
                <div className={classes.intouchMeContainer}>
                  <div className={classes.intouchMeIcon}>
                    <LocalPhoneOutlined className={classes.pointerEventsNone} />
                  </div>
                  {agentProfile.enableWhatsapp && (
                    <div className={classes.intouchMeIcon}>
                      <WhatsApp className={classes.pointerEventsNone} />
                    </div>
                  )}
                  <div className={classes.intouchMeIcon}>
                    <MailOutline className={classes.pointerEventsNone} />
                  </div>
                  <div className={classes.intouchMeIcon}>
                    <img
                      src={PUBLIC_URL + "/media/svg/icons/Person/add-person.svg"}
                      className={classes.pointerEventsNone}
                    />
                  </div>
                </div>
              ) : (
                <div className={classes.intouchMeContainer}>
                  <a
                    href={`tel:${agentProfile.agentDetail.phone.mobile}`}
                    className={`auto_click_tracking ${classes.intouchMeIcon}`}
                    data-auto_click_tracking={JSON.stringify({
                      ...clickGetInTouchWithMeTrackingData,
                      target: "phone",
                    })}
                  >
                    <LocalPhoneOutlined className={classes.pointerEventsNone} />
                  </a>
                  {agentProfile.enableWhatsapp && (
                    <a
                      href={`https://wa.me/${phone}`}
                      target="_blank"
                      className={`auto_click_tracking ${classes.intouchMeIcon}`}
                      data-auto_click_tracking={JSON.stringify({
                        ...clickGetInTouchWithMeTrackingData,
                        target: "whatsapp",
                      })}
                    >
                      <WhatsApp className={classes.pointerEventsNone} />
                    </a>
                  )}
                  <a
                    href={`mailto:${agentProfile.agentDetail.email}`}
                    className={`auto_click_tracking ${classes.intouchMeIcon}`}
                    data-auto_click_tracking={JSON.stringify({
                      ...clickGetInTouchWithMeTrackingData,
                      target: "email",
                    })}
                  >
                    <MailOutline className={classes.pointerEventsNone} />
                  </a>
                  <a
                    href={getSaveContact()}
                    className={`auto_click_tracking ${classes.intouchMeIcon}`}
                    data-auto_click_tracking={JSON.stringify({
                      ...clickGetInTouchWithMeTrackingData,
                      target: "vcard",
                    })}
                  >
                    <img
                      src={PUBLIC_URL + "/media/svg/icons/Person/add-person.svg"}
                      className={classes.pointerEventsNone}
                    />
                  </a>
                </div>
              )}
              {showReferralLinkEntry && (
                <div className={classes.viewMore}>
                  <GAClickFragment
                    journey="referral_link_form_submission"
                    stage="enter_referral_link"
                    object_name="LeaveUsYourContact"
                    object_type="HYPERLINK"
                  >
                    <Link
                      to={`../../form/${refId}${isPreview ? "?preview=1" : ""}`}
                      relative="path"
                      className="auto_click_tracking"
                      data-auto_click_tracking={JSON.stringify({
                        ...clickGetInTouchWithMeTrackingData,
                        target: "leave_us_your_contact",
                      })}
                    >
                      {t("arl.leave-us-your-contact")}
                    </Link>
                  </GAClickFragment>
                  <ArrowRightIcon />
                </div>
              )}
            </div>
          )}
          {hasAwards && (
            <div className={classes.sectionContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>{t(`agentProfile.pp_my_awards_header_title`)}</div>
              </div>
              <>
                <AwardList
                  mappedLocale={mappedLocale}
                  badges={agentProfile.awardBadges}
                  awards={isIPadAwardViewMore ? awardList : agentProfile.awards}
                />
                <div
                  className={`auto_click_tracking ${classes.viewMore}`}
                  data-auto_click_tracking={JSON.stringify(clickViewMoreAwardTrackingData)}
                  onClick={() => {
                    if (isPhone) {
                      navigate(`${ROOT_PATH}${AGENT_PROFILE_BASE_PATH}/${refId}/award-view-more`);
                    } else {
                      setIsIPadAwardViewMore(!isIPadAwardViewMore);
                    }
                  }}
                >
                  {!isIPadAwardViewMore
                    ? t("agentProfile.pp_view_more_label")
                    : t("agentProfile.pp_view_less_label")}{" "}
                  <ArrowRightIcon />
                </div>
              </>
            </div>
          )}
          {hasPromotions && (
            <div className={classes.sectionContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>{t(`agentProfile.pp_promotions_header_title`)}</div>
              </div>
              <div>
                {agentProfile.promotions[promotionMappedLocale].map((promotion) => (
                  <div
                    className={`auto_click_tracking ${classes.promotionCard}`}
                    data-auto_click_tracking={JSON.stringify({
                      ...clickPromotionTrackingData,
                      target: promotion.uuid,
                    })}
                    onClick={() => {
                      if (promotion.url) {
                        window.location.href =
                          `${promotion.url}/${refId}` + (isPreview ? "?preview=1" : "");
                      }
                    }}
                  >
                    <ItemCard
                      disableSelect
                      item={promotion}
                      image={promotion.coverpage.url}
                      name={promotion.name || "-"}
                      labels={promotion.tag}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentProfileEditMainForm;
