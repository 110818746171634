import React, { Component } from "react";
import { CustomQuestionFileModel } from "./question";
import CircularProgress from "@mui/material/CircularProgress";
import { StorageFileTypeEnum } from "../../../network/file-op-interface";
import { confirmActionAsync } from "survey-core";
import CancelIcon from "@mui/icons-material/Cancel";
import { ActionStatusEnum } from "./action-enum";
import { isArray, get, set } from "lodash";
import { getI18n } from "react-i18next";
import { imageOpen } from "../../../../../components/modal/image-open";
import { pdfOpen } from "../../../../../components/modal/pdf-open";
import { getIconSource } from "./get-file-icon";
import { FileContent } from "./content-interface";
import Compressor from "compressorjs";
import { confirm } from "../../../../../components/modal/comfirm";

type Props = {
  /**
   * should have file or resourceId
   */
  file?: File;
  resourceId?: string;
  fileName?: string;
  base64?: string;
  index?: number;

  fileType?: StorageFileTypeEnum;
  mimeType?: string;
  questionModel: CustomQuestionFileModel;
};
type States = {
  base64URL?: string;
  resourceId?: string;
  file?: File;

  mimeType?: string;

  loading: boolean;
  uploading: boolean;
  fileName?: string;
};
interface ImageSize {
  width: number;
  height: number;
}

export class FileCard extends Component<Props, States> {
  question: CustomQuestionFileModel;
  constructor(props: Props) {
    super(props);
    this.question = this.props.questionModel;
    this.state = {
      loading: this.props.base64 ? false : true,
      uploading: false,
      resourceId: this.props.resourceId,
      base64URL: this.props.base64,
      file: this.props.file,
      fileName: this.props.fileName,
      mimeType: this.props.mimeType,
    };
  }

  getImageSize(file: File): Promise<ImageSize> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          });
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    });
  }

  async compress(file: File, maxFileSize: number): Promise<File> {
    const imageSize = await this.getImageSize(file);
    return new Promise((resolve) => {
      new Compressor(file, {
        width: imageSize?.width * 0.95,
        height: imageSize?.height * 0.95,
        success: (result) => {
          if (result.size > maxFileSize * 1024 * 1024) {
            resolve(this.compress(result as File, maxFileSize));
          } else {
            resolve(result as File);
          }
        },
      });
    });
  }

  removeFromQuestionFiles(file?: File) {
    if (!file) return;
    const index = this.question.files.findIndex((v) => v.content === file);
    if (index === -1) return;
    const indent = this.question.originResource.length;
    const realIndex = index + indent;
    const values = this.question.value.slice();
    values.splice(realIndex, 1);
    this.question.value = values;
    this.question.files.splice(index, 1);
  }

  componentDidMount(): void {
    this.loadFile(this.props.file);
    this.loadResource(this.props.resourceId);
  }
  loadResource(resourceId?: string) {
    if (!resourceId) return;
    this.question.survey.downloadFile(
      this.question,
      this.question.name,
      resourceId,
      (status, data) => {
        if (status === ActionStatusEnum.error) {
          this.setState({ loading: false });
          this.question.onFileError.next(
            getI18n().t("onboarding.fail_to_get_file", {
              fileName: this.state.fileName ?? "",
            }),
          );
          return;
        }
        if (status === ActionStatusEnum.success) {
          this.setState({
            loading: false,
            base64URL: data.base64URL,
            fileName: data.fileName,
            mimeType: data.mimeType,
          });
        }
      },
    );
  }
  async loadFile(file?: File) {
    if (!file) return;
    let f = file;
    if (file.type.startsWith("image")) {
      this.setState({
        fileName: f.name,
        mimeType: f.type,
      });
      const autoResize = this.question.autoResize;
      const maxFileSize = this.question.maxFileSize;
      if (autoResize && file.size > maxFileSize * 1024 * 1024) {
        f = await this.compress(file, maxFileSize);
      }
      set(f, "index", get(file, "index", 1));
    }
    if (!this.question.allFilesOk([f])) return this.removeFromQuestionFiles(file);
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const result = e.target?.result as string;
      this.setState({
        base64URL: result,
        fileName: f.name,
        mimeType: f.type,
        loading: false,
      });
      if (this.question.storeDataAsText) {
        if (this.question.allowMultiple && isArray(this.question.value)) {
          const value = this.question.value.slice();
          value.push({
            content: result,
            name: f.name,
            type: f.type,
            index: get(f, "index"),
          });
          this.question.value = value;
        } else {
          this.question.value = [
            {
              content: result,
              name: f.name,
              type: f.type,
              index: get(f, "index"),
            },
          ];
        }
      } else {
        this.uploadFile(f);
      }
    };
    fileReader.readAsDataURL(f);
  }
  removeFile() {
    if (!this.question.storeDataAsText) {
      const resourceId = this.state.resourceId;
      if (!resourceId) return;
      this.setState({ loading: true });
      this.question.fileUploadService.addTasks([
        () => {
          return new Promise((resolve) => {
            this.question.survey.clearFiles(
              this.question,
              this.question.name,
              this.question.value,
              resourceId,
              (status, data) => {
                resolve(undefined);
                this.setState({ loading: false });
                if (status === ActionStatusEnum.success) {
                  if (this.state.file) {
                    const index = this.question.files.findIndex(
                      (f) => f.content === this.props.file,
                    );
                    if (index !== -1) {
                      this.question.files.splice(index, 1);
                    }
                  }
                  if (this.state.resourceId) {
                    const index = (this.question.value as FileContent[]).findIndex(
                      (f: any) => get(f, "content", undefined) === this.state.resourceId,
                    );
                    if (index !== -1) {
                      const value = this.question.value.slice();
                      value.splice(index, 1);
                      this.question.value = value;
                    }
                    const orIndex = this.question.originResource.findIndex(
                      (c: any) => get(c, "content", undefined) === this.state.resourceId,
                    );
                    if (orIndex !== -1) {
                      this.question.originResource.splice(orIndex, 1);
                    }
                  }
                  this.question.onFileChange.next();
                }
                if (status === ActionStatusEnum.error) {
                  this.question.onFileError.next(
                    getI18n().t("onboarding.fail_to_delete_file", {
                      fileName: this.state.fileName ?? "",
                    }),
                  );
                  return;
                }
              },
            );
          });
        },
      ]);
    } else {
      if (this.question.allowMultiple && isArray(this.question.value)) {
        const index = this.question.value.findIndex((v) => v.content === this.state.base64URL);
        if (index === -1) return;
        const newValue = this.question.value.slice();
        newValue.splice(index, 1);
        this.question.value = newValue;
      } else {
        this.question.value = [];
      }
    }
  }
  doRemoveFile() {
    if (this.question.needConfirmRemoveFile) {
      confirm({
        message: this.question.getConfirmRemoveMessage(this.state.fileName ?? ""),
      }).then(({ ok }) => {
        if (ok) {
          this.removeFile();
        }
      });
    } else {
      this.removeFile();
    }
  }
  uploadFile(compressFile: File) {
    if (this.state.uploading) return;
    this.setState({ uploading: true });
    this.question.fileUploadService.addTasks([
      () => {
        return new Promise((resolve) => {
          this.question.survey.uploadFiles(
            this.question,
            this.question.name,
            [compressFile],
            (status, data) => {
              resolve(undefined);

              this.setState({ uploading: false });
              if (status === ActionStatusEnum.error) {
                this.removeFromQuestionFiles(this.props.file);
                this.question.onFileChange.next();
                this.question.onFileError.next(
                  getI18n().t("onboarding.fail_to_upload_file", {
                    fileName: compressFile.name,
                  }),
                );
                return;
              }
              if (status === ActionStatusEnum.minSizeError) {
                this.removeFromQuestionFiles(this.props.file);
                this.question.onFileChange.next();
                this.question.onFileError.next(getI18n().t("onboarding.file_is_too_small"));
                return;
              }
              if (status === ActionStatusEnum.imageBlurError) {
                this.removeFromQuestionFiles(this.props.file);
                this.question.onFileChange.next();
                this.question.onFileError.next(getI18n().t("onboarding.file_is_too_blur"));
                return;
              }
              if (status === ActionStatusEnum.success) {
                const resourceId = data[0];
                if (isArray(this.question.value)) {
                  const values = this.question.value.slice();
                  const index = this.question.files.findIndex((v) => v.content === this.props.file);
                  if (index === -1) return;
                  const indent = this.question.originResource.length;
                  const realIndex = index + indent;
                  values.splice(realIndex, 1, {
                    content: resourceId,
                    name: compressFile.name,
                    type: compressFile.type,
                    index: get(this.question.files, `[${index}].content.index`),
                  });
                  this.question.value = values;
                } else {
                  this.question.value = [
                    {
                      content: resourceId,
                      name: compressFile.name,
                      type: compressFile.type,
                      index: 1,
                    },
                  ];
                }

                this.setState({ resourceId: resourceId });
              }
            },
          );
        });
      },
    ]);
  }

  render(): React.ReactNode {
    const loading = this.state.uploading || this.state.loading;
    const canPreivew = this.question.canPreviewImage({ content: this.state.base64URL });
    const isImage = this.state.mimeType?.startsWith("image") ?? false;
    const success = !!this.state.base64URL;

    return (
      <span
        style={{
          position: "relative",
        }}
      >
        <div>
          {loading && (
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                style={{
                  height: 24,
                  width: 24,
                  color: "#666",
                }}
              />
            </div>
          )}
          {canPreivew ? (
            <img
              onClick={() => {
                if (this.state.base64URL)
                  imageOpen({
                    url: this.state.base64URL,
                  });
              }}
              style={{
                width: `${this.question.imageWidth ?? 80}px`,
                height: `${this.question.imageHeight ?? 80}px`,
                borderRadius: 8,
              }}
              src={this.state.base64URL}
              alt="File preview"
            />
          ) : (
            <div
              onClick={() => {
                if (this.state.mimeType === "application/pdf" && this.state.base64URL)
                  pdfOpen({
                    url: this.state.base64URL,
                  });
              }}
              style={{
                width: `${this.question.imageWidth ?? 80}px`,
                height: `${this.question.imageHeight ?? 80}px`,
                borderRadius: 8,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                style={{
                  width: `${36}px`,
                  height: `${36}px`,
                  color: "#666",
                }}
                src={getIconSource(loading, success, isImage)}
                alt="img-icon"
              ></img>
            </div>
          )}
          {!this.question.isReadOnly && !loading ? (
            <div
              style={{
                color: "#cccccc",
                position: "absolute",
                top: 0,
                right: 0,
              }}
              onClick={() => this.doRemoveFile()}
            >
              <CancelIcon
                style={{
                  width: 16,
                  height: 16,
                }}
              ></CancelIcon>
            </div>
          ) : null}
        </div>
      </span>
    );
  }
}
