import { Button } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SvgAssets } from "../../../../components/icon/assets";
import Icon from "../../../../components/icon/icon";
import { getI18nData } from "../../../../components/surveyjs/survey/survey.util";
import { useAppSelector } from "../../../../redux/store";
import { EVENT_PATH, ROOT_PATH, constants } from "../../../../routes/constants";
import { CheckAction, EventType, REG_ATTENDANCE_RULE, Session } from "../../network/type";
import "../../pages/detail/detail-screen.css";
import { formatEventTime } from "../../util/event.util";

export const Sessions = () => {
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.event.eventDetailData);
  const multiSession = data?.multiSession;
  return data?.sessions && data?.sessions?.length > 0 ? (
    <Fragment>
      <div className="px-3 w-full my-5 ">
        {data?.sessions?.map((session, i) => {
          const isParenSession = session?.subSessions && session.subSessions?.length > 0;

          return (
            <div className={` mb-4 md:mb-5 `} key={i}>
              <SessionItem
                item={session}
                num={i + 1}
                isParenSession={isParenSession}
                multiSession={multiSession}
              />

              {session?.subSessions?.map((item, inx) => {
                return (
                  <SessionItem
                    item={item}
                    num={inx + 1}
                    isSub
                    key={item.key}
                    multiSession={multiSession}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </Fragment>
  ) : null;
};

const SessionItem = ({
  item,
  num,
  isSub,
  isParenSession,
  multiSession,
}: {
  item: Session;
  num: number;
  isSub?: boolean;
  isParenSession?: boolean;
  multiSession?: boolean;
}) => {
  const { refId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useAppSelector((state) => state.event.eventDetailData);
  const sessionCheckData = useAppSelector((state) => state.event.sessionCheckData);
  const { name, venue, startTime, endTime, regAttendanceRule } = item;

  const handleCheckIn = useCallback(
    (session: Session) => {
      navigate(
        `${ROOT_PATH}/${EVENT_PATH}/check/${refId}?action=${CheckAction.CHECK_IN}&sessionId=${session._id}&regAttendanceRule=${regAttendanceRule}`,
      );
    },
    [refId, regAttendanceRule, navigate],
  );
  const handleCheckOut = useCallback(
    (session: Session) => {
      const url =
        sessionCheckData?.[`session-${session?._id}`]?.registrationIds &&
        sessionCheckData?.[`session-${session?._id}`]?.qrCodes
          ? `${ROOT_PATH}/${EVENT_PATH}/check_result/${refId}?sessionId=${session._id}&checkType=${CheckAction.CHECK_IN}&regAttendanceRule=${regAttendanceRule}&quick=true`
          : `${ROOT_PATH}/${EVENT_PATH}/check/${refId}?action=${CheckAction.CHECK_OUT}&sessionId=${session._id}&regAttendanceRule=${regAttendanceRule}`;

      navigate(url);
    },
    [sessionCheckData, refId, regAttendanceRule, navigate],
  );

  return (
    <div
      className={`w-full px-3 flex flex-col break-inside-avoid-column mb-4 md:mb-5 last:mb-0 bg-white
     ${isParenSession ? "bg-[#F5F5F5]" : "rounded-lg card-shadow"}`}
    >
      <div className="w-full flex flex-row py-4  ">
        <div
          className={`md:w-[72px] w-[60px] h-auto flex flex-col  items-center justify-center rounded-[11px] mr-3 ${
            isSub ? "bg-[#EBF3FF]" : "bg-[#E0FBFF]"
          }`}
        >
          <span
            className={`text-xs text-center font-semibold md:text-[14px] md:leading-4 whitespace-pre-wrap ${
              isSub ? "text-[#4386E5]" : "text-[#24B1C7]"
            }`}
          >
            {!multiSession ? t("event.event") : isSub ? t("event.sub_session") : t("event.session")}
          </span>
          {multiSession && (
            <span
              className={`text-xs text-center font-semibold md:text-[14px] md:leading-4 ${
                isSub ? "text-[#4386E5]" : "text-[#24B1C7]"
              }`}
            >
              {num}
            </span>
          )}
        </div>

        <div className="flex flex-col flex-1 min-w-0 gap-y-[5px] md:gap-y-[6px]">
          <span className="text-base font-medium md:text-xl">{getI18nData(name)}</span>
          <div className="flex flex-row items-center">
            <Icon svg={SvgAssets.locationGray} width={16} height={16} className="flex-shrink-0" />
            <span className="flex-1 min-w-0 text-[#999] text-sm font-medium ml-1 md:text-lg">
              {getI18nData(venue) ||
                (data?.type === EventType.VIRTUAL
                  ? t("event.online_event")
                  : getI18nData(data?.venue))}
            </span>
          </div>

          <div className="flex flex-row items-center">
            <Icon svg={SvgAssets.timeGray} width={16} height={16} className="flex-shrink-0" />
            <span className="flex-1 min-w-0 text-[#999] text-sm font-medium ml-1 md:text-lg">
              {formatEventTime(startTime, endTime)}
            </span>
          </div>
          {!isParenSession && (
            <div className="mt-4 flex flex-row-reverse items-center">
              {regAttendanceRule === REG_ATTENDANCE_RULE.CHECK_IN_OUT && (
                <Button variant="outlined" color="info" onClick={() => handleCheckOut(item)}>
                  {t("event.check_out")}
                </Button>
              )}

              <Button
                variant="outlined"
                color="info"
                onClick={() => handleCheckIn(item)}
                style={{ marginRight: "10px" }}
              >
                {t("event.check_in")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
