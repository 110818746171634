export const handleBase64Url = (base64URL: string) => {
  const result = base64URL.split(",");
  if (result.length < 1) throw new Error("not base64 url");
  const prefix = result[0].replace(URLPREFIX_DATA, "").replace(URLPREFIX_BASE64, "");
  return {
    base64: result[1],
    mimetype: prefix,
  };
};
export const toBase64Url = (base64: string, mimeType: string) => {
  return `${URLPREFIX_DATA}${mimeType}${URLPREFIX_BASE64},${base64}`;
};
const URLPREFIX_DATA = "data:";
const URLPREFIX_BASE64 = ";base64";
