import React, { Fragment, useCallback } from "react";
import ProfileSvg from "../../img/profile.svg";
import { useAppSelector } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { Attachment as AttachmentType, Attachment as AttachmentItemType } from "../../network/type";
import { getI18nData } from "../../../../components/surveyjs/survey/survey.util";

export const Attachment = () => {
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.event.eventDetailData);

  const onClick = useCallback((item: AttachmentItemType) => {
    window.open(item.url);
  }, []);

  const attachmentsList = getI18nData(data?.attachments);

  return attachmentsList && attachmentsList?.length > 0 ? (
    <Fragment>
      <div className="divider" />
      <span className="text-base font-semibold mb-4 md:text-2xl md:mb-[30px]">
        {t("event.attachment")}
      </span>
      <div className="flex flex-col md:-mb-5">
        {attachmentsList?.map((item: AttachmentType) => {
          return (
            <div
              className="flex flex-row items-center mb-5 cursor-pointer"
              key={item.filename}
              onClick={() => onClick(item)}
            >
              <img className="w-[28px] h-8" src={ProfileSvg} alt="profile" />
              <span className="font-semibold ml-3 text-sm md:text-xl">{item.filename}</span>
            </div>
          );
        })}
      </div>
    </Fragment>
  ) : null;
};
