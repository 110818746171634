export const label = { color: "#333" };
export const formControl = { mt: 3 };
export const groupWidth = {
  border: "1px solid #ccc",
  borderRadius: 8,
  marginLeft: 0,
  flex: 1,
  height: 56,
};
export const formControlPhone = { mt: 3, display: "flex", justifyContent: "space-between" };

export const inputLabelProps = {
  shrink: true,
  style: { color: "#333" },
};
