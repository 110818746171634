import React from "react";

import pruforceBg from "../../../../components/icon/assets/candidate_pruforce_bg.svg";
import defaultAvatar from "../../../../components/icon/assets/default-avatar.png";
import editLogo from "../../../../components/icon/assets/edit.svg";
import emailLogo from "../../../../components/icon/assets/email.svg";
import licenseExamLogo from "../../../../components/icon/assets/license-exam.svg";
import logoutLogo from "../../../../components/icon/assets/logout.svg";
import prudnaLogo from "../../../../components/icon/assets/prudna.png";
import settingLogo from "../../../../components/icon/assets/setting.svg";
import trainingLogo from "../../../../components/icon/assets/training.svg";
import { useCandidateHome } from "./camdidate-home.hook";

import styles from "./camdidate-home.module.css";
type CandidateHomeProps = {};

export const CandidateHomeScreen = (props: CandidateHomeProps) => {
  const { t, clickEmail, clickSetting, clickProfile, candidateProfile } = useCandidateHome();

  return (
    <div className={styles.container}>
      {/* Header section */}
      <header className={styles.header}>
        <img src={pruforceBg} alt=""></img>
        <div className={styles.operation}>
          <img src={emailLogo} alt="" className={styles.logoImg} onClick={clickEmail}></img>
          <img src={settingLogo} alt="" className={styles.logoImg} onClick={clickSetting}></img>
          <img src={logoutLogo} alt="" className={styles.logoutLogo} onClick={clickSetting}></img>
        </div>
      </header>

      {/* profile section */}
      <div className={styles.profileContainer}>
        <div className={styles.profile}>
          <div className={styles.profileHeader}>
            <div className={styles.profilePictureContainer}>
              <img
                src={candidateProfile?.profile_img_url || defaultAvatar}
                alt=""
                className={styles.profileImage}
              />
              <div className={styles.overlay} onClick={clickProfile}>
                <img src={editLogo} alt="" className={styles.editIcon} />
              </div>
            </div>
            <div className={styles.profileInfo}>
              <h2 className={styles.profileName}>{candidateProfile?.name}</h2>
              <span className={styles.profileStatus}>{candidateProfile?.status}</span>
            </div>
          </div>
          <div className={styles.actions}>
            <div className={styles.actionItem}>
              <img src={prudnaLogo} alt="" className={styles.actionIcon}></img>
              <span className={styles.text}>{t("Common.prudna")}</span>
            </div>
            <div className={styles.actionItem}>
              <img src={trainingLogo} alt="" className={styles.actionIcon}></img>
              <span className={styles.text}>{t("Common.training")}</span>
            </div>
            <div className={styles.actionItem}>
              <img src={licenseExamLogo} alt="" className={styles.actionIcon}></img>
              <span className={styles.text}>{t("Recruitment.license_exam")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
