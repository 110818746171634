import { OnboardingLessonStatus, OnboardingQuizStatus } from "../../types";

export type MenuData = {
  header?: HeaderItem[];
  menu?: ModuleSection[];
  doc?: DocItem[];
  isCompleted?: boolean;
};
export type ModuleSection = {
  title: string;
  /**
   * id
   */
  name: string;
  numerator?: number;
  denominator?: number;
  status?: keyof typeof StatusEnum;
  expanded?: boolean;
  data: SectionItem[];
};

export type SectionItem = {
  title: string;
  /**
   * id
   */
  name: string;
  // numerator?: number;
  // denominator?: number;
  status?: keyof typeof StatusEnum;
  type?: OnboardingCourseResourceType;
  data?: any;
};
export type DocItem = {
  name: string;
  title: string;
  data: string[];
};

export type HeaderItem = {
  key?: string;
  value?: string;
  valueColor?: string;
};

export const StatusEnum = { ...OnboardingLessonStatus, ...OnboardingQuizStatus };

export enum OnboardingTrainingModuleType {
  Training = "onboarding_training",
  ExamRegistration = "license_exam_registration",
}

export enum OnboardingCourseResourceType {
  Lesson = "lesson",
  LessonQuiz = "lesson_quiz",
  CourseQuiz = "course_quiz",
}

export enum OnboardingTrainingPlatform {
  COE = "COE",
  LOCAL = "LOCAL",
  NEW = "NEW",
}
