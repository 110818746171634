import { Dispatch } from "react";
import { get } from "lodash";
import service from "../../../utils/axios/axios";
import { AgentProfileItem, AgentAwardsType } from "../types/agent-profile-types";

const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");
const APIM_PUBLIC = get(window, "envConfig.REACT_APP_APIM_PUBLIC", "");
const agentProfileEndpoint = "/e-profiles";

export const fetchAgentProfile = async (
  refId: string,
  langCode: string,
  dispatch?: Dispatch<any>,
): Promise<AgentProfileItem> => {
  return service
    .get<AgentProfileItem>(`${agentProfileEndpoint}/${refId}?lang=${langCode}`)
    .then((response) => response.data)
    .catch((err) => {
      if (!err.response) {
        throw err;
      }
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw err.message;
      }
    });
};

export const getAgentAwards = async (
  refId: string,
  lang: string,
  dispatch?: Dispatch<any>,
): Promise<AgentAwardsType> => {
  return service
    .get<AgentAwardsType>(`${agentProfileEndpoint}/${refId}/agent-awards?lang=${lang}`)
    .then((response) => response.data)
    .catch((err) => {
      if (!err.response) {
        throw err;
      }
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw err.message;
      }
    });
};

export const fetchSaveContact = (refId: string, dispatch?: Dispatch<any>): string => {
  // Need to return full api url instead of using service
  return `${APIM_BASE_URL}${APIM_PUBLIC}${agentProfileEndpoint}/getVCard/${refId}`;
};
