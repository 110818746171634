import React, { useCallback, useEffect, useRef, useState } from "react";
import { HeaderComponent } from "../../../../components/header/header.component";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "../../../../utils/hooks/use-query";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { TodoTask, getI18nData } from "./type";
import { get, isString } from "lodash";
import { InfiniteScroll } from "../../../../components/infinite-scroll-component";
import { getTodoTasks } from "../../network/network";
import moment from "moment";
import useThrottledTouchEnd from "../../hooks/use-throtted-touch-end";

type Props = {};
const pullDownHold = 200;
const progressSize = 24;
/**
 * 3 * 4 = 12px
 */
const listPadding = 3;

export const PendingFollowUpScreen = (props: Props) => {
  useThrottledTouchEnd();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();
  const [data, setData] = useState<TodoTask[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const lang = query.get("lang") ?? "en";
  const page = useRef(0);
  const totalPage = useRef(0);

  const fetchData = useCallback(async (page = 0, limit = 20) => {
    try {
      const applicationId = get<string | undefined>(location.state, "applicationId", undefined);
      if (!applicationId) return;
      const res = await getTodoTasks({
        applicationId: location.state.applicationId,
        page: page,
        limit: limit,
        type: "FOLLOWUP_REQUIRED",
      });
      if (get(res, "error", false)) return;
      const data = get<TodoTask[]>(res.data, "data", []);
      const t = get(res.data, "totalPages", 0);
      totalPage.current = t;
      return data;
    } catch {}
  }, []);
  const nextPage = useCallback(async () => {
    const next = page.current + 1;
    if (next >= totalPage.current) return setHasMore(false);
    const d = await fetchData(next);
    if (!d) return;
    page.current = next;
    setData([...data, ...d]);
  }, [page]);
  const initPage = useCallback(async () => {
    const d = await fetchData(page.current);
    page.current = 0;
    if (!d) return setData([]);
    setData(d);
  }, []);
  useEffect(() => {
    initPage();
  }, []);

  return (
    <div className="flex-col fixed overflow-hidden overscroll-y-none h-screen w-screen flex flex-col">
      <HeaderComponent title={t("onboarding.pending_follow_up")} />
      <div className="overflow-auto flex-1 bg-neutral-100 pb-3 " id={"scrollTarget"}>
        <InfiniteScroll
          className={`grid gap-4 !px-${listPadding} bg-neutral-100 !w-screen`}
          dataLength={data.length} //This is important field to render the next data
          next={nextPage}
          hasMore={hasMore}
          scrollableTarget="scrollTarget"
          loader={
            <div className="bg-neutral-100 items-center flex justify-center mt-1">
              <CircularProgress size={progressSize} color="error"></CircularProgress>
            </div>
          }
          // below props only if you need pull down functionality
          refreshFunction={initPage}
          endMessage={<></>}
          pullDownToRefresh
          pullDownToRefreshThreshold={pullDownHold}
          pullDownToRefreshContent={(value) => {
            return (
              <div className="bg-neutral-100 items-center flex justify-center mt-1">
                <CircularProgress
                  size={progressSize}
                  color="error"
                  variant="determinate"
                  value={(value / pullDownHold) * 100}
                ></CircularProgress>
              </div>
            );
          }}
          releaseToRefreshContent={
            <div className="bg-neutral-100 items-center flex justify-center mt-1">
              <CircularProgress size={progressSize} color="error"></CircularProgress>
            </div>
          }
        >
          {data.map((info, index) => {
            const no = index + 1;
            const name = getI18nData(info.assigneeName, lang);
            /**
             * task id index should be 3
             */
            const taskIdIndex = 3;
            const p = {
              infos: [
                {
                  key: t("onboarding.assignee"),
                  value: name,
                },
                {
                  key: t("onboarding.task_issue_date"),
                  value: moment.utc(info.taskIssueDate).format("DD/MM/YYYY"),
                },
                {
                  key: t("onboarding.task_id"),
                  value: info.taskId,
                },
                {
                  key: t("onboarding.task_details"),
                  value: info.taskDetails,
                },
              ],
              title: "Task " + no,
            };
            return (
              <ListItem
                className="rounded-xl	!p-0 bg-white"
                key={info.taskId}
                style={{
                  width: `calc(100vw - ${listPadding * 4 * 2}px)`,
                }}
              >
                <ListItemButton
                  className="flex flex-col !p-4 !items-start"
                  onClick={() => {
                    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/menu?${query.toString()}`, {
                      replace: true,
                    });
                  }}
                >
                  <ListItemText
                    className="!mb-3 !mt-0 text-sm font-medium"
                    style={{
                      color: "#333",
                    }}
                  >
                    {p.title}
                  </ListItemText>
                  {p.infos.map((info, index) => {
                    return (
                      <div className="flex w-full	mb-2" key={info.key}>
                        <ListItemText
                          className="!mb-0 !mt-0 text-xs font-medium !flex-none !mr-2"
                          style={{
                            color: "#666",
                          }}
                        >
                          {`${info.key}:`}
                        </ListItemText>
                        <ListItemText
                          className="!mb-0  !mt-0 text-xs font-medium justify-end flex text-end"
                          style={{
                            overflow: "hidden",
                            color: "#666",
                            wordBreak: index + 1 === taskIdIndex ? "break-all" : "normal",
                          }}
                        >
                          {info.value}
                        </ListItemText>
                      </div>
                    );
                  })}
                </ListItemButton>
              </ListItem>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};
