import React, { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { NOT_FOUND_ERROR_PAGE_PATH } from "../../../../constants/constants";
import { AgentProfileItem, AwardItem } from "../../types/agent-profile-types";
import { fetchAgentProfile, getAgentAwards } from "../../network/agentProfileCrud";
import { localeMapToBackend } from "../../utils/locale-utils";
import { moveSelectedAwardsToTop } from "../../utils/list-utils";
import { Layout } from "../../../../layout/layout";
import AwardViewMoreForm from "./components/AwardViewMoreForm";

const AwardViewMorePage: FC = () => {
  const { refId } = useParams<{ refId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const lang = i18n.language;
  let mappedLocale = localeMapToBackend[lang] || lang;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>();
  const [awardList, setAwardList] = useState<AwardItem[]>([]);

  const reloadData = async () => {
    if (refId) {
      setIsLoading(true);
      try {
        const res = await fetchAgentProfile(refId, mappedLocale, dispatch);
        setAgentProfile(res);
        const awardListRes = await getAgentAwards(refId, mappedLocale, dispatch);
        setAwardList(moveSelectedAwardsToTop(res.awards, awardListRes.awards));
      } catch (err) {
        navigate(NOT_FOUND_ERROR_PAGE_PATH);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   if ((window as any).ReactNativeWebView) {
  //     (window as any).ReactNativeWebView.postMessage(
  //       `{ "action": "loadheaderprops", "payload": { "title": "myAwardsAndAccomplishment", "backTarget": "web" } }`,
  //     );
  //   }
  // }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [refId, i18n.language]);

  const title = t("agentProfile.pp_agent_profile_title");

  return (
    <>
      {isLoading || !agentProfile ? (
        <></>
      ) : (
        <Layout title={title}>
          <AwardViewMoreForm
            mappedLocale={mappedLocale}
            agentProfile={agentProfile}
            awardList={awardList}
          />
        </Layout>
      )}
    </>
  );
};

export default AwardViewMorePage;
