/**
 *
//  * @param imageUrl
//  * @param maxWidth
//  * @param maxHeight
//  * @param rotation
//  * @param targetFormat image/${format}
//  * @returns
 */

/**
 *
 * @param imageUrl
 * @param targetWidth
 * @param targetHeight
 * @param rotation
 * @param targetFormat
 * @returns
 */
export function processImage(
  imageUrl: string,
  targetWidth: number,
  targetHeight: number,
  rotation: number,
  targetFormat: string,
): Promise<string> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      const { width, height } = image;

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (context === null) return reject(new Error("failed to create canvas"));

      const resolution = getRatio(context);

      const scaleRatio = Math.min(
        targetWidth / (width / resolution),
        targetHeight / (height / resolution),
      );

      const scaledWidth = width * scaleRatio;
      const scaledHeight = height * scaleRatio;
      // const rotatedWidth =
      //   Math.abs(Math.cos(rotation)) * scaledWidth + Math.abs(Math.sin(rotation)) * scaledHeight;
      // const rotatedHeight =
      //   Math.abs(Math.sin(rotation)) * scaledWidth + Math.abs(Math.cos(rotation)) * scaledHeight;

      canvas.width = scaledHeight;
      canvas.height = scaledWidth;

      context.translate(scaledWidth / 2, scaledHeight / 2);

      // context.rotate(rotation);
      // context.rotate((rotation * Math.PI) / 180);

      context.scale(scaleRatio, scaleRatio);

      context.drawImage(
        image,
        -scaledWidth / scaleRatio / 2,
        -scaledHeight / scaleRatio / 2,
        scaledWidth / scaleRatio,
        scaledHeight / scaleRatio,
      );

      // 将 canvas 转换为目标格式的图片数据
      const data = canvas.toDataURL(targetFormat);
      resolve(data);
    };

    image.onerror = () => {
      reject(new Error("Failed to load image."));
    };

    // 加载图片
    image.src = imageUrl;
  });
}

export function resizeCanvas(canvas: HTMLCanvasElement) {
  var context = canvas.getContext("2d");
  if (context === null) return;
  const ratio = getRatio(context);

  var oldWidth = canvas.width;
  var oldHeight = canvas.height;

  canvas.width = oldWidth * ratio;
  canvas.height = oldHeight * ratio;

  canvas.style.width = oldWidth + "px";
  canvas.style.height = oldHeight + "px";

  context.scale(ratio, ratio);
}
export function getRatio(context: CanvasRenderingContext2D) {
  var devicePixelRatio = window.devicePixelRatio || 1;
  var backingStoreRatio =
    //@ts-ignore
    context.webkitBackingStorePixelRatio ||
    //@ts-ignore
    context.mozBackingStorePixelRatio ||
    //@ts-ignore
    context.msBackingStorePixelRatio ||
    //@ts-ignore
    context.oBackingStorePixelRatio ||
    //@ts-ignore
    context.backingStorePixelRatio ||
    1;

  return devicePixelRatio / backingStoreRatio;
}
