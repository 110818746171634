import React, { MouseEventHandler } from "react";
import Button from "@mui/material/Button";
import Icon from "../icon/icon";
import { SvgAssets } from "../icon/assets";
import { useButtonStyles } from "../buttons";
import { getI18n } from "react-i18next";

export interface FeedbackComponentProps {
  title: string;
  description: string;
  onConfirm?: MouseEventHandler;
  confirmButtonText?: string;
  icon?: React.ReactElement;
}
export const FeedbackComponent: React.FC<FeedbackComponentProps> = ({
  onConfirm,
  title,
  description,
  confirmButtonText,
  icon,
}) => {
  const classes = useButtonStyles();
  return (
    <div className="flex flex-1 justify-center flex-col items-center p-1">
      {icon}

      <div className="flex w-full justify-center items-center">
        <span className="text-center text-grey-33 font-sf-pro-text text-base font-semibold leading-snug pt-5">
          {title}
        </span>
      </div>
      <div className="flex w-full justify-center items-center max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
        <span className="text-center text-grey-66 font-sf-pro-text text-sm font-medium leading-snug py-5">
          {description}
        </span>
      </div>
      {confirmButtonText && (
        <div className="flex w-48 justify-center items-center">
          <Button className={classes.confirmButton} onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </div>
      )}
    </div>
  );
};
