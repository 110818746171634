import React, { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Menu, IconButton } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./top-menu.module.css";

type ComponentProps = {
  file: string; // URL or path to the file
  onZoomIn: () => void; // Function to handle zooming in
  onZoomOut: () => void; // Function to handle zooming out
};

export const TopMenuComponent: React.FC<ComponentProps> = memo(
  ({ file, onZoomOut, onZoomIn }: ComponentProps) => {
    // i18n
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const download = () => {
      if (file) {
        const link = document.createElement("a");
        link.href = file;
        link.download = file.split("/").pop() as string; // Set the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      // Close the menu
      setAnchorEl(null);
    };

    return (
      <div className={styles.wrap}>
        <IconButton
          aria-label="more"
          id="topbutton"
          aria-controls={open ? "topmenu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="topmenu"
          MenuListProps={{ "aria-labelledby": "topbutton" }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={onZoomIn}>{t("arl.zoomin")}</MenuItem>
          <MenuItem onClick={onZoomOut}>{t("arl.zoomout")}</MenuItem>
          {window.ReactNativeWebView ? null : (
            <MenuItem onClick={download}>{t("arl.download")}</MenuItem>
          )}
        </Menu>
      </div>
    );
  },
);
