import { GlobalHelper } from "../../utils/helpers/global-helper";
import { ToastType } from "./toast-context";

export interface ToastProps extends ToastType {}

export const PruToast = (payload: ToastProps) => {
  const { show, hide } = GlobalHelper.getGlobalToastRef();
  show && show(payload);
  return { hide };
};
