import React from "react";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { useNavigate } from "react-router-dom";
import { color } from "../../../../mui-theme/palette";
import { CircularProgress } from "@mui/material";
import { useRedirect } from "./redirect.hook";

export const RedirectScreen: React.FC = () => {
  const navigate = useNavigate();

  const { loading } = useRedirect({ navigate });

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <Icon svg={SvgAssets.prudnaLogo} width={242.5} height={64} />
        {loading && (
          <div className="my-20">
            <CircularProgress sx={{ color: color.majorRed }} size={40} />
          </div>
        )}
      </div>
    </div>
  );
};
