import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  videoContainer: (props: any) => ({
    position: "absolute",
    display: "flex",
    width: props.videoWidth,
  }),
  playIcon: {
    alignSelf: "center",
    margin: "auto",
    width: 42,
    height: 42,
    color: "#FFFFFF",
  },
  pointerEventsNone: {
    pointerEvents: "none",
  },
}));
