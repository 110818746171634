import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { palette } from "./palette";
// use responsiveFontSizes
export const theme = responsiveFontSizes(
  createTheme({
    typography: {
      // fontFamily: ["Arial"].join(","), // use tailwind font-family: web/src/index.css
      button: { textTransform: "none" },

      fontSize: 16, // default 14
    },
    shape: {
      borderRadius: 8, // defaults to 4
    },
    palette: palette.light,
  }),
);
