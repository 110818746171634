import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import { EVENT_PATH, ROOT_PATH } from "../../../../routes/constants";
import { useQuery } from "../../../../utils/hooks/use-query";
import { CheckAction } from "../../network/type";
import { submitParticipantInfoAsync } from "../../redux/event-slice";

export const useCheckInOutResult = () => {
  const navigate = useNavigate();

  const { refId = "" } = useParams();
  const sessionId = useQuery().get("sessionId") || "";
  const isQuickEntrance = useQuery().get("quick") === "true";

  // redux
  const dispatch = useDispatch();
  const sessionCheckData = useAppSelector((state) => state.event.sessionCheckData);
  const qrCodes = sessionCheckData?.[`session-${sessionId}`]?.qrCodes;

  useEffect(() => {
    if (!isQuickEntrance) return;
    const registrationIds = sessionCheckData?.[`session-${sessionId}`]?.registrationIds;
    dispatch<any>(
      submitParticipantInfoAsync({
        refId,
        data: { registrationIds, sessionId, action: CheckAction.CHECK_IN },
      }),
    );
  }, [dispatch, isQuickEntrance, refId, sessionCheckData, sessionId]);

  const checkoutPress = useCallback(async () => {
    const submitRes = await dispatch<any>(
      submitParticipantInfoAsync({
        refId,
        data: { qrCodes, sessionId, action: CheckAction.CHECK_OUT },
      }),
    );
    if (!submitRes?.error) {
      navigate(
        `${ROOT_PATH}/${EVENT_PATH}/check_result/${refId}?sessionId=${sessionId}&checkType=${CheckAction.CHECK_OUT}`,
      );
    }
  }, [qrCodes, dispatch, refId, sessionId, navigate]);

  const backPress = useCallback(() => {
    navigate(`${ROOT_PATH}/${EVENT_PATH}/${refId}/attendance`);
  }, [navigate, refId]);

  return {
    checkoutPress,
    backPress,
  };
};
