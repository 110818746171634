import React, { useMemo } from "react";

import { useParams } from "react-router-dom";
import { ErrorView } from "../../components/error-view";
import { Header } from "../../components/header";
import { LandingTop } from "../../components/landing/landing-top";
import { Sessions } from "../../components/landing/sessions";
import { Code } from "../../network/type";
import { getTitleByCode } from "../../util/event.util";
import { useLanding } from "./landing.hook";
import { LangOptionComponent } from "../../../../layout/lang-option/lang-option.component";
import { useTranslation } from "react-i18next";

export const LandingPage: React.FC = () => {
  const { refId = "" } = useParams();
  const { t } = useTranslation();

  const { loading, eventDetailData, code } = useLanding({ refId: refId });
  const isSuccess = useMemo(() => code === Code.SUCCESS, [code]);

  return (
    <React.Fragment>
      <LangOptionComponent />
      <div
        className={`flex flex-col min-h-screen break-words ${
          isSuccess ? "bg-[#F5F5F5]" : "bg-white"
        } `}
      >
        <Header />
        <div className="w-full flex flex-col min-h-screen ">
          {!loading ? (
            <>
              {eventDetailData && <LandingTop showTime={!isSuccess} />}
              {isSuccess && eventDetailData ? (
                <Sessions />
              ) : (
                <ErrorView title={t("event.event_expired")} desc={getTitleByCode(code)} />
              )}
            </>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};
