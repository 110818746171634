import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AgentProfileItem, AwardItem } from "../../../types/agent-profile-types";
import BasicInfoImage from "../../../components/BasicInfoImage";
import BasicInfo from "../../../components/BasicInfo";
import AwardList from "../../../components/AwardList";
import { useStyles } from "./styles/award-view-more.styles";

type AwardViewMoreFormProps = {
  mappedLocale: string;
  agentProfile: AgentProfileItem;
  awardList: AwardItem[];
};

const AwardViewMoreForm: FC<AwardViewMoreFormProps> = ({
  mappedLocale,
  agentProfile,
  awardList,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <BasicInfoImage agentProfile={agentProfile} />
      <div className={classes.basicInfoSectionContainer}>
        <BasicInfo agentProfile={agentProfile} />
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>{t(`agentProfile.pp_my_awards_header_title`)}</div>
        </div>
        <AwardList
          mappedLocale={mappedLocale}
          badges={agentProfile.awardBadges}
          awards={awardList}
        />
      </div>
    </div>
  );
};

export default AwardViewMoreForm;
