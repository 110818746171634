import React, { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../components/icon/icon";
import { SvgAssets } from "../../../../../components/icon/assets";
import { useAppSelector } from "../../../../../redux/store";
import { useQuery } from "../../../../../utils/hooks/use-query";
import { getI18nData } from "../../../../../utils/i18n/i18n.util";
import { formatEventTime, sessionFilter } from "../../../util/event.util";
import { EventType, Session } from "../../../network/type";
import { DetailDesc } from "../../event-detail/detail-desc";

type ComponentProps = {
  // name: string;
  /* otherProp */
};

export const EventBaseInfoComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { t } = useTranslation();
  const query = useQuery();
  const sessionId = query.get("sessionId") || "";
  const { sessions, venue, type } = useAppSelector((state) => state.event.eventDetailData) ?? {};

  const curSessionData = useMemo(() => {
    return sessionFilter(sessionId, sessions);
  }, [sessionId, sessions]);

  return (
    <div className="w-full py-5 px-6 flex flex-col bg-white">
      <DetailDesc />
      <div className="flex flex-row items-center my-2">
        <Icon svg={SvgAssets.timeGrey66} width={16} height={16} className="flex-shrink-0" />
        <span className="text-sm font-medium text-[#666] ml-1 min-w-0">
          {formatEventTime(curSessionData?.startTime!, curSessionData?.endTime!)}
        </span>
      </div>
      <div className="flex flex-row items-center">
        <Icon svg={SvgAssets.locationGrey66} width={16} height={16} className="flex-shrink-0" />
        <span className="text-sm font-medium text-[#666] ml-1 min-w-0">
          {getI18nData(curSessionData?.venue) ||
            (type === EventType.VIRTUAL ? t("event.online_event") : getI18nData(venue))}
        </span>
      </div>
    </div>
  );
});
