import dayjs from "dayjs";
import React, { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SvgAssets } from "../../../../components/icon/assets";
import Icon from "../../../../components/icon/icon";
import { getI18nData } from "../../../../components/surveyjs/survey/survey.util";
import { useAppSelector } from "../../../../redux/store";
import { constants } from "../../../../routes/constants";
import { ACCESSIBILITY_TYPE, EventType } from "../../network/type";

export const LandingTop = ({ showTime }: { showTime: boolean }) => {
  const { t } = useTranslation();
  const { name, category, type, venue, startTime, endTime } = useAppSelector(
    (state) => state.event.eventDetailData,
  )!;

  const eventTypeConfig = useMemo(
    () => ({
      [EventType.PHYSICAL]: t("event.physical"),
      [EventType.VIRTUAL]: t("event.virtual"),
      [EventType.HYBRID]: t("event.hybrid"),
    }),
    [t],
  );

  const accessibilityConfig = useMemo(
    () => ({
      [ACCESSIBILITY_TYPE.INTERNAL]: t("event.internal"),
      [ACCESSIBILITY_TYPE.PUBLIC]: t("event.public"),
    }),
    [t],
  );

  return (
    <Fragment>
      <div className="px-4 pt-5 pb-4 md:px-[60px] md:pt-[60px] md:pb-[100px] lg:px-[100px] bg-white">
        <span className="font-semibold text-xl md:text-4xl md:leading-[54px]">
          {getI18nData(name)}
        </span>

        <div className="mt-2 md:mt-4 ">
          <span className="event-tag text-[#666]">
            {accessibilityConfig[category?.accessibility]}
          </span>
          <span className="tag-divider" />
          <span className="event-tag text-[#666]">{getI18nData(category?.name)}</span>
          <span className="tag-divider" />
          <span className="event-tag text-[#666]">{eventTypeConfig[type]}</span>
          {showTime && (
            <div className="mt-2">
              <div className="mb-2 flex flex-row items-center cursor-pointer">
                <Icon svg={SvgAssets.timeGrey66} width={16} height={16} />
                <span className="text-[14px] font-medium text-[#666] md:text-xl px-2">
                  {`${dayjs(startTime).format(constants.Time_Format)}-${dayjs(endTime).format(
                    constants.Time_Format,
                  )}`}
                </span>
              </div>
              <div className="flex flex-row items-center cursor-pointer">
                <Icon svg={SvgAssets.locationGrey66} width={16} height={16} />
                <span className="text-[14px] font-medium text-[#666] md:text-xl px-2">
                  {getI18nData(venue) ||
                    (type === EventType.VIRTUAL ? t("event.online_event") : getI18nData(venue))}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
