import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Layout } from "../../../../layout/layout";
import { RootState } from "../../../../redux/store";
import {
  TrackingData,
  EventModule,
  EventFeature,
} from "../../../../utils/event-tracking/event-tracking.model";
import { ResourceDetail } from "../../components/resource-detail";
import { fetchResourceByIdAsync } from "../../redux/resource-slice";
import "./landing-screen.css";
import { AxiosError } from "axios";
import { ErrorStateBlock, StatusCode } from "../../../../components/error-state/error-state";
import { get } from "lodash";
import { getLanguage } from "../../../../utils/lang";
import arlEntry from "../../img/arl-entry.png";
import { getARLConfig } from "../../../agent-referral-link/network/network";
import GAClickFragment from "../../../../common/ga/components/ga-click-fragment";

export const LandingScreen: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  /** define the page tracking data */
  const pageTrackingData: TrackingData = useMemo(() => {
    return {
      name: "prospect::view_source",
      module: EventModule.resource,
      feature: EventFeature.resource,
      target: params.resourceId ?? "",
    };
  }, []);

  const { t, i18n } = useTranslation();
  const title = t("resource-landing-title");
  window.document.title = title;
  const resourceDetail = useSelector((state: RootState) => {
    return state.resource.data;
  });
  const hashEntry = useSelector<RootState>((state) => state.common?.hashEntry) as any;
  const resourceError: AxiosError = useSelector((state: RootState) => {
    return state.resource.getResourceError;
  });

  const [enableArlEntry, setEnableArlEntry] = useState(false);

  useEffect(() => {
    dispatch<any>(
      fetchResourceByIdAsync({
        uuid: params.resourceId ?? "",
        langkey: getLanguage(i18n.language ?? ""),
        refId: params.refId ?? "",
      }),
    );
  }, [i18n.language]);

  useEffect(() => {
    if (resourceDetail?.name) {
      window.document.title = resourceDetail.name;
    }
  }, [resourceDetail]);

  const hashEntryProductType = get(hashEntry, "data.referralLinkOptions[0].name") || "";
  const resourceProductType = get(resourceDetail, "productType") || "";

  useEffect(() => {
    /**
     * check if: resource productType == shared with arl productType
     * shared with arl, hash-entry will have props: referralLinkOptions[0].name
     */
    if (hashEntryProductType && params.refId) {
      // defaulty hashEntryProductType if no specific resourceProductType
      if (hashEntryProductType === resourceProductType || !resourceProductType) {
        /**
         * get ARL status to show ARL entry
         * activie: api fullfiled
         * inactive: api exception
         */
        getARLConfig(params.refId).then(() => {
          setEnableArlEntry(true);
        });
      }
    }
  }, [hashEntryProductType, resourceProductType, params.refId]);

  const queryParams = new URLSearchParams(window.location.search);
  const preview = queryParams.get("preview");

  return (
    <React.Fragment>
      <Layout trackingData={pageTrackingData} title={title}>
        <div className="flex flex-col px-4 py-2 justify-center font-semibold text-lg border-b-2">
          {resourceDetail?.name}
        </div>
        {!resourceError && resourceDetail && (
          <>
            <div className={enableArlEntry ? "fixed-top-right" : "hidden"}>
              <GAClickFragment
                journey="referral_link_form_submission"
                stage="enter_referral_link"
                object_name="ReferralLinkShortcut"
                object_type="BUTTON"
              >
                <Link
                  to={`../../../form/${params.refId}?uuid=${params.resourceId}&name=${resourceDetail.name}&preview=${preview}`}
                  relative="path"
                >
                  <img
                    id="arl-entry"
                    src={arlEntry}
                    alt="arl entry"
                    className="auto_click_tracking"
                    data-auto_click_tracking={JSON.stringify({
                      ...pageTrackingData,
                      target: "enter_referral_link",
                    })}
                  />
                </Link>
              </GAClickFragment>
            </div>
            <ResourceDetail resource={resourceDetail} />
          </>
        )}
        {resourceError?.response?.status && (
          <ErrorStateBlock
            statusCode={resourceError.response.status.toString() as StatusCode}
            description={resourceError.message}
          />
        )}
      </Layout>
    </React.Fragment>
  );
};
