import React from "react";
import { useGlobalModal } from "../../use-modal/use-modal";
import { FeedbackComponentProps, FeedbackComponent } from "../../feedback/feedback.component";
import { getI18n } from "react-i18next";

const FeedbackComponentModalView = (
  props: FeedbackComponentProps & {
    close?: Function;
  },
) => {
  return (
    <div className="h-screen w-screen flex">
      <FeedbackComponent
        confirmButtonText={getI18n().t("global.text.confirm")}
        {...props}
        onConfirm={(params) => {
          props.onConfirm?.(params);
          props.close?.();
        }}
      />
    </div>
  );
};

export const feedbackOpen = (
  props: FeedbackComponentProps & {
    close?: Function;
  },
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useGlobalModal().open(<FeedbackComponentModalView {...props} />, {
    showHeader: false,
    modalType: "bottom",
    modalProps: {
      className: "dialog-full",
      PaperProps: {
        style: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          width: window.innerWidth + "px",
        },
      },
    },
  });
};
