import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HeaderComponent } from "../../../../components/header/header.component";
import { useCanGoBack } from "../../../../utils/hooks/use-can-go-back";
import { useQuery } from "../../../../utils/hooks/use-query";
import { selectCandidateOnboardingForm, selectDocumentDownloadList } from "../../redux/selector";
import { SourceEnum } from "../../network/file-op-interface";
import { fetchOnboardingDocumentUploadAsync } from "../../redux/onboarding-slice";
import { getIconSourceByType, IconType } from "../../components/surveyjs/file/get-file-icon";
import { ListItemText } from "@mui/material";
import { downFile } from "../../network/network";
import downloadImage from "../../components/surveyjs/file/image/download.png";
import { styles } from "./style";

type MenuScreenProps = {};

export const DocumentDownloadScreen = (props: MenuScreenProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const candidateOnboardingForm = useSelector(selectCandidateOnboardingForm);
  const documentDownloadList = useSelector(selectDocumentDownloadList);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const fetchData = useCallback(async () => {
    setLoading(true);
    await dispatch<any>(
      fetchOnboardingDocumentUploadAsync({
        applicationId: candidateOnboardingForm?.applicationId,
        source: SourceEnum.DOCUMENT_DOWNLOAD,
      }),
    );
    setLoading(false);
  }, [dispatch]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const canGoBack = useCanGoBack();

  const handleClick = async (item: any) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        action: "saveFile",
        payload: { resourceId: item.resourceId, fileName: item?.fileName },
      }),
    );
  };

  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen w-screen flex flex-col">
      <HeaderComponent
        title={t("document_download")}
        onBack={() => {
          if (canGoBack()) {
            navigate(-1);
          }
        }}
      />
      <div style={styles.documentContain}>
        {documentDownloadList?.map((item: any, index: number) => {
          return (
            <div key={index} style={styles.documentItem}>
              <div style={styles.documentImgContain}>
                <img
                  style={styles.img}
                  src={getIconSourceByType(IconType.PDF)}
                  alt="img-icon"
                ></img>
                <ListItemText
                  primary={item?.fileName}
                  primaryTypographyProps={{
                    noWrap: true,
                    textOverflow: "ellipsis",
                    style: {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    },
                  }}
                />
              </div>
              <img
                style={styles.img}
                src={downloadImage}
                alt="img-icon"
                onClick={() => handleClick(item)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
