import { QuestionFactory, Serializer, QuestionFileModel } from "survey-core";
import { FileUploaderService } from "./upload-service";
import { Subject } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { isArray, last, set } from "lodash";
import { getI18n } from "react-i18next";
import { ExceedSizeError } from "./error";
import { FileContent, MessageContent } from "./content-interface";
import { isFileTypeValid } from "./check-file-type";
import { strToFile } from "./base64-to-blob";
import { resizeImage, getImageDimensions } from "./file.util";
import { mapSurveyLanguage } from "../../../network/network";

/**
 * A class that describes the File Upload question type.
 *
 * [View Demo](https://surveyjs.io/form-library/examples/file-upload/ (linkStyle))
 */
//@ts-expect-error
export class CustomQuestionFileModel extends QuestionFileModel {
  files: {
    content: File;
    uuid: string;
  }[] = [];
  originResource: FileContent[];
  indexIndent: number;
  fileUploadService = new FileUploaderService();
  constructor(name: string) {
    super(name);
    this.fileUploadService.onUpload.subscribe((val) => {
      //@ts-expect-error
      this.isUploading = val;
    });
    this.originResource = isArray(this.value) ? this.value.slice() : [];
    this.originResource.sort((a, b) => a.index - b.index);
    let maxindex = 1;
    this.originResource.forEach((v) => {
      if (v.index > maxindex) maxindex = v.index;
    });
    this.indexIndent = maxindex;
  }

  onFileChange = new Subject<void>();
  onFileError = new Subject<string>();

  protected loadPreview(newValue: any): void {
    /**
     * override super
     */
  }
  public get maxFileNumber(): number {
    return this.getPropertyValue("maxFileNumber");
  }
  public set maxFileNumber(val: number) {
    this.setPropertyValue("maxFileNumber", val);
  }
  public get compressQuality(): number {
    return this.getPropertyValue("compressQuality");
  }
  public getLocale(): string {
    return mapSurveyLanguage(getI18n().language ?? "en");
  }
  public set compressQuality(val: number) {
    this.setPropertyValue("compressQuality", val);
  }
  public get takePhotoWithWatermark(): boolean {
    return this.getPropertyValue("takePhotoWithWatermark");
  }
  public set takePhotoWithWatermark(val: boolean) {
    this.setPropertyValue("takePhotoWithWatermark", val);
  }
  // need to define both getter & setter
  public get imageDimension(): { width: number; height: number } {
    return this.getPropertyValue("imageDimension");
  }
  public set imageDimension(val: { width: number; height: number }) {
    this.setPropertyValue("imageDimension", val);
  }
  beforeDestroyQuestionElement(el: HTMLElement): void {
    super.beforeDestroyQuestionElement(el);
    this.onFileChange.complete();
    this.fileUploadService.onUpload.complete();
  }

  private async onChange(src: any) {
    if (!(<any>window)["FileReader"]) return;
    if (!src || !src.files || src.files.length < 1) return;
    let files = [];
    let allowCount = this.allowMultiple ? src.files.length : 1;
    for (let i = 0; i < allowCount; i++) {
      files.push(src.files[i]);
    }
    src.value = "";

    let imageDimensionsValid = true;
    // if need to check image dimension
    if (this.imageDimension) {
      const len = files.length;
      for (let index = 0; index < len; index++) {
        const curFile = files[index] as File;
        if (!curFile.type.startsWith("image")) {
          continue;
        }
        const { width, height } = await getImageDimensions(curFile);
        const { width: expectedWidth, height: expectedHeight } = this.imageDimension;
        if (width < expectedWidth || height < expectedHeight) {
          imageDimensionsValid = false; // update flag
          const errMsg = getI18n().t("onboarding.invalid_img_dimension", {
            dimension: `${expectedWidth}x${expectedHeight}`,
          });
          this.onFileError.next(errMsg);
          break;
        }
        if (width === expectedWidth && height === expectedHeight) {
          continue;
        }

        const resizedFile = await resizeImage(curFile, expectedWidth, expectedHeight).catch(
          (err) => {
            imageDimensionsValid = false; // update flag
            this.onFileError.next("image resize error!"); // not important error, no i18n for this
          },
        );

        files[index] = resizedFile;
      }
    }

    if (!imageDimensionsValid) {
      return;
    }

    this.loadFiles(files);
  }
  public async handleRNUploadfile(data: MessageContent[]) {
    const dds = data.filter((d) => !!d.content);
    const files = await Promise.all(
      dds.map(async (d) => {
        return await strToFile(d.content as string, d.name, d.type);
      }),
    );
    this.loadFiles(files);
  }

  public allFilesOk(files: File[], checkImage = true): boolean {
    const fs = files || [];
    for (let i = 0; i < fs.length; i++) {
      const file = fs[i];
      if (checkImage && file.type.startsWith("image")) {
        if (this.maxSize > 0 && file.size > this.maxSize) {
          const error = new ExceedSizeError(this.maxSize);
          this.onFileError.next(error.getText());
          return false;
        }
        if (this.autoResize && file.size / 1024 < this.minFileSize * 1024) {
          this.onFileError.next(getI18n().t("onboarding.file_is_too_small"));
          return false;
        }
      }
    }
    return true;
  }

  public loadFiles(files: File[]) {
    if (!this.survey) {
      return;
    }
    let isAccept = true;
    files.forEach((f) => {
      const accept = isFileTypeValid(f.type, this.acceptedTypes);
      if (!accept) isAccept = false;
    });

    if (!isAccept) return this.onFileError.next(getI18n().t("onboarding.not_accepted_file_type"));
    if (files.length + (this.value?.length ?? 0) > this.maxFileNumber)
      return this.onFileError.next(
        getI18n().t("onboarding.selected_files_exceeds_limit", { limit: this.maxFileNumber }),
      );

    this.errors = [];
    if (!this.allFilesOk(files, true)) {
      return;
    }

    var loadFilesProc = () => {
      this.stateChanged("loading");
      var content = <Array<FileContent>>[];
      if (this.storeDataAsText) {
        files.forEach((file, index) => {
          let fileReader = new FileReader();
          this.indexIndent += 1;
          fileReader.onload = (e) => {
            content = content.concat([
              {
                name: file.name,
                type: file.type,
                content: fileReader.result as string,
                index: this.indexIndent,
              },
            ]);
            if (content.length === files.length) {
              this.value = ((this.value as FileContent[]) || [])
                .concat(content)
                .sort((a, b) => a.index - b.index);
            }
          };
          fileReader.readAsDataURL(file);
        });
      } else {
        this.files.push(
          ...files.map((f) => {
            this.indexIndent += 1;
            set(f, "index", this.indexIndent);
            return {
              content: f,
              uuid: uuidv4(),
            };
          }),
        );
        if (isArray(this.value)) {
          const values = this.value.slice();
          const len = files.length;
          for (let i = 1; i <= len; i++) {
            values.push(undefined);
          }
          this.value = values;
        } else {
          this.value = Array(this.files.length).fill(undefined);
        }
        this.onFileChange.next();
      }
    };

    loadFilesProc();
  }
}

export const initFileSerializer = () => {
  Serializer.addClass(
    "file",
    [
      { name: "showCommentArea:switch", layout: "row", visible: true, category: "general" },
      { name: "showPreview:boolean", default: true },
      "allowMultiple:boolean",
      { name: "allowImagesPreview:boolean", default: true },
      { name: "imageHeight:number", default: 80 },
      { name: "imageWidth:number", default: 80 },
      "acceptedTypes",
      { name: "storeDataAsText:boolean", default: false },
      { name: "waitForUpload:boolean", default: true },
      { name: "maxSize:number", default: 4194304 },
      { name: "compressQuality:number", default: 0.8 },
      { name: "maxFileNumber:number", default: 5 },
      { name: "minFileNumber:number", default: 0 },
      { name: "defaultValue", visible: false },
      { name: "correctAnswer", visible: false },
      { name: "validators", visible: false },
      { name: "takePhotoWithWatermark:boolean", default: false },
      { name: "needConfirmRemoveFile:boolean" },
      { name: "allowCameraAccess:switch", category: "general" },
      { name: "imageDimension:dropdown" },
      { name: "autoResize:boolean", default: false },
      { name: "maxFileSize:number", default: 5 },
      { name: "minFileSize:number", default: 0 },
    ],
    function () {
      return new CustomQuestionFileModel("");
    },
    "question",
  );
  QuestionFactory.Instance.registerQuestion("file", (name) => {
    return new CustomQuestionFileModel(name);
  });
};
