import React, { useMemo } from "react";

import { useAppSelector } from "../../../../redux/store";
import { Bottom } from "../../components/check-result/Bottom";
import { List } from "../../components/check-result/list";
import { Tips } from "../../components/check-result/tips";
import { Header } from "../../components/header";
import { Code, SubmitParticipantsInfoCodeEnum } from "../../network/type";
import { useCheckInOutResult } from "./check-in-out-result.hook";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { t } from "i18next";
import { ErrorView } from "../../components/error-view";
import { getTitleByCode } from "../../util/event.util";

export const CheckInOutResultPage: React.FC = () => {
  const { loading, code } = useAppSelector((state) => state.event);
  const { checkoutPress, backPress } = useCheckInOutResult();

  return (
    <div className="w-full min-h-screen flex flex-col">
      <Header />

      {!loading &&
        (code === SubmitParticipantsInfoCodeEnum.SUCCESS ? (
          <div className="px-4">
            <Tips />
            <List />
            <Bottom checkoutPress={checkoutPress} backPress={backPress} />
          </div>
        ) : (
          <ErrorPage code={code} />
        ))}
    </div>
  );
};

const ErrorPage = ({ code }: { code: any }) => {
  const notExist = code === SubmitParticipantsInfoCodeEnum.CHECK_IN_NOT_EXIST;
  return (
    <div className="w-full flex flex-col flex-1 items-center justify-center">
      {code === SubmitParticipantsInfoCodeEnum.ALREADY_CHECKED_IN ||
      code === SubmitParticipantsInfoCodeEnum.ALREADY_CHECKED_OUT ? (
        <>
          <Icon svg={SvgAssets.success} width={80} height={80} />
          <span className="text-base font-semibold text-center mt-4">
            {code === SubmitParticipantsInfoCodeEnum.ALREADY_CHECKED_IN
              ? t("event.already_checked_in")
              : t("event.already_checked_out")}
          </span>
        </>
      ) : (
        <ErrorView
          statusCode={notExist ? Code.EVENT_CANCELLED : Code.EVENT_EXPIRED}
          title={notExist ? t("event.sorry") : t("event.event_expired")}
          desc={notExist ? t("event.check_out_failed_no_record") : getTitleByCode(code)}
        />
      )}
    </div>
  );
};
