import { useCallback } from "react";
export const useCanGoBack = () => {
  return useCallback(() => {
    notifyAppNavBack();
    return true;
  }, []);
};

export function notifyAppNavBack() {
  window.ReactNativeWebView?.postMessage?.('{ "action" : "routeGoBack"}');
}
