import React, { useCallback, useEffect, useState } from "react";
import { ModuleSection, StatusEnum } from "./type";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import arrowRight from "../../assets/img/arrow-right.png";
import {
  completeBoxMarginStyle,
  completeBoxStyle,
  completeIconStyle,
  subheader,
  sectionItem,
  item,
} from "./style";
import { useNavigate, useNavigation } from "react-router-dom";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import style from "./style.module.css";
import { ListSubheader } from "@mui/material";
import { color } from "../../../../mui-theme/palette";
import { useQuery } from "../../../../utils/hooks/use-query";

type MenuListProps = {
  sections?: ModuleSection[];
};

export const MenuList = (props: MenuListProps) => {
  const { sections = [] } = props;
  const navigate = useNavigate();
  const query = useQuery();

  const [expand, setExpand] = useState(
    new Set(sections.filter((p) => p.expanded).map((p) => p.name)),
  );
  const onClick = useCallback((name: string) => {
    setExpand((expandeds) => {
      const next = new Set(expandeds);
      next.has(name) ? next.delete(name) : next.add(name);
      return next;
    });
  }, []);

  return (
    <div
      className={`${style["list-container"]} ${style.flex1} ${style["flex-column"]} ${style.padding}`}
    >
      <List>
        {sections.map((section) => {
          return (
            <div key={section.name} className={`${style.item}`}>
              <ListSubheader sx={subheader}>
                <ListItemButton
                  onClick={() => {
                    onClick(section.name);
                  }}
                >
                  {section.status === StatusEnum.completed ? (
                    <Box sx={completeBoxMarginStyle}>
                      <CheckIcon sx={completeIconStyle} />
                    </Box>
                  ) : (
                    <ListItemText
                      sx={sectionItem}
                      primary={`(${section.numerator}/${section.denominator})`}
                    />
                  )}
                  <ListItemText primary={section.title} />
                  {expand.has(section.name) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListSubheader>
              <Collapse in={expand.has(section.name)}>
                <div className={`${style["padding-left-right"]}`}>
                  <List>
                    {section.data.map((d) => {
                      return (
                        <div className={`${style["item-child"]}`} key={d.name}>
                          <ListItemButton
                            onClick={() => {
                              query.set("sectionName", d.name);
                              navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/form?${query.toString()}`);
                            }}
                          >
                            <ListItemText sx={item} primary={d.title} />
                            <StatusIcon status={d.status}></StatusIcon>
                          </ListItemButton>
                        </div>
                      );
                    })}
                  </List>
                </div>
              </Collapse>
            </div>
          );
        })}
      </List>
    </div>
  );
};

const StatusIcon = ({ status }: { status?: keyof typeof StatusEnum }) => {
  switch (status) {
    case StatusEnum.completed:
      return (
        <Box sx={completeBoxStyle}>
          <CheckIcon sx={completeIconStyle} />
        </Box>
      );
    case StatusEnum.editing:
      return <img src={arrowRight} alt="banner"></img>;
    case StatusEnum.untouched:
      return <ChevronRightIcon />;
  }
  return <></>;
};
