import React from "react";
import { SvgAssets } from "../../../../../../components/icon/assets";
import Icon from "../../../../../../components/icon/icon";

interface QuizOverviewProps {
  totalQuestions: number;
  correctAnswers: number;
  incorrectAnswers: number;
}

const QuizOverview: React.FC<QuizOverviewProps> = ({
  totalQuestions,
  correctAnswers,
  incorrectAnswers,
}) => {
  return (
    <div className="flex justify-between mx-5 py-2 px-6 items-center flex-shrink-0 rounded-xl bg-[#F5F5F5]">
      <div className="flex flex-row items-end">
        <span className="text-[#333] font-montserrat text-base font-bold leading-5 mb-[3px] mr-1">
          Total
        </span>
        <span className="text-[#333] font-montserrat text-3xl font-bold leading-9">
          {totalQuestions}
        </span>
      </div>
      <div className="flex flex-row items-center">
        <Icon svg={SvgAssets.success} height={24} width={24} />
        <span className="text-[#333] font-montserrat text-3xl font-bold leading-9 ml-4">
          {correctAnswers}
        </span>
      </div>
      <div className="flex flex-row items-center">
        <Icon svg={SvgAssets.failure} height={24} width={24} />{" "}
        <span className="text-[#333] font-montserrat text-3xl font-bold leading-9 ml-4">
          {incorrectAnswers}
        </span>
      </div>
    </div>
  );
};

export default QuizOverview;
