import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import i18next from "i18next";
import { get } from "lodash";
import React, { useMemo, useState } from "react";
import { canUseCookie } from "../../utils/cookie";
import { isTncUpdated } from "../../utils/event-tracking/event-tracking.service";
import { getLanguage } from "../../utils/lang";
import "./tnc.component.css";
interface Props {
  setting: {
    enabled?: boolean;
    content?: any;
    updatedAt?: string;
  };
  onAccept: () => void;
  onDecline: () => void;
}
/**
 * sample
 "content": {
    "enabled": true,
    "content": {
      "zh-Hant": "aaaavv",
      "en": "bbbb"}},
 */
export const TncComponent: React.FC<Props> = ({ setting, onAccept, onDecline }) => {
  const updated = useMemo(() => {
    const updatedAt = get(setting, "updatedAt", "");
    return isTncUpdated(updatedAt);
  }, []);
  const [showTnc, setShowTnc] = useState<boolean>(updated);
  // const [showTnc, setShowTnc] = useState<boolean>(true);
  const handleClick = (isAccept: boolean) => {
    setShowTnc(false);
    onAccept();
  };

  // disabled
  if (!get(setting, "content.enabled") || !showTnc) {
    return null;
  }

  // console.log("ccc",i18next.language)
  const curLang = i18next.language;
  const mapLang = getLanguage(curLang); // zhhk -> zh-Hant
  const text = get(setting, `content.content.${mapLang}`) || get(setting, `content.content.en`);
  const arrContent = getTextAndLinkChunksFromText(text);
  return (
    <div className="tnc-wrap">
      <div id="close-btn-wrap">
        <Close
          onClick={() => {
            handleClick(false);
          }}
        />
      </div>
      {arrContent.map((item: { text: string; link: string }, index: number) => {
        if (item.link) {
          return (
            <a key={index} className="link" href={item.link} target="_blank">
              {item.text}
            </a>
          );
        }
        return item.text;
      })}
      <div className="btn-wrap">
        {/* <Button
          size="small"
          onClick={() => {
            handleClick(true);
          }}
          color={"white" as any}
          variant="contained"
        >
          {i18next.t("pp_accept_button")}
        </Button> */}
      </div>
    </div>
  );
};

const BULLET_LINK_REGEX_SEPARATOR = `(\\[.*?\\]\\(.*?\\))`;
const BULLET_LINK_REGEX = `\\[(.*?)\\]\\((.*?)\\)`;
/**
 * get link and text from mark-down format
 * sample:
 * source: user accept the [terms and policy](https://xxx.com) and continue
 * result:[
    {"text": "user accept the "},
    {"text": "terms and policy","link": "https://xxx.com"},
    {"text": " and continue"}]
 * @param text
 * @returns
 */
export const getTextAndLinkChunksFromText = (text: string) => {
  if (!text) {
    return [];
  }
  const arr: any = [];
  text.split(new RegExp(BULLET_LINK_REGEX_SEPARATOR, "g")).forEach((txt) => {
    if (txt) {
      if (!new RegExp(BULLET_LINK_REGEX, "g").test(txt)) {
        arr.push({ text: txt });
      } else {
        const matches = txt.match(new RegExp(BULLET_LINK_REGEX)) || [];
        arr.push({
          text: matches[1],
          link: matches[2],
        });
      }
    }
  });
  return arr;
};
