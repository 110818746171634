import React, { useEffect, useRef } from "react";

interface ProgressBarProps {
  progress: number;
  text?: string;
  className?: string;
}
const ProgressBar = ({ progress, text, className }: ProgressBarProps) => {
  const progressPercentage = progress * 100;
  const progressText = text || progressPercentage.toFixed(0) + "%";

  const progressRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.transition = "width 0.2s ease-in-out";
      progressRef.current.style.width = `${progressPercentage}%`;
    }
  }, [progressPercentage]);

  return (
    <div className={`w-screen flex flex-row align-center justify-center ${className}`}>
      <div className="flex flex-1 h-2 self-center bg-gray-200 rounded overflow-hidden">
        <div className="h-4 bg-green-400 rounded" ref={progressRef} />
      </div>
      <span className="text-base font-normal ml-5">{progressText}</span>
    </div>
  );
};

export default ProgressBar;
