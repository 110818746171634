import * as React from "react";
import ConfirmButtonGroup from "../../buttons/confirm-button-group";
import { useGlobalModal } from "../../use-modal/use-modal";
import { Modal } from "../../use-modal/modal.type";
import { useTranslation } from "react-i18next";

interface ConfirmModalProps {
  title?: string;
  content: string | React.ReactElement;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  close?: () => void;
  maxHeight?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  content,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  close,
  maxHeight,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="w-full flex flex-col px-4 pt-5 pb-4 text-sm font-medium break-words"
      style={{ maxHeight }}
    >
      {title ? <span className="text-center text-2xl font-semibold mb-3">{title}</span> : null}
      {content}
      <div className="w-full mt-5">
        <ConfirmButtonGroup
          confirmButtonText={confirmButtonText ?? t("global.text.confirm")}
          cancelButtonText={cancelButtonText ?? t("app.button.cancel")}
          onConfirm={() => {
            onConfirm?.();
            close?.();
          }}
          onCancel={() => {
            onCancel?.();
            close?.();
          }}
        />
      </div>
    </div>
  );
};
export const customizeConfirm = (props: ConfirmModalProps, openProps?: Modal) => {
  return new Promise<{ ok: boolean }>((resolve, reject) => {
    useGlobalModal().open(
      <ConfirmModal
        {...props}
        onConfirm={() => {
          props.onConfirm?.();
          resolve({ ok: true });
        }}
        onCancel={() => {
          props.onCancel?.();
          resolve({ ok: false });
        }}
      />,
      {
        showHeader: false,
        ...openProps,
      },
    );
  });
};
