import React, { useCallback, useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { SurveyForm } from "../../../../components/surveyjs/survey/survey-default-form/survey-default-form";
import { Layout } from "../../../../layout/layout";
import { useTranslation } from "react-i18next";
import { convert2SurveyjsI18nData, MircrositeDetail } from "./microsite.util";
import { StatusCode } from "../../../../components/error-state/error-state";
import bannerDefault from "../form/img/banner.png";
import svgDone from "../form/img/done.svg";
import { StatusEnum } from "../form/form.util";
import { ErrorScreen } from "../../../../components/error-screen/error-screen";
import { getI18nData } from "../../../../components/surveyjs/survey/survey.util";
import { getMicrositeById, submitMicrosite } from "../../network/network";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { takeUIClickEvent } from "../../../../common/ga/ga";
import { TakeUIClickEventParameters } from "../../../../common/ga/types/ga-general-type";
import {
  EventFeature,
  EventModule,
  TrackingEventType,
  TrackingInfo,
} from "../../../../utils/event-tracking/event-tracking.model";
import { eventTrackingService } from "../../../../utils/event-tracking/event-tracking.service";
// import { dataMock } from "./json";

export const MicrositeScreen: React.FC = () => {
  const [data, setData] = useState<MircrositeDetail>();
  const [surveyJson, setSurveyJson] = useState<any>();
  const [errorStatus, setErrorStatus] = useState<StatusCode | "0">("0");
  const [submitStatus, setSubmitStatus] = useState<StatusEnum>(StatusEnum.IDLE);

  const { t } = useTranslation();

  const params = useParams();

  useEffect(() => {
    if (!params.refId) {
      return;
    }
    getMicrositeById(params.refId)
      .then((res: MircrositeDetail) => {
        // res = dataMock;
        const jsonCopy = res.inputFieldsConfig;
        if (jsonCopy) {
          const callToAction = get(res, "callToActionText");
          let tncList = get(res, "customerDisclaimer", []);

          jsonCopy.completeText = convert2SurveyjsI18nData(callToAction);
          tncList = convert2SurveyjsI18nData(tncList);
          if (tncList) {
            const tnc = {
              type: "tnc",
              name: "tnc",
              titleLocation: "hidden",
              choices: tncList,
            } as any;
            jsonCopy.pages[0].elements.push(tnc);
          }
          setSurveyJson(jsonCopy);
        }
        setData(res);
      })
      .catch((err: AxiosError) => {
        const status = get(err, "response.status", "-1"); // -1: unknown error by default
        setErrorStatus(status.toString() as StatusCode);
      });
    submitEvent(params.refId, TrackingEventType.start_view);
  }, [params.refId]);

  const submitForm = useCallback(
    (formData: any) => {
      if (!params.refId) {
        return;
      }

      submitMicrosite(params.refId, formData)
        .then(() => setSubmitStatus(StatusEnum.DONE))
        .catch((err: AxiosError) => {
          const status = get(err, "response.status", "-1"); // -1: unknown error by default
          setErrorStatus(status.toString() as StatusCode);
        });
      setSubmitStatus(StatusEnum.DONE);
      const gaData: TakeUIClickEventParameters = {
        journey: "microsite_form_submission",
        stage: "submit_microsite_form",
        object_name: "CTA",
        object_type: "BUTTON",
      };
      takeUIClickEvent(gaData);
      submitEvent(params.refId, TrackingEventType.click);
    },
    [params.refId],
  );
  const bannerImg = useMemo(() => {
    if (!data) {
      return null;
    }
    const bannerType = data.bannerType;
    let bannerUrl: any = bannerDefault;
    if (bannerType !== "default") {
      bannerUrl = get(data, "bannerData.fileUrl");
    }
    return bannerUrl;
  }, [data]);

  const submitEvent = useCallback((refId: string, type: TrackingEventType) => {
    const postBody: TrackingInfo = {
      subject: `anonymous:ref=${refId}`,
      data: {
        context: {
          src: "publicweb",
          uuid: "",
          userAgent: window.navigator.userAgent,
        },
        name: "prospect::submit_leadform",
        module: EventModule.lead_form,
        feature: EventFeature.microsite,
        params: {
          startDatetime: Date.now(),
          refId: refId,
          sessionId: sessionStorage.getItem("session_id") || "",
          target: "microsite", // click target
        },
        type: type,
      },
    };
    eventTrackingService.doReport(postBody, true);
  }, []);

  if (errorStatus !== "0") {
    return (
      <Layout>
        <ErrorScreen statusCode={errorStatus} />
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="root-wrap">
        <div className="banner">
          <img alt="banner" src={bannerImg} />
        </div>
        <div className="content">
          <h1 className="title">{getI18nData(data?.title)}</h1>
          <p className="sub-title">{getI18nData(data?.subTitle)}</p>

          {/* submit done */}
          <div
            className={
              submitStatus === StatusEnum.DONE
                ? "flex flex-col w-full h-full overflow-x-hidden items-center justify-center mt-16"
                : "hidden"
            }
          >
            <div>
              <img alt="submit-done" className="mt-4 h-auto" src={svgDone} />
            </div>
            <div className="text-md lg:text-2xl font-bold pt-6">
              {getI18nData(data?.submissionResponseTitle)}
            </div>
            <div className="text-sm lg:text-lg text-center px-6 pt-2 text-gray-400">
              {getI18nData(data?.submissionResponseContent)}
            </div>
          </div>
          <div className={submitStatus !== StatusEnum.IDLE ? "hidden" : ""}>
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: getI18nData(data?.body),
              }}
            />
            <div className="flex-container top-line" />
          </div>
          {/** content end */}
        </div>

        {submitStatus === StatusEnum.IDLE && surveyJson && (
          <SurveyForm onSubmit={submitForm} json={surveyJson} />
        )}
      </div>
    </Layout>
  );
};
