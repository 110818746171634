import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  agentNameStyle: {
    fontSize: 20,
    paddingTop: 4,
    fontWeight: 700,
  },
  informationContainer: {
    fontSize: 14,
  },
  informationContentStyle: {
    paddingTop: 4,
  },
}));
