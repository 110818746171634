import { get } from "lodash";

/**
 * ROOT_PATH defined on docker env
 * detail: https://code.pruconnect.net/projects/PCAAEB/repos/ebriefcase_devops/browse/k8s-manifests/coedev/env-configmap.yaml
 */
export const FRONTEND_ROOT_PATH = get(window, "envConfig.COMMON_PUBLIC_FRONTEND_ROOT_PATH", "");
export const ROOT_PATH = `${FRONTEND_ROOT_PATH}/web`;

export const NOT_FOUND_ERROR_PAGE_PATH = "/error/not-found";
export const FORBIDDEN_ERROR_PAGE_PATH = "/error/forbidden";

export const MANDATORY_FIELD_ERROR_TEXT = "This field is mandatory";
export const VALIDATION_FIELD_ERROR_TEXT = "Please fill in correct data";
export const DATE_ERROR_TEXT = "End date must be after Begin date";
export const INVALID_PATTERN_ERROR_TEXT = "Invalid data. Please remove redundant symbols";
export const INVALID_CURRENCY_ERROR_TEXT = "Please fill in currency symbol followed by numbers";
export const ONBOARDING_PATH = "onboarding";
export const EVENT_PATH = "event";
export const PRUDNA_PATH = "PRUdna";

export const constants = {
  Standard_DateFormat: "YYYY-MM-DDTHH:mm:ssZ",
  Default_Date_Format: "DD/MM/YYYY",
  Date_Minute_Scale_Format: "DD/MM/YYYY HH:mm",
  Time_Format: "HH:mm",
};
