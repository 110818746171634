import React, { useState, useEffect, useRef, useMemo, Ref, useContext } from "react";
import {
  TransformWrapper,
  useControls,
  useTransformEffect,
  TransformComponent,
  ReactZoomPanPinchContext,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";
import { debounce } from "lodash";
import { PdfViewContext } from "./page-context";
import { PdfImageView, PdfViewProps } from "./pdf-image";

type Props = {
  transformProps?: {
    wrapperClass?: string;
    contentClass?: string;
    wrapperStyle?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
    contentProps?: React.HTMLAttributes<HTMLDivElement>;
  };
} & PdfViewProps;

const transformLimit = 100;

export const TransformPdfView = (props: Props) => {
  const context = useContext(PdfViewContext);
  return (
    <TransformWrapper
      onPanningStop={(ref, event) => {
        if (ref.state.scale !== 1) return;
        const postionx = ref.state.positionX;
        if (postionx > 0) {
          if (postionx < 100) return;
          context.prePage?.();
          return;
        }
        if (postionx < 0) {
          const rightLimit = ref.instance.bounds?.minPositionX ?? 0;
          if (postionx > rightLimit - transformLimit) return;
          context.nextPage?.();
          return;
        }
      }}
    >
      <TransformComponent wrapperStyle={{ backgroundColor: "#f0f0f0" }} {...props.transformProps}>
        <PdfImageView {...props}></PdfImageView>
      </TransformComponent>
    </TransformWrapper>
  );
};
