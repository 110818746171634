export enum UserTypeEnum {
  AGENT = "AGENT",
  CANDIDATE = "CANDIDATE",
  MANAGER = "MANAGER",
  ANONYMOUS = "ANONYMOUS",
}

export enum OtpContextEnum {
  FIRST_LOGIN = "FirstLogin",
  FORGOT_PASSWORD = "ForgotPassword",
  PublicSharing = "PublicSharing",
}
