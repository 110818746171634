import React from "react";
import { useGlobalModal } from "../../use-modal/use-modal";
import { ImgResource } from "../../../modules/resource/components/resource-detail";

interface ImagePreviewProps {
  url: string;
  close?: Function;
}

export const ImagePreview = ({ url, close }: ImagePreviewProps) => {
  // const { t } = useTranslation();
  return (
    <div onClick={() => close!()} className="flex flex-col w-screen h-screen">
      <ImgResource url={url} />
    </div>
  );
};

export const imageOpen = (props: ImagePreviewProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useGlobalModal().open(<ImagePreview {...props} />, {
    showHeader: false,
    modalType: "bottom",
    modalProps: {
      className: "dialog-full",
      PaperProps: {
        style: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          width: window.innerWidth + "px",
        },
      },
    },
  });
};
