import React from "react";
import prudentialIcon from "../../../../components/icon/assets/prudential-logo.svg";
import pruforceIcon from "../../../../components/icon/assets/pruforce-logo.svg";

import { CircularProgress } from "@mui/material";
import { PasswordInput } from "../../../../components/password-input/password-input.component";
import { RegionPhone } from "../../../../components/region-phone/region-phone";
import { color } from "../../../../mui-theme/palette";
import { useLogin } from "./login.hook";
import styles from "./login.module.css";

import { ErrorStateBlock } from "../../../../components/error-state/error-state";
import { getAccessibilityLabel, languageSelectionConfig } from "../../utils";

type LoginScreenProps = {};

export const LoginScreen = (props: LoginScreenProps) => {
  const {
    t,
    isCandidateWebLoginEnabled,
    selectedLang,
    region,
    setRegion,
    handleLogin,
    phoneNumber,
    setPhoneNumber,
    password,
    setPassword,
    isLoading,
    handleCreateAccount,
    handleForgetPassword,
    handleDisclaimerClick,
    handlePrivacyPolicyClick,
    islanguagePopupOpen,
    toggleLanguagePopupOpen,
    handleLanguageChange,
  } = useLogin();

  return isCandidateWebLoginEnabled ? (
    <div className={styles.mainContain}>
      <div className={styles.header}>
        <div className={styles.logoSection}>
          <img className={styles.logo} src={pruforceIcon} alt="" />
          <span className={styles.tips}>{t("Common.pruforce_candidate_portal")}</span>
        </div>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.formContainer}>
          <RegionPhone
            label={t("Common.registered_phone_number")}
            region={region}
            setRegion={setRegion}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
          <PasswordInput
            label={t("Common.password")}
            value={password}
            onChange={setPassword}
            placeholder=""
          />
          <div className={styles.container}>
            <div className={styles.forgotPassword}>
              <label
                htmlFor="forgot-password"
                className={styles.forgotPasswordLabel}
                onClick={handleForgetPassword}
              >
                {t("Common.forgot_password")}
              </label>
            </div>

            <div className={styles.buttonGroup}>
              <button className={styles.loginButton} onClick={handleLogin}>
                {t("Common.login")}
              </button>

              <button className={styles.createAccountButton} onClick={handleCreateAccount}>
                {t("Common.create_account")}
              </button>
            </div>
          </div>
          <div className={styles.footer}>
            <img src={prudentialIcon} alt="Prudential Icon" className={styles.prudentialIcon} />
            <div className={styles.footerText}>
              <span className={styles.disclaimer} onClick={handleDisclaimerClick}>
                {t("Common.disclaimer")}
              </span>
              <span>{t("Common.and")}</span>
              <span className={styles.privacyPolicy} onClick={handlePrivacyPolicyClick}>
                {t("Common.privacy_policy")}
              </span>
            </div>
            <div className={styles.version}>{t("Common.version")} 1.0</div>
          </div>
        </div>
      </div>

      <div className={styles.headerSection}>
        <span className={styles.language}>{getAccessibilityLabel(selectedLang)}</span>
        <div className={styles.languageIcon} onClick={toggleLanguagePopupOpen}></div>{" "}
      </div>
      {islanguagePopupOpen && (
        <div className={styles.popup}>
          <ul>
            {languageSelectionConfig().availableLanguages.map((lang) => (
              <li
                key={lang.i18nLanguageCode}
                className={styles.languageItem}
                onClick={() => handleLanguageChange(lang.i18nLanguageCode)}
              >
                {lang.languageFullName}
              </li>
            ))}
          </ul>
        </div>
      )}
      {isLoading && (
        <div className={styles.circularProgress}>
          <CircularProgress sx={{ color: color.majorRed }} />
        </div>
      )}
    </div>
  ) : (
    <ErrorStateBlock
      statusCode="-1"
      title={t("Common.service_unavailable")}
      description={t("Common.the_candidate_portal_is_not_accessible")}
    />
  );
};
