import React from "react";
import EventHeaderImg from "../img/event-header.svg";

export const Header = () => {
  return (
    <div className="h-[50px] p-4 border-b-[1px] border-[#F0F0F0] md:h-16 md:px-[30px] md:py-5 bg-white">
      <img className="h-full block" src={EventHeaderImg} alt="header" />
    </div>
  );
};
