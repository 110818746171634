import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useCanGoBack } from "../../../../../../utils/hooks/use-can-go-back";
import {
  OnboardingExam,
  OnboardingExamRegistryStatus,
  OnboardingExamResult,
} from "../../../../types";
import Icon from "../../../../../../components/icon/icon";
import { SvgAssets } from "../../../../../../components/icon/assets";
import { HeaderComponent } from "../../../../../../components/header/header.component";
import useThrottledTouchEnd from "../../../../hooks/use-throtted-touch-end";
import { useQuery } from "../../../../../../utils/hooks/use-query";
import { useDispatch, useSelector } from "react-redux";
import { selectOnboardingCurrentExam } from "../../../../redux/selector";
import { fetchTrainingExamByIdAsync } from "../../../../redux/onboarding-slice";

type ExamFeedbackScreenProps = {};

export const ExamFeedbackScreen = (props: ExamFeedbackScreenProps) => {
  useThrottledTouchEnd();
  const { t } = useTranslation();
  const location = useLocation();

  const dispatch = useDispatch();
  const query = useQuery();
  const { examId } = useParams();
  const candidateId = query.get("candidateId")!;
  const currentExam = useSelector(selectOnboardingCurrentExam);
  const dataFetched = useRef(false);
  const exam: OnboardingExam = useMemo(() => {
    if (location.state?.exam) return location.state?.exam;
    if (!dataFetched.current) {
      if (!examId || !candidateId) return;
      dispatch<any>(fetchTrainingExamByIdAsync({ candidateId, examId: examId! }));
      dataFetched.current = true;
    }
    return currentExam;
  }, [location.state?.exam, examId, candidateId, dispatch, currentExam]);

  const navigate = useNavigate();
  const canGoBack = useCanGoBack();
  const confirmHandler = useCallback(() => {
    if (canGoBack()) {
      navigate(-1);
    }
  }, [canGoBack, navigate]);
  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen w-screen flex flex-col">
      <HeaderComponent
        title={""}
        onBack={() => {
          if (canGoBack()) {
            navigate(-1);
          }
        }}
      />
      {exam && (
        <div className="flex flex-1 w-full items-center flex-col pt-20">
          <Icon
            svg={
              exam?.status === OnboardingExamRegistryStatus.PASSED
                ? SvgAssets.congrats
                : SvgAssets.failFeedback
            }
            width={200}
            height={200}
          />
          {exam?.status === OnboardingExamRegistryStatus.PASSED ? (
            <div className="text-green-500 text-3xl font-semibold">{t("exam_result_congrats")}</div>
          ) : (
            <div className="text-red-500 text-3xl font-semibold">{t("exam_result_failed")}</div>
          )}
          <div className="text-gray-900 text-3xl font-bold mt-9">
            {(exam.examResult as OnboardingExamResult)?.score ? (
              <>
                <span className="text-[40px]">
                  {(exam.examResult as OnboardingExamResult)?.score ?? "-"}
                </span>
                <span>/100</span>
              </>
            ) : null}
          </div>
          <div className="text-center text-gray-600 text-sm font-medium pt-14 px-12">
            {exam?.status === OnboardingExamRegistryStatus.PASSED
              ? t("exam_pass_announcement")
              : t("exam_failed_register_again")}
          </div>
        </div>
      )}
    </div>
  );
};
