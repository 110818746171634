import React, { CSSProperties } from "react";
import { ElementFactory, Question, Serializer, FunctionFactory } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";
// mui start
import { TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
// mui end

import { get } from "lodash";
import { CustomType } from "../survey/survey.util";
import { DatePicker } from "@mui/x-date-pickers";

dayjs.extend(utc);
const CUSTOM_TYPE = CustomType.DatePicker;

export class QuestionDatePickerModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  // PCAAEB-35757: remove this method(not in use) to avoid script error
  // get commentPlaceHolder(): string {
  //   // return this.commentPlaceHolder;
  // }
}

// Add question type metadata for further serialization into JSON
export const initDatePickerSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [],
    function () {
      return new QuestionDatePickerModel("");
    },
    "question",
  );
};

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionDatePickerModel(name);
});

/** birth allow range */
const maxDate = dayjs();
const minDate = dayjs(new Date()).subtract(120, "year");
// A class that renders questions of the new type in the UI
export class SurveyQuestionDatePicker extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.question.value,
      maxDate: dayjs(),
      minDate: dayjs(new Date()).subtract(120, "year"),
    };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get disableAlpha() {
    return this.question.disableAlpha;
  }

  // Support read-only
  get style() {
    return (
      this.question.getPropertyValue("readOnly") ? { pointerEvents: "none" } : undefined
    ) as CSSProperties;
  }
  onDateChange = (date: Dayjs | null) => {
    this.question.value = date?.utc(true)?.format();
  };

  renderElement() {
    const disabled = false;
    return (
      <div style={this.style}>
        <FormControl fullWidth variant="standard">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              minDate={this.state.minDate}
              maxDate={this.state.maxDate}
              disabled={disabled}
              slotProps={{ textField: { size: "medium" } }}
              format="DD/MM/YYYY"
              onChange={this.onDateChange.bind(this)}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
    );
  }
}

/**
 * check phone number regex when it's not empty
 * @param param0
 * @returns
 */
function dateOfBirthValidate([value]: Dayjs[]): boolean {
  if (!value) {
    return true;
  }
  const selectedDate = dayjs(value);
  if (selectedDate > maxDate || selectedDate < minDate) {
    return false;
  }
  return true;
}

/** custom validation */
FunctionFactory.Instance.register("dateOfBirthValidate", dateOfBirthValidate);
