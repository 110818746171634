export enum ApplicationType {
  NewRecruit = "NewRecruit",
  ForReinstatement = "ForReinstatement",
}
export const ApplicationDetailTemplate = {
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "radiogroup",
          name: "applicationType",
          isRequired: true,
          title: "Recruitment.application_type",
          tooltips: "Recruitment.collect_info_before_application",
          choices: [
            { text: "Recruitment.new_recruit", value: ApplicationType.NewRecruit },
            { text: "Recruitment.reinstatement", value: ApplicationType.ForReinstatement },
          ],
          requiredErrorText: "Recruitment.application_type_required_error",
        },
        {
          type: "text",
          visibleIf: "{applicationType} = 'ForReinstatement'",
          name: "formerAgentCode",
          isRequired: true,
          title: "Recruitment.former_agent_code",
          placeholder: "Recruitment.input_your_agent_code",
          validators: [
            {
              type: "regex",
              text: "Recruitment.format_error",
              regex: /^[a-zA-Z0-9_-]+$/,
            },
          ],
          requiredErrorText: "Recruitment.former_agent_code_required_error",
        },
        {
          type: "text",
          visibleIf: "{applicationType} = 'ForReinstatement'",
          inputType: "date",
          name: "licenseExpirationDate",
          isRequired: true,
          title: "Recruitment.license_expiration_date",
          placeholder: "Recruitment.placeholder_select_license_expiration_date",
          requiredErrorText: "Recruitment.license_expiration_date_required_error",
        },
        {
          type: "text",
          visibleIf: "{applicationType} = 'ForReinstatement'",
          inputType: "date",
          name: "appointmentDate",
          isRequired: true,
          title: "Recruitment.appointment_date",
          placeholder: "Recruitment.placeholder_select_appointment_date",
          requiredErrorText: "Recruitment.appointment_date_required_error",
        },
        {
          type: "text",
          visibleIf: "{applicationType} = 'ForReinstatement'",
          inputType: "date",
          name: "terminationDate",
          isRequired: true,
          title: "Recruitment.termination_date",
          placeholder: "Recruitment.placeholder_select_termination_date",
          requiredErrorText: "Recruitment.termination_date_required_error",
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};
