import { canUseCookie } from "./cookie";

export function getItem<T>(key: string, toJSON?: boolean): T | null {
  /** need to check first. local-storage Access is denied when disabling cookie */
  if (!canUseCookie()) {
    return null;
  }
  try {
    if (typeof window === "undefined" || !localStorage) return null;

    // @ts-ignore
    const rawData = localStorage.getItem(key) || "";
    if (!rawData) {
      return null;
    }
    return toJSON ? JSON.parse(rawData) : rawData;
  } catch (err) {
    console.error(`localStorage getItem ${key} error`, err);
  }
  return null;
}

export function setItem<T>(key: string, item: T) {
  /** need to check first. local-storage Access is denied when disabling cookie */
  if (!canUseCookie()) {
    return null;
  }
  try {
    if (typeof window === "undefined" || !localStorage) return null;
    const value = typeof item === "object" ? JSON.stringify(item) : (item as string);
    return localStorage.setItem(key, value);
  } catch (err) {
    console.error(`localStorage setItem ${key} error`, err);
  }
}

export function removeItem(key: string) {
  if (typeof window === "undefined" || !localStorage) return null;

  try {
    return localStorage.removeItem(key);
  } catch (err) {
    console.error(`localStorage remove ${key} error`, err);
  }
}
