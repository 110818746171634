import * as React from "react";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { color } from "../../mui-theme/palette";
interface ConfirmButtonGroupProps {
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  disableConfirm?: boolean;
  orientation?: "horizontal" | "vertical";
}

export const ConfirmButton = styled(Button)`
  background-color: ${color.majorRed};
  color: ${color.white};
  border-radius: 12px;
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px;
  text-transform: none;

  &:hover {
    background-color: ${color.majorRed};
  }
  &:disabled {
    background-color: ${color.greyCc};
    color: ${color.white};
  }
`;

const CancelButton = styled(Button)`
  background-color: ${color.white};
  color: ${color.grey33};
  border-width: 2px;
  border-color: ${color.greyCc};
  border-radius: 12px;
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px;
  text-transform: none;
`;

export const useButtonStyles = makeStyles({
  confirmButton: {
    backgroundColor: `${color.majorRed} !important`,
    color: color.white,
    borderRadius: "12px",
    flex: 1,
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    padding: "12px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: color.majorRed,
    },
  },
  cancelButton: {
    backgroundColor: color.white,
    color: color.grey33,
    borderWidth: "2px",
    borderColor: color.greyCc,
    borderRadius: "12px",
    flex: 1,
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    padding: "12px",
    textTransform: "none",
  },
});
const ConfirmButtonGroup: React.FC<ConfirmButtonGroupProps> = ({
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  disableConfirm,
  onCancel,
  orientation = "vertical",
}) => {
  const classes = useButtonStyles();
  return (
    <div className={`flex flex-1 ${orientation === "horizontal" ? "flex-row" : "flex-col"}`}>
      {confirmButtonText ? (
        <ConfirmButton disabled={disableConfirm} onClick={onConfirm}>
          {confirmButtonText}
        </ConfirmButton>
      ) : null}
      <div className="p-2" />
      {cancelButtonText ? (
        <CancelButton variant="outlined" onClick={onCancel}>
          {cancelButtonText}
        </CancelButton>
      ) : null}
    </div>
  );
};

export default ConfirmButtonGroup;
