import React, { Component, useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { color } from "../../../../../mui-theme/palette";

export const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: color.majorRed,
  backgroundColor: color.redLight,
  "&:hover": {
    backgroundColor: color.redLight,
  },
  padding: 0,
}));
export const ColorButton2 = styled(Button)<ButtonProps>(({ theme }) => ({
  color: color.blue,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&:disabled": {
    color: color.grey99,
  },
  padding: 0,
  fontSize: 12,
  fontWeight: 500,
}));

export type OtpButtonProps = ButtonProps;

const TIME = 60;
// const TIME = 1;

export const OtpButton = (props: OtpButtonProps) => {
  const [click, setClick] = useState<number | undefined>();
  useEffect(() => {
    if (!click) return;
    const interval = setInterval(() => {
      setClick(click - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [click]);

  return (
    <ColorButton2
      {...props}
      disabled={!!click || props.disabled}
      onClick={(e) => {
        setClick(TIME);
        props.onClick?.(e);
      }}>
      Send Code{!!click ? ` (${click}s)` : ""}
    </ColorButton2>
  );
};
