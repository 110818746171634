import { useNavigate } from "react-router-dom";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateProfileAsync } from "../../../auth/redux/auth-slice";
import { selectCandidateProfile, selectToken } from "../../../auth/redux/selector";
import { TokenPayload } from "../../../auth/network/type";
import { getToken, parseJwt } from "../../utils";
import { merge } from "lodash";

interface HookProps {}

export const useCandidateHome = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const tokenData = useMemo(() => {
    const tokenFromStorage = getToken();
    const tokenPayload = parseJwt(tokenFromStorage);
    return merge(tokenPayload, token);
  }, [token]);

  const candidateProfile = useSelector(selectCandidateProfile);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (tokenData) {
          const res: TokenPayload = tokenData;
          const candidateId = res?.sub;
          if (candidateId) {
            await dispatch<any>(getCandidateProfileAsync(candidateId));
          }
        }
      } catch (error) {
        console.error("Failed to fetch candidate profile:", error);
      }
    };

    fetchProfile();
  }, [dispatch, tokenData]);

  const clickEmail = () => {};
  const clickSetting = () => {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/setting`);
  };
  const clickProfile = useCallback(() => {
    navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/join-now`, {
      state: {
        candidateProfile,
      },
    });
  }, [navigate, candidateProfile]);

  return {
    t,
    clickEmail,
    clickSetting,
    clickProfile,
    candidateProfile,
  };
};
