import { MenuData, HeaderItem, DocItem, ModuleSection, SectionItem, StatusEnum } from "./type";
import { upperFirst, get, lowerCase } from "lodash";
import moment from "moment";
import { getI18n } from "react-i18next";
import { OnboardingApplicationSectionStatus, OnboardingApplicationStatus } from "../../types";

export function convertData(
  formInstance: any,
  menuFormTemplate: any,
  translate: {
    applicationNumber: string;
    applicationStatus: string;
    lastupdatedat: string;
  },
): MenuData {
  if (!formInstance || !menuFormTemplate) return {};
  const menuData: MenuData = {};
  if (!!formInstance) {
    const headerItems: HeaderItem[] = [];
    if (formInstance.applicationId)
      headerItems.push({
        key: translate.applicationNumber,
        value: formInstance.applicationId,
      });
    if (formInstance?.applicationStatus)
      headerItems.push({
        key: translate.applicationStatus,
        value: getStatusText(formInstance.applicationStatus),
        valueColor: getValueColor(formInstance.applicationStatus),
      });
    if (formInstance.lastUpdatedDate)
      headerItems.push({
        key: translate.lastupdatedat,
        value: moment(moment.utc(formInstance.lastUpdatedDate).toDate()).format("DD/MM/yyyy HH:mm"),
      });
    menuData.header = headerItems;
  }
  if (!menuFormTemplate) return menuData;
  const pages: any[] = menuFormTemplate;
  const pageForms = get<any>(formInstance, "modules", undefined);
  const sections: ModuleSection[] = [];
  let hasExpandPage = false;
  let firsUneditingPageIndex: number | undefined;

  pages.forEach((page, pageIndex) => {
    const pageName = get(page, "name", "");
    const moduleSection: ModuleSection = {
      title: get(page, "title", ""),
      name: pageName,
      data: [],
      expanded: false,
      status: StatusEnum.untouched,
    };

    if (!pageForms) return;
    const pageForm = get<any>(pageForms, pageName, undefined);
    const panels = get<any[]>(page, "elements", []);

    moduleSection.denominator = panels.length;
    moduleSection.numerator = 0;

    let moduleStatus: StatusEnum = StatusEnum.untouched;
    let isAllComplete = true;
    let hasEditing = false;
    let hasUntouchedSection = false;
    let isAllUntouched = true;

    panels.forEach((panel, panelIndex) => {
      const panelName = get(panel, "name", "");
      const panelForm = get<any>(pageForm, panelName, undefined);
      let status: StatusEnum = StatusEnum.untouched;
      const formPanelStatus = get(panelForm, "status", "");
      if (formPanelStatus === OnboardingApplicationSectionStatus.COMPLETED) {
        status = StatusEnum.completed;
        isAllUntouched = false;
        moduleSection.numerator = (moduleSection.numerator ?? 0) + 1;
      } else if (formPanelStatus === OnboardingApplicationSectionStatus.WARNING) {
        isAllComplete = false;
        status = StatusEnum.editing;
        hasEditing = true;
        isAllUntouched = false;
      } else {
        hasUntouchedSection = true;
        isAllComplete = false;
        if (firsUneditingPageIndex === undefined) firsUneditingPageIndex = pageIndex;
      }

      const sectionItem: SectionItem = {
        title: get(panel, "title", ""),
        name: panelName,
        status: status,
      };
      moduleSection.data.push(sectionItem);
    });
    if (isAllComplete) moduleStatus = StatusEnum.completed;
    if (hasEditing || (hasUntouchedSection && !isAllUntouched)) moduleStatus = StatusEnum.editing;

    moduleSection.status = moduleStatus;

    moduleSection.expanded = moduleStatus === StatusEnum.editing;
    if (moduleSection.expanded) hasExpandPage = true;
    if (moduleSection.data.length > 0) sections.push(moduleSection);
  });
  if (!hasExpandPage && firsUneditingPageIndex !== undefined) {
    let actualSectionIndex = firsUneditingPageIndex;
    sections[actualSectionIndex].expanded = true;
  }
  menuData.menu = sections;
  menuData.isCompleted = sections.every((section) => section.status === StatusEnum.completed);

  return menuData;
}

function getStatusText(status: OnboardingApplicationStatus): string {
  switch (status) {
    case OnboardingApplicationStatus.DRAFT:
    case OnboardingApplicationStatus.EDITING:
      return getI18n().t("Recruitment.onboarding_form_status_editing");
    case OnboardingApplicationStatus.PENDING_SIGNATURE:
      return getI18n().t("Recruitment.onboarding_form_status_pending_signature");
    case OnboardingApplicationStatus.FOLLOW_UP_REQUIRED:
      return getI18n().t("Recruitment.onboarding_form_status_follow_up_required");
    case OnboardingApplicationStatus.SUBMITTED:
      return getI18n().t("Recruitment.onboarding_form_status_submitted");
    case OnboardingApplicationStatus.ACCEPTED:
      return getI18n().t("Recruitment.onboarding_form_status_accepted");
    case OnboardingApplicationStatus.REJECTED:
      return getI18n().t("Recruitment.onboarding_form_status_rejected");
    case OnboardingApplicationStatus.EXPIRED:
      return getI18n().t("Recruitment.onboarding_form_status_expired");
    case OnboardingApplicationStatus.DELETED:
      return getI18n().t("Recruitment.onboarding_form_status_deleted");
    case OnboardingApplicationStatus.L1_PENDING_APPROVAL:
      return getI18n().t("Recruitment.onboarding_form_status_l1_pending_approval");
    case OnboardingApplicationStatus.L2_PENDING_APPROVAL:
      return getI18n().t("Recruitment.onboarding_form_status_l2_pending_approval");
    case OnboardingApplicationStatus.L3_PENDING_APPROVAL:
      return getI18n().t("Recruitment.onboarding_form_status_l3_pending_approval");
    case OnboardingApplicationStatus.L4_PENDING_APPROVAL:
      return getI18n().t("Recruitment.onboarding_form_status_l4_pending_approval");
    case OnboardingApplicationStatus.L1_APPROVED:
      return getI18n().t("Recruitment.onboarding_form_status_l1_approved");
    case OnboardingApplicationStatus.L2_APPROVED:
      return getI18n().t("Recruitment.onboarding_form_status_l2_approved");
    case OnboardingApplicationStatus.L3_APPROVED:
      return getI18n().t("Recruitment.onboarding_form_status_l3_approved");
    case OnboardingApplicationStatus.CANCELLED:
      return getI18n().t("Recruitment.onboarding_form_status_cancelled");
  }
}
function getValueColor(status: OnboardingApplicationStatus): string | undefined {
  if (status === OnboardingApplicationStatus.FOLLOW_UP_REQUIRED) return "#FF872E";
}
