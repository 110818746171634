import { useDispatch, useSelector } from "react-redux";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderComponent } from "../../../../../../components/header/header.component";
import { useQuery } from "../../../../../../utils/hooks/use-query";
import { useCanGoBack } from "../../../../../../utils/hooks/use-can-go-back";
import { LessonView } from "../../../../components/pdf-viewer/component";
import {
  OnboardingLesson,
  OnboardingLessonStatus,
  OnboardingTrainingCourse,
} from "../../../../types";
import { ROOT_PATH, ONBOARDING_PATH } from "../../../../../../routes/constants";
import { useGlobalModal } from "../../../../../../components/use-modal/use-modal";
import { PreQuizOverview } from "../course-quiz-screen/pre-quiz-overview.component";
import { lessonStatusUpdateAsync } from "../../../../redux/onboarding-slice";
import useThrottledTouchEnd from "../../../../hooks/use-throtted-touch-end";
import { selectOnboardingCourses } from "../../../../redux/selector";

type LessonScreenProps = {};

export const CourseLessonScreen = (props: LessonScreenProps) => {
  /**
   * trigger refresh
   */
  const { t } = useTranslation();
  useThrottledTouchEnd();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();
  const courses = useSelector(selectOnboardingCourses);
  const {
    currentCourse,
    currentLesson,
  }: { currentLesson: OnboardingLesson; currentCourse: OnboardingTrainingCourse } = useMemo(() => {
    let _currentLesson;
    const _currentCourse = courses?.find((course) =>
      course.lessons.find((lesson) => {
        if (lesson.lessonId === location.state.data?.lessonId) {
          _currentLesson = lesson;
          return true;
        }
      }),
    );
    return { currentLesson: _currentLesson ?? location.state.data, currentCourse: _currentCourse! };
  }, [courses, location.state.data]);
  const courseLessonsCompleted = useMemo(() => {
    return currentCourse.lessons.every(
      (lesson) => lesson.status === OnboardingLessonStatus.COMPLETED,
    );
  }, [currentCourse]);
  const navigate = useNavigate();
  const canGoBack = useCanGoBack();
  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen flex flex-col">
      <HeaderComponent
        title={currentLesson.courseName}
        onBack={() => {
          if (canGoBack()) {
            navigate(-1);
          }
        }}
      />
      <LessonView
        nextPageText={t("next_page")}
        prePageText={t("previous_page")}
        endPageText={currentLesson.quiz || currentLesson.courseQuiz ? t("take_quiz") : t("proceed")}
        title={currentLesson.name}
        description={t("onboarding.lesson.complete_reading_material_tip")}
        onFinish={() => {
          if (currentLesson.status !== OnboardingLessonStatus.COMPLETED) {
            dispatch<any>(
              lessonStatusUpdateAsync({
                lessonId: currentLesson.lessonId,
                status: OnboardingLessonStatus.COMPLETED,
              }),
            );
          }
          const quiz =
            currentLesson.quiz ?? (courseLessonsCompleted ? currentLesson.courseQuiz : undefined);
          if (quiz) {
            const handlerPromise = new Promise((resolve, reject) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useGlobalModal().open(
                <PreQuizOverview
                  onConfirm={() => {
                    resolve(`quiz/${quiz!.quizId}`);
                  }}
                  onCancel={() => {}}
                  quiz={quiz}
                />,
                {
                  showHeader: false,
                  modalType: "bottom",
                  modalProps: {
                    className: "dialog-full",
                    PaperProps: {
                      style: {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        width: window.innerWidth + "px",
                      },
                    },
                  },
                },
              );
            });
            handlerPromise.then((pathSuffix) => {
              navigate(
                `${ROOT_PATH}/${ONBOARDING_PATH}/courses/${pathSuffix}?${query.toString()}`,
                {
                  state: { data: currentLesson.quiz },
                  replace: true,
                },
              );
            });
          } else {
            navigate(-1);
          }
        }}
        resourceUrl={currentLesson.materialUpload}
      />
    </div>
  );
};
