import { makeStyles } from "@mui/styles";

const SWITCH_CONTAINER_WIDTH = 86;
const SWITCH_CONTAINER_HEIGHT = 36;
const SWITCH_CONTAINER_SIDE_PADDING = 4;
const SWITCH_CONTAINER_TOP_BOTTOM_PADDING = 3;

const SWITCH_WIDTH = (SWITCH_CONTAINER_WIDTH - SWITCH_CONTAINER_SIDE_PADDING * 2) / 2;
const SWITCH_HEIGHT = SWITCH_CONTAINER_HEIGHT - SWITCH_CONTAINER_TOP_BOTTOM_PADDING * 2;

export const useStyles = makeStyles(() => ({
  switchContainer: {
    backgroundColor: "#F5F5F5",
    position: "absolute",
    top: 16,
    right: 16,
    width: SWITCH_CONTAINER_WIDTH,
    height: SWITCH_CONTAINER_HEIGHT,
    padding: `${SWITCH_CONTAINER_TOP_BOTTOM_PADDING}px ${SWITCH_CONTAINER_SIDE_PADDING}px`,
    borderRadius: 10,
    zIndex: 1,
  },
  switch: {
    width: SWITCH_WIDTH,
    height: SWITCH_HEIGHT,
    margin: 0,
    // public portal specific
    justifyContent: "center",
  },
  switchChecked: {
    color: "#E8192C",
    backgroundColor: "white",
    borderRadius: 8,
    // public portal specific
    margin: 0,
    justifyContent: "center",
  },
  switchUnchecked: {
    color: "#999999",
    // public portal specific
    margin: 0,
    justifyContent: "center",
  },
  switchLabel: {
    width: "100%",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Arial",
  },
  langCheckBox: {
    display: "none !important", // PCAAEB-30180
  },
}));
