import { Description } from "@mui/icons-material";
import { PaymentType } from "../../../../types";

export const ExamTemplate = {
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "boolean",
          name: "hasPassedExam",
          title: "I have taken and passed this exam",
          isRequired: true,
          disallowedActions: "delete",
          labelTrue: "Yes",
          labelFalse: "No",
          valueTrue: true,
          valueFalse: false,
          requiredErrorText: "This field is mandatory",
        },

        {
          type: "boolean",
          visibleIf: `{hasPassedExam} = true`,
          name: "hasLicenseProof",
          title: "I have a proof for this passed exam",
          isRequired: true,
          disallowedActions: "delete",
          labelTrue: "Yes",
          labelFalse: "No",
          valueTrue: true,
          valueFalse: false,
          requiredErrorText: "This field is mandatory",
        },

        {
          type: "html",
          name: "content",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = false`,
          html: `
            <div style="padding: 0 24px;">
              <p style="font-size: 0.875rem; font-weight: 500; line-height: 20px; text-align: left; margin-bottom: 10px; color:#333">
                Request a copy of Exam Certificate
              </p>
              <p style="font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; color: #999999;">
                Please pay your Exam Certificate request fee of Php 500,00 to any Pru Life UK branch near you.
              </p>
            </div>
          `,
        },

        {
          type: "text",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = true`,
          name: "examScore",
          inputType: "number",
          title: "Exam Score",
          isRequired: true,
          isPII: true,
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "text",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = true`,
          name: "examDate",
          title: "Exam Date",
          isRequired: true,
          disallowedActions: "delete",
          isPII: true,
          inputType: "date",
          requiredErrorText: "This field is mandatory",
          maxValueExpression: "today",
        },
        {
          type: "file",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = true`,
          name: "proofInfos",
          title: "Exam Proof Upload ",
          description:
            "Please only upload PDF/ JPG/ JPEG/ PNG <br/>Each file should not be larger than 4MB",
          isRequired: true,
          allowMultiple: true,
          acceptedTypes: ".jpeg, .jpg, .png, .pdf",
          waitForUpload: true,
          maxSize: 4194304,
          needConfirmRemoveFile: true,
          acceptedTypesErrorText: {},
          convertImageToPdf: true,
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "text",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = false`,
          name: "officialReceiptNumber",
          title: "Official Receipt Number",
          isRequired: true,
          isPII: true,
          requiredErrorText: "This field is mandatory",
          validators: [
            {
              type: "regex",
              text: "Recruitment.format_error",
              regex: /^[a-zA-Z0-9_-]+$/,
            },
          ],
        },
        {
          type: "text",
          visibleIf: `{hasPassedExam} = true && {hasLicenseProof} = false`,
          name: "paymentReceiptDate",
          title: "Payment Receipt Date",
          isRequired: true,
          isPII: true,
          inputType: "date",
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "dropdown",
          visibleIf: `{hasPassedExam} = false`,
          name: "examCentre",
          title: "Please select the city",
          isRequired: true,
          choices: [{}, {}],
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "dropdown",
          visibleIf: `{hasPassedExam} = false`,
          name: "examScheduleId",
          title: "Available Schedule",
          isRequired: true,
          choices: [{}, {}],
          // inputType: "date",
          requiredErrorText: "This field is mandatory",
        },
      ],
    },
    {
      name: "page2",
      elements: [
        {
          type: "radiogroup",
          name: "paymentType",
          isRequired: true,
          title: "Payment Type",
          choices: [
            { text: "Full Payment", value: PaymentType.FullPayment },
            { text: "Code Now, Pay Later", value: PaymentType.CodeNowPayLater },
          ],
          requiredErrorText: "Application type is required",
        },
        {
          type: "text",
          visibleIf: `{paymentType} = '${PaymentType.FullPayment}'`,
          name: "licenseFeeReceiptNo",
          isRequired: true,
          title: "License Fee Official Receipt Number",
          requiredErrorText: "This field is mandatory",
          validators: [
            {
              type: "regex",
              text: "Recruitment.format_error",
              regex: /^[a-zA-Z0-9_-]+$/,
            },
          ],
        },
        {
          type: "text",
          visibleIf: `{paymentType} = '${PaymentType.FullPayment}'`,
          inputType: "date",
          name: "licenseFeeReceiptDate",
          isRequired: true,
          title: "License Fee Official Receipt Date",
          requiredErrorText: "This field is mandatory",
        },
        {
          type: "text",
          visibleIf:
            "{paymentType} = '" +
            PaymentType.FullPayment +
            "' || {paymentType} = '" +
            PaymentType.CodeNowPayLater +
            "'",
          name: "examFeeReceiptNo",
          isRequired: true,
          title: "Exam Fee Official Receipt Number",
          requiredErrorText: "This field is mandatory",
          validators: [
            {
              type: "regex",
              text: "Recruitment.format_error",
              regex: /^[a-zA-Z0-9_-]+$/,
            },
          ],
        },
        {
          type: "text",
          visibleIf:
            "{paymentType} = '" +
            PaymentType.FullPayment +
            "' || {paymentType} = '" +
            PaymentType.CodeNowPayLater +
            "'",
          inputType: "date",
          name: "examFeeReceiptDate",
          isRequired: true,
          title: "Exam Fee Official Receipt Date",
          requiredErrorText: "This field is mandatory",
          maxValueExpression: "today",
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};
