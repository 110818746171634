import { combineReducers } from "redux";

import { commonSlice } from "./common/common-slice";
import { persistReducer } from "redux-persist";
import { demoSlice, demoPersistConfig } from "../modules/demo/redux/demo-slice";
import { resourcePersistConfig, resourceSlice } from "../modules/resource/redux/resource-slice";
import { referralLinkSlice } from "../modules/agent-referral-link/redux/referral-link-slice";
import { gaSlice } from "../common/ga/redux/ga-slice";
import {
  onboardingPersistConfig,
  onboardingSlice,
} from "../modules/onboarding/redux/onboarding-slice";
import { eventPersistConfig, eventSlice } from "../modules/event/redux/event-slice";
import { authPersistConfig, authSlice } from "../modules/auth/redux/auth-slice";

export const rootReducer = combineReducers({
  common: commonSlice.reducer,
  ga: gaSlice.reducer,
  demo: persistReducer(demoPersistConfig, demoSlice.reducer),
  resource: persistReducer(resourcePersistConfig, resourceSlice.reducer),
  referralLink: referralLinkSlice.reducer,
  onboarding: persistReducer(onboardingPersistConfig, onboardingSlice.reducer),
  event: persistReducer(eventPersistConfig, eventSlice.reducer),
  auth: persistReducer(authPersistConfig, authSlice.reducer),
});
