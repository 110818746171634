import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../header/header.component";
import { useGlobalModal } from "../../use-modal/use-modal";
import { PdfResource } from "../../../modules/resource/components/resource-detail";

interface PdfPreviewProps {
  url: string;
  headerTx?: string;
  close?: Function;
  isConfirmed?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  headerComponent?: React.ReactElement | null;
}

export const PdfPreview = ({
  url,
  close,
  onConfirm,
  isConfirmed,
  onCancel,
  headerTx,
  headerComponent,
}: PdfPreviewProps) => {
  const { t } = useTranslation();
  const [reachedBottom, setReachedBottom] = useState(false);
  return (
    <div className="flex flex-col w-screen h-screen">
      <div className="sticky top-0 z-10">
        {headerComponent ?? (
          <HeaderComponent
            title={headerTx}
            onBack={() => {
              onCancel?.();
              close?.();
            }}
          />
        )}
      </div>
      <PdfResource
        url={url}
        onBottomReached={() => {
          setReachedBottom(true);
        }}
      />
      {onConfirm ? (
        <div className="sticky w-screen flex pb-4 z-10 px-4">
          {isConfirmed || reachedBottom ? (
            <button
              className="bg-red-500 flex-1 text-white font-semibold py-3  rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
              onClick={() => {
                onConfirm();
                close?.();
              }}
            >
              {t("I_have_completed_reading")}
            </button>
          ) : (
            <button
              onClick={() => {
                // onConfirm?.();
                // close?.();
              }}
              className="bg-gray-300 flex-1 text-white font-semibold py-3  rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-75"
            >
              {t("scroll_down_and_read_all")}
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export const pdfOpen = (props: PdfPreviewProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useGlobalModal().open(<PdfPreview {...props} />, {
    showHeader: false,
    modalType: "bottom",
    modalProps: {
      className: "dialog-full",
      PaperProps: {
        style: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          width: window.innerWidth + "px",
        },
      },
    },
  });
};
