export enum FromPageEnum {
  CREATE = "create",
  EDIT = "edit",
}

export enum StatusEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DISABLED = "disabled",
}

export enum AboutMeDisplayStatusEnum {
  DEFAULT = "default",
  SHOW = "show",
  HIDE = "hide",
}

export type I18nDataStringItem = {
  [locale: string]: string;
};

export type AgentProfileItem = {
  _id: string;
  name: I18nDataStringItem;
  banner: I18nDataStringItem;
  agentCode: string;
  tier: string;
  status: StatusEnum;
  myAboutMe?: I18nDataStringItem;
  aboutMeTemplate: string;
  myHighlight?: I18nDataStringItem;
  agentDetail: agentDetailType;
  awards: AwardItem[];
  awardBadges: AwardItem[];
  achievements: string[];
  promotions: PromotionListWithLang;
  enableWhatsapp: boolean;
  enableMyAboutMe: boolean;
  enableMyHighlight: boolean;
  aboutMeDisplayStatus?: AboutMeDisplayStatusEnum;
};

export type agentDetailType = {
  designation: I18nDataStringItem;
  email: string;
  gender: string;
  joinDate: string;
  license: { gi: string; hkfi: string; mpf: string; sfc: string; ia: string; mdrt: string };
  phone: { general: string; direct: string; mobile: string };
  profilePictureUrl: string;
  lbuName?: I18nDataStringItem;
  firstNameEn?: string;
  lastNameEn?: string;
  displayNameEn?: string;
  otherNameEn?: string;
  nickNameEn?: string;
  firstNameLocal?: string;
  surnameLocal?: string;
  yearOfExperience?: number;
};

export type AgentAwardsType = {
  achievements: string[];
  agentCode: string;
  awards: AwardItem[];
  badges: AwardItem[];
};

export type AwardItem = {
  typeId: string;
  awardCode: string;
  awardName: I18nDataStringItem;
  awardYear?: string;
  image?: string;
  isGroupedYear?: boolean;
};

export type PromotionListWithLang = {
  [locale: string]: PromotionItem[];
};

export type PromotionItem = {
  uuid: string;
  name: string;
  tag: string[];
  url: string;
  coverpage: CoverPageItem;
};

export type AboutMeTemplates = {
  docs: AboutMeTemplateItem[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  page: number;
  totalDocs: number;
  totalPages: number;
};

export type AboutMeTemplateItem = {
  content: I18nDataStringItem;
  isDefault: boolean;
  templateCode: string;
  id: string;
};

export type CoverPageItem = {
  url: string;
};
