import { get } from "lodash";
import { matchPath } from "react-router-dom";
import { getLoginUserData, getToken } from "../../modules/onboarding/utils";
import { OnboardingFormSectionName, RoleEnum } from "../../modules/onboarding/types";
import { GlobalHelper } from "../helpers/global-helper";

const defaultSecurityConfig = require("./security-authorization-public-web.config.json");

const CACHEd_JWT = {
  token: "",
  parsedToken: null,
};

export function parseJwt(token: string) {
  let totalTime = "parseJwt";
  console.time(totalTime);
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  const atob = (input: string = "") => {
    const str = input?.replace(/[=]+$/, "");
    let output = "";

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (
      let bc = 0, bs = 0, buffer, i = 0;
      (buffer = str.charAt(i++));
      ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      buffer = chars.indexOf(buffer);
    }
    return output;
  };

  const base64Url = token.split(".")[1];
  const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(""),
  );

  const res = JSON.parse(jsonPayload);
  console.time(totalTime);
  return res;
}

// todo
export function getParsedToken(token: string) {
  if (!token) {
    return null;
  }

  if (token === CACHEd_JWT.token) {
    return CACHEd_JWT.parsedToken;
  } else {
    CACHEd_JWT.parsedToken = parseJwt(token);
    CACHEd_JWT.token = token;
    return CACHEd_JWT.parsedToken;
  }
}
/**
 * get security config
 * @returns
 */
export const getSecurityConfig = () => {
  try {
    const AppGlobalConfig = GlobalHelper.getGlobalPublicConfig();
    const securityPolicy = get(AppGlobalConfig, "Security", defaultSecurityConfig);

    return securityPolicy;
  } catch (error) {
    return defaultSecurityConfig;
  }
};

enum SpecificRoutes {
  OnboardingForm = "/web/onboarding/form",
}

const checkSpecificQuery = (routeName: string, search?: string) => {
  switch (routeName) {
    case SpecificRoutes.OnboardingForm:
      const sectionName = new URLSearchParams(search).get("sectionName");
      if (
        getLoginUserData()?.role === RoleEnum.SECRETARY &&
        (sectionName === OnboardingFormSectionName.eSignatureSection ||
          sectionName === OnboardingFormSectionName.termsNConditionsSection)
      ) {
        return false;
      }
      return true;
    default:
      return true;
  }
};

export const checkOperationPermission = (
  SecurityConfig: Record<string, any>,
  operationId: string,
  jwtPayload?: Record<string, any> | null,
) => {
  const policy = SecurityConfig?.policies?.find((p: any) => p.operationId === operationId);
  const denied_id: string[] = [];
  const allowed_id: string[] = [];

  if (!policy || !jwtPayload) {
    return {
      allow: true,
      denied_id,
      allowed_id,
    };
  }

  const checkPermissions = (rule: any) => {
    if (!rule.permissions) return true;
    return rule.permissions.every(
      (perm: string) =>
        jwtPayload.permissions[perm] &&
        (jwtPayload.permissions[perm].includes("*") || jwtPayload.permissions[perm].length > 0),
    );
  };

  if (policy.denied) {
    for (let denyRule of policy.denied) {
      const hasDeniedRole = denyRule.roles.includes(jwtPayload.role);
      const hasDeniedPermissions = checkPermissions(denyRule);
      if (hasDeniedRole && hasDeniedPermissions) {
        denied_id.push(denyRule.id);
      }
    }
  }

  for (let allowRule of policy.allowed) {
    const hasAllowedRole = allowRule.roles.includes(jwtPayload.role);
    const hasAllowedPermissions = checkPermissions(allowRule);
    if (hasAllowedRole && hasAllowedPermissions) {
      allowed_id.push(allowRule.id);
    }
  }

  return {
    allow: allowed_id.length > 0,
    denied_id,
    allowed_id,
  };
};

export const matchRule = (path: string[], routeName: string) => {
  return !!path.some((p) => matchPath(p, routeName));
};

/**
 * to check if the route is allowed to access.
 * @param routeName
 * @param token
 * @param matchRule
 * @returns
 * @description can be used to check if the screen is allowed to access.
 */
export const checkRouteAccess = (location: { pathname: string; search?: string }) => {
  try {
    const SecurityConfig = getSecurityConfig();
    const routeName = location.pathname;
    const operation = SecurityConfig.operations.find((op: any) => matchRule(op.path, routeName));
    const jwtPayload = getParsedToken(getToken());
    if (!operation) {
      return {
        allow: true,
        operationId: null,
        denied_id: [],
        matched_operations: [],
        allowed_id: [],
      };
    }
    const { allow, denied_id, allowed_id } = checkOperationPermission(
      SecurityConfig,
      operation.id,
      jwtPayload,
    );

    if (allow) {
      const isSpecificQueryAllowed = checkSpecificQuery(routeName, location.search);
      if (!isSpecificQueryAllowed) {
        return {
          allow: false,
          operationId: operation.id,
          denied_id,
          matched_operations: operation.id,
          allowed_id,
        };
      }
    }

    return {
      allow,
      operationId: operation.id,
      denied_id,
      matched_operations: operation.id,
      allowed_id,
    };
  } catch (error) {
    return {
      allow: true,
      operationId: null,
      denied_id: [],
      matched_operations: [],
      allowed_id: [],
    };
  }
};

/**
 * check if the operation is allowed to access
 * @param operationId
 * @param jwtPayload
 * @returns
 * @description can be used to check if the button which can tigger target operation is allowed to show.
 */
export const checkOperationAccess = (operationId: string, token: string) => {
  try {
    const SecurityConfig = getSecurityConfig();
    const jwtPayload = getParsedToken(token);
    const { allow } = checkOperationPermission(SecurityConfig, operationId, jwtPayload);
    return allow;
  } catch (error) {
    return true;
  }
};
