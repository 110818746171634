import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  iconSize: {
    fontSize: 18,
  },
  editIcon: {
    color: "#38527E",
  },
  viewMoreIcon: {
    color: "#333333",
  },
}));
