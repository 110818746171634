import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { QuestionRadiogroupModel as Question } from "survey-core";
import { HeaderComponent } from "../../../../../../components/header/header.component";
import { useQuery } from "../../../../../../utils/hooks/use-query";
import { useCanGoBack } from "../../../../../../utils/hooks/use-can-go-back";
import { OnboardingQuiz } from "../../../../types";
import { QuizSurvey } from "./quiz-survey.component";
import QuizOverview from "./quiz-overview.component";
import { confirm } from "../../../../../../components/modal/comfirm";
import { fetchTrainingCourseQuizDetailAsync } from "../../../../redux/onboarding-slice";
import { selectOnboardingQuizContent } from "../../../../redux/selector";
import { cloneDeep, merge } from "lodash";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../../../routes/constants";
import { ErrorScreen } from "../../../../../../components/error-screen/error-screen";
import useThrottledTouchEnd from "../../../../hooks/use-throtted-touch-end";

type QuizScreenProps = {};

export const CourseQuizScreen = (props: QuizScreenProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useThrottledTouchEnd();
  const query = useQuery();
  const { quizId } = useParams();
  const candidateId = query.get("candidateId")!;
  const lang = query.get("lang") ?? "en";

  const quizContent = useSelector(selectOnboardingQuizContent);
  const [dataLoaded, setDataLoaded] = useState(false);
  const currentQuiz: OnboardingQuiz & { candidateId: string } = useMemo(() => {
    return { candidateId, ...quizContent } as any;
  }, [candidateId, quizContent]);
  const fetchData = useCallback(async () => {
    if (!quizId || dataLoaded) return;
    await dispatch<any>(fetchTrainingCourseQuizDetailAsync({ quizId, candidateId }));
    setDataLoaded(true);
  }, [quizId, dataLoaded, dispatch]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const navigate = useNavigate();
  const canGoBack = useCanGoBack();

  const surveyUI = useMemo(() => {
    return (
      currentQuiz.questions?.length && (
        <QuizSurvey
          json={convertToSurveyJSON(cloneDeep(currentQuiz.questions))}
          onSubmit={function (data: any, action: "SAVE" | "SUBMIT" | "VALIDATE"): void {
            throw new Error("Function not implemented.");
          }}
        />
      )
    );
  }, [currentQuiz.questions]);
  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen flex flex-col">
      <HeaderComponent
        title={t("onboarding_training_quiz")}
        onBack={() => {
          confirm({
            title: t("reminder_title"),
            message: t("confirm_leave_without_save"),
            confirmButtonText: t("leave_the_quiz"),
            cancelButtonText: t("onboarding.continue"),
          }).then((result) => {
            if (result.ok) {
              if (canGoBack()) {
                navigate(-1);
              }
            }
          });
        }}
      />

      {currentQuiz?.readonly ? (
        <div className="flex-1 w-screen items-center justify-center">
          <ErrorScreen statusCode="403" />
        </div>
      ) : (
        dataLoaded && (
          <>
            <div className="mt-4 mb-3">
              <QuizOverview
                totalQuestions={currentQuiz.questions?.length ?? 0}
                correctAnswers={currentQuiz.passedCount ?? 0}
                incorrectAnswers={currentQuiz.failedCount ?? 0}
              />
            </div>
            {surveyUI}
          </>
        )
      )}
    </div>
  );
};

const convertToSurveyJSON = (questions: Question[]) => {
  return {
    pages: [
      {
        questions: questions.map((q) => {
          q.preset = "quiz";
          q.isRequired = true;
          q.showClearButton = false;
          q.requiredErrorText = getI18n().t("select_option_to_proceed");
          return q;
        }),
      },
    ],
    showQuestionNumbers: "off",
    questionsOnPageMode: "questionPerPage",
  };
};
