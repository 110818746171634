import { makeStyles } from "@mui/styles";

const SECTION_PADDING = 20;
const SECTION_MARGIN = 8;
const BASIC_INFO_SECTION_PADDING = "40px 30px 16px";

export const useStyles = makeStyles(() => ({
  header: {
    fontSize: 16,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    "&:before": {
      content: "''",
      display: "inline-block",
      height: 16,
      width: 3,
      backgroundColor: "red",
      marginRight: 10,
      marginLeft: 10,
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  sectionContainer: {
    padding: SECTION_PADDING,
    paddingBottom: 8,
    marginBottom: SECTION_MARGIN,
    backgroundColor: "white",
  },
  basicInfoSectionContainer: {
    padding: BASIC_INFO_SECTION_PADDING,
    marginBottom: SECTION_MARGIN,
    backgroundColor: "white",
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
  },
}));
