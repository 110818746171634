import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { fetchPolicyConfig } from "../../modules/common/network/network";
import { readConfigs } from "../../utils/common-utils";

export enum AlertType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export type AlertItem = {
  severity: AlertType;
  title: string;
  content: string;
};

export type CommonState = {
  alertState: AlertItem[];
  apiLoading: boolean; // api request is loading or not
  hashEntry?: any; // resource.hash-entry document
  policyConfigs?: any;
};

const commonState: CommonState = {
  alertState: [],
  apiLoading: false,
  hashEntry: {} as any,
};

type AddAlertAction = {
  payload: AlertItem[];
};

type DismissAlertAction = {
  payload: {
    index: number;
  };
};

export const fetchPolicyConfigAsync = createAsyncThunk<any, void, { state: CommonState }>(
  "policy-configs/get",
  async (thunkAPI: any) => {
    try {
      const result = await fetchPolicyConfig();
      const configs = readConfigs(result.map((configRes: any) => configRes.data));
      return { data: configs };
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const commonSlice = createSlice({
  name: "common",
  initialState: commonState,
  reducers: {
    appendAlertAction: (state: CommonState, action: AddAlertAction) => {
      return {
        ...state,
        alertState: [...state.alertState, ...action.payload],
      };
    },
    dismissAlertAction: (state: CommonState, action: DismissAlertAction) => {
      let currentAlert = [...state.alertState];
      currentAlert.splice(action.payload.index, 1);
      return {
        ...state,
        alertState: currentAlert,
      };
    },
    showLoadingAction: (state: CommonState) => {
      return {
        ...state,
        apiLoading: true,
      };
    },
    hideLoadingAction: (state: CommonState) => {
      return {
        ...state,
        apiLoading: false,
      };
    },
    setHashEntryAction: (state: CommonState, action: { payload: any }) => {
      return {
        ...state,
        hashEntry: action.payload,
      };
    },
  },
  extraReducers(builder: any) {
    builder.addCase(
      fetchPolicyConfigAsync.fulfilled,
      (state: CommonState, action: { payload: any }) => {
        state.policyConfigs = { ...state.policyConfigs, ...action.payload.data };
      },
    );
  },
});
