import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Model, settings } from "survey-core";
import { Survey } from "survey-react-ui";
import {
  confirmActionAsyncFunc,
  markdownHandler,
} from "../../components/surveyjs/survey/survey.util";
import { initSurvey } from "../../components/surveyjs/survey/init-survey";
import { getI18n, useTranslation } from "react-i18next";
import { useQuery } from "../../../../utils/hooks/use-query";
import { PruToast } from "../../../../components/pru-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { CandidateDataTemplate, TemplateField } from "./template";
import {
  fetchGlobalConfig,
  getInvitationsByUserName,
  getInvitationStatus,
  inviteCandidate,
  inviteCandidateEdit,
  submitCandidateData,
  updateCandidateProfile,
} from "../../network/network";
import { cloneDeep, set } from "lodash";
import { getFullRegionList, getRecruitPublicConfig } from "../../../common/network/network";
import { getIdTypeOptions } from "../../../../utils/common-utils";
import { GlobalHelper } from "../../../../utils/helpers/global-helper";
import { NameCompositionEnum } from "../../../../constants/constants";
import { commonSlice } from "../../../../redux/common/common-slice";
import moment from "moment";
import PageContainer from "../../../../components/page-container/page-container";
import { EditMode, handleInvitationRes } from "./utils";
import { refreshTokenAsync } from "../../../auth/redux/auth-slice";
import { useDispatch } from "react-redux";
import { getRefreshToken, getToken, parseJwt } from "../../utils";
import { RoleEnum } from "../../types";
import { ROOT_PATH, ONBOARDING_PATH, constants } from "../../../../routes/constants";
import { Edit } from "@mui/icons-material";
const { showLoadingAction, hideLoadingAction } = commonSlice.actions;
const elements = CandidateDataTemplate.pages[0].elements;

export const CandidateDataFormScreen: React.FC = () => {
  initSurvey();
  const { t } = useTranslation();
  const query: any = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [invitationType, setInvitationType] = useState("new");
  const [formValues, setForm] = useState({} as any);
  const [pageInit, setPageInit] = useState(false);
  const [globalConfigs, setGlobalConfigs] = useState({} as any);
  const [recruitPublicConfigs, setRecruitPublicConfigs] = useState({} as any);
  const inviteId = query.get("iid");
  const agentCodeInPath = query.get("code");
  const applicationDetails: any = useMemo(() => {
    return location.state?.applicationDetails;
  }, [location.state?.applicationDetails]);
  const userName = location.state?.userName;
  const candidateProfile = location.state?.candidateProfile;
  const [invitations, setInvitations] = useState<any[]>([]);
  const editMode: EditMode = useMemo(() => {
    if (inviteId && invitationType === "edit") {
      return EditMode.EditByInvitation;
    }
    if (inviteId && invitationType === "new") {
      return EditMode.NewWithInvitation;
    }
    if (!inviteId && userName) {
      return EditMode.NewWithLoginName;
    }
    if (!inviteId && candidateProfile) {
      return EditMode.EditBaseOnProfile;
    }
    return EditMode.NewWithInvitation;
  }, [invitationType, inviteId, candidateProfile, userName]);

  const redirectTo = useMemo(() => {
    if (editMode === EditMode.NewWithLoginName) {
      const rawToken = getToken();
      const tokenData = rawToken ? parseJwt(rawToken) : {};
      if (tokenData.role === RoleEnum.CANDIDATE) {
        return `${ROOT_PATH}/${ONBOARDING_PATH}/candidate-home`;
      }
      if (
        !getRefreshToken() &&
        [EditMode.NewWithLoginName, EditMode.EditBaseOnProfile].includes(editMode)
      ) {
        return `${ROOT_PATH}/${ONBOARDING_PATH}/login`;
      }
    }
    return "";
  }, [editMode]);

  const pageTitle = useMemo(() => {
    if (!pageInit) {
      return "";
    }
    if (editMode === EditMode.EditBaseOnProfile) {
      return t("Recruitment.profile");
    }
    return t("Common.candidate_data_screen_title");
  }, [editMode, pageInit, t]);

  const idFields = useMemo(() => {
    const idTypeConfigs = globalConfigs?.region?.idType;

    if (recruitPublicConfigs?.disableCandidateDataIdDocCheck || !idTypeConfigs) {
      return [];
    }
    const options = getIdTypeOptions(globalConfigs?.region?.idType)?.options;
    return [
      {
        type: "dropdown",
        name: "idType",
        title: t("Common.id_type"),
        isRequired: true,
        choices: options ?? [],
        requiredErrorText: t("Common.please_select_id_type"),
        readOnly: [EditMode.EditByInvitation, EditMode.EditBaseOnProfile].includes(editMode),
      },
      ...Object.keys(idTypeConfigs).map((key, index) => {
        const option = idTypeConfigs[key];
        return {
          type: "text",
          name: `idNum`,
          visibleIf: `{idType}="${option.value}"`,
          title: t("Common.id_num"),
          isRequired: true,
          tooltips: option.desc ? t(option.desc.replace(":", ".")) : "",
          placeholder: t("Common.id_num_nric_placeholder"),
          readOnly: [EditMode.EditByInvitation, EditMode.EditBaseOnProfile].includes(editMode),
          validators: [EditMode.NewWithInvitation, EditMode.NewWithLoginName].includes(editMode)
            ? [
                {
                  type: "expression",
                  text: t(option.errMsg.replace(":", ".")),
                  expression: "idNumValidate({#idType},{idNum})",
                },
              ]
            : [],
          inputType: "text",
          requiredErrorText: t("Common.id_num_nric_placeholder"),
        };
      }),
    ];
  }, [
    globalConfigs?.region?.idType,
    recruitPublicConfigs?.disableCandidateDataIdDocCheck,
    t,
    editMode,
  ]);
  useEffect(() => {
    GlobalHelper.getGlobalDispatch()?.(!pageInit ? showLoadingAction() : hideLoadingAction());
  }, [pageInit]);

  useEffect(() => {
    if (redirectTo) {
      GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
      navigate(redirectTo);
      return;
    }
    if (!inviteId && !userName && !candidateProfile) {
      PruToast({ message: "Wrong url, please check" });
      GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
      return;
    }
    const request = [getRecruitPublicConfig(), fetchGlobalConfig(), getFullRegionList()];
    if ([EditMode.EditByInvitation, EditMode.NewWithInvitation].includes(editMode)) {
      request.push(getInvitationStatus(inviteId));
    } else if (editMode === EditMode.NewWithLoginName) {
      request.push(getInvitationsByUserName(userName));
    } else if (editMode === EditMode.EditBaseOnProfile) {
      const arrPhone = candidateProfile?.phone?.split(" ");
      setForm({
        ...formValues,
        agentCode: candidateProfile?.agentid,
        firstname: candidateProfile?.firstname,
        lastname: candidateProfile?.lastname,
        gender: candidateProfile?.gender,
        birthday: candidateProfile?.birthday,
        idType: candidateProfile?.identityDocumentType,
        idNum: candidateProfile?.identityDocumentNumber,
        email: candidateProfile?.email,
        regionCode: arrPhone[0],
        phoneNumber: arrPhone[1],
        languagePreference: candidateProfile?.languagePreference,
      });
    }

    Promise.all(request)
      .then((reses: any) => {
        const [recruitPublicConfigRes, globalConfigRes, regionConfigs, res] = reses;
        setRecruitPublicConfigs(recruitPublicConfigRes.Recruitment);
        const regionPhoneField = elements.find((item: any) => item.name === "regionPhone");
        if (regionPhoneField?.templateElements?.[0] && regionConfigs) {
          const regionOpts = Object.values(regionConfigs).map((region: any) => ({
            text: `${region.code}`,
            key: region.code,
            value: region.code,
            reg: region.regex,
          }));
          // @ts-ignore
          regionPhoneField.templateElements[0].choices = regionOpts;

          setForm((pre: any) => ({
            ...pre,
            regionCode: regionOpts?.[0].key ?? "+852",
          }));
        } else {
          setForm((pre: any) => ({
            ...pre,
            regionCode: "+852",
          }));
        }
        setGlobalConfigs(globalConfigRes.data?.content);
        GlobalHelper.setIdTypeConfigs(globalConfigRes.data?.content?.region?.idType);
        handleInvitationRes({
          res,
          recruitPublicConfigRes,
          globalConfigRes,
          applicationDetails,
          query,
          setInvitationType,
          setForm,
          formValues,
          t,
          navigate,
          location,
          elements,
          editMode,
          setInvitations,
        });
      })
      .finally(() => {
        setPageInit(true);
      });
    return () => {
      GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
    };
  }, []);
  const submitCanidateData = useCallback(
    (candidateData: any) => {
      GlobalHelper.getGlobalDispatch()?.(showLoadingAction());
      if (editMode === EditMode.NewWithInvitation) {
        inviteCandidate(candidateData, navigate).finally(() => {
          GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
        });
      } else if (editMode === EditMode.EditByInvitation) {
        inviteCandidateEdit(candidateData, navigate).finally(() => {
          GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
        });
      } else if (editMode === EditMode.NewWithLoginName) {
        submitCandidateData({
          candidateData,
          navigate,
          recruitPublicConfigs,
          globalConfigs,
        })
          .then(async (res: any) => {
            // if response is ok, refresh the token
            if (res.status === 201) {
              await dispatch<any>(refreshTokenAsync());
              navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/candidate-home`);
            }
          })
          .finally(() => {
            GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
          });
      } else if (editMode === EditMode.EditBaseOnProfile) {
        updateCandidateProfile({
          candidateData: { ...candidateProfile, ...candidateData },
          navigate,
          recruitPublicConfigs,
          globalConfigs,
        })
          .then((res: any) => {
            // if response is ok, refresh the token
            if (res.status === 200) {
              navigate(-1);
            }
          })
          .finally(() => {
            GlobalHelper.getGlobalDispatch()?.(hideLoadingAction());
          });
      }
    },
    [dispatch, editMode, globalConfigs, navigate, recruitPublicConfigs],
  );

  const form = useMemo(() => {
    if (!formValues.regionCode || !formValues.phoneNumber) {
      return null;
    }

    // make id fileds readonly if invitationType is edit and otherwise update the id fields
    const idFieldAlreadyExist = elements.find((element) => element.name === TemplateField.idType);
    const idFieldStartIndex = 3;
    const needRemoveItems = idFieldAlreadyExist ? idFields.length : 0;
    const idFieldReadOnly = [EditMode.EditByInvitation, EditMode.EditBaseOnProfile].includes(
      editMode,
    );
    const newIdFields = formValues.idNum
      ? idFields.map((field) => {
          const element = elements.find((ele) => ele.name === field.name);
          if (element && idFieldReadOnly) {
            element.readOnly = true;
          }
          return field;
        })
      : idFields;
    elements.splice(idFieldStartIndex, needRemoveItems, ...(newIdFields as any));

    // insert the invitation radio group depending on the `invitations` array
    const invitationField: any = elements.find(
      (element) => element.name === TemplateField.invitation,
    );
    let defaultInvitationValue = invitations[0]?.value ?? "";
    if (invitations.length > 0) {
      if (invitationField) {
        invitationField.presets = "radio";
        invitationField.choices = invitations.map((invitation) => ({
          value: invitation.value,
          text: invitation.label,
        }));
      } else {
        // insert the invitation radio group before the field `privacyPolicyAgreement`
        const privacyPolicyAgreementIndex = elements.findIndex(
          (element) => element.name === "privacyPolicyAgreement",
        );
        if (privacyPolicyAgreementIndex > -1) {
          elements.splice(privacyPolicyAgreementIndex, 0, {
            type: "radiogroup",
            preset: "radio",
            name: TemplateField.invitation,
            title: t("Common.your_recruiting_agent"),
            isRequired: true,
            value: invitations[0].value,
            choices: invitations.map((invitation) => ({
              value: invitation.value,
              text: invitation.label,
            })),
          } as any);
        } else {
          elements.push({
            type: "radiogroup",
            preset: "radio",
            name: TemplateField.invitation,
            title: t("Common.your_recruiting_agent"),
            isRequired: true,
            value: invitations[0].value,
            choices: invitations.map((invitation) => ({
              value: invitation.value,
              text: invitation.label,
            })),
          } as any);
        }
      }
    }

    const candidateFullnameComposition = recruitPublicConfigs?.candidateFullnameComposition;
    if (
      candidateFullnameComposition &&
      !candidateFullnameComposition.includes(NameCompositionEnum.lastName)
    ) {
      const index = elements.findIndex((ele) => ele.name === NameCompositionEnum.lastName);
      if (index > -1) {
        elements[index - 1].title = "Common.fullname";
        elements.splice(index, 1);
      }
    }
    convertTemplate(CandidateDataTemplate, recruitPublicConfigs, globalConfigs);
    const survey = new Model(cloneDeep(CandidateDataTemplate));
    survey.showPageTitles = false;
    survey.showNavigationButtons = "none";
    survey.questionErrorLocation = "bottom";
    survey.showCompletedPage = false;
    const defaultValue: any = {
      firstName: formValues.firstname ?? "",
      lastName: formValues.lastname ?? "",
      gender: formValues.gender,
      email: formValues.email ?? "",
      idType: formValues.idType ?? "",
      idNum: formValues.idNum ?? "",
      languagePreference: formValues.languagePreference,
      birthday: moment(formValues.birthday, constants.Default_Date_Format).toISOString(),
      [TemplateField.invitation]: defaultInvitationValue,
      regionPhone: [
        {
          regionCode: formValues.regionCode ?? "",
          phoneNumber: formValues.phoneNumber,
        },
      ],
    };

    // set the first invitation as default
    if (invitationField && invitationField.choices) {
      defaultValue.invitation = invitationField.choices[0].value;
    }

    survey.mergeData(defaultValue);
    survey.onComplete.add((_sender: any) => {});
    survey.onTextMarkdown.add(markdownHandler);
    survey.onGetQuestionTitleActions.add((_, options) => {
      if (options.question.tooltips) {
        options.titleActions = [
          {
            id: "show-popover",
            component: "popover",
            tooltip: options.question.tooltips,
          },
        ];
      }
    });
    settings.confirmActionAsync = confirmActionAsyncFunc;
    if (!pageInit) {
      return null;
    }
    return (
      <div className="flex flex-1 flex-col max-w-md sm:max-w-xl lg:max-w-xl px-6">
        <div className="flex flex-1 flex-col w-full pb-4">
          <div className=" bg-white w-full rounded-tr-3xl h-10"></div>
          <Survey currentPageNo={0} className="survey-common" id="onboarding-join" model={survey} />
        </div>

        <div className=" w-full sticky bottom-0 bg-white pt-2 pb-7 flex items-center justify-between gap-4">
          <button
            className="bg-red-500 flex-1 text-white font-semibold py-3  rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
            onClick={() => {
              let isValid = false;
              isValid = survey.validateCurrentPage();

              if (!isValid) {
                return;
              }
              const surveyData = survey.data;
              const candidateData: any = {};
              candidateData.inviteId = inviteId;

              candidateData.gender = surveyData.gender;
              candidateData.firstname = surveyData.firstName;
              candidateData.lastname = surveyData.lastName;
              candidateData.birthday = moment(surveyData.birthday).format(
                constants.Default_Date_Format,
              );
              candidateData.identityDocumentType = surveyData.idType;
              candidateData.identityDocumentNumber = surveyData.idNum;
              candidateData.email = surveyData.email;
              candidateData.isAgreedPolicy = surveyData.privacyPolicyAgreement;
              candidateData.regionCode = survey.data.regionPhone[0].regionCode;
              candidateData.phoneNumber = survey.data.regionPhone[0].phoneNumber;
              candidateData.isAgreedPolicy = surveyData.privacyPolicyAgreement;
              candidateData.isUpdate = false;
              candidateData.languagePreference = surveyData.languagePreference ?? "en";
              candidateData.agentCode = agentCodeInPath ?? formValues.agentCode;
              if (surveyData.invitation) {
                candidateData.invitationId = surveyData.invitation;
                candidateData.agentCode = invitations.find(
                  (item) => item.value === surveyData.invitation,
                )?.agentCode;
              }
              if (applicationDetails) {
                candidateData.applicationDetails = {
                  ...applicationDetails,
                };
              }
              submitCanidateData(candidateData);
            }}
          >
            {t("global.text.confirm")}
          </button>
        </div>
      </div>
    );
  }, [
    formValues,
    idFields,
    editMode,
    recruitPublicConfigs,
    globalConfigs,
    pageInit,
    t,
    inviteId,
    agentCodeInPath,
    applicationDetails,
    submitCanidateData,
    invitations,
  ]);
  return <PageContainer title={pageTitle}>{form}</PageContainer>;
};

const convertTemplate = (template: any, recruitPublicConfigs: any, globalConfigs: any) => {
  // Function to translate a given field if it exists
  const translateField = (obj: any, field: string) => {
    if (obj?.[field]) {
      obj[field] = getI18n().t(obj[field]);
    }
  };

  const emailField = template.pages[0].elements.find((item: any) => item.name === "email");
  emailField.isRequired = true;
  if (!recruitPublicConfigs?.candidateProfile?.emailMandatory) {
    emailField.isRequired = false;
  }

  const birthdayField = template.pages[0].elements.find((item: any) => item.name === "birthday");

  birthdayField.visible = true;
  if (!recruitPublicConfigs?.candidateProfile?.enableBirthday) {
    birthdayField.visible = false;
  } else if (globalConfigs?.region.ageValidation) {
    const { min, max } = globalConfigs?.region?.ageValidation;
    const minDate = max?.value
      ? moment().subtract(max.value, "years").format("YYYY-MM-DD")
      : undefined;
    const maxDate = min?.value
      ? moment().subtract(min.value, "years").format("YYYY-MM-DD")
      : undefined;

    birthdayField.min = minDate;
    birthdayField.max = maxDate;
  }

  // Loop through each element in the first page
  template.pages[0].elements.map((q: any) => {
    // Translate title, labelTrue, labelFalse, requiredErrorText fields
    translateField(q, "title");
    translateField(q, "placeholder");
    translateField(q, "labelTrue");
    translateField(q, "labelFalse");
    translateField(q, "requiredErrorText");
    translateField(q, "tooltips");

    // translate choices if they exist
    if (q.choices && Array.isArray(q.choices)) {
      q.choices.forEach((choice: any) => {
        translateField(choice, "text");
      });
    }

    // Check if the element has validators and translate their text fields
    if (q.validators && Array.isArray(q.validators)) {
      q.validators.forEach((validator: any) => {
        translateField(validator, "text");
      });
    }

    // If the element is a paneldynamic type, we need to handle nested templateElements
    if (q.type === "paneldynamic" && q.templateElements && Array.isArray(q.templateElements)) {
      q.templateElements = q.templateElements.map((te: any) => {
        translateField(te, "title");
        translateField(te, "labelTrue");
        translateField(te, "labelFalse");
        translateField(te, "requiredErrorText");

        if (te.validators && Array.isArray(te.validators)) {
          te.validators.forEach((validator: any) => {
            translateField(validator, "text");
          });
        }

        return te;
      });
    }

    return q;
  });
};
