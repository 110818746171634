import { isString } from "lodash";
import { PruToast } from "../../../components/pru-toast";
import { translate, I18nModule } from "../../../i18n/translation";
import { Model } from "survey-core";

export const handleSurveyError = ({
  errors,
  survey,
  defaultErrMsg,
}: {
  errors: any;
  survey: Model;
  defaultErrMsg: string;
}) => {
  if (!errors) {
    PruToast({ status: "error", message: defaultErrMsg });
    return;
  }
  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      const errMsgKey = error.message ?? "Unknown error";
      const errMsg = translate(errMsgKey, I18nModule.Recruitment);
      PruToast({ status: "error", message: errMsg });
    });
  } else {
    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        const theError = errors[key]?.[0];
        const customErr = theError?.text ?? theError;
        const errMsgKey = isString(customErr) ? customErr : theError?.message ?? "Unknown error";
        const errMsg = translate(errMsgKey, I18nModule.Recruitment);
        const question = survey.getQuestionByName(key);
        if (question) {
          question.addError(errMsg);
        } else {
          PruToast({ status: "error", message: errMsg });
        }
      }
    }
  }
};
