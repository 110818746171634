import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControlLabelProps } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = {
  root: {
    marginLeft: "0 !important",
    marginRight: "0 !important",
  },
  label: {
    width: "100%",
  },
};

class MyFormControlLabel extends React.Component<FormControlLabelProps> {
  render() {
    return <FormControlLabel {...this.props} />;
  }
}

export default (withStyles as any)(styles)(MyFormControlLabel);
