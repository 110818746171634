import React from "react";
import { Popover } from "@mui/material";
import Icon from "../../../../../components/icon/icon";
import { SvgAssets } from "../../../../../components/icon/assets";
import Typography from "@mui/material/Typography";
import { popoverItem } from "./popover.style";

export function PopoverComponent(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Icon svg={SvgAssets.alertCircle} width={16} height={16} />
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={popoverItem}>{props?.item?.tooltip}</Typography>
      </Popover>
    </div>
  );
}
