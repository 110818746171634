import moment from "moment";
import { AgentProfileItem } from "../types/agent-profile-types";
import { firstUpperLetter } from "./common-utils";

const timezone = "+0800";

export const getAboutDataTemplateMapping = (profile: AgentProfileItem) => {
  const dataTemplateMappingList = {
    firstNameLocal: firstUpperLetter(profile?.agentDetail?.firstNameLocal),
    surnameLocal: firstUpperLetter(profile?.agentDetail?.surnameLocal),
    firstNameEn: firstUpperLetter(profile?.agentDetail?.firstNameEn),
    lastNameEn: firstUpperLetter(profile?.agentDetail?.lastNameEn),
    otherNameEn: firstUpperLetter(profile?.agentDetail?.otherNameEn),
    nickNameEn: firstUpperLetter(profile?.agentDetail?.nickNameEn),
    displayNameEn: firstUpperLetter(profile?.agentDetail?.displayNameEn),
    joinDate: moment(profile?.agentDetail?.joinDate).utcOffset(timezone).format("YYYY"),
    joinYears:
      parseInt(moment().utcOffset(timezone).format("YYYY")) -
      parseInt(moment(profile?.agentDetail?.joinDate).utcOffset(timezone).format("YYYY")),
    mobile: profile?.agentDetail?.phone?.mobile,
    yearOfExperience: profile?.agentDetail?.yearOfExperience,
    gi: profile?.agentDetail?.license?.gi,
    ia: profile?.agentDetail?.license?.ia,
    mpf: profile?.agentDetail?.license?.mpf,
    sfc: profile?.agentDetail?.license?.sfc,
    mdrt: profile?.agentDetail?.license?.mdrt,
  };

  return dataTemplateMappingList;
};
