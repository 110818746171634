import React from "react";
import { DemoLandingScreen } from "./pages/landing-screen/landing-screen";
import { AutoTrackingScreen } from "./pages/auto-tracking-screen/auto-tracking-screen";
import { Test1Screen, Test2Screen } from "./pages/test-screen/test-screen";
import { RouteObject } from "react-router-dom";

export const routesDemo: RouteObject[] = [
  { path: "demo-home", index: true, element: <DemoLandingScreen /> },
  { path: "demo-auto-tracking", element: <AutoTrackingScreen /> },
  { path: "demo-page1", element: <Test1Screen /> },
  {
    path: "demo-page2",
    children: [
      {
        path: "",
        element: <Test2Screen />,
      },
      {
        path: "test",
        element: <Test2Screen />,
      },
    ],
  },
];
