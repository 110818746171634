import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderComponent } from "../../../../../../components/header/header.component";
import Icon from "../../../../../../components/icon/icon";
import { SvgAssets } from "../../../../../../components/icon/assets";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTrainingCourseQuizDetailAsync,
  updateCourseQuizRandomQuestionAsync,
} from "../../../../redux/onboarding-slice";
import { cloneDeep } from "lodash";
import { selectOnboardingQuizContent } from "../../../../redux/selector";
import { OnboardingQuiz, OnboardingQuizBase, OnboardingQuizStatus } from "../../../../types";
import { useQuery } from "../../../../../../utils/hooks/use-query";
import { PruToast } from "../../../../../../components/pru-toast";
import { ConfirmButton } from "../../../../../../components/buttons";

interface PreQuizOverviewProps {
  close?: Function;
  onConfirm?: () => void;
  onCancel?: () => void;
  headerComponent?: React.ReactElement | null;
  quiz: OnboardingQuizBase;
}

export const PreQuizOverview = ({ close, onConfirm, onCancel, quiz }: PreQuizOverviewProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const candidateId = query.get("candidateId")!;
  // const [needPassCounts, setNeedPassCounts] = useState('_')
  const fetchData = useCallback(async () => {
    if (!quiz) return;
    await dispatch<any>(updateCourseQuizRandomQuestionAsync({ quizId: quiz.quizId, candidateId }));
    await dispatch<any>(fetchTrainingCourseQuizDetailAsync({ quizId: quiz.quizId, candidateId }));
  }, [quiz, dispatch]);
  const quizContent = useSelector(selectOnboardingQuizContent);
  const currentQuiz: OnboardingQuiz = useMemo(() => {
    return Object.assign({ ...quiz }, quizContent);
  }, [quiz, quizContent]);
  const needPassCounts = useMemo(() => {
    return currentQuiz.questions?.length
      ? Math.ceil((currentQuiz.questions.length * (currentQuiz.passingScore ?? 0)) / 100)
      : 0;
  }, [currentQuiz]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div className="flex flex-col w-screen h-screen">
      <HeaderComponent
        title={t("onboarding_training_quiz")}
        onBack={() => {
          onCancel?.();
          close?.();
        }}
      />
      <div className="flex flex-col flex-1 w-full items-center justify-center px-10">
        <Icon svg={SvgAssets.notice} width={80} height={80} />
        <p className="mt-6 mb-8 text-center text-sm font-medium text-gray-700 dark:text-gray-400 whitespace-pre-line">
          {currentQuiz.status !== OnboardingQuizStatus.PASSED
            ? (t("pass_quiz_questions_target_tip", { count: needPassCounts } as any) as any)
            : (t("repass_quiz_questions_target_tip", { count: needPassCounts } as any) as any)}
        </p>
        <div className="flex w-full">
          <ConfirmButton
            disabled={!currentQuiz.questions?.length}
            onClick={() => {
              onConfirm?.();
              close?.();
            }}
          >
            {currentQuiz.status !== OnboardingQuizStatus.PASSED
              ? t("take_the_quiz")
              : t("take_the_quiz_again")}
          </ConfirmButton>
        </div>
      </div>
    </div>
  );
};
