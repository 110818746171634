import React, { CSSProperties, SyntheticEvent } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import { SurveyQuestionElementBase } from "survey-react-ui";
// mui start
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
// mui end
import * as style from "../survey/common.style";
import { AutoCompleteType, CustomType, Choices, getI18nData } from "../survey/survey.util";
import i18next from "i18next";

const CUSTOM_TYPE = CustomType.Dropdown;
// const CUSTOM_TYPE = "dropdown2";

export class QuestionDropdownModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get choices() {
    return this.getPropertyValue("choices");
  }
  set choices(val) {
    this.setPropertyValue("choices", val);
  }
  get placeholder(): string {
    return this.getPropertyValue("placeholder");
  }
  set placeholder(val) {
    this.setPropertyValue("placeholder", val);
  }
}

// Add question type metadata for further serialization into JSON
export const initDropdownSerializer = () => {
  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: "choices",
        default: [],
      },
      {
        name: "placeholder",
        default: "Please select",
      },
    ],
    function () {
      return new QuestionDropdownModel("");
    },
    "question",
  );
};

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionDropdownModel(name);
});
// A class that renders questions of the new type in the UI
export class SurveyQuestionDropdown extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.question.value,
      options: getChoices(this.question.choices),
      language: i18next.language,
      choices: this.question.choices, // raw data
    };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get disableAlpha() {
    return this.question.disableAlpha;
  }
  get placeholder() {
    return getI18nData(this.question.placeholder);
  }
  onAutoCompleteChange(event: SyntheticEvent<Element, Event>, value: any | null) {
    this.question.value = { ...value, type: CUSTOM_TYPE };
  }

  // Support read-only
  get style() {
    return (
      this.question.getPropertyValue("readOnly") ? { pointerEvents: "none" } : undefined
    ) as CSSProperties;
  }
  static getDerivedStateFromProps(nextProps: Readonly<any>, prevState: Readonly<any>) {
    if (i18next.language !== prevState.language) {
      return {
        language: i18next.language,
        options: getChoices(prevState.choices), // update options in current-language
      };
    }
    return null;
  }

  renderElement() {
    const selectedItem = getSelectedOption(this.state.options, this.value);
    // const defaultProps = {
    //   options: this.state.options,
    //   getOptionLabel: (option: AutoCompleteType) => option.label,
    // };
    return (
      <div style={this.style}>
        <FormControl fullWidth sx={style.formControl} variant="standard">
          {/*  <span className="label">{this.state.title.default}</span>*/}
          <Autocomplete
            key={i18next.language} // re-rendering when the language changes
            options={this.state.options}
            getOptionLabel={(option: AutoCompleteType) => option.label}
            value={selectedItem}
            onChange={this.onAutoCompleteChange.bind(this)}
            id={this.question.name}
            size="medium"
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={this.placeholder}
                InputLabelProps={style.inputLabelProps}
                size="medium"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </div>
    );
  }
}
/**
 * get Autocomplete dropdown options with current-lang label
 * @param arrRawdata
 * @returns dropdown options array
 */
function getChoices(arrRawdata: Choices[]): AutoCompleteType[] {
  const arrOption = arrRawdata.map((item: Choices) => {
    if (typeof item === "string") {
      return {
        key: item,
        label: item,
      };
    }
    return {
      key: item.value,
      parentKey: item.parentKey,
      parentValue: item.parentValue,
      label: getI18nData(item.text),
    };
  });
  return arrOption;
}
/**
 * get Autocomplete selected item
 * @param arrOption
 * @param selectedOption
 * @returns selected item
 */
function getSelectedOption(arrOption: AutoCompleteType[], selectedOption: any) {
  if (!selectedOption) {
    return undefined;
  }
  const selectedItem = arrOption.find((item) => {
    return selectedOption?.key === item.key;
  });
  return selectedItem;
}
