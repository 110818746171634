import { OtpContextEnum } from "../../../common/network/enum";
import { createCandidate } from "../../network/network";
import { handlerError } from "../otp-verification-screen/handle-otp-error";

export const createCandidateFunc = async (props: {
  username: string;
  password: string;
  context: OtpContextEnum;
  otp: string;
}): Promise<any> => {
  const { username, password, context, otp } = props;
  try {
    const res = await createCandidate({
      username: username,
      password: password,
      context: context,
      otp: otp,
    });
    return handlerError(res);
  } catch (e) {
    return handlerError(e);
  }
};
