import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = "primary" | "secondary" | "info" | "success" | "warning" | "error";

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    neutral: string;
    footer: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module "@mui/material" {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}
// SETUP COLORS
// MUI control color: grey, e.g. <TextField color="info" />
// const PRIMARY = {
//   lighter: "#546E7A",
//   light: "#455A64",
//   main: "#555",
//   dark: "#37474F",
//   darker: "#263238",
// };
const PRIMARY = {
  lighter: "#666",
  light: "#555",
  main: "#444",
  dark: "#333",
  darker: "#263238",
};
// MUI button color: red, e.g. <Button variant="contained">Click Me</Button>
const SECONDARY = {
  lighter: "#EF5350",
  light: "#F44336",
  main: "#E8192C",
  dark: "#D32F2F",
  darker: "#b1000c",
};
const INFO = {
  lighter: "#E6E6E6",
  light: "#E6E6E6",
  main: "#535353",
  dark: "#555",
  darker: "#444",
};
const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
};
const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
};
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
};

const WHITE = {
  lighter: "#FFF",
  light: "#FFF",
  main: "#FFF",
  dark: "#FFF",
  darker: "#FFF",
};

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: GREY[900] },
  warning: { ...WARNING, contrastText: GREY[900] },
  error: { ...ERROR, contrastText: "#fff" },
  white: { ...WHITE, contrastText: "#333" },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export const palette = {
  light: {
    ...COMMON,
    mode: "light",
    text: { primary: GREY[900], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: "#fff", default: "#fff", neutral: GREY[200], footer: "#17171F" },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: "dark",
    text: { primary: "#fff", secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[900], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
} as const;
export const color = {
  cobalt: "#38527E",
  cobaltLight: "#D6E0F1",
  orange: "#FF872E",
  orangeLight: "#FFEFE3",
  yellow: "#FFB83D",
  yellowLight: "#FFF5E0",
  green: "#24C791",
  greenLight: "#DFF7EF",
  cyan: "#24B1C7",
  cyanLight: "#E0FBFF",
  blue: "#4386E5",
  blueLight: "#EBF3FF",
  purple: "#A574F3",
  purpleLight: "#F3EBFF",
  majorRed: "#E8192C",
  redBg: "#EB3B4C",
  redLight: "#FFE4E6",
  black: "#000000",
  grey33: "#333333",
  grey66: "#666666",
  grey99: "#999999",
  greyCc: "#CCCCCC",
  greyE5: "#E5E5E5",
  greyLine: "#F0F0F0",
  greyBg: "#F5F5F5",
  white: "#FFFFFF",
};
