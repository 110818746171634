import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchTrainingExamsAsync, fetchConfigAsync } from "../../redux/onboarding-slice";
import { isEmpty, throttle } from "lodash";
import i18next from "i18next";
import { CircularProgress } from "@mui/material";
import { ErrorScreen } from "../../../../components/error-screen/error-screen";
import { useNavigate } from "react-router-dom";
import { HeaderComponent } from "../../../../components/header/header.component";
import { color } from "../../../../mui-theme/palette";
import { useQuery } from "../../../../utils/hooks/use-query";
import { convertLanguageKey } from "../../../../utils/lang";
import { selectOnboardingExams } from "../../redux/selector";
import { OnboardingExamRegistryStatus, OnboardingTypeExam } from "../../types";
import { useCanGoBack } from "../../../../utils/hooks/use-can-go-back";
import { ModuleSection, SectionItem } from "./type";
import { MenuList } from "./menu";
import useThrottledTouchEnd from "../../hooks/use-throtted-touch-end";
import { takeUIScreenViewEvent } from "../../../../common/ga/ga";
import { TakeUIScreenViewEventParameters } from "../../../../common/ga/types/ga-general-type";

type MenuScreenProps = {};

export const ExamMenuScreen = (props: MenuScreenProps) => {
  useThrottledTouchEnd();
  const { t } = useTranslation();
  const translate = useMemo(
    () => ({
      application: t("Recruitment.onboarding_application"),
    }),
    [t],
  );
  const dispatch = useDispatch();
  const query = useQuery();
  const candidateId = query.get("candidateId")!;
  const lang = query.get("lang") ?? "en";

  const navigate = useNavigate();
  const [data, setData] = useState<ModuleSection[] | undefined>(undefined);
  const exams = useSelector(selectOnboardingExams);
  const [loading, setLoading] = useState(true);
  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!candidateId) return setLoading(false);
    await dispatch<any>(fetchTrainingExamsAsync({ candidateId }));
    setLoading(false);
  }, [candidateId, dispatch]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const gaData: TakeUIScreenViewEventParameters = {
      module: "Recruitment",
      feature: "License",
      journey: "view_license_menu",
      stage: "journey_landing",
      screen_id: "SCR_RCRTMT_LCNS_MN",
      screen_name: "Recruitment-License-Menu-Screen",
    };
    takeUIScreenViewEvent(gaData);
  }, []);

  useEffect(() => {
    if (isEmpty(exams)) {
      return;
    }
    setData(
      exams?.map((typeExam: OnboardingTypeExam) => {
        const typeExams: SectionItem[] = [];
        for (const exam of typeExam.exams) {
          typeExams.push({
            name: exam.type,
            title: exam.name,
            data: exam,
            status: exam.status,
          });
        }
        return {
          title: typeExam.type,
          name: typeExam.type,
          data: typeExams,
        };
      }),
    );
  }, [exams]);

  const throttledTouchEnd = useCallback(
    throttle(() => {
      window.ReactNativeWebView?.postMessage?.('{ "action" : "touchend"}');
    }, 1000),
    [],
  );
  useEffect(() => {
    dispatch<any>(fetchConfigAsync());
    document.addEventListener("touchend", throttledTouchEnd);
  }, []);
  useEffect(() => {
    i18next.changeLanguage(convertLanguageKey(lang));
  }, [lang]);
  const canGoBack = useCanGoBack();
  return (
    <div className="fixed overflow-hidden overscroll-y-none h-screen flex flex-col">
      <HeaderComponent
        title={"License Exam Registration"}
        onBack={() => {
          if (canGoBack()) {
            navigate(-1);
          }
        }}
      />
      {
        <div className={`w-screen flex flex-1 overflow-auto`}>
          {loading ? (
            <div className={`h-screen flex flex-1 items-center justify-center`}>
              {/* <CircularProgress sx={{ color: color.majorRed }} /> */}
            </div>
          ) : isEmpty(data) ? (
            <ErrorScreen statusCode="-1" />
          ) : (
            <>
              <div className={`w-screen flex flex-1 flex-col`}>
                <div className="flex-1 flex-col bg-gray-100">
                  <MenuList sections={data} />
                </div>
              </div>
            </>
          )}
        </div>
      }
    </div>
  );
};
