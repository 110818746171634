import React, { FC, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QrCodeContentComponent } from "./qr-code-content/qr-code-content.component";
import { AgendaComponent } from "./agenda/agenda.component";
import { ReminderComponent } from "./reminder/reminder.component";
import { prompt } from "../../../../components/modal/prompt";
import Icon from "../../../../components/icon/icon";
import { SvgAssets } from "../../../../components/icon/assets";
import { useParticipationQrCode } from "./participation-qr-code.hook";
import { useAppSelector } from "../../../../redux/store";
import { PartyType, RegisterStatus } from "../../network/type";
import { TagComponent } from "./tag/tag.component";
import dayjs from "dayjs";

export const ParticipationQrCodeComponent = memo(() => {
  // i18n
  const { t } = useTranslation();
  const { status, qrCode, isAttended, party, expiredTime } = useAppSelector(
    (state) => state.event.eventQRcodeData,
  )!;

  const openPromptModal = useCallback(() => {
    prompt({
      icon: SvgAssets.notice,
      title: t("event.cancel_registration"),
      message: t("event.agent_required_registration_cancelled_modal_desc"),
    });
  }, [t]);

  const showBottomButton = useMemo(
    () => status === RegisterStatus.REGISTERED && !isAttended && !dayjs().isAfter(expiredTime),
    [expiredTime, isAttended, status],
  );

  const { handleClick } = useParticipationQrCode({
    qrCode,
    openPromptModal,
    isAgent: party === PartyType.AGENT,
  });

  return (
    <div className="w-full h-full px-4 pt-3 bg-[#F5F5F5] flex-1 participation-view break-words">
      <div
        className={`bg-white relative rounded-xl px-4 pb-6 ${showBottomButton ? "mb-6" : "mb-10"}`}
        id="event-qr-code"
      >
        <TagComponent />
        <QrCodeContentComponent />
        <AgendaComponent />
        <ReminderComponent />
      </div>

      {/* bottom button */}
      {showBottomButton && (
        <div
          className="w-full px-4 py-2 rounded-xl border-2 border-[#CCC] mb-4 bg-white flex"
          onClick={handleClick}
        >
          <span className="flex-1 text-center font-semibold">{t("event.cancel_registration")}</span>
        </div>
      )}
    </div>
  );
});
