import React from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";

export interface RoutedProps<Params = any, State = any> {
  location: Location;
  navigate: NavigateFunction;
  params: Params;
}

export function withRouter(Child: any) {
  return (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Child {...props} navigate={navigate} location={location} params={ params } />;
  };
}

/**
 * how to use
 */
/*
interface Props extends RoutedProps {
  title:string 
} 
type State = {
  count: number;
};
export class ClassComponent extends React.Component<Props, State> {
  // now you can use router props
  this.props.navigate("/");
  this.props.location.href;
  this.props.params.xx;
  render(){}
}
export const ClassComponentWithRouter = withRouter(ClassComponent)

*/
