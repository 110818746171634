import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  ModalsContextType,
  ModalContextType,
  ModalProps,
  UseKeepAliveModal,
  UseModal,
} from "./modal.type";

export const ModalsContext = createContext<ModalsContextType>({} as ModalsContextType);
export const ModalContext = createContext<ModalContextType>({} as ModalContextType);

export const createModalId = () => `${Date.now()}${Math.random()}`;
let modalContextRef: ModalsContextType;

export const useModalsValue = (): ModalsContextType => {
  const [modals, setModals] = useState<Modal[]>([]);
  const resolveListRef = useRef<((resolve: boolean) => void)[]>([]);
  const opened = useRef<boolean>(false);

  useEffect(() => {
    if (modals.length) {
      const lastVisibleModalIndex = modals.reduce((total, current, index) => {
        return current.visible ? index : total;
      }, 0);
      opened.current = modals.some((modal) => modal.visible);

      if (!opened.current) {
        return setModals([]);
      }

      if (lastVisibleModalIndex < modals.length - 1) {
        setModals(modals.slice(0, lastVisibleModalIndex + 1));
      }
    }
  }, [modals.map((modal) => modal.visible).join("_")]);

  useEffect(() => {
    if (!opened.current && !modals.filter((modal) => modal.visible).length) {
      const current = resolveListRef.current.shift();

      if (current) {
        current(true);
        opened.current = true;
      }
    }
  }, [modals]);

  const openModal = (modal: Modal) => {
    opened.current = true;
    setModals((modals) => {
      const lastModal = modals[modals.length - 1];
      const zIndex = modal.zIndex || (lastModal ? (lastModal.zIndex || 0) + 1 : 0);
      const newModal: Modal = {
        ...modal,
        zIndex,
        id: modal.id,
        visible: true,
      };
      return [...modals, newModal].sort((a, b) => ((a.zIndex || 0) > (b.zIndex || 0) ? 1 : -1));
    });
  };

  const closeModal = (id: string) => {
    setModals((modals) => {
      return modals.map((item) => {
        if (item.id === id) {
          item?.close && item.close();

          return {
            ...item,
            visible: false,
          };
        }
        return item;
      });
    });
  };

  const waitUntilIdle = (): Promise<boolean> => {
    return new Promise((resolve) => {
      resolveListRef.current.push(resolve);

      if (resolveListRef.current.length === 1) {
        setTimeout(() => {
          setModals((modals) => [...modals]);
        });
      }
    });
  };

  const updateModal = (id: string, updateProps: any) => {
    setModals((modals) =>
      modals.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...updateProps,
          };
        }

        return item;
      }),
    );
  };

  return (modalContextRef = {
    updateModal,
    modals,
    openModal,
    closeModal,
    waitUntilIdle,
    context: null,
  });
};

export function useGlobalModal(): UseModal {
  return (() => {
    const { closeModal, openModal, waitUntilIdle } = modalContextRef;
    const id = createModalId();

    const close = () => closeModal(id);
    const open = (children: JSX.Element, modalProps: ModalProps = {}) => {
      openModal({
        ...modalProps,
        id,
        children,
      } as any);
    };

    return {
      open,
      close,
      openAtIdle: async (children: JSX.Element, openProps: ModalProps = {}) => {
        await waitUntilIdle();
        open(children, openProps);
      },
    };
  })();
}

export const useModalsContext = () => {
  return useContext(ModalsContext);
};

export const useModalContext = function () {
  return useContext(ModalContext);
};

export function useModal(): UseModal {
  const { openModal, closeModal, waitUntilIdle } = useModalsContext();
  const id = useRef<string>(createModalId()).current;
  const close = () => closeModal(id);

  const open = (children: JSX.Element, modalProps: ModalProps = {}) => {
    openModal({
      ...modalProps,
      id,
      children,
    });

    return close;
  };

  return {
    open,
    close,
    openAtIdle: async (children: JSX.Element, modalProps: ModalProps = {}) => {
      await waitUntilIdle();
      open(children, modalProps);
    },
  };
}

export function useKeepAliveModal(
  children: JSX.Element,
  modalProps: ModalProps,
): UseKeepAliveModal {
  const { openModal, updateModal, closeModal, waitUntilIdle } = useModalsContext();
  const id = useRef<string>(createModalId()).current;
  const childrenProps = children.props;
  const memoChildren = useMemo(
    () => children,
    Object.entries(childrenProps).map((item) => childrenProps[item[0]]),
  );

  useEffect(() => {
    updateModal(id, {
      children: memoChildren,
    });
  }, [memoChildren]);

  const close = () => closeModal(id);
  const open = () => {
    openModal({
      ...modalProps,
      id,
      children,
    });
  };

  return {
    open,
    close,
    openAtIdle: async () => {
      await waitUntilIdle();
      open();
    },
  };
}
