import { OtpContextEnum, UserTypeEnum } from "../../network/enum";
import { validateOtp } from "../../network/network";
import { handlerError } from "./handle-otp-error";

export const validateOtpFunc = async (props: {
  context: OtpContextEnum;
  otp: string;
  regionCode: string;
  phone: string;
  userType: UserTypeEnum;
}) => {
  const { context, otp, regionCode, phone, userType } = props;
  try {
    const res = await validateOtp({
      context: context,
      username: `${regionCode} ${phone}`,
      otp: otp,
      userType: userType,
    });
    return handlerError(res);
  } catch (e) {
    return handlerError(e);
  }
};
