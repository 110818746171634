import moment from "moment";
import { PaymentType } from "../../types";

export const TrainingPaymentTemplate = {
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "radiogroup",
          name: "paymentType",
          isRequired: true,
          title: "Recruitment.payment_type",
          choices: [
            { text: "Recruitment.full_payment", value: PaymentType.FullPayment },
            { text: "Recruitment.code_now_pay_later", value: PaymentType.CodeNowPayLater },
          ],
          requiredErrorText: "Recruitment.application_type_required_error",
        },
        {
          type: "text",
          visibleIf: `{paymentType} = '${PaymentType.FullPayment}'`,
          name: "feeOfficialReceiptNo",
          isRequired: true,
          title: "Recruitment.training_fee_or_no",
          requiredErrorText: "Common.this_field_is_mandatory",
          placeholder: "Recruitment.official_receipt_number",
          validators: [
            {
              type: "regex",
              text: "Recruitment.format_error",
              regex: /^[a-zA-Z0-9_-]+$/,
            },
          ],
        },
        {
          type: "text",
          visibleIf: `{paymentType} = '${PaymentType.FullPayment}'`,
          inputType: "date",
          name: "officialReceiptDate",
          isRequired: true,
          title: "Recruitment.or_date",
          placeholder: "Common.select_date",
          max: moment().format("YYYY-MM-DD"),
          requiredErrorText: "Common.this_field_is_mandatory",
        },
        {
          type: "text",
          visibleIf: `{paymentType} = '${PaymentType.FullPayment}'`,
          inputType: "text",
          name: "officialReceiptAmount",
          isRequired: true,
          title: "Recruitment.training_or_amount",
          placeholder: "Common.input_amount",
          requiredErrorText: "Common.this_field_is_mandatory",
          validators: [
            {
              type: "regex",
              text: "Recruitment.format_error",
              regex: /^\d+(\.\d+)?$/,
            },
          ],
        },
      ],
    },
  ],
  showQuestionNumbers: "off",
};
