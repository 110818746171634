// Layout.tsx
import React from "react";
import IdleTimer from "./idle-timer";
import { confirm } from "../../../components/modal/comfirm";
import { useTranslation } from "react-i18next";
import { SvgAssets } from "../../../components/icon/assets";
import { prompt } from "../../../components/modal/prompt";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "../../auth/redux/auth-slice";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../routes/constants";

interface IdleTimerLayoutProps {
  children: React.ReactNode;
}

const IdleTimerLayout: React.FC<IdleTimerLayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleIdle = () => {
    if (sessionStorage.getItem("AUTH_TOKEN")) {
      const config = {
        icon: SvgAssets.notice,
        title: t("reminder_title"),
        message: t("Common.system_idle_for_15_minutes"),
        confirmButtonText: t("Common.confirm"),
        onConfirm: () => {
          sessionStorage.removeItem("AUTH_TOKEN");
          dispatch(reset());
          navigate(`${ROOT_PATH}/login`);
        },
      };
      prompt(config);
    }
  };

  return (
    <>
      {<IdleTimer onIdle={handleIdle} />}
      {children}
    </>
  );
};

export default IdleTimerLayout;
