const LangCodeMap = {
  en: "en",
  id: "id",
  my: "my",
  // zhHk,
  zhhk: "zh-Hant",
  // fil_PH,
  fil_ph: "ph",
  // km_KH,
  km_kh: "km",
};
export function getLanguage(langKey: string = ""): string {
  let lang: string = langKey || "en";
  lang = lang.toLowerCase();
  // mapping confluence page:https://collaborate.pruconnect.net/pages/viewpage.action?spaceKey=PCASPTL&title=RESTful+API+Guideline
  // @ts-ignore
  return LangCodeMap[lang] || lang;
}
/** conver REGION_LANGUAGE to i18n resources key: web/src/i18n/i18n-config.ts
 * REGION_LANGUAGE env: https://code.pruconnect.net/projects/PCAAEB/repos/ebriefcase_devops/browse/k8s-manifests/coedev/env-configmap.yaml
 */
export function convertLanguageKey(lang: string): string {
  const langMapping = {
    en: "en",
    "en-US": "en",
    zhhk: "zhhk",
    zhHk: "zhhk",
    "zh-Hant": "zhhk",
    "zh-Hant-TW": "zhhk",
    id: "id", // indonesia
    my: "my",
    ph: "ph",
    km: "km",
  };
  // @ts-ignore
  return langMapping[lang] || "en";
}
