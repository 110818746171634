import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { fetchResourceDetail } from "../network/resource-crud";
import { GetResourceThunkArg, ResourceObject } from "../types/reource";
import { AxiosError } from "axios";

export const resourcePersistConfig = {
  key: "resource",
  storage,
};

const initialState: ResourceState = {
  requesting: "idle",
};

export interface ResourceState {
  data?: Partial<ResourceObject>;
  requesting?: "idle" | "pending" | "fulfilled" | "rejected";
  loaded?: boolean;
  getResourceError?: AxiosError | any;
}

export const fetchResourceByIdAsync = createAsyncThunk<
  // Return type of the payload creator
  Partial<ResourceObject> | any,
  // First argument to the payload creator (provide void if there isn't one)
  GetResourceThunkArg,
  // Types for ThunkAPI
  { state: ResourceState; rejectValue: any }
>("resources/detail", async (params: any, thunkAPI: any) => {
  try {
    const result = await fetchResourceDetail(params);
    return result;
  } catch (error: AxiosError | any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {},
  extraReducers(builder: any) {
    builder
      .addCase(fetchResourceByIdAsync.pending, (state: ResourceState) => {
        state.requesting = "pending";
        state.data = undefined;
        state.loaded = false;
        state.getResourceError = null;
      })
      .addCase(
        fetchResourceByIdAsync.fulfilled,
        (state: ResourceState, action: { payload: Partial<ResourceObject> | undefined }) => {
          state.requesting = "fulfilled";
          state.data = action.payload;
          state.loaded = true;
          if (!state.data) {
            state.getResourceError = {
              ...new AxiosError("Resource not found"),
              response: { status: 404 },
            };
          } else {
            state.getResourceError = null;
          }
        },
      )
      .addCase(fetchResourceByIdAsync.rejected, (state: ResourceState, action: any) => {
        state.requesting = "rejected";
        state.data = undefined;
        state.loaded = false;
        state.getResourceError = action.payload;
      });
  },
});
