export interface FileUpload {
  applicationId: string;
  moduleName: string;
  sectionName: string;
  questionName: string;
  data: string;
  fileType: StorageFileTypeEnum;

  /**
   * optional for signature
   */
  fileName: string;
  /**
   * optional
   */
  mimeType?: string;
  index?: number;
}

export enum StorageFileTypeEnum {
  SIGNATURE = "SIGNATURE",
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
}

export interface DeleteFile {
  applicationId?: string;

  resourceId: string;
}

export type DownLoadFile = DeleteFile;

export enum SourceEnum {
  DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD",
  DOCUMENT_DOWNLOAD = "DOCUMENT_DOWNLOAD",
}
export interface DocumentUpload {
  applicationId?: string;
  source: SourceEnum;
}
