import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GAFeature, GAJourney, GAModule, GAScreenName, GAStage } from "../types/ga-parameter-type";
import { RootState } from "../../../redux/store";

export type GAData = {
  module: GAModule;
  feature: GAFeature;
  journey: GAJourney;
  stage: GAStage;
  screen_name: GAScreenName;
};

export type GAState = {
  data: {
    module: GAModule;
    feature: GAFeature;
    journey: GAJourney;
    stage: GAStage;
    screen_name: GAScreenName;
  } | null;
};

const initialState: GAState = {
  data: null,
};

export const gaSlice = createSlice({
  name: "ga",
  initialState,
  reducers: {
    updateGAData: (state, payload: PayloadAction<GAData | null>) => {
      state.data = payload.payload;
    },
  },
});

export const { updateGAData } = gaSlice.actions;

export const getGAData = (state: RootState) => state.ga.data;
