import { get } from "lodash";
import { http } from "../../../utils/axios/axios";
import { getI18n } from "react-i18next";
import {
  RegistrationFormReqType,
  SubmitParticipantsInfoReqType,
  SubmitRegistrationFormReqType,
  ValidateInfoReqType,
} from "./type";

const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");
const APIM_PUBLIC = get(window, "envConfig.REACT_APP_APIM_PUBLIC", "");
export const baseURL = `${APIM_BASE_URL}${APIM_PUBLIC}`;

export const fetchEventDetail = (shortenCode: string) => {
  return http.get(`${baseURL}/v2/events/${shortenCode}`);
};

export const fetchEventQRcodeDetail = (shortenCode: string) => {
  return http.get(`${baseURL}/v2/events/registrations-result/${shortenCode}`);
};

export const fetchRegistrationForm = ({
  shortenCode,
  registrationId,
  type,
  sessionId,
}: RegistrationFormReqType) => {
  return http.get(`${baseURL}/v2/events/registrations/${shortenCode}`, {
    params: {
      registrationId,
      type,
      sessionId,
    },
  });
};

export const submitRegistrationForm = ({
  shortenCode,
  surveyResultBody,
  type,
  sessionId,
}: SubmitRegistrationFormReqType) => {
  return http.post(`${baseURL}/v2/events/registrations/${shortenCode}`, {
    surveyResultBody,
    lang: getI18n().language,
    type,
    sessionId,
  });
};

export const fetchEventValidate = (shortenCode: string) => {
  return http.get(`${baseURL}/v2/events/scanner/${shortenCode}/validate`);
};
export const cancelRegistration = (qrCode: string) => {
  return http.get(`${baseURL}/v2/events/cancel-registrations/${qrCode}`);
};

export const validateParticipantInfo = (shortenCode: string, data: ValidateInfoReqType) => {
  return http.post(`${baseURL}/v2/events/attendance/${shortenCode}/validate`, data);
};

export const fetchParticipantsInfoList = (shortenCode: string, data: ValidateInfoReqType) => {
  return http.post(`${baseURL}/v2/events/attendance/${shortenCode}/list`, data);
};

export const submitParticipantInfo = (shortenCode: string, data: SubmitParticipantsInfoReqType) => {
  return http.post(`${baseURL}/v2/events/attendance/${shortenCode}/submit`, data);
};

// Nlwqjm  mu
//uH2Cl4  single
//sNCaUy  real

export const fetchAttendanceLanding = (shortenCode: string) => {
  return http.get(`${baseURL}/v2/events/attendance/${shortenCode}/landing`);
};
