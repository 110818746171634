export type MenuData = {
  header?: HeaderItem[];
  menu?: ModuleSection[];
  doc?: DocItem[];
  isCompleted?: boolean;
};
export type ModuleSection = {
  title: string;
  /**
   * id
   */
  name: string;
  numerator?: number;
  denominator?: number;
  status?: keyof typeof StatusEnum;
  expanded?: boolean;
  data: SectionItem[];
};

export type SectionItem = {
  title: string;
  /**
   * id
   */
  name: string;
  // numerator?: number;
  // denominator?: number;
  status?: keyof typeof StatusEnum;
};
export type DocItem = {
  name: string;
  title: string;
  data: string[];
};

export type HeaderItem = {
  key?: string;
  value?: string;
  valueColor?: string;
};

export const enum StatusEnum {
  editing = "editing",
  completed = "completed",
  completedButDisabled = "completedButDisabled",
  untouched = "untouched",
  disabled = "disabled",
}

export enum OnboardingTrainingModuleType {
  Training = "onboarding_training",
  ExamRegistration = "license_exam_registration",
}

export enum PageNameEnum {
  CandidateProfile = "CandidateProfile",
  OnboardingApplication = "OnboardingApplication",
}
