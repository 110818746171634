import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/store";
import "../../pages/detail/detail-screen.css";
import dayjs from "dayjs";
import { constants } from "../../../../routes/constants";

export const List = () => {
  const { t } = useTranslation();
  const { participantsInfo } = useAppSelector((state) => state.event);

  return participantsInfo && participantsInfo?.length > 0 ? (
    <Fragment>
      <div className="w-full flex-grow overflow-auto">
        {participantsInfo?.map((item, i) => {
          return (
            <div className="w-full flex  flex-col" key={i}>
              <div className="flex flex-row items-center justify-between cursor-pointer">
                <span className="text-[16px] font-medium text-[#666] md:text-xl ">
                  {t("event.participant_first_name")}:
                </span>
                <span className="text-[16px] font-medium text-[#333] md:text-xl ">
                  {item.firstName}
                </span>
              </div>
              <div className="flex flex-row items-center justify-between cursor-pointer py-2">
                <span className="text-[16px] font-medium text-[#666] md:text-xl ">
                  {t("event.participant_last_name")}:
                </span>
                <span className="text-[16px] font-medium text-[#333] md:text-xl ">
                  {" "}
                  {item.lastName}
                </span>
              </div>
              <div className="flex flex-row items-center justify-between cursor-pointer py-2">
                <span className="text-[16px] font-medium text-[#666] md:text-xl ">
                  {item?.agentCode ? t("event.agent_code") : t("event.referred_agent_code")}
                </span>
                <span className="text-[16px] font-medium text-[#333] md:text-xl ">
                  {item?.agentCode ? item?.agentCode : item?.referredAgentCode}
                </span>
              </div>
              {item?.referredAgentCode && (
                <div className="flex flex-row items-center justify-between cursor-pointer py-2">
                  <span className="text-[16px] font-medium text-[#666] md:text-xl ">
                    {t("event.referred_agent_name")}:
                  </span>
                  <span className="text-[16px] font-medium text-[#333] md:text-xl ">
                    {item?.referredAgentName}
                  </span>
                </div>
              )}
              {item?.referredAgentCode && (
                <div className="flex flex-row items-center justify-between cursor-pointer py-2">
                  <span className="text-[16px] font-medium text-[#666] md:text-xl ">
                    {t("event.referred_agent_phone_number")}:
                  </span>
                  <span className="text-[16px] font-medium text-[#333] md:text-xl ">
                    {item.referredAgentPhone}
                  </span>
                </div>
              )}

              <div className="flex flex-row items-center justify-between cursor-pointer py-2">
                <span className="text-[16px] font-medium text-[#666] md:text-xl ">
                  {t("event.check_in_at")}:
                </span>
                <span className="text-[16px] font-medium text-[#333] md:text-xl ">
                  {`${dayjs(item.checkInAt).format(constants.Date_Minute_Scale_Format)}`}
                </span>
              </div>
              {item.checkOutAt && (
                <div className="flex flex-row items-center justify-between cursor-pointer ">
                  <span className="text-[16px] font-medium text-[#666] md:text-xl ">
                    {t("event.check_out_at")}:
                  </span>
                  <span className="text-[16px] font-medium text-[#333] md:text-xl ">
                    {`${dayjs(item.checkOutAt).format(constants.Date_Minute_Scale_Format)}`}
                  </span>
                </div>
              )}

              {i !== participantsInfo?.length - 1 && (
                <div className="w-full h-[1px] bg-[#e6e6e6] my-4" />
              )}
            </div>
          );
        })}
      </div>
    </Fragment>
  ) : null;
};
