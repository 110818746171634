import { useCallback, useEffect } from "react";
import { throttle } from "lodash";

function useThrottledTouchEnd() {
  const throttledTouchEnd = useCallback(
    throttle(() => {
      window.ReactNativeWebView?.postMessage?.('{ "action" : "touchend"}');
    }, 1000),
    [],
  );

  useEffect(() => {
    document.addEventListener("touchend", throttledTouchEnd);

    return () => {
      document.removeEventListener("touchend", throttledTouchEnd);
    };
  }, [throttledTouchEnd]);
}

export default useThrottledTouchEnd;
