import { Model } from "survey-core";
import { TextMarkdownEvent } from "survey-core/typings/survey-events-api";
import { EVENT_OPEN_LINK_WITHIN_PAGE } from "../../../../../constants/constants";
import { OnboardingFormModuleName } from "../../../types";
import { confirm } from "../../../../../components/modal/comfirm";
import { getI18n } from "react-i18next";
import { GlobalHelper } from "../../../../../utils/helpers/global-helper";
import { customMarked } from "../../../../../components/surveyjs/survey/survey.util";

export enum CustomType {
  Text = "text",
  Dropdown = "dropdown",
  Boolean = "boolean",
  Checkbox = "checkbox",
  RadioGroup = "radiogroup",
  Download = "download",
}

const funcprefix = "onBordingForm";

export const CustomFunctionName = {
  sendOtp: `${funcprefix}candidate-send-otp`,
  getOid: `${funcprefix}candidateGetOid`,
};
export type EndAdornment = {
  path?: string;
  triggerKey?: string;
  type?: string;
  btnText?: string;
  position?: "start" | "end";
  actionParams?: Object;
};

export type SurveyTemplate = Model;

export const getTranslations = () => {
  return {
    the_data_has_been_saved: getI18n().t("Recruitment.onboarding_application_saved_successfully"),
    the_data_has_been_submitted: getI18n().t(
      "Recruitment.onboarding_application_submitted_successfully",
    ),
  };
};
export function findFirstPanelIndex(
  template: SurveyTemplate,
  module: OnboardingFormModuleName,
): number {
  let totalPanelCount = 0;
  let moduleFirstPanelIndex = -1;

  for (let page of template.pages) {
    if (page.name === module) {
      for (let j = 0; j < page.elements.length; j++) {
        const element = page.elements[j] as any;
        if (element.type === "panel") {
          moduleFirstPanelIndex = totalPanelCount + j;
          break;
        }
      }
      break;
    }

    for (let element of page.elements as any) {
      if (element.type === "panel") {
        totalPanelCount++;
      }
    }
  }

  return moduleFirstPanelIndex;
}

export function findPanelIndex(template: SurveyTemplate, sectionName: string): number {
  let panelIndex = 0;
  for (const page of template.pages) {
    for (const element of page.elements as any) {
      if (element.type === "panel" && element.name === sectionName) {
        return panelIndex;
      }
      panelIndex++;
    }
  }
  return -1;
}

export function regionPhoneValidate([region, number]: any[]): boolean {
  if (!number || !region) {
    return false;
  }
  const allRegions = require("./full-region-list.json");
  let regex = new RegExp(
    (Object as any).values(allRegions).find((regionItem: any) => regionItem.code === region)
      ?.regex ?? "^(\\d){5,20}$",
  );
  return regex.test(number);
}
export function idNumValidate([idType, idNum]: any[]) {
  const validationRules =
    GlobalHelper.getIdTypeConfigs() ?? require("./identity-type-configs.json");
  if (!validationRules) return true;

  const rule: any = Object.values(validationRules).find((rule: any) => rule?.value === idType);

  if (!rule) {
    return true;
  }

  const isValid = new RegExp(rule.regex).test(idNum);

  if (!isValid) {
    return getI18n().t(rule.errMsg.replace(":", "."));
  }

  return true;
}
export function markdownHandler(survey: Model, options: TextMarkdownEvent) {
  let str: string = options.text;
  if (str.includes("\n")) {
    str = str.replaceAll("\n", "<p />");
  }
  if (str.includes("[") && str.includes("]")) {
    // Convert Markdown to HTML
    str = customMarked.parse(str, { async: false });
    const onClickHandler = `
      var target = event.target;
      if (target.tagName === 'A') {
        event.preventDefault();
        var event = new CustomEvent('${EVENT_OPEN_LINK_WITHIN_PAGE}', {detail: {url: target.href}, isTrusted: true});
        document.dispatchEvent(event);
      }
    `;
    str = `<span onclick="${onClickHandler}">${str}</span>`;
  }
  // Set HTML markup to render
  options.html = str;
}

export function findSectionByIndex(data: any, index: number) {
  let total = 0;
  for (let i = 0; i < data.pages.length; i++) {
    let page = data.pages[i];
    let len = page.elements.length;
    if (total + len > index) {
      return page.elements[index - total]
        ? { ...page.elements[index - total], moduleName: page.name }
        : undefined;
    }
    total += len;
  }
}

export const confirmActionAsyncFunc = (message: string, resCallback: Function) => {
  confirm({
    message: message,
  }).then((result) => {
    resCallback(result.ok);
  });
  return true; //should return true
};
