import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AgentProfileItem } from "../types/agent-profile-types";
import { localeMapToBackend } from "../utils/locale-utils";
import { useStyles } from "./styles/basic-info-image.styles";

type BasicInfoImageProps = {
  agentProfile: AgentProfileItem;
};

const BasicInfoImage: FC<BasicInfoImageProps> = ({ agentProfile }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const mappedLocale = localeMapToBackend[currentLang] || currentLang;

  const { profilePictureUrl } = agentProfile.agentDetail;

  const defaultImg = process.env.PUBLIC_URL + "/media/svg/icons/Head/default-head.svg";

  return (
    <div>
      <div className={classes.card}>
        <img src={agentProfile.banner[mappedLocale]} alt="" className={classes.banner} />
      </div>
      <div className={classes.mainContainer}>
        <div className={classes.imgCircleContainer}>
          <img
            src={profilePictureUrl || defaultImg}
            alt=""
            className={classes.imgCircle}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              if (target.src !== defaultImg) {
                target.src = defaultImg;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInfoImage;
