import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { getARLConfig, getCountryCodeAndCity } from "../network/network";
import { AxiosError } from "axios";
import { stringify, parse } from "flatted";
import { getItem, setItem } from "../../../utils/session-storage";
export const resourcePersistConfig = {
  key: "referralLink",
  storage,
};

const initialState: ReferralLink = {
  requesting: "idle",
  dropdown: undefined,
};

export interface ReferralLink {
  dropdown?: Partial<any>;
  requesting?: "idle" | "pending" | "fulfilled" | "rejected";
  loaded?: boolean;
  getResourceError?: AxiosError | any;
}

export const fetchConfig = createAsyncThunk(
  "get-country-code-city",
  async (param: { region: string; channel: string }, thunkAPI: any) => {
    try {
      const { region, channel } = param;
      // cache in sessionStorage
      const sessionStorageKey = `country-code-city-${region}-${channel}`;
      const cache = getItem(sessionStorageKey);
      if (cache) {
        return cache;
      }
      const result = await getCountryCodeAndCity(param.region, param.channel);
      setItem(sessionStorageKey, result);
      return result;
    } catch (error: AxiosError | any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const referralLinkSlice = createSlice({
  name: "referralLink",
  initialState,
  reducers: {},
  extraReducers(builder: any) {
    builder
      .addCase(
        fetchConfig.fulfilled,
        (state: ReferralLink, action: { payload: any | undefined }) => {
          state.requesting = "fulfilled";
          state.dropdown = action.payload;
          state.loaded = true;
          if (!action.payload) {
            state.getResourceError = {
              ...new AxiosError("Resource not found"),
              response: { status: 404 },
            };
          } else {
            state.getResourceError = null;
          }
        },
      )
      .addCase(fetchConfig.rejected, (state: ReferralLink, action: any) => {
        state.requesting = "rejected";
        state.dropdown = undefined;
        state.loaded = false;
        state.getResourceError = action.payload;
      });
  },
});
