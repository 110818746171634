import { convert2SurveyLocale } from "../../../../components/surveyjs/survey/survey.util";

export type MircrositeDetail = {
  status: string;
  productType?: string;
  bannerType: string;
  bannerLink?: string;
  bannerData?: ResourceData;
  title: I18nData<string>;
  subTitle: I18nData<string>;
  body: I18nData<string>;
  customerDisclaimer: I18nData<string>[];
  callToActionText: I18nData<string>;
  submissionResponseTitle: I18nData<string>;
  submissionResponseContent: I18nData<string>;
  campaignId: string;
  inputFieldsConfig?: any;
  keepOriginActive?: boolean;
};
export enum LeadMicrositeStatusEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum LeadMicrositeContentTypeEnum {
  DEFAULT = "default",
  CUSTOM = "custom",
}

export type I18nData<T> = {
  [locale: string]: T;
};
export type ResourceData = {
  id: string;
  fileName: string;
  fileUrl: string;
};
// { en: "Go", "zh-Hant": "Go" }
/**
 * to surveyJS i18n data
 * sample1: { en: "Go", "zh-Hant": "Go" } => { default: "Go", "zh-tw": "Go" }
 * sample2: [{ en: "tnc", "zh-Hant": "tnc" },{ en: "tnc2", "zh-Hant": "tnc2" }] =>  { default: ["tnc","tnc2"], "zh-tw": ["tnc","tnc2"] }
 * @param data
 */
export function convert2SurveyjsI18nData(data: I18nData<string> | I18nData<string>[]) {
  if (!data) {
    return null;
  }
  const dataType = typeof data;
  if (Array.isArray(data)) {
    let result: any = {};
    data.map((item) => {
      for (const key in item) {
        const locale = convert2SurveyLocale(key);
        const value = item[key];
        result[locale] ? result[locale].push(item[key]) : (result[locale] = [value]);
      }
    });
    return result;
  } else {
    let result: any = {};
    for (const key in data) {
      const locale = convert2SurveyLocale(key);
      result[locale] = data[key];
    }
    return result;
  }
}
// [{ en: "tnc", "zh-Hant": "tnc" }],
