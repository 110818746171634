import { Snackbar, SnackbarContent, Typography, styled } from "@mui/material";
import React, { createContext, useState, useCallback, useContext } from "react";
import "./index.css";
import Icon from "../icon/icon";
import { SvgAssets } from "../icon/assets";

interface ToastContextType {
  hide?: () => void;
  show?: (args: ToastType) => void;
  toast?: ToastType;
}

export interface ToastType {
  visible?: boolean;
  message?: string;
  duration?: number;
  yAlign?: "top" | "center" | "bottom";
  status?: "success" | "warning" | "error";
}
const initialToast: ToastType = {
  visible: false,
};

export const ToastContext = createContext<ToastContextType>({
  hide: undefined,
  show: undefined,
  toast: undefined,
});
let toastSnapshot: ToastType = initialToast;
export const ToastProvider = ({ children }: any) => {
  const [toast, setToast] = useState<ToastType>(toastSnapshot);

  const show = (args: any) => {
    toastSnapshot = { ...toastSnapshot, visible: true, ...args };
    setToast(toastSnapshot);
  };

  const hide = useCallback(() => {
    toastSnapshot = initialToast;
    setToast(initialToast);
  }, [toast]);
  return (
    <>
      <ToastContext.Provider
        value={{
          hide,
          show,
          toast,
        }}>
        {children}
        <ToastContextView />
      </ToastContext.Provider>
    </>
  );
};
export const ToastContextView = () => {
  const { toast, hide } = useContext(ToastContext);
  return (
    <Snackbar
      className={toast?.yAlign ? `y-${toast.yAlign}` : "y-center"}
      sx={{ width: "fit-content", marginLeft: "50%", transform: "translateX(-50%)" }}
      open={toast?.visible}
      autoHideDuration={toast?.duration ?? 5000}
      onClose={hide}>
      <div className="w-screen flex justify-center">
        <div
          style={{ maxWidth: `calc(100vw - 80px)` }}
          className="rounded-lg bg-black w-fit text-center text-white py-2 px-4 shadow-sm shadow-lg:hover text-sm font-medium leading-tight tracking-normal">
          {toast?.status === "success" ? (
            <div className="w-full flex justify-center py-2">
              <Icon svg={SvgAssets.toastSuccess} width={32} height={32} />
            </div>
          ) : null}
          {toast?.status === "error" && (
            <div className="w-full flex justify-center py-2">
              <Icon svg={SvgAssets.error} width={32} height={32} />
            </div>
          )}
          {toast?.message}
        </div>
      </div>
    </Snackbar>
  );
};
