import { isString } from "lodash";

export type CardProps = {
  infos: {
    key: string;
    value: string;
  }[];
  title: string;
};

export type TodoTask = {
  /**
   * {
   *  enUs?: string // en
   *  zhHk?: string
   *  [key: string]: string
   * }
   */
  assigneeName: string | { [key: string]: string };
  assigneeId?: string;
  taskIssueDate: string;
  taskId: string;
  taskDetails: string;
};

export function getI18nData(
  value: { [key: string]: string } | undefined | string,
  lang: string | undefined,
) {
  if (!value) return "";
  if (isString(value)) return value;
  const defaultValue = value?.default || value?.enUs || value?.en;
  if (lang === "en" || lang === "enUs") {
    return defaultValue;
  }
  const arrKey = Object.keys(value);
  const filteredKey = arrKey.find((key) => key !== "default" && key !== "enUs" && key !== "en");
  return filteredKey ? value[filteredKey] : defaultValue;
}
