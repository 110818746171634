import React, { useCallback, useEffect, useState } from "react";
import { usePdfViewContext, PdfViewContext } from "./page-context";
import { Dialog } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { color } from "../../../../mui-theme/palette";
import { downFile } from "../../network/network";
import { toBase64Url } from "../surveyjs/file/base64";
import { PdfThumbnailView } from "./thumbnail-view";
import { useGlobalModal } from "../../../../components/use-modal/use-modal";
import { PdfFullView } from "./full-view";

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

interface PropsBase {
  title?: string;
  nextPageText: string;
  prePageText: string;
  endPageText: string;
  description?: string;
  resourceId?: string;
  resourceUrl?: string;
  onFinish?: () => void;
  onPageChange?: (page: number) => void;
}

type Props = RequireAtLeastOne<PropsBase, "resourceId" | "resourceUrl">;

export const LessonView = (props: Props) => {
  const pdfContext = usePdfViewContext();
  const initPage = useCallback(async () => {
    let content: string | File = props.resourceUrl ?? "";
    if (props.resourceId) {
      const file = await downFile({ resourceId: props.resourceId });
      content = toBase64Url(file.data.fileContent, file.data.mimeType);
    }

    pdfContext.setFile(content);
  }, [pdfContext, props.resourceId, props.resourceUrl]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    pdfContext.setLoading(true);
    initPage();
  }, []);

  return (
    <PdfViewContext.Provider value={pdfContext}>
      {pdfContext.loading ? (
        <div className={`h-screen w-screen flex flex-1 items-center justify-center`}>
          <CircularProgress sx={{ color: color.majorRed }} />
        </div>
      ) : (
        <div className="overflow-auto flex-1 flex bg-white p-4 flex-col ">
          <div className="overflow-auto flex-1 bg-white">
            {!!props.title && (
              <div className="mb-3">
                <span className="text-gray-800 text-xl not-italic font-semibold leading-7">
                  {props.title}
                </span>
              </div>
            )}
            {!!props.description && (
              <div className="mb-6">
                <span className="text-gray-700 text-sm not-italic font-medium leading-5">
                  {props.description}
                </span>
              </div>
            )}
            <PdfThumbnailView clickZoom={() => setOpen(true)} />
          </div>
          <div className="flex gap-4 ">
            {pdfContext.currentPage > 1 && (
              <button
                className="border-2 border-neutrals-grey-cc bg-neutrals-white flex-1 text-gray-700 font-semibold py-3  rounded-lg shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75"
                style={{
                  color: color.majorRed,
                  backgroundColor: color.redLight,
                }}
                onClick={pdfContext.prePage}
              >
                {props.prePageText}
              </button>
            )}
            {pdfContext.currentPage < pdfContext.total && (
              <button
                className="border-2 border-neutrals-grey-cc bg-neutrals-white flex-1 text-gray-700 font-semibold py-3  rounded-lg shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75"
                style={{
                  color: color.majorRed,
                  backgroundColor: color.redLight,
                }}
                onClick={pdfContext.nextPage}
              >
                {props.nextPageText}
              </button>
            )}
            {pdfContext.currentPage === pdfContext.total && (
              <button
                className="bg-red-500 flex-1 text-white font-semibold py-3  rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
                onClick={(event) => {
                  pdfContext.nextPage();
                  props.onFinish?.();
                }}
              >
                {props.endPageText}
              </button>
            )}
          </div>
        </div>
      )}
      <Dialog
        className="dialog-full"
        PaperProps={{
          style: {
            margin: 0,
          },
        }}
        open={open}
      >
        <PdfFullView
          clickZoom={() => {
            setOpen(false);
          }}
        />
      </Dialog>
    </PdfViewContext.Provider>
  );
};
