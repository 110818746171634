import React, { FC } from "react";
import { useStyles } from "./styles/item-card.styles";

type ItemCardProps = {
  item: any;
  image: any;
  name: string;
  caption?: string;
  labels?: string[];
  selected?: boolean;
  selectOrder?: number;
  disableImage?: boolean;
  disableText?: boolean;
  disableSelect?: boolean;
  handleSelect?: (item: any, selectOrder: number) => void;
};

const ItemCard: FC<ItemCardProps> = ({
  item,
  image,
  name,
  caption,
  labels,
  selected,
  selectOrder,
  disableImage,
  disableText,
  disableSelect,
  handleSelect,
}) => {
  const classes = useStyles();

  return (
    <div
      className={`
        ${
          disableImage
            ? classes.textOnlyContainer
            : disableText
            ? classes.imgOnlyContainer
            : classes.container
        }
        ${disableSelect || !selected ? classes.unselected : classes.selected}
        ${disableSelect ? classes.pointerEventsNone : ""}
      `}
      onClick={() => {
        if (!disableSelect && handleSelect && selectOrder !== undefined) {
          handleSelect(item, selectOrder);
        }
      }}
    >
      <div className={`${classes.contentContainer} ${selected ? classes.borderCompensator : ""}`}>
        {!disableImage && (
          <div className={!disableText ? classes.imgContainer : ""}>
            {image ? <img src={image} alt="" width={84} className={classes.img} /> : <></>}
          </div>
        )}
        {!disableText && (
          <div className={!disableImage ? classes.textContainer : ""}>
            <div className={classes.title}>{name}</div>
            {caption && <div className={classes.caption}>{caption}</div>}
            {labels && (
              <div className={classes.labelContainer}>
                {labels.map((label) => (
                  <span className={classes.label}>{label}</span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {!disableSelect && selected && selectOrder !== undefined && (
        <div className={classes.order}>
          <span className={classes.orderFont}>{selectOrder + 1}</span>
        </div>
      )}
    </div>
  );
};

export default ItemCard;
