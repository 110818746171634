import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

export type DemoState = {
  title: string;
  description: string;
};

export const demoPersistConfig = {
  key: "demo",
  storage,
};

const initialState: DemoState = {
  title: "",
  description: "",
};

type DemoAction = {
  payload: string;
};

export const demoSlice = createSlice({
  name: "demo",
  initialState,
  reducers: {
    updateDemoTitle: (state: DemoState, action: DemoAction) => {
      state.title = action.payload;
    },
    reset: (state: DemoState) => {
      state = initialState;
    },
  },
});

export const { updateDemoTitle, reset } = demoSlice.actions;
