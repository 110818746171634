import { BehaviorSubject, forkJoin, from, concat } from "rxjs";

export class FileUploaderService {
  tasks: (() => Promise<any>)[];
  onUpload = new BehaviorSubject<Boolean>(false);
  constructor() {
    this.tasks = [];
  }
  addTasks(task: (() => Promise<any>)[]) {
    this.onUpload.next(true);
    this.tasks.push(...task);
    forkJoin(
      task.map((t) => {
        return from(t());
      }),
    ).subscribe((v) => {
      this.tasks.splice(0, v.length);
      if (this.tasks.length !== 0) return;
      this.onUpload.next(false);
    });
  }
}
