import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { ONBOARDING_PATH, ROOT_PATH } from "../../../../routes/constants";
import { styled } from "@mui/material/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, useNavigation } from "react-router-dom";
import { useQuery } from "../../../../utils/hooks/use-query";
import { getTodoTasks } from "../../network/network";
import { get } from "lodash";
import { OnboardingState } from "../../redux/onboarding-slice";
import { useSelector } from "react-redux";
import { TakeUIClickEventParameters } from "../../../../common/ga/types/ga-general-type";
import { takeUIClickEvent } from "../../../../common/ga/ga";

type Props = {
  applicationId: string;
};

export const PendingFollowUpBanner = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const followUpTasks = useSelector(
    (state: { onboarding: OnboardingState }) => state.onboarding.followUpTasks,
  );
  return (
    <>
      {!!followUpTasks.totalNumbers && (
        <div
          onClick={() => {
            const gaData: TakeUIClickEventParameters = {
              module: "Recruitment",
              feature: "ApplicationForm",
              journey: "edit_application_form",
              stage: "view_follow_up",
              screen_id: "SCR_RCRTMT_APPLCTN_MN",
              screen_name: "Recruitment-Application-Menu-Screen",
              object_name: "Pending-Follow-up-Banner",
              object_type: "BUTTON",
            };
            takeUIClickEvent(gaData);
            navigate(`${ROOT_PATH}/${ONBOARDING_PATH}/follow-up?${query.toString()}`, {
              state: {
                applicationId: props.applicationId,
              },
            });
          }}
          className="py-2.5 px-4 flex justify-between	items-center"
          style={{
            backgroundColor: "#FF872E",
          }}
        >
          <span className="text-sm text-white	font-medium mr-auto">
            {t("onboarding.pending_follow_up")}
          </span>
          <NavigateNextIcon className="text-white" />
          <StyledBadge badgeContent={followUpTasks.totalNumbers} color="info"></StyledBadge>
        </div>
      )}
    </>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 40,
    top: 0,
    backgroundColor: "white",
    color: "#FF872E",
    fontSize: "14px",
    fontWeight: 500,
    padding: "0 4px",
  },
}));
