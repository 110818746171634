import React from "react";
import type { RouteObject } from "react-router-dom";
import AgentProfileEditMainPage from "./pages/profile-main/AgentProfileEditMainPage";
import AwardViewMorePage from "./pages/award-view-more/AwardViewMorePage";
import ErrorPage from "./pages/error-screen/ErrorPage";

export const routesAgentProfile: RouteObject[] = [
  { path: "profile-home", index: true, element: <AgentProfileEditMainPage /> },
  { path: "award-view-more", element: <AwardViewMorePage /> },
  // { path: "error", element: <ErrorPage /> },
];
