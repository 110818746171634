import { ArrowBack } from "@mui/icons-material";
import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface Props {
  title?: string;
  onBack?: Function;
  rightAnchor?: React.ReactElement;
}
export const HeaderComponent: React.FC<Props> = ({ title, onBack, rightAnchor }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goBack = useCallback(() => (onBack ? onBack() : navigate(-1)), [onBack]);
  return (
    <div className="flex justify-start p-3">
      <ArrowBack onClick={goBack} />
      <div className="flex-1">
        <span className="text-xl text-gray-800 font-semibold font-sans line-height-28 pl-3">
          {title}
        </span>
      </div>
      {rightAnchor}
    </div>
  );
};
