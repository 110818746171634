import * as React from "react";
import { makeStyles } from "@mui/styles";
import * as commonStyle from "../../../modules/onboarding/components/surveyjs/survey/common.style";
import ConfirmButtonGroup from "../../buttons/confirm-button-group";
import { useGlobalModal } from "../../use-modal/use-modal";
import { Modal } from "../../use-modal/modal.type";
import { useTranslation } from "react-i18next";
import { FormControl, FormControlLabel, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { color } from "../../../mui-theme/palette";
import Icon from "../../icon/icon";
import { SvgAssets } from "../../icon/assets";

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

interface ConfirmModalViewBaseProps {
  title?: string;
  icon?: React.ReactElement | keyof typeof SvgAssets;
  message?: string | React.ReactElement;
  messageComponent?: React.ReactElement;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  close?: () => void;
  confirmationCheckboxLabel?: string;
  maxHeight?: string;
  hideCancelButton?: boolean;
}

type ConfirmModalViewProps = RequireAtLeastOne<
  ConfirmModalViewBaseProps,
  "message" | "messageComponent"
>;

const useStyles = makeStyles({});
const ConfirmModalView: React.FC<ConfirmModalViewProps> = ({
  title,
  icon,
  message = "",
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  close,
  confirmationCheckboxLabel,
  maxHeight,
  hideCancelButton,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isConfirmed, setConfirmState] = React.useState(confirmationCheckboxLabel ? false : true);
  const iconEle = React.useMemo(() => {
    if (typeof icon === "string") {
      return <Icon svg={(SvgAssets as any)[icon]} width={80} height={80} />;
    }
    return icon;
  }, [icon]);
  return (
    <div className="flex flex-col pb-4 px-4 py-5" style={{ maxHeight }}>
      <div className="flex flex-1 justify-center items-center">{iconEle}</div>
      {title ? (
        <div className="flex flex-1 justify-center items-center">
          <span className="text-center text-grey-33 font-sf-pro-text text-2xl font-medium leading-snug pt-5">
            {title}
          </span>
        </div>
      ) : null}
      <div className="flex flex-1 justify-center items-center">
        <span
          className={`flex-1 ${
            title ? "" : "text-center"
          } text-grey-33 font-sf-pro-text text-sm font-medium leading-snug py-5 px-2`}
          style={{ textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: typeof message === "string" ? message : "" }}
        ></span>
      </div>
      {confirmationCheckboxLabel ? (
        <div
          className="flex flex-row items-start mb-5"
          onClick={() => setConfirmState((pre) => !pre)}
        >
          {isConfirmed ? (
            <CheckBoxIcon className="text-red-600" fontSize="small" />
          ) : (
            <CheckBoxOutlineBlankIcon fontSize="small" className="text-gray-400" />
          )}
          <p className="flex-1 font-sans text-gray-400 text-xs font-medium pl-3">
            {confirmationCheckboxLabel}
          </p>
        </div>
      ) : null}
      <ConfirmButtonGroup
        disableConfirm={!isConfirmed}
        confirmButtonText={confirmButtonText ?? t("global.text.confirm")}
        cancelButtonText={hideCancelButton ? "" : cancelButtonText ?? t("app.button.cancel")}
        onConfirm={() => {
          const result: any = onConfirm?.();
          if (!result?.keepAlive) {
            close?.();
          }
        }}
        onCancel={() => {
          const result: any = onCancel?.();
          if (!result?.keepAlive) {
            close?.();
          }
        }}
        // orientation="horizontal"
      />
    </div>
  );
};
export const confirm = (props: ConfirmModalViewProps, openProps?: Modal) => {
  return new Promise<{ ok: boolean }>((resolve, reject) => {
    useGlobalModal().open(
      <ConfirmModalView
        {...props}
        onConfirm={() => {
          const res = props.onConfirm?.();
          resolve({ ok: true });
          return res;
        }}
        onCancel={() => {
          const res = props.onCancel?.();
          resolve({ ok: false });
          return res;
        }}
      />,
      {
        showHeader: false,
        ...openProps,
      },
    );
  });
};
