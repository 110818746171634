export const styles = {
  contain: {},
  booleanItemLeft: {
    flex: 1,
    borderRadius: "12px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    marginRight: 0,
    marginLeft: 0,
  },
  booleanItemRight: {
    flex: 1,
    borderRadius: "12px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    marginLeft: 0,
    marginRight: 0,
  },
  defaultButton: {
    borderRadius: "12px",
    border: "2px solid  #CCC",
    color: "#333",
  },
  selectedBg: {
    background: "#FFE4E6",
  },
  defaultBg: {
    background: "#FFF",
  },
  selectedButton: {
    borderRadius: "12px",
    background: "#FFE4E6",
    color: "#E8192C",
    border: "none",
  },

  documentContain: {
    alignItems: "center",
    margin: "0 16px",
    backgroundColor: "#f5f5f5",
    padding: "8px 8px",
    flexFlow: "wrap",
    borderRadius: 12,
    overflow: "auto",
  },
  documentItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px",
  },
  documentImgContain: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    maxWidth: window.innerWidth - 120,
  },
  img: {
    width: `${30}px`,
    height: `${30}px`,
    color: "#666",
  },
};
