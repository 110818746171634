import React from "react";
import { ReactComponent as Notice } from "./notice.svg";
import { ReactComponent as Success } from "./success.svg";
import { ReactComponent as Failure } from "./failure.svg";
import { ReactComponent as HeaderBg } from "./header_bg.svg";
import { ReactComponent as Expired } from "./expired.svg";
import { ReactComponent as EditNote } from "./edit-note.svg";
import { ReactComponent as InProgressArrowRight } from "./in-progress-arrow-right.svg";
import { ReactComponent as Congrats } from "./congrats.svg";
import { ReactComponent as FailFeedback } from "./fail-feedback.svg";
import { ReactComponent as ToastSuccess } from "./toast_success.svg";
import { ReactComponent as Customer } from "./customer.svg";
import { ReactComponent as Date } from "./date.svg";
import { ReactComponent as TimeRed } from "./time-red.svg";
import { ReactComponent as TimeGray } from "./time-gray.svg";
import { ReactComponent as TimeGrey66 } from "./time-grey66.svg";
import { ReactComponent as LocationRed } from "./location-red.svg";
import { ReactComponent as LocationGray } from "./location-gray.svg";
import { ReactComponent as LocationGrey66 } from "./location-grey66.svg";
import { ReactComponent as Arrow } from "./arrow-down.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as PRUdnaLogo } from "./prudna_logo.svg";
import { ReactComponent as Error } from "./error.svg";
import { ReactComponent as AlertCircle } from "./alert-circle.svg";
import { ReactComponent as Withdraw } from "./withdraw.svg";
import { ReactComponent as Quota } from "./quota.svg";
import { ReactComponent as QuotaSmall } from "./quota-small.svg";
import { ReactComponent as previewClose } from "./preview-close.svg";
import { ReactComponent as previewOpen } from "./preview-open.svg";
import { ReactComponent as prudentialLogo } from "./prudential-logo.svg";

export const SvgAssets = {
  notice: Notice,
  success: Success,
  failure: Failure,
  headerBg: HeaderBg,
  expired: Expired,
  editNote: EditNote,
  inProgressArrowRight: InProgressArrowRight,
  congrats: Congrats,
  failFeedback: FailFeedback,
  toastSuccess: ToastSuccess,
  customer: Customer,
  time: TimeRed,
  location: LocationRed,
  date: Date,
  timeGray: TimeGray,
  locationGray: LocationGray,
  arrow: Arrow,
  close: Close,
  download: Download,
  timeGrey66: TimeGrey66,
  locationGrey66: LocationGrey66,
  prudnaLogo: PRUdnaLogo,
  error: Error,
  alertCircle: AlertCircle,
  withdraw: Withdraw,
  quota: Quota,
  quotaSmall: QuotaSmall,
  previewClose: previewClose,
  previewOpen: previewOpen,
  prudentialLogo: prudentialLogo,
};
