import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import { SurveyQuestionElementBase, attachKey2click } from "survey-react-ui";
import { CustomQuestionFileModel } from "./question";
import { FileCard } from "./file-card";
import { StorageFileTypeEnum } from "../../../network/file-op-interface";
import { isArray } from "lodash";
import { Snackbar, styled } from "@mui/material";
import { MessageContent } from "./content-interface";
import { WebviewActionEnum } from "./action-enum";
import { v4 as uuidv4 } from "uuid";

export class SurveyQuestionFile extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.question.originResource = isArray(this.question.value) ? this.question.value.slice() : [];
    this.question.onFileChange.subscribe(() => {
      this.forceUpdate();
    });
    this.question.onFileError.subscribe((error) => {
      this.setState({ openSnackBar: true, errorMessage: error });
    });
    this.state = { openSnackBar: false };
    this.id = uuidv4();
  }
  public id: string | undefined = undefined;
  componentDidMount(): void {
    const handle = (e: Event) => {
      if (this.isDisplayMode) return;
      //@ts-ignore
      if (typeof e.data !== "string") {
        return;
      }
      //@ts-ignore
      const { action, data, name } = JSON.parse(e.data) as {
        action: string;
        name: string;
        data: MessageContent[];
      };
      if (action && action === WebviewActionEnum.uploadfile && name === this.id && data) {
        this.question.handleRNUploadfile(data);
      }
    };
    /**
     * for android
     */
    document.addEventListener("message", handle);
    /**
     * for ios
     */
    window.addEventListener("message", handle);
  }
  componentWillUnmount(): void {
    /**
     * clear file storage when unmount
     */
    this.question.files = [];
  }

  protected get question(): CustomQuestionFileModel {
    let isModuleReadOnly = false;

    if (this.questionBase.survey) {
      const activePage: any = this.questionBase.survey.currentPage;
      if (activePage) {
        isModuleReadOnly = activePage.readOnly;
      }
    }

    this.questionBase.readOnly = this.questionBase.readOnly || isModuleReadOnly;
    return this.questionBase as CustomQuestionFileModel;
  }

  protected renderElement(): JSX.Element {
    var fileInput: JSX.Element | null = null;
    fileInput = this.isDisplayMode ? (
      <input
        type="file"
        disabled={this.isDisplayMode || this.question.isUploading}
        className={
          !this.isDisplayMode
            ? this.question.cssClasses.fileInput
            : this.question.getReadOnlyFileCss()
        }
        id={this.question.inputId}
        ref={(input) => this.setControl(input)}
        style={!this.isDisplayMode ? {} : { color: "transparent" }}
        onChange={!this.isDisplayMode ? this.question.doChange : () => {}}
        multiple={this.question.allowMultiple}
        placeholder={this.question.title}
        accept={this.question.acceptedTypes}
      />
    ) : (
      <input
        type="file"
        disabled={this.isDisplayMode || this.question.isUploading}
        tabIndex={-1}
        className={
          !this.isDisplayMode
            ? this.question.cssClasses.fileInput
            : this.question.getReadOnlyFileCss()
        }
        id={this.question.inputId}
        ref={(input) => this.setControl(input)}
        style={!this.isDisplayMode ? {} : { color: "transparent" }}
        onChange={!this.isDisplayMode ? this.question.doChange : () => {}}
        aria-required={this.question.ariaRequired}
        aria-label={this.question.ariaLabel}
        aria-invalid={this.question.ariaInvalid}
        aria-describedby={this.question.ariaDescribedBy}
        multiple={this.question.allowMultiple}
        title={this.question.inputTitle}
        accept={this.question.acceptedTypes}
        capture={this.question.renderCapture as any}
      />
    );
    const fileContainer = this.renderFileContainer();
    return (
      <div>
        {fileInput}
        <div
          onDrop={this.question.onDrop}
          onDragOver={this.question.onDragOver}
          onDragLeave={this.question.onDragLeave}
          onDragEnter={this.question.onDragEnter}
        >
          {fileContainer}
        </div>
        <Snackbar
          open={this.state.openSnackBar}
          onClick={() => this.setState({ openSnackBar: false })}
          ContentProps={{
            style: {
              borderRadius: 12,
              maxWidth: "100%",
              minWidth: 0,
            },
          }}
          message={
            <span className="break-words whitespace-pre-wrap max-w-[calc(100vw-48px)] block text-center">
              {this.state.errorMessage}
            </span>
          }
          autoHideDuration={4000}
          onClose={() => this.setState({ openSnackBar: false })}
          sx={{ height: "100%" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        ></Snackbar>
      </div>
    );
  }

  protected renderFileContainer(): JSX.Element {
    const preview = this.renderPreview();
    let noFileChosen;
    if (this.question.isEmpty()) {
      noFileChosen = (
        <span className={this.question.cssClasses.noFileChosen}>
          {this.question.noFileChosenCaption}
        </span>
      );
    }
    const disableAdd = (this.question.value?.length ?? 0) >= this.question.maxFileNumber;
    const chooseFile =
      this.question.readOnly || disableAdd
        ? null
        : attachKey2click(
            <label
              style={{
                width: this.question.imageWidth ?? 80,
                height: this.question.imageHeight ?? 80,
                padding: 24,
                borderRadius: 8,
                border: "1px dashed var(--neutrals-grey-99, #999)",
              }}
              htmlFor={this.question.inputId}
              aria-label={this.question.chooseButtonCaption}
              onClick={(e) => {
                if (window.ReactNativeWebView && this.question.takePhotoWithWatermark) {
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      action: WebviewActionEnum.uploadfile,
                      payload: {
                        allowMultiple: this.question.allowMultiple,
                        acceptedTypes: this.question.acceptedTypes.split(",").map((d) => d.trim()),
                        sourceType: "base64",
                        takePhotoWithWatermark: this.question.takePhotoWithWatermark,
                        name: this.id,
                      },
                    }),
                  );
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              <AddIcon
                style={{
                  width: 30,
                  height: 30,
                }}
              ></AddIcon>
            </label>,
          );

    return (
      <div
        style={{
          backgroundColor: "#f5f5f5",
          display: "flex",
          padding: "16px 8px",
          flexFlow: "wrap",
          minHeight: 80,
          gap: 10,
          borderRadius: 12,
        }}
      >
        {preview}
        {chooseFile}
      </div>
    );
  }
  protected renderPreview(): JSX.Element | null {
    let renderes: JSX.Element[] = [];
    if (this.question.originResource) {
      this.question.originResource.forEach((v: any) => {
        const render = (
          <FileCard
            key={v.content || v.resourceId}
            resourceId={this.question.storeDataAsText ? undefined : v.content || v.resourceId}
            base64={this.question.storeDataAsText ? v.content : undefined}
            fileName={v.name || v.fileName}
            mimeType={v.type || v.mimeType}
            questionModel={this.question}
            fileType={StorageFileTypeEnum.UPLOAD_DOCUMENT}
          ></FileCard>
        );
        renderes.push(render);
      });
    }
    this.question.files.forEach((file, index) => {
      const filesRender = (
        <FileCard
          key={file.uuid}
          file={file.content}
          fileName={file.content.name}
          mimeType={file.content.type}
          questionModel={this.question}
          fileType={StorageFileTypeEnum.UPLOAD_DOCUMENT}
        ></FileCard>
      );
      renderes.push(filesRender);
    });
    return <>{renderes}</>;
  }
}
