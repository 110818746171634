import { getI18n } from "react-i18next";
import { PruToast } from "../../../../components/pru-toast";
import { get } from "lodash";

export const enum OtpErrorCodeEnum {
  NOT_INVITED = "notInvited",
  UNABLE_TO_REGISTER = "unableToRegister",
  REACH_OTP_LIMITS_ATTEMPTS = "reachOtpLimits",
  REQUEST_TOO_FREQUENCY = "requestTooFrequency",
  REQUEST_OTP_TOO_MANY_TIMES = "requestTooManyTimes", // You have requested verification codes for too many times. Try again later.
  VALIDATE_OTP_TOO_MANY_TIMES = "validateTooManyTimes", // You have entered the incorrect verification codes for too many times. Try again later.
  REACH_MAXIMUM_ATTEMPTS = "reachMaximumAttempts",
  INVALID_OTP_STATUS = "invalidOtpStatus",
  INVALID_PHONE_NUMBER_OR_OTP_CODE = "invalidPhoneNumberOrOtpCode", // Incorrect phone number and/ or verification code
  INVALID_OTP_CODE = "invalidOtpCode", // Incorrect phone number and/ or verification code
  EXPIRED_OTP_CODE = "expiredOtpCode",
  INVALID_OTP_CONFIG = "otpConfigInvalid",
}

export const handlerError = (res?: any, code?: OtpErrorCodeEnum) => {
  const error = get(res, "response.data.errors", undefined) || code;
  if (!error) {
    return true;
  }
  const errorCode = code || (get(error, "[0].code", undefined) as OtpErrorCodeEnum | undefined);
  switch (errorCode) {
    case OtpErrorCodeEnum.REQUEST_TOO_FREQUENCY:
    case OtpErrorCodeEnum.REQUEST_OTP_TOO_MANY_TIMES:
      PruToast({
        message: getI18n().t("Recruitment.request_otp_too_many_times"),
        status: "error",
        duration: 3000,
      });
      break;
    case OtpErrorCodeEnum.VALIDATE_OTP_TOO_MANY_TIMES:
      PruToast({
        message: getI18n().t("Recruitment.validate_otp_too_many"),
        status: "error",
        duration: 3000,
      });
      break;
    case OtpErrorCodeEnum.INVALID_OTP_CODE:
      return getI18n().t("Common.incorrect_verification_code");
    case OtpErrorCodeEnum.INVALID_PHONE_NUMBER_OR_OTP_CODE:
      PruToast({
        message: getI18n().t("incorrect_phone_or_otp_code"),
        status: "error",
        duration: 3000,
      });
      break;
    case OtpErrorCodeEnum.NOT_INVITED:
      return getI18n().t("Recruitment.not_invited");
    case OtpErrorCodeEnum.UNABLE_TO_REGISTER:
      PruToast({
        message: getI18n().t("Recruitment.unable_to_register"),
        status: "error",
        duration: 3000,
      });
      break;
    case OtpErrorCodeEnum.REACH_OTP_LIMITS_ATTEMPTS:
      PruToast({
        message: getI18n().t("Recruitment.reach_otp_limits_attempts"),
        status: "error",
        duration: 3000,
      });
      break;
    case OtpErrorCodeEnum.REACH_MAXIMUM_ATTEMPTS:
      PruToast({
        message: getI18n().t("Recruitment.reach_maximum_attempts"),
        status: "error",
        duration: 3000,
      });
      break;
    case OtpErrorCodeEnum.INVALID_OTP_STATUS:
    case OtpErrorCodeEnum.EXPIRED_OTP_CODE:
      PruToast({
        message: getI18n().t("Recruitment.expired_verification_code"),
        status: "error",
        duration: 3000,
      });
      break;
    default:
      PruToast({
        message: getI18n().t("Common.server_error"),
        status: "error",
        duration: 3000,
      });
      break;
  }
  return false;
};
