import moment from "moment";
import { http } from "../../../utils/axios/axios";
import { GetResourceThunkArg, ResourceObject } from "../types/reource";
import { AxiosError } from "axios";
import qs from "qs";

// const PUBLIC_URL = `${process.env.REACT_APP_APIM_BASE_URL}${process.env.REACT_APP_APIM_PUBLIC}`;
export const fetchResourceDetail = async (
  param: GetResourceThunkArg,
): Promise<ResourceObject[]> => {
  const curTime = moment(new Date()).utc().format();
  const query = {
    locale: param.langkey,
    refId: param.refId,
    pagination: {
      limit: -1,
    },
    filters: {
      $or: [
        {
          effectiveDate: {
            $lte: curTime,
          },
          expiredDate: {
            $gte: curTime,
          },
        },
        {
          effectiveDate: {
            $lte: curTime,
          },
          expiredDate: {
            $null: true,
          },
        },
        {
          effectiveDate: {
            $null: true,
          },
          expiredDate: {
            $gte: curTime,
          },
        },
        {
          effectiveDate: {
            $null: true,
          },
          expiredDate: {
            $null: true,
          },
        },
      ],
    },
  } as any;
  return http
    .get<{ data: ResourceObject[] }>(
      `/content-delivery/material/${param.uuid}?${qs.stringify(query)}`,
      {
        headers: { __loading: true },
      },
    )
    .then((response) => response.data)
    .catch((err: AxiosError) => {
      throw err;
    });
};
