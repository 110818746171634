import { cloneDeep, get, omit, set, throttle } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { getItem, setItem } from "../local-storage";
import dayjs from "dayjs";
import ifvisible from "ifvisible";
import {
  AcceptTrackingKey,
  AcceptType,
  AutoTrackingClassName,
  AutoVideoTrackingClassName,
  TrackingData,
  TrackingInfo,
  TrackingEventType,
  AutoTrackingDataKey,
  TrackingSource,
  TncDeclined,
  DateFormat,
} from "./event-tracking.model";
import "../robot-check.browser";
import { getCookie } from "../cookie";
// import envConfig from "../../env-config";

/**
 * docs:
 * https://collaborate.pruconnect.net/display/PCAAEB/Customer+Event+Tracking
 * https://collaborate.pruconnect.net/display/PCAAEB/Anonymous+Experience+Service
 */
class EventTrackingService {
  enableTracking: () => boolean = () => false; // user don't accept tnc
  addAutoEventListener: boolean = false;
  refId: string = "";
  uuid: string = "";
  url: string = ""; // tracking api
  trackingData: TrackingData = {} as TrackingData;

  constructor() {
    this.uuid = this.userInfo();
    const userAgent = window.navigator.userAgent;
    // init context with fixed values
    this.trackingData.context = {
      src: TrackingSource,
      userAgent: userAgent,
      uuid: this.uuid,
      referrer: document.referrer ?? "",
    };
    const APIM_BASE_URL = get(window, "envConfig.REACT_APP_APIM_BASE_URL", "");
    const APIM_ANONYMOUS = get(window, "envConfig.REACT_APP_APIM_ANONYMOUS", "");
    this.url = `${APIM_BASE_URL}${APIM_ANONYMOUS}/tracking/events`;
    document.addEventListener("visibilitychange", this.handerVisibilityChange);
    const isOnIOS = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? "blur" : "beforeunload"; // iOS not fire event beforeunload, use blur instead
    const trigger = isOnIOS ? "blur" : "unload";
    window.addEventListener(eventName, (e) => this.leaveReport(trigger));
  }
  /** user info: only uuid for now */
  private userInfo(): string {
    let user_uuid: string | null = getItem("uuid_v4");
    if (!user_uuid) {
      user_uuid = uuidv4();
      setItem("uuid_v4", user_uuid);
    }
    return user_uuid;
  }
  /**
   * get session id by refId.
   * @param refId
   * @returns
   */
  private getSessionId(
    refId: string,
    target: string,
  ): {
    sessionId: string;
    isRenewed: boolean;
  } {
    let isRenewed = false;
    let sessionId: string | null = sessionStorage.getItem("session_id");
    const keyword = `${refId}_${target}_`;
    if (!sessionId || !sessionId.includes(keyword) || this.isSessionExpired(sessionId)) {
      sessionId = `${keyword}${dayjs().toISOString()}`;
      sessionStorage.setItem("session_id", sessionId);
      isRenewed = true;
    }
    return { sessionId, isRenewed };
  }
  /**
   * check if session time is longer than 10 minutes.
   * @param sessionId format: 8cwnMx__2023-02-01T09:58:17.856Z | 8cwnMx_15224450fe654666832f8afe15e45666_2023-02-01T09:58:17.856Z
   */
  private isSessionExpired(sessionId: string = "") {
    const index = sessionId.lastIndexOf("_");
    const dateString = sessionId.substring(index + 1);

    // check if (now - session_dateTime) >= 10
    const diff = dayjs().diff(dateString, "minute");
    if (diff >= 10) {
      return true;
    }
    return false;
  }
  /** base-screen will call this to set tracking-data for differ screens */
  setTrackingData(refId: string, data: TrackingData, fn: () => boolean) {
    this.refId = refId;
    let tmpTrackingData = { ...this.trackingData, ...data };
    set(tmpTrackingData, "params.startDatetime", Date.now());
    set(tmpTrackingData, "params.refId", refId);
    this.trackingData = tmpTrackingData;
    this.enableTracking = fn;
    /**
     * If page is visible send env_view on every 15 seconds
     * This method is smart and it will stop executing when the page is not active
     */
    ifvisible.onEvery(15, () => this.leaveReport("interval"));
  }
  /** tracking for screen show/hide */
  handerVisibilityChange = () => {
    if (window.document.hidden) {
      this.leaveReport("visibilitychange");
      // window.document.addEventListener("visibilitychange", this.handerVisibilityChange);
    } else {
      this.entryReport(false, "visibilitychange");
    }
  };
  /**
   * post tracking-data to server
   * @param trackingInfo trackingData
   * @param force force to do tracking or not. default false, if true: ignore the checking of enableTracking() for current page
   * @returns
   */
  doReport(trackingInfo?: TrackingInfo | undefined, force: boolean = false) {
    if (!force && !this.enableTracking()) {
      return;
    }
    // if (trackingInfo?.data?.target !== TncDeclined) {
    //   // except for "close-tnc tracking", others need to check
    //   if (!this.enableTracking()) {
    //     return;
    //   }
    // }
    const trackingData: TrackingInfo = trackingInfo || {
      subject: `anonymous:ref=${this.refId}`,
      data: this.trackingData,
    };

    const headers = {
      "content-type": "application/json",
      "robot-check": getCookie("robot-check") || "",
    };

    /*** debug-start */
    // const start = dayjs(trackingData.data?.params?.startDatetime).toISOString();
    // const end = dayjs(trackingData.data?.params?.endDatetime).toISOString();
    // const url = `http://192.168.19.210:3266/tracking/events?type=${trackingData.data?.type}&start=${start}&end=${end}&trigger=${trackingData.data?.params?.trigger}`;
    /*** debug-end */
    fetch(this.url, {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify(trackingData),
      keepalive: true,
    })
      .then()
      .catch();
  }

  /**
   * start_view tracking
   * @param force is force to add tracking, ignore session validation
   * @param trigger event name
   * @returns
   */
  entryReport(force: boolean = false, trigger: string = "") {
    if (!this.refId) {
      return;
    }
    // start_view should always use now() as startDatetime
    set(this.trackingData, "params.startDatetime", Date.now());

    const res = this.getSessionId(this.refId, this.trackingData.target || "");
    // set sessionId
    set(this.trackingData, "params.sessionId", res.sessionId);
    if (!force && res.isRenewed === false) {
      // NOTE: only add start_view tracking for new-session-id
      return;
    }
    this.trackingData.type = TrackingEventType.start_view;
    // delete endDatetime for start_view
    const dataWithoutEndDatetime = omit(this.trackingData, "params.endDatetime");
    set(dataWithoutEndDatetime, "params.trigger", trigger);
    const trackingData: TrackingInfo = {
      subject: `anonymous:ref=${this.refId}`,
      data: dataWithoutEndDatetime,
    };

    this.doReport(trackingData);
    // window.document.addEventListener("visibilitychange", this.handerVisibilityChange);
  }
  /**
   * end_view tracking
   * @param trigger event name
   * @returns
   */
  leaveReport(trigger: string = "") {
    if (!this.refId) {
      return;
    }
    const trackingDataCopy = cloneDeep(this.trackingData);

    const now = Date.now();
    // reset startDatetime, for end_view loop: ifvisible.onEvery()
    set(this.trackingData, "params.startDatetime", now);

    const startTime = get(trackingDataCopy, "params.startDatetime", 0);
    const duration = now - startTime;
    if (!startTime || duration < 1000) {
      return; //ignore tracking if stay-time < 1sec
    }
    set(trackingDataCopy, "params.endDatetime", now);
    set(trackingDataCopy, "params.trigger", trigger);
    set(trackingDataCopy, "params.duration", duration);
    trackingDataCopy.type = TrackingEventType.end_view;

    this.doReport({
      subject: `anonymous:ref=${this.refId}`,
      data: trackingDataCopy,
    });
    // remove listener
    // window.document.removeEventListener("visibilitychange", this.handerVisibilityChange);
  }
  // throttleLeave = throttle(() => this.doLeaveReport(), 3000, {
  //   leading: true,
  //   trailing: false,
  // });
  // leaveReport() {
  //   this.throttleLeave();
  // }
  /** user close tnc tracking */
  declineReport() {
    const trackingDataCopy = {
      ...this.trackingData,
      type: TrackingEventType.click,
      target: TncDeclined, // use close tnc
    };
    // decline time
    set(trackingDataCopy, "params.startDatetime", Date.now());
    // delete endDatetime
    const dataWithoutEndDatetime = omit(trackingDataCopy, "params.endDatetime");
    const trackingData: TrackingInfo = {
      subject: `anonymous:ref=${this.refId}`,
      data: dataWithoutEndDatetime,
    };

    this.doReport(trackingData);
    // window.document.addEventListener("visibilitychange", this.handerVisibilityChange);
  }

  /** click event auto-tracking */
  clickEventAutoTracking() {
    if (this.addAutoEventListener) {
      return;
    }
    this.addAutoEventListener = true; // only need to add-Listener once

    /**
     * click will trigger auto-tracking for elements as below example:
     * <a data-auto_click_tracking={JSON.stringify(trackingData)} className="auto_click_tracking">auto tracking element</a>
     */
    document.addEventListener("click", (e) => {
      const target = e.target as HTMLTextAreaElement;
      const isAutoTracking = get(target, "classList")?.contains(AutoTrackingClassName);

      if (isAutoTracking) {
        try {
          const rawData = JSON.parse(target.getAttribute(AutoTrackingDataKey) || "");
          const trackingDataCopy = cloneDeep(this.trackingData);
          // delete endDatetime for click event
          const omitEndDatetime = omit(trackingDataCopy, "params.endDatetime");
          const dataTracking = { ...omitEndDatetime, ...rawData, type: TrackingEventType.click };
          set(dataTracking, "params.startDatetime", Date.now());
          set(dataTracking, "params.target", rawData.target); // use params to extend target field
          const trackingData: TrackingInfo = {
            subject: `anonymous:ref=${this.refId}`,
            data: dataTracking,
          };
          this.doReport(trackingData, true);
        } catch (error) {}
      }
      // NOTE: below code will break end_view data, comment it for now
      /*
      else if (classList.contains(AutoVideoTrackingClassName)) {
        try {
          const rawData = JSON.parse(target.getAttribute(AutoTrackingDataKey) || "");
          const trackingInfo: TrackingInfo = {
            subject: `anonymous:ref=${this.refId}`,
            data: undefined,
          };
          const now = Date.now();
          if (rawData.type === TrackingEventType.start_view) {
            // delete endDatetime for start video click event
            set(this.trackingData, "params.startDatetime", now);
            const omitEndDatetime = omit(this.trackingData, "params.endDatetime");
            const dataTracking = { ...omitEndDatetime, ...rawData };
            trackingInfo.data = dataTracking;
          } else {
            const startTime = get(this.trackingData, "params.startDatetime");
            if (!startTime) {
              return;
            }
            set(this.trackingData as object, "params.endDatetime", now);

            const dataTracking = { ...this.trackingData, ...rawData };
            trackingInfo.data = dataTracking;
          }
          this.doReport(trackingInfo);
        } catch (error) {}
      }
      */
    });
  }
}

export const eventTrackingService = new EventTrackingService();

/** save accept tnc time to local-storage */
export function saveAcceptTncTime(updatedAt: string | Date): void {
  let dateString = dayjs(updatedAt).format(DateFormat);
  if (dateString === "Invalid Date") {
    dateString = dayjs(Date.now()).format(DateFormat);
  }
  setItem(AcceptTrackingKey, dateString);
}
/** tnc is updated: updatedAt > saved-time */
export function isTncUpdated(updatedAt: string): boolean {
  const dateUpdatedAt = dayjs(updatedAt);
  const dateSaveAt = dayjs(getItem(AcceptTrackingKey));
  // console.log("dateUpdatedAt====",dateUpdatedAt)
  // console.log("dateSaveAt isValid===",dateSaveAt)

  if (!dateUpdatedAt.isValid()) {
    return false; // no update-time
  }

  if (!dateSaveAt.isValid()) {
    return true; // The user enters the page for the first time
  }

  // compare date: if there is a new tnc since last accept
  const isUpdated = dayjs(dateUpdatedAt.format(DateFormat)).isAfter(dateSaveAt);
  return isUpdated;
}
