import React, { useEffect, useState } from "react";
import { getFullRegionList } from "../../modules/common/network/network";

interface RegionPhoneProps {
  label?: string;
  region: string;
  setRegion: (code: string) => void;
  phoneNumber: string;
  setPhoneNumber: (number: string) => void;
}

interface Region {
  code: string;
  displayName: string;
}
export const RegionPhone: React.FC<RegionPhoneProps> = ({
  label,
  region,
  setRegion,
  phoneNumber,
  setPhoneNumber,
}) => {
  const [regions, setRegions] = useState<Region[]>([]);

  useEffect(() => {
    getFullRegionList()
      .then((regionConfigs: any) => {
        if (regionConfigs) {
          const regions = Object.values(regionConfigs).map((region: any) => ({
            code: region.code,
            displayName: region.displayName,
          }));
          setRegions(regions);
        } else {
          setRegions([
            {
              code: "+852",
              displayName: "",
            },
          ]);
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="mb-4">
      {label && (
        <label
          htmlFor="phone-number"
          className="block font-semibold text-gray-800 font-body text-sm leading-5 mb-1"
        >
          {label}
        </label>
      )}

      <div className="flex w-full border-b border-gray-300 focus:outline-none pb-2">
        <select
          id="country-code"
          className="border-none focus:outline-none mr-2"
          value={region}
          onChange={(e) => setRegion(e.target.value)}
        >
          {regions.map((region) => (
            <option key={`${region.code}-${region.displayName}`} value={region.code}>
              {`${region.code}`}
            </option>
          ))}
        </select>
        <input
          type="tel"
          id="phone-number"
          className="w-full border-none focus:outline-none pl-2"
          placeholder=""
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
      </div>
    </div>
  );
};
